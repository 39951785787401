import React from 'react';
import WalletInfoWaynance from './wallet.waynance.view';
import { useTranslation } from 'react-i18next';
import * as UserService from '../../../services/UserService';
import { showDialog } from '@arcaitprod/react-utils-components';
import {
  getWaynanceIdSelector,
  getWaynanceEmailSelector,
} from '../../../store/selectors/authSelector';
import { useSelector } from 'react-redux';

const WalletSettingContainer = () => {
  const { t } = useTranslation();
  const waynancecode = useSelector(getWaynanceIdSelector);
  const waynanceEmail = useSelector(getWaynanceEmailSelector);

  const initialValues = {
    waynanceCode: waynancecode || '',
    email: waynanceEmail || '',
  };

  const handleSubmit = async (values) => {
    try {
      const result = await UserService.updateWaynanceWallet(values);
      let title = '';
      let text = '';
      let icon = '';
      if (result && result === 'OK') {
        title = t('forms.wallet.waynance.waynanceCode');
        text = t('forms.wallet.waynance.success');
        icon = 'success';
      } else {
        title = 'Error';
        text = t('forms.wallet.waynance.error');
        icon = 'error';
      }

      showDialog(title, text, icon, false, 'OK', false);
    } catch (error) {
      //console.log('Error', error);
    }
  };

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-12 mb-4 d-flex justify-content-start align-items-center">
          <h3>{t('waynanceWallet')}</h3>
        </div>
      </div>
      <WalletInfoWaynance initialValues={initialValues} onSubmit={handleSubmit} />
    </>
  );
};

WalletSettingContainer.propTypes = {};

export default WalletSettingContainer;
