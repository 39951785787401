import React from 'react';
import { useTranslation } from 'react-i18next';
// import PropTypes from 'prop-types';
import {
  Title,
  Title2,
  Text,
  ImgBanner,
  SubTitle,
  IconOpt,
  SubTitleIcon,
  SubTitle01,
  Banner,
  ImgBanner02,
} from './desertAnts.styled';
import { useSelector } from 'react-redux';
import { getRegionSelector } from '../../../store/selectors/navSelector';
import ProductGrid from '../../others/products/productsGrid/productsGrid.view';
import SliderPagination from '../../utils/CustomPaginatedList/SliderPagination.view';

const AboutUs = (props) => {
  const { t, i18n } = useTranslation();
  const { addToShoppingCart, listRemarkableProducts, showMoreInfo, showPreview } = props;
  const language = i18n.language;
  const region = useSelector(getRegionSelector);

  return (
    <>
      <div className="row no-margin justify-content-center mt-4">
        <div className="col-12 col-md-12 px-md-5">
          <ImgBanner
            src={`/assets/themes/${process.env.REACT_APP_THEME}/images/Banner-DesertAnts.jpg`}
            alt=""
          />
        </div>
      </div>
      <div className="row no-margin mt-3 mt-md-5">
        <div className="col-12 col-md-12 col-lg-6 ps-md-5 mb-5">
          <Title>{t('pages.desertAnts.title')}</Title>
          {/* <SubTitle className="mb-3">{t('pages.desertAnts.subtitle')}</SubTitle> */}

          <Text className="pe-1  mb-3 mt-3">{t('pages.desertAnts.text01')}</Text>
          <Text className="pe-1  mb-3 mt-3">{t('pages.desertAnts.text02')}</Text>
          <Text className="pe-1  mb-3 mt-3">{t('pages.desertAnts.text05')}</Text>
          <Text className="pe-1  mb-3 mt-3">{t('pages.desertAnts.text06')}</Text>
        </div>
        <div className="col-12 col-md-12 col-lg-6 pe-md-5 mb-5">
          <ImgBanner
            src={`/assets/themes/${process.env.REACT_APP_THEME}/images/desert01.jpg`}
            alt=""
          />
        </div>
      </div>
      <div className="row no-margin justify-content-center mt-4">
        <div className="col-12 col-md-10 text-center mb-4">
          <Title2>{t('pages.desertAnts.title2')}</Title2>
        </div>
      </div>
      <div className="row no-margin justify-content-center mt-4">
        <div className="col-11 col-md-8 text-center">
          <div className="row no-margin">
            <div className="col-12 mb-4 d-flex  align-items-center">
              <IconOpt
                src={`/assets/themes/${process.env.REACT_APP_THEME}/images/Technology.png`}
                alt=""
              />
              <SubTitleIcon>{t('pages.desertAnts.op1')}</SubTitleIcon>
            </div>
          </div>
          <div className="row no-margin">
            <div className="col-12 mb-4 d-flex align-items-center">
              <IconOpt
                src={`/assets/themes/${process.env.REACT_APP_THEME}/images/Electricity.png`}
                alt=""
              />
              <SubTitleIcon>{t('pages.desertAnts.op2')}</SubTitleIcon>
            </div>
          </div>
          <div className="row no-margin">
            <div className="col-12 mb-4 d-flex align-items-center">
              <IconOpt
                src={`/assets/themes/${process.env.REACT_APP_THEME}/images/Momentum.png`}
                alt=""
              />
              <SubTitleIcon>{t('pages.desertAnts.op3')}</SubTitleIcon>
            </div>
          </div>
          <div className="row no-margin">
            <div className="col-12 mb-4 d-flex align-items-center">
              <IconOpt
                src={`/assets/themes/${process.env.REACT_APP_THEME}/images/People.png`}
                alt=""
              />
              <SubTitleIcon>{t('pages.desertAnts.op4')}</SubTitleIcon>
            </div>
          </div>
          <div className="row no-margin">
            <div className="col-12 mb-4 d-flex align-items-center">
              <IconOpt
                src={`/assets/themes/${process.env.REACT_APP_THEME}/images/Security.png`}
                alt=""
              />
              <SubTitleIcon>{t('pages.desertAnts.op5')}</SubTitleIcon>
            </div>
          </div>
          <div className="row no-margin">
            <div className="col-12 mb-4 d-flex align-items-center">
              <IconOpt
                src={`/assets/themes/${process.env.REACT_APP_THEME}/images/Comissions.png`}
                alt=""
              />
              <SubTitleIcon>{t('pages.desertAnts.op6')}</SubTitleIcon>
            </div>
          </div>
          <div className="row no-margin">
            <div className="col-12 mb-4 d-flex align-items-center">
              <IconOpt
                src={`/assets/themes/${process.env.REACT_APP_THEME}/images/Comissions2.png`}
                alt=""
              />
              <SubTitleIcon>{t('pages.desertAnts.op7')}</SubTitleIcon>
            </div>
          </div>
        </div>
      </div>
      <div className="row no-margin mt-3 mt-md-5">
        <div className="col-12 col-md-12 col-lg-12 ps-md-5 mb-5">
          <Title>{t('pages.desertAnts.title5')}</Title>
          {/* <SubTitle className="mb-3">{t('pages.desertAnts.subtitle')}</SubTitle> */}

          <Text className="pe-1  mb-3 mt-3">{t('pages.desertAnts.text08')}</Text>
          <Text className="pe-1  mb-3 mt-3">{t('pages.desertAnts.text09')}</Text>
          <Text className="pe-1  mb-3 mt-3">{t('pages.desertAnts.text10')}</Text>
          <Text className="pe-1  mb-3 mt-3">{t('pages.desertAnts.text11')}</Text>
        </div>
        {/* <div className="col-12 col-md-12 col-lg-6 pe-md-5 mb-5">
          <ImgBanner
            src={`/assets/themes/${process.env.REACT_APP_THEME}/images/desert01.jpg`}
            alt=""
          />
        </div> */}
      </div>
      <Banner className="row no-margin justify-content-center mt-4">
        <div className="col-5 d-flex justify-content-center align-items-center">
          <ImgBanner02
            src={`/assets/themes/${process.env.REACT_APP_THEME}/images/desert02.jpg`}
            alt=""
          />
        </div>
        <div className="col-5 ps-5">
          <div className="row">
            <div className="col-12 mt-5">
              <Title>{t('pages.desertAnts.title3')}</Title>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Text>{t('pages.desertAnts.txt03')}</Text>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Text>{t('pages.desertAnts.txt04')}</Text>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Text>{t('pages.desertAnts.txt07')}</Text>
            </div>
          </div>
        </div>
      </Banner>
      <div className="row no-margin justify-content-center mt-5">
        <div className="col-12 col-md-10 text-center mb-5">
          <Title2>{t('pages.desertAnts.title4')}</Title2>
        </div>
      </div>
      <div className="row justify-content-center no-margin mt-3 pb-5">
        <div className="col-12">
          <ProductGrid
            numItemPage={10}
            onListProduct={listRemarkableProducts}
            showMoreInfo={showMoreInfo}
            showPreview={showPreview}
            addToShoppingCart={addToShoppingCart}
            region={region}
            // isSlider
            // customPagination={<SliderPagination />}
            // hiddenPagination
          />
        </div>
      </div>
    </>
  );
};

AboutUs.propTypes = {};

export default AboutUs;
