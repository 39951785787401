import styled from 'styled-components';
import { Link } from '@arcaitprod/react-basic-components';

export const AddItemContainerStyled = styled.div`
  text-align: right;
`;

export const LinkAddItemStyled = styled(Link)`
  font-size: 1.5rem;
`;

export const FloatAddItem = styled(Link)`
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  z-index: 100;
  background-color: ${({ theme, variant }) =>
    theme ? theme.buttons[variant].backgroundColor : '#000'} !important;
  color: ${({ theme, variant }) => (theme ? theme.buttons[variant].color : '#fff')} !important;
  border-radius: 50px;
  text-align: center;
  box-shadow: 2px 2px 3px #5d5d5d;
  border: 0px;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    text-decoration: none !important;
    color: ${({ theme, variant }) =>
      theme ? theme.buttons[variant].colorHover : '#fff'} !important;
    background-color: ${({ theme, variant }) =>
      theme ? theme.buttons[variant].backgroundColorHover : '#000'} !important;
    border-color: ${({ theme, variant }) =>
      theme ? theme.buttons[variant].borderColorHover : '#000'} !important;
  }
`;

export const EmptyDataContainer = styled.div`
  min-height: 20vh;
  color: #b4b4b4;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 700;
  /* display: flex;
  justify-content: space-between;
  align-items: center; */
`;
