import styled from 'styled-components';

export const SpanMaxAmountStyled = styled.div`
  color: ${({ theme }) => theme.colors.terciary};
  font-size: 0.875rem;
`;

export const SpanExchangeAmountStyled = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 0.875rem;
  font-weight: bold;
`;

export const ErrorStyled = styled.div`
  color: #ff0000;
  margin-top: -15px;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.275rem 0.55rem;
  text-align: right;
  width: 100%;
  right: 10px;
  position: relative !important;
  font-size: 0.85rem !important;
`;

export const MaxAmount = styled.div`
  font-weight: bold;
  color: ${({ theme }) => theme.colors.terciary};
  font-size: 0.875rem;
`;

export const MaxAmount2 = styled.div`
  font-weight: bold;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 0.875rem;
  margin-left: 20px;
`;

export const ResumenContainer = styled.div`
  background-color: #fff;
  padding: 35px 35px;
  border-radius: 25px;
  -webkit-box-shadow: 10px 9px 28px -12px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 9px 28px -12px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 9px 28px -12px rgba(0, 0, 0, 0.75);
  border: 1px solid #00a79d;
`;

export const DataExchange = styled.div`
  font-weight: bold;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1.275rem;
`;
