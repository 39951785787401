import evolutionTheme from './evolution';
import defaultTheme from './default';

export const getTheme = (themeName = 'default') => {
  if (themeName === 'evolution') {
    return evolutionTheme;
  } else {
    return defaultTheme;
  }
};
