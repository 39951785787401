import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as CampaignServices from '../../../../services/CampaignService';
import { withTranslation } from 'react-i18next';
import {
  ImageItem,
  CampaignContainer,
  TitleCampaign,
  ProgressText,
  Status,
  ProgressBar,
  IconPerson,
  NumPerson,
} from './shockwaveList.styled';
import { AbilityContext } from '../../../permissions/Can';
import moment from 'moment-timezone';
import { InfiniteList } from '@arcaitprod/react-complex-components';
// import { USER_PERMISSIONS } from '../../../permissions/rolesPermission';
import ContentLoader from 'react-content-loader';
import { checkBootstrapSizeBreak } from '../../../../utils/device/checkDevice';
import { connect } from 'react-redux';
import { getTimeZoneSelector } from '../../../../store/selectors/navSelector';
import { Button } from '@arcaitprod/react-basic-components';
import ReactTooltip from 'react-tooltip';
import { withRouter } from 'react-router-dom';
import { CAMPAIGN_STATUS } from '../../../../constants/index';

class ShockwaveListContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      refreshData: false,
      refreshItem: null,
      itemReturn: null,
      breakDevice: checkBootstrapSizeBreak(),
    };
  }

  componentDidUpdate(prevState) {
    if (prevState.refreshData !== this.state.refreshData && this.state.refreshData === true) {
      this.resetRefreshData();
    }
  }

  resetRefreshData = () => {
    this.setState({ refreshData: false });
  };

  toList = async (filter) => {
    let result = await CampaignServices.listMyCampaign();
    const finalCampaign = [];
    if (result && result.length > 0) {
      for (const userCampaign of result) {
        const indexCampaign = finalCampaign.findIndex(
          (camp) => camp.uuid === userCampaign.campaign.uuid
        );
        if (indexCampaign !== -1) {
          finalCampaign[indexCampaign].packages.push(userCampaign);
        } else {
          const tempCampaign = { ...userCampaign.campaign };
          tempCampaign.packages = [userCampaign];
          finalCampaign.push(tempCampaign);
        }
      }
    }
    return { docs: finalCampaign && finalCampaign.length > 0 ? finalCampaign : [] };
  };

  renderMyCampaign = (campaign) => {
    const { t, i18n } = this.props;
    const status = CAMPAIGN_STATUS.find((sta) => sta.code === campaign.status);
    // const percentageProgress =
    //   (campaign.campaign.numParticipants * 100) / campaign.campaign.minParticipants;
    return (
      <div key={campaign.uuid} className="col-4 mt-4">
        <CampaignContainer>
          <div className="row mb-2">
            <div className="col-12">
              <TitleCampaign>{campaign.name}</TitleCampaign>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              {`${t('forms.shockwave.labels.status')}: `}
              <Status>{`${t(status.label)}`}</Status>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              {`${t('forms.shockwave.labels.myParticipants')}: `}
              <Status>{`${t(campaign.packages.length)}`}</Status>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12 text-end">
              <Status>{`${t('forms.shockwave.labels.moreInfo')} >> `}</Status>
            </div>
          </div>
          {/*<div className="col-6">
              <ImageItem
                src={campaign.package.imageUrl}
                alt={campaign.package.minifyData[i18n.language].title}
              />
            </div>
            <div className="col-6">
              <div className="row">
                <TitleCampaign className="col-12">
                  {campaign.package.minifyData[i18n.language].title}
                </TitleCampaign>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="progress">
                    <ProgressBar
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: `${percentageProgress}%` }}
                      aria-valuenow={campaign.campaign.numParticipants}
                      aria-valuemin="0"
                      aria-valuemax={campaign.campaign.minParticipants}
                    >
                      {`${parseInt(percentageProgress)}%`}
                    </ProgressBar>
                  </div>
                  <ProgressText>{`${campaign.campaign.numParticipants}/${
                    campaign.campaign.minParticipants
                  } (${parseInt(percentageProgress)}%)`}</ProgressText>
                </div>
              </div>
              <div className="row">
                <div className="col-12 d-flex align-items-center">
                  {`${t('forms.shockwave.labels.status')}: `}
                  <Status>{`${t(status.label)}`}</Status>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-12 d-flex align-items-center justify-content-center">
                  <IconPerson
                    className="fa-solid fa-user-plus"
                    title={`${t('forms.shockwave.labels.totalReferer')}`}
                  ></IconPerson>
                  <NumPerson>{`${campaign.numReferer}`}</NumPerson>
                </div>
              </div>
            </div>*/}
        </CampaignContainer>
      </div>
    );
  };

  goToDetail = (purchaseUuid) => {
    this.props.history.push(`/orders/detail/${purchaseUuid}`);
  };

  renderListLoader = () => {
    return (
      <div className="row">
        <div className="col-12 mt-4">
          <ContentLoader
            speed={2}
            viewBox="0 0 400 160"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            style={{ width: '100%' }}
          >
            <rect x="5" y="1" rx="3" ry="3" width="400" height="5" />
            <rect x="5" y="15" rx="3" ry="3" width="400" height="5" />
          </ContentLoader>
        </div>
      </div>
    );
  };

  render() {
    const { t, filters, className } = this.props;
    return (
      <>
        <div className="">
          <InfiniteList
            id="myCampaigns"
            renderItem={this.renderMyCampaign}
            toList={this.toList}
            filters={filters}
            variantTitle="primary"
            forceRefresh={this.state.refreshData}
            itemRefresh={this.state.refreshItem}
            endMessage={t('goToTop')}
            messageButtonMore={t('readMore')}
            variantButtonMore="simplePrimary"
            customClassName="row no-margin"
            idItem="uuid"
            renderLoader={this.renderListLoader}
          />
        </div>
      </>
    );
  }
}

ShockwaveListContainer.contextType = AbilityContext;

ShockwaveListContainer.propTypes = {
  t: PropTypes.any,
  filters: PropTypes.object,
  className: PropTypes.string,
  general: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  timeZone: getTimeZoneSelector(state),
});

export default withRouter(connect(mapStateToProps, {})(withTranslation()(ShockwaveListContainer)));
