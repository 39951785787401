import { store } from '../store/index';
import { filterOrder, getOrderByUuid } from '../store/actions/OrderActions';

/**
 * List Orders
 */
export const listFilter = async (filter) => {
  const result = await store.dispatch(filterOrder(filter));
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};

/**
 * List Orders
 */
export const getOrder = async (uuid) => {
  const result = await store.dispatch(getOrderByUuid([uuid]));
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};
