import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { getAuthTokenSelector, getUserPersonalInfoSelector } from '../store/selectors/authSelector';
import { getInfoSelector } from '../store/selectors/dashboardSelector';
import PATHS from '../constants/paths';
import queryString from 'query-string';
import * as NavService from '../services/NavService';

class AuthRoute extends Component {
  componentDidMount() {
    const query = queryString.parse(this.props.location.search);
    if (query && query.evoRef) {
      NavService.setEvolutionRef(query.evoRef);
    }
    if (query && query.campaign) {
      NavService.setCampaignRef(query.campaign);
    }
  }

  render() {
    const { token, infoUser, dashboardInfo, component: Component, ...rest } = this.props;

    return (
      <Route
        {...rest}
        render={(props) => {
          const pass = props?.location?.state?.pass ? props.location.state.pass : false;
          const path = props?.location?.pathname ? props.location.pathname : '';
          if (
            path !== PATHS.HOME &&
            path !== PATHS.SECURITY &&
            !pass &&
            token &&
            infoUser &&
            dashboardInfo &&
            infoUser.hasWallet === false &&
            (dashboardInfo.pendingInvest > 0 || dashboardInfo.totalPersonalInvest > 0)
          ) {
            return (
              <Redirect
                to={{
                  pathname: PATHS.WALLETS,
                  state: { pass: true },
                }}
              />
            );
          } else if (token) {
            return <Component {...props} />;
          } else {
            return <Redirect to={PATHS.INIT} />;
          }
        }}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  token: getAuthTokenSelector(state),
  infoUser: getUserPersonalInfoSelector(state),
  dashboardInfo: getInfoSelector(state),
});

export default connect(mapStateToProps, {})(AuthRoute);
