import styled from 'styled-components';
import { NormalInfoText } from './../wallet/wallet.styled';

export const DesertAnts = styled.div`
  /* background-image: ${() =>
    `url("/assets/themes/${process.env.REACT_APP_THEME}/images/home/Banner-DesertAnts.jpg")`};
  min-height: 300px; */
  /* min-height: 300px; */
`;
export const DesertAntImage = styled.img`
  width: 100%;
  min-height: 200px;
  object-fit: cover;
  /* max-height: 96.5%; */
`;

export const DesertAntTet = styled.div`
  background: transparent;
  position: absolute;
  top: 15%;
  left: 15%;
  /* min-height: 300px; */
`;

export const DesertAntText01 = styled.div`
  color: white;
  font-size: 2.6rem;
  font-weight: bolder;
  font-family: 'Biome Light';
  -webkit-letter-spacing: 1px;
  -moz-letter-spacing: 1px;
  -ms-letter-spacing: 1px;
  letter-spacing: 2px;
  line-height: 32px;
  /* min-height: 300px; */
`;

export const DesertAntText02 = styled.div`
  color: white;
  font-size: 2.6rem;
  font-weight: bold;
  font-family: 'Biome Light';
  line-height: 35px;
  letter-spacing: 2px;
  /* min-height: 300px; */
`;

export const DesertAntText03 = styled.div`
  color: white;
  font-size: 1.8rem;
  font-weight: bold;
  font-family: 'Biome Light';
  line-height: 25px;
  letter-spacing: 2px;
  /* min-height: 300px; */
`;

export const ButtonDesert = styled.button`
  border: 3px solid #ffc52b;
  margin-top: 10px;
  border-radius: 5px;
  padding: 7px 16px;
  background: #0000007a;
  color: #fff;
  font-family: Biome Light;
  font-weight: 600;
  letter-spacing: 1px;
  width: 170px;
`;

export const ShockText = styled.div`
  background: transparent;
  position: absolute;
  top: 15%;
  right: 10%;
  /* min-height: 300px; */
`;

export const ShockText01 = styled.div`
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
  font-family: 'Biome Light';
  line-height: 20px;
  -webkit-letter-spacing: 2px;
  -moz-letter-spacing: 2px;
  -ms-letter-spacing: 2px;
  letter-spacing: 2px;
  text-shadow: 5px 6px 6px rgba(0, 0, 0, 0.9);
`;

export const ShockText02 = styled.div`
  color: white;
  font-size: 0.6rem;
  font-weight: bold;
  font-family: 'Biome Light';
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: 12px;
  -webkit-letter-spacing: 2px;
  -moz-letter-spacing: 2px;
  -ms-letter-spacing: 2px;
  -webkit-letter-spacing: 2px;
  -moz-letter-spacing: 2px;
  -ms-letter-spacing: 2px;
  letter-spacing: 2px;
  text-shadow: 5px 6px 6px rgba(0, 0, 0, 0.9);
`;

export const ButtonShock = styled.button`
  border: 3px solid #ff035e;
  margin-top: 10px;
  border-radius: 5px;
  padding: 7px 16px;
  background: #0000007a;
  color: #fff;
  font-family: Biome Light;
  font-weight: 600;
  letter-spacing: 1px;
`;

export const ButtonOther = styled.button`
  border: 3px solid #04fff6;
  margin-top: 10px;
  border-radius: 5px;
  padding: 7px 16px;
  background: #0000007a;
  color: #fff;
  font-family: Biome Light;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
`;

export const OtherText = styled.div`
  background: transparent;
  position: absolute;
  bottom: 5%;
  left: 10%;
  /* min-height: 300px; */
`;

export const OtherText01 = styled.div`
  color: white;
  font-size: 1rem;
  font-weight: bold;
  font-family: 'Biome Light';
  line-height: 20px;
  -webkit-letter-spacing: 2px;
  -moz-letter-spacing: 2px;
  -ms-letter-spacing: 2px;
  letter-spacing: 2px;
  text-shadow: 5px 6px 6px rgba(0, 0, 0, 0.9);
`;

export const HomeImage3 = styled.img`
  width: 100%;
  height: 100%;
`;

export const TitleSection = styled.span`
  font-size: 1.6rem;
`;

export const SubTitleSection = styled.span`
  font-size: 0.9rem;
  color: #6c6c6c;
`;

export const PartnerImage = styled.img`
  height: 80px;
`;

export const VideoContainer = styled.div`
  /* min-height: 50vh; */
  /* padding-top: 8vh; */
  background: ${() =>
    `url('/assets/themes/${process.env.REACT_APP_THEME}/images/Nosotros__Banner_4.webp')`};
  background-position: top 40% center;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const Backdrop = styled.div`
  /* min-height: 50vh; */
  /* padding-top: 8vh; */
  background: rgba(63, 6, 66, 0.5);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 20px 30px 20px;
`;

export const Text01 = styled.div`
  font-size: 1rem;
  color: #fff;
  text-align: center;
`;

export const Text02 = styled.div`
  font-size: 1.3rem;
  color: #fff;
  font-weight: normal;
  margin-bottom: 15px;
  text-align: center;
`;

export const ButtonVideo = styled.button`
  font-size: 1.2rem;
  color: #fff;
  border: 1px solid #fff;
  background: transparent;
  border-radius: 18px;
  padding: 5px 60px;
  text-transform: uppercase;
`;

export const BusinessContainer = styled.div`
  background: ${() =>
    `url('/assets/themes/${process.env.REACT_APP_THEME}/images/Banner_Plan.jpg')`};
  background-position: top 40% center;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const BackdropBusiness = styled.div`
  background: transparent; // rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 20px 30px 20px;
`;
