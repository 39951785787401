import React from 'react';
import Header from '../commons/header/SimpleHeader';
import {
  BodyContainer,
  LinkTerms,
  PageContent,
  SectionBanner,
  BannerText,
  ImgBannerCenter,
} from '../simpleLayout/styled';
import { useTranslation } from 'react-i18next';
import ShoppingCartNav from '../commons/shoppingCartNav';
import Nav from '../commons/nav';
import Footer from '../commons/footer';
import { checkBootstrapSizeBreak } from '../../../utils/device/checkDevice';
import SubNav from '../commons/subNav';

const SimpleLayout = ({ ...prop }) => {
  const { showBanner, bannerName, textBanner, bannerPosition, imgBannerCenter, showShoppingCart } =
    prop;
  const { t } = useTranslation();
  const breakSizeDevice = checkBootstrapSizeBreak();

  return (
    <div id="sb-site">
      <BodyContainer className="container-fluid" id="page-wrapper">
        <div className="sticky-top">
          <Header showUserMenu={prop.showUserMenu !== undefined ? prop.showUserMenu : undefined} />
          {/* <Nav></Nav> */}
          {breakSizeDevice < 3 && <Nav breakDevice={breakSizeDevice}></Nav>}
        </div>
        {showBanner && (
          <SectionBanner
            breakSizeDevice={breakSizeDevice}
            bannerName={bannerName}
            bannerPosition={bannerPosition}
          >
            {imgBannerCenter ? (
              <ImgBannerCenter
                src={`/assets/themes/${process.env.REACT_APP_THEME}/images/${imgBannerCenter}`}
                alt="Ignite"
              />
            ) : (
              <BannerText>{textBanner ? t(`banners.${textBanner}`) : ''}</BannerText>
            )}
          </SectionBanner>
        )}
        {showShoppingCart && <SubNav />}

        <div id="page-content-wrapper">
          {/* {!prop.hiddenButtonShoppingCart && <ShoppingCartNav />} */}

          <PageContent id="page-content">{prop.body}</PageContent>
        </div>
      </BodyContainer>
      <Footer></Footer>
      {/* <Footer>
        <div>
          <LinkTerms variant="primary" to={urlPolicy} target="_blank">
            {t('policy')}
          </LinkTerms>
          <LinkTerms variant="primary" to={urlLegal} target="_blank">
            {t('legal')}
          </LinkTerms>
        </div>
        <div>{t('reserved')}</div>
      </Footer> */}
    </div>
  );
};

export default SimpleLayout;
