import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import * as AuthService from '../../../../../../services/AuthService';
import * as NavService from '../../../../../../services/NavService';
import PATHS from '../../../../../../constants/paths';
import { Button } from '@arcaitprod/react-basic-components';
import Menu, { Item as MenuItem, SubMenu } from 'rc-menu';
import 'rc-menu/assets/index.css';
import {
  IconStyled,
  IconUser,
  IconArrowLogin,
  LabelLogin,
  LoginButton,
  ImgIconStyled,
} from './styled';
import { showDialog } from '@arcaitprod/react-utils-components';
import { Can, AbilityContext } from '../../../../../permissions/Can';
import { USER_PERMISSIONS } from '../../../../../permissions/rolesPermission';
import { checkBootstrapSizeBreak } from '../../../../../../utils/device/checkDevice';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class UserMenuHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false,
    };
  }

  switchShowMenu = () => {
    this.setState((prevState) => ({
      showMenu: !prevState.showMenu,
    }));
  };

  onClick = async (info) => {
    const { key } = info;
    const { nickname, t, urlSponsor } = this.props;
    this.switchShowMenu();

    switch (key) {
      case 'home':
        this.props.history.push(PATHS.HOME);
        break;
      case 'settings':
        this.props.history.push(PATHS.MY_SETTINGS);
        break;
      case 'unilevelTree':
        if (nickname) {
          this.props.history.push(PATHS.UNI_TREENODES);
        } else {
          showDialog(
            t('unilevel'),
            t('nickname_required'),
            'warning',
            false,
            'OK',
            false,
            undefined,
            undefined,
            undefined,
            '#e10a41',
            '#272727'
          );
        }
        break;
      case 'cashflow':
        this.props.history.push(PATHS.MY_CASHFLOW_LIST);
        break;
      case 'doc':
        this.props.history.push(PATHS.DOCS_LIST);
        break;
      case 'membership':
        this.props.history.push(PATHS.REQUEST_MEMBERSHIP);
        break;
      case 'subscriptions':
        this.props.history.push(PATHS.MY_SUBSCRIPTIONS_LIST);
        break;
      case 'dashboard':
        this.props.history.push(PATHS.MY_DASHBOARD);
        break;
      case 'notifications':
        this.props.history.push(PATHS.NOTIFICATION_LIST);
        break;
      case 'shockwave':
        this.props.history.push(PATHS.SHOCKWAVE);
        break;
      case 'myOrder':
        this.props.history.push(PATHS.MY_ORDER_LIST);
        break;
      case 'ignite':
        this.props.history.push(PATHS.IGNITE);
        break;
      case 'smartnet':
        window.open(`${process.env.REACT_APP_URL_HOME_SMARTNET}`, '_blank');
        break;
      case 'commissions':
        this.props.history.push(PATHS.MY_COMMISSIONS_LIST);
        break;
      case 'desertAnt':
        this.props.history.push(PATHS.DESERT_ANTS);
        break;
      case 'myDesertAnt':
        this.props.history.push(PATHS.MY_DESERT_ANTS);
        break;
      case 'wallets':
        this.props.history.push(PATHS.WALLETS);
        break;
      case 'freeCourse':
        this.props.history.push(PATHS.FREE_COURSE);
        break;

      case 'myLink':
        navigator.clipboard.writeText(urlSponsor.url);
        toast(t('linkCopied'), {
          position: 'top-right',
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
        break;

      case 'close_session':
        localStorage.removeItem('evoToken');
        await AuthService.logout();
        await NavService.setCleanDashboard();
        this.props.history.push(PATHS.HOME);
        break;
      default:
        break;
    }
  };

  render() {
    const { t } = this.props;
    const breakDevice = checkBootstrapSizeBreak();
    return (
      <>
        <div className="buttonMenu2">
          <LoginButton type="button" variant="trasparent" onClick={() => this.switchShowMenu()}>
            {/* <i className="fas fa-bars" /> */}
            <IconUser className="fa-solid fa-user"></IconUser>
            {breakDevice >= 2 ? <LabelLogin>{this.props.t('myAccount')}</LabelLogin> : ''}
            <IconArrowLogin className="fa-solid fa-angle-down"></IconArrowLogin>
          </LoginButton>
        </div>
        <CSSTransition
          in={this.state.showMenu}
          timeout={400}
          classNames="menu-transition"
          unmountOnExit
          appear
        >
          <div className="menuContainer">
            <Menu mode="inline" onClick={this.onClick}>
              <MenuItem key="home">
                <IconStyled className="fa-solid fa-cart-shopping" />
                {t('home')}
              </MenuItem>
              {this.context.can(USER_PERMISSIONS.DASHBOARD) && (
                <MenuItem key="dashboard">
                  <IconStyled className="fa-solid fa-gauge" />
                  {t('dashboardMenu')}
                </MenuItem>
              )}
              {!this.context.can(USER_PERMISSIONS.IS_MEMBER) && (
                <MenuItem key="membership">
                  <IconStyled className="fa-solid fa-people-group" />
                  {t('membership')}
                </MenuItem>
              )}
              {this.context.can(USER_PERMISSIONS.TREE_VIEW) && (
                <MenuItem key="unilevelTree">
                  <IconStyled className="fas fa-project-diagram" />
                  {t('user_tree')}
                </MenuItem>
              )}
              {/* {this.context.can(USER_PERMISSIONS.IS_MEMBER) && ( */}
              <MenuItem key="desertAnt">
                <ImgIconStyled
                  loading="lazy"
                  src={`/assets/themes/${process.env.REACT_APP_THEME}/images/categories/Mineria_b.png`}
                  alt=""
                />
                {t('desertAnt')}
              </MenuItem>
              <MenuItem key="myDesertAnt" className="subMenuItem">{`-  ${t(
                'myDesertAnt'
              )}`}</MenuItem>
              {/* )} */}
              {/* )} */}
              {/* <MenuItem key="ignite">
                <ImgIconStyled
                  loading="lazy"
                  src={`/assets/themes/${process.env.REACT_APP_THEME}/images/ignite.png`}
                  alt=""
                />
                {t('ignite')}
              </MenuItem> */}
              {/* <MenuItem key="shockwave">
                <ImgIconStyled
                  loading="lazy"
                  src={`/assets/themes/${process.env.REACT_APP_THEME}/images/shockwave.png`}
                  alt=""
                />
                {t('shockwave')}
              </MenuItem> */}
              {/* <MenuItem key="subscriptions">
                <IconStyled className="fa-regular fa-rectangle-list" />
                {t('mySubscriptions')}
              </MenuItem> */}
              {/* <MenuItem key="notifications">
                <IconStyled className="fas fa-bell" />
                {t('myNotifications')}
              </MenuItem> */}
              {/* {this.context.can(USER_PERMISSIONS.HAS_WALLET) && ( */}
              <MenuItem key="cashflow">
                <IconStyled className="fa-solid fa-list" />
                {t('cashflowMenu')}
              </MenuItem>
              {/* )} */}
              {this.context.can(USER_PERMISSIONS.IS_MEMBER) && (
                <MenuItem key="commissions">
                  <IconStyled className="fa-solid fa-sack-dollar" />
                  {t('commissionsMenu')}
                </MenuItem>
              )}
              {/* {this.context.can(USER_PERMISSIONS.IS_MEMBER) && ( */}
              <MenuItem key="myOrder">
                <IconStyled className="fa-solid fa-basket-shopping" />
                {t('myOrder')}
              </MenuItem>
              {/* )} */}
              {this.context.can(USER_PERMISSIONS.WALLETS) && (
                <MenuItem key="wallets">
                  <IconStyled className="fa-solid fa-wallet" />
                  {t('myWallet')}
                </MenuItem>
              )}
              {/* <MenuItem key="doc">
                <IconStyled className="fa-solid fa-book" />
                {t('documentation')}
              </MenuItem> */}
              {/* <MenuItem key="freeCourse">
                <IconStyled className="fa-solid fa-graduation-cap" />
                {t('freeCourse')}
              </MenuItem> */}
              {this.context.can(USER_PERMISSIONS.IS_MEMBER) && (
                <MenuItem key="myLink">
                  <IconStyled className="fa-solid fa-link" />
                  {t('copyMyLink')}
                </MenuItem>
              )}
              <MenuItem key="settings">
                <IconStyled className="fas fa-user-cog" />
                {t('settings')}
              </MenuItem>
              {/* <MenuItem key="smartnet">
                <ImgIconStyled
                  loading="lazy"
                  src={`/assets/themes/${process.env.REACT_APP_THEME}/images/smartnet.png`}
                  alt=""
                />
                {t('smartnet')}
              </MenuItem> */}
              <MenuItem key="close_session">
                <IconStyled className="fas fa-power-off" />
                {t('close_session')}
              </MenuItem>
            </Menu>
          </div>
        </CSSTransition>
      </>
    );
  }
}

UserMenuHeader.contextType = AbilityContext;

UserMenuHeader.propTypes = {
  registerPayed: PropTypes.bool,
  nickname: PropTypes.string,
  urlSponsor: PropTypes.any,
};

export default withRouter(withTranslation()(UserMenuHeader));
