import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { withFormik, Field } from 'formik';

import { withTranslation } from 'react-i18next';
import { InputTextFormik } from '@arcaitprod/react-forms-components';
import { dataModel, validationDataModel, setInitialValues } from './myProfile.data.form';
import { Letter, Nickname, Email, RefererLink, ButtonsContainer } from './myProfile.styled';
import { useSelector } from 'react-redux';
import { getUrlSponsorSelector } from '../../../store/selectors/userSelector';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button } from '@arcaitprod/react-basic-components';
import { Can, AbilityContext } from '../../permissions/Can';
import { USER_PERMISSIONS } from '../../permissions/rolesPermission';

const MyProfileForm = (props) => {
  const { t, handleSubmit, userInfo } = props;
  const urlSponsor = useSelector(getUrlSponsorSelector);
  const context = useContext(AbilityContext);

  return (
    <>
      <div className="row">
        <div className="col-12 mb-5">
          <div className="row">
            <div className="col-12 d-flex mb-4">
              <Letter>
                {userInfo && userInfo.email ? userInfo.email.substr(0, 1).toUpperCase() : ''}
              </Letter>
              <div className="d-flex flex-column ms-3 justify-content-end">
                <Nickname>{userInfo?.nicknameLocal || ''}</Nickname>
                <Email>{userInfo?.email || ''}</Email>
              </div>
            </div>
          </div>
          {context.can(USER_PERMISSIONS.IS_MEMBER) && (
            <>
              <div className="row">
                <RefererLink className="col-12 d-flex mb-2">{t('myLink')}</RefererLink>
              </div>
              <div className="row">
                <div className="col-12 d-flex mb-4">
                  <div className="alert alert-danger" role="alert">
                    <CopyToClipboard
                      text={urlSponsor.url}
                      onCopy={() => {
                        toast(t('linkCopied'), {
                          position: 'top-right',
                          autoClose: 1500,
                          hideProgressBar: true,
                          closeOnClick: true,
                          pauseOnHover: false,
                          draggable: false,
                          progress: undefined,
                        });
                      }}
                    >
                      <span title={t('copyLink')} className="urlSponsord">
                        {urlSponsor.url} <i className="fas fa-copy"></i>
                      </span>
                    </CopyToClipboard>
                  </div>
                </div>
              </div>
            </>
          )}
          <form className="form-horizontal bordered-row" onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-12 col-sm-7 col-lg-8">
                {!userInfo.nicknameLocal && (
                  <div className="row">
                    <div className="col-12">
                      <Field
                        id="nicknameLocal"
                        className="form-control"
                        labelField={t('forms.myProfile.labels.nickName')}
                        name="nicknameLocal"
                        component={InputTextFormik}
                        fieldType="text"
                        placeholder=""
                      />
                    </div>
                  </div>
                )}
                <div className="row">
                  <div className="col-12">
                    <Field
                      id="nameLocal"
                      className="form-control"
                      labelField={t('forms.myProfile.labels.name')}
                      name="nameLocal"
                      component={InputTextFormik}
                      fieldType="text"
                      placeholder=""
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <Field
                      id="surnameLocal"
                      className="form-control"
                      labelField={t('forms.myProfile.labels.surname')}
                      name="surnameLocal"
                      component={InputTextFormik}
                      fieldType="text"
                      placeholder=""
                    />
                  </div>
                </div>
                <div className="row">
                  <ButtonsContainer className="col-10 col-sm-10 containerButtons">
                    <Button type="submit" className="btn btn-md" variant="primary">
                      {t('forms.myProfile.buttons.save')}
                    </Button>
                  </ButtonsContainer>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

MyProfileForm.propTypes = {
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  userInfo: PropTypes.object,
};

const MyProfileFormF = withFormik({
  mapPropsToValues: (props) => {
    if (props.initialValues) {
      return setInitialValues(props.initialValues);
    }
    return dataModel;
  },
  validationSchema: validationDataModel,
  handleSubmit: (values, { props }) => {
    props.onSubmit(values);
  },
  enableReinitialize: true,
  displayName: 'MyProfileForm',
})(withTranslation()(MyProfileForm));

export default MyProfileFormF;
