import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import * as GeneralServices from '../../../../../../services/GeneralServices';
import * as UserService from '../../../../../../services/UserService';
import PropTypes from 'prop-types';
import { LANGUAGES, LANGUAGE_ES, LANGUAGE_EN } from '../../../../../../constants/index';
import { Label, Image, IconArrowLogin } from './styled';
import { getLangSelector } from '../../../../../../store/selectors/navSelector';
import { connect } from 'react-redux';
import ReactCountryFlag from 'react-country-flag';

class Languages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      languages: [],
      selected: undefined,
    };
  }

  componentDidMount() {
    const { preferendLanguage } = this.props;
    let dataLang;
    if (preferendLanguage && preferendLanguage !== this.props.i18n.language) {
      dataLang = preferendLanguage;
    } else {
      dataLang = this.props.i18n.language;
    }
    let langD = LANGUAGES.find((lng) => lng.code === dataLang);
    if (!langD) {
      langD = LANGUAGE_ES;
    }
    this.changeLanguage(langD.code);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.preferendLanguage &&
      prevProps.preferendLanguage !== this.props.preferendLanguage
    ) {
      this.changeLanguage(this.props.preferendLanguage);
    }
  }

  loadCountries = (lang) => {
    const { languageSet } = this.props;
    // if (lang !== languageSet) {
    GeneralServices.getAllCountriesOrder(lang);
    // }
  };

  changeLanguage = (lang) => {
    const { token, preferendLanguage } = this.props;
    this.props.i18n.changeLanguage(lang);
    this.loadCountries(lang);
    GeneralServices.setPreferendLanguage({ lang: lang });
    this.setState({
      selected: lang ? lang : 'en',
      languages: LANGUAGES.map((lang) => lang.code), //['es', 'en'],
      // languages: this.props.i18n.languages ? this.props.i18n.languages : ['es', 'en'],
    });
    if (token && preferendLanguage && preferendLanguage !== this.props.i18n.language) {
      UserService.updatePreferendLanguage(lang);
    }
  };

  render() {
    const { t, breakDevice } = this.props;
    return (
      <>
        {breakDevice >= 2 ? <Label>{t('languageText')}</Label> : ''}
        <div className="dropdown select-languages">
          <button
            className="btn btn-sm dropdown-toggle"
            type="button"
            data-toggle="dropdown"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            id="dropdownMenuButton"
          >
            {/* <Image
              src={`/assets/images/${this.state.selected}.png`}
              alt={`language.languages.${this.state.selected}`}
            /> */}
            <ReactCountryFlag
              countryCode={this.state.selected === 'en' ? 'gb' : this.state.selected}
              svg
            />
            {breakDevice < 2 ? (
              <IconArrowLogin className="fa-solid fa-angle-down"></IconArrowLogin>
            ) : (
              ''
            )}
            {/* <div>
              {this.state.selected
                ? t(`language.languages.${this.state.selected}`)
                : t('language.select_option')}
            </div> */}
          </button>
          <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
            {this.state.languages && this.state.languages.length > 0 && (
              <>
                {this.state.languages.map((lang) => {
                  return (
                    <button
                      key={lang}
                      className="dropdown-item language-item"
                      onClick={() => {
                        this.changeLanguage(lang);
                      }}
                    >
                      <ReactCountryFlag countryCode={lang === 'en' ? 'gb' : lang} svg />
                      {/* <img src={`/assets/images/${lang}.png`} alt={`language.languages.${lang}`} /> */}
                      <div>{t(`language.languages.${lang}`)}</div>
                    </button>
                  );
                })}
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}

Languages.propTypes = {
  preferendLanguage: PropTypes.string,
  token: PropTypes.string,
  breakDevice: PropTypes.any,
};

const mapStateToProps = (state) => ({
  languageSet: getLangSelector(state),
});

// export default withTranslation()(Languages);
export default connect(mapStateToProps, {})(withTranslation()(Languages));
