import styled from 'styled-components';

export const ImgBanner = styled.img`
  /* height: 70px; */
  width: 100%;
`;

export const BannerText = styled.div`
  background: transparent;
  position: absolute;
  top: ${({ breakDevice }) => (breakDevice !== undefined && breakDevice <= 2 ? '15%' : '25%')};
  right: ${({ breakDevice }) => (breakDevice !== undefined && breakDevice <= 2 ? '8%' : '20%')};
  /* min-height: 300px; */
`;

export const BannerMember = styled.img`
  width: 100%;
  min-height: 200px;
  object-fit: cover;
  /* max-height: 96.5%; */
`;

export const Title = styled.h3`
  font-size: 1.5rem;
  font-family: 'Biome Light';
  /* font-weight: 600; */
  color: #fff;
`;

export const Text = styled.p`
  font-size: 0.875rem;
  font-family: 'Biome Light';
  color: #fff;
  white-space: break-spaces;
  line-height: 1rem;
`;

export const ContainerNewAge = styled.section`
  /* min-height: calc(100vh - 300px); */
  background: transparent;
`;

export const TitleNewAge = styled.h2`
  /* font-size: 1rem; */
  font-family: 'Gotham Book';
  color: #000;
`;

export const ImgNewAge = styled.img`
  /* height: 70px; */
  max-width: 100%;
  height: 120px;
  margin-bottom: 15px;
`;

export const LabelNewAge = styled.p`
  padding: 10px;
  font-family: 'Gotham Book';
  color: #a9a9a9;
  font-size: 0.875rem;
  line-height: 1rem;
  width: 100%;
  text-align: center;
`;

export const ContainerBoss = styled.section`
  /* height: calc(100vh - 150px); */
  background: transparent;
`;

export const ContainerBoss01 = styled.section`
  /* height: calc((100vh - 150px) / 2); */
  background: transparent;
`;

export const BossImage = styled.img`
  /* height: 70px; */
  height: 15rem;
  /* padding-left: 20px; */
`;

export const BossImage02 = styled.img`
  /* height: 70px; */
  height: 15rem;
  /* padding-right: 40px; */
`;

export const BossImgContainer = styled.div`
  /* height: 70px; */
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BossTextContainer = styled.div`
  /* height: 70px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
`;

export const ContainerBossItemLeft = styled.div`
  /* padding: 25px 15px 25px 100px; */
  /* display: flex;
  justify-content: start;
  align-items: center; */
`;

export const ContainerBossItemRight = styled.div`
  /* padding: 25px 100px 25px 15px; */
  /* display: flex;
  justify-content: start;
  align-items: center; */
`;

export const BossName = styled.div`
  font-family: 'Gotham Book';
  color: #474747;
  font-size: 1.5rem;
`;

export const BossStatus = styled.div`
  font-family: 'Gotham Book';
  color: #474747;
  font-size: 0.9rem;
  margin-top: -8px;
`;

export const BossText = styled.div`
  font-family: 'Gotham Book';
  color: #a9a9a9;
  font-size: 0.875rem;
  line-height: 1rem;
`;

export const MemberText01 = styled.div`
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
  font-family: 'Biome Light';
  line-height: 20px;
  -webkit-letter-spacing: 2px;
  -moz-letter-spacing: 2px;
  -ms-letter-spacing: 2px;
  letter-spacing: 2px;
  text-shadow: 5px 6px 6px rgba(0, 0, 0, 0.9);
`;

export const MemberText02 = styled.div`
  color: white;
  font-size: 0.875rem;
  font-weight: bold;
  font-family: 'Biome Light';
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: 12px;
  -webkit-letter-spacing: 2px;
  -moz-letter-spacing: 2px;
  -ms-letter-spacing: 2px;
  -webkit-letter-spacing: 2px;
  -moz-letter-spacing: 2px;
  -ms-letter-spacing: 2px;
  letter-spacing: 2px;
  text-shadow: 5px 6px 6px rgba(0, 0, 0, 0.9);
`;

export const ButtonOther = styled.button`
  border: 3px solid #04fff6;
  margin-top: 10px;
  border-radius: 5px;
  padding: 7px 16px;
  background: #0000007a;
  color: #fff;
  font-family: Biome Light;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
`;
