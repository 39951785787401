import React from 'react';
import Dashboard from '../../../components/user/dashboardUser';
import Layout from '../../../components/layouts/simpleLayout';

const DashboardPage = () => {
  return (
    <Layout
      hiddenButtonShoppingCart
      body={
        <>
          <Dashboard />
        </>
      }
    />
  );
};

DashboardPage.propTypes = {};

export default DashboardPage;
