import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// import PropTypes from 'prop-types';
import DesertAntsView from './desertAnts.view';
import { useDispatch } from 'react-redux';
import { show, hide } from 'redux-modal';
import * as ProductService from '../../../services/ProductService';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import PreviewProduct from '../../others/products/detail';
import { getAuthTokenSelector, getAuthInfoSelector } from '../../../store/selectors/authSelector';
import { useSelector } from 'react-redux';

const AboutUs = (props) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const auth = useSelector(getAuthTokenSelector);

  const handlerAddShoppingCart = async (values) => {
    if (values.amount > 0) {
      let product = data.find((item) => item.uuid === values.item);
      const result = await ProductService.addProduct(values.amount, product, auth, t);
      if (result !== 'noStock') {
        // if (userInfo?.extra || campaign) {
        //   const campaignToken = userInfo?.extra ? userInfo.extra : campaign;
        //   await FBCampaignService.sendTrackAddToCartEvent(campaignToken, product, location);
        // }
        let myOffcanvas = document.getElementById('offcanvasRight');
        let bsOffcanvas = new window.bootstrap.Offcanvas(myOffcanvas);
        bsOffcanvas.show();
      }
    }
  };

  const toListRemarkableProducts = async (filter, region) => {
    if (filter.filters) {
      filter.filters.productLineType = 'DESERT_ANTS';
    } else {
      filter.filters = { productLineType: 'DESERT_ANTS' };
    }
    const result = await ProductService.listFilter(filter);
    if (result.docs && result.docs.length > 0) {
      setData(result.docs);
    }
    return result;
  };

  const handlerClosePopup = (popupName) => {
    dispatch(hide(popupName));
  };

  const handlerShowPreview = (item) => {
    console.log(item);
    dispatch(
      show('ModalPreview', {
        handleHide: () => handlerClosePopup('ModalPreview'),
        body: (
          <>
            <PreviewProduct
              uuid={item.uuid}
              preview
              closeModal={() => handlerClosePopup('ModalPreview')}
            ></PreviewProduct>
          </>
        ),
      })
    );
  };

  const handlerOnClickItem = (item) => {
    history.push(`/products/detail/${item.uuid}`);
  };

  return (
    <>
      <DesertAntsView
        addToShoppingCart={handlerAddShoppingCart}
        listRemarkableProducts={toListRemarkableProducts}
        showMoreInfo={handlerOnClickItem}
        showPreview={handlerShowPreview}
      />
    </>
  );
};

AboutUs.propTypes = {};

export default AboutUs;
