import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CustomTable from './CustomPaginatedList.view';

const CustomTableContainer = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageCount, setPageCount] = React.useState(0);
  const {
    columns,
    filters,
    defaultPageSize,
    renderTable,
    renderPagination,
    hiddenPagination,
    isSlider,
    contentLoader,
  } = props;
  const fetchIdRef = React.useRef(0);

  const getData = async (filter, region) => {
    const { toList } = props;
    if (toList) {
      const result = await toList(filter, region);
      setPageCount(result?.totalPages || 0);
      setData(result?.docs || []);
      setLoading(false);
    }
  };

  const fetchData = React.useCallback(({ pageSize, pageIndex, region }) => {
    const fetchId = ++fetchIdRef.current;
    if (fetchId === fetchIdRef.current) {
      const filter = {
        page: pageIndex + 1,
        pageSize: pageSize || 10,
      };

      if (filters) {
        filter.filters = this.props.filters;
        filter.filters.countryUuid = region.uuid;
      }

      getData(filter, region);
    }
  }, []);

  return (
    <>
      <CustomTable
        columns={columns}
        data={data}
        fetchData={fetchData}
        defaultPageSize={defaultPageSize || 10}
        pageCount={pageCount}
        renderTable={renderTable}
        renderPagination={renderPagination}
        hiddenPagination={hiddenPagination}
        isSlider={isSlider}
        loading={loading}
        contentLoader={contentLoader}
      />
    </>
  );
};

CustomTableContainer.propTypes = {
  columns: PropTypes.any,
  toList: PropTypes.func,
  filters: PropTypes.any,
  defaultPageSize: PropTypes.number,
  renderTable: PropTypes.func,
  renderPagination: PropTypes.any,
  hiddenPagination: PropTypes.bool,
  isSlider: PropTypes.bool,
  contentLoader: PropTypes.func,
};

export default CustomTableContainer;
