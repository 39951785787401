import styled from 'styled-components';

export const IconPerson = styled.i`
  font-size: 1.65rem;
  color: #a820ce;
`;

export const NumPerson = styled.span`
  font-size: 1.5rem;
  margin-left: 10px;
`;

export const CampaignContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.primary};
  padding: 20px;
  border-radius: 10px;
`;

export const ProgressText = styled.span`
  font-size: 0.65rem;
  font-weight: bold;
  width: 100%;
  display: flex;
  justify-content: end;
`;

export const ImageItem = styled.img`
  width: 100%;
  /* max-height: 100px; */
  display: flex;
  justify-content: center;
`;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const TitleCampaign = styled.div`
  font-size: 0.8rem;
  text-transform: uppercase;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: underline;
`;

export const Status = styled.span`
  font-size: 0.8rem;
  color: #469fa3;
  margin-left: 5px;
  margin-top: 0px;
`;

export const ProgressBar = styled.div`
  background: #469fa3 !important;
`;
