import React from 'react';
import PropTypes from 'prop-types';
import Details from '../../../../components/user/order/orderDetails';
import Layout from '../../../../components/layouts/simpleLayout';

const NotificationFormPage = (props) => {
  return (
    <Layout
      body={
        <>
          <Details uuid={props.uuid} />
        </>
      }
    />
  );
};

NotificationFormPage.propTypes = {};

export default NotificationFormPage;
