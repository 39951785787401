import React, { Component } from 'react';

import Maintenance from './maintenance.view';

class MaintenanceContainer extends Component {
  render() {
    return <Maintenance />;
  }
}

MaintenanceContainer.propTypes = {};

export default MaintenanceContainer;
