import styled, { css } from 'styled-components';

export const LinkLogo = styled.a`
  width: 100%;
  background: #000;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TitleCategories = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #707070;
  font-family: 'Gotham';
  font-size: 1.1rem;
  letter-spacing: 0.2rem;
`;

export const MenuContainer = styled.ul`
  width: 30px;
  /* border: 1px solid ${({ theme }) => theme.colors.primary}; */
  border-radius: 10px;
  padding: 10px 5px 10px 25px;
  margin-top: 15px;
`;

export const IconStyled = styled.img`
  width: 25px;
  margin-right: 15px;
`;

export const Separator = styled.div`
  width: 85%;
  height: 1px;
  background-color: #ddd;
  margin: auto;
  margin-top: 3px;
  margin-bottom: 3px;
`;

export const IconArrow = styled.i`
  ${({ theme, active }) => {
    if (active) {
      return css`
        width: 30px;
        color: ${theme ? theme.sidebar.colorLink : '#fff'};

        &:hover {
          color: ${theme ? theme.sidebar.colorLinkHover : '#fff'};
        }
      `;
    } else {
      return css`
        width: 30px;
        background: transparent;
        color: #707070;
        cursor: initial !important;

        &:hover {
          color: color: #707070;;
        }
      `;
    }
  }}
`;

export const OffCanvasHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 0.5rem 0.5rem 1rem 1rem !important;
  z-index: 99999 !important;
`;

export const ButtonOption = styled.button`
  ${({ theme, active }) => {
    if (active) {
      return css`
        border: 0px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background: transparent;
        color: ${theme ? theme.sidebar.colorLink : '#fff'};
        text-align: left;
        font-size: 0.8rem;

        &:hover {
          color: ${theme ? theme.sidebar.colorLinkHover : '#fff'};
          /* font-weight: bold; */
        }
      `;
    } else {
      return css`
        border: 0px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background: transparent;
        color: #707070;
        cursor: initial !important;
        text-align: left;
        font-size: 0.8rem;

        &:hover {
          color: color: #707070;;
        }
      `;
    }
  }}
`;

export const SubButtonOption = styled.button`
  ${({ theme, active }) => {
    if (active) {
      return css`
        border: 0px;
        background: transparent;
        color: ${theme ? theme.sidebar.colorLink : '#fff'};
        padding-left: 35px;

        &:hover {
          color: ${theme ? theme.sidebar.colorLinkHover : '#fff'};
        }
      `;
    } else {
      return css`
        border: 0px;
        background: transparent;
        color: #707070;
        cursor: initial !important;
        padding-left: 35px;

        &:hover {
          color: color: #707070;
        }
      `;
    }
  }}
`;

export const Logo = styled.img`
  width: 100%;
  max-width: 80px;
  /* max-width: ${({ offCanvas }) => (offCanvas ? '100px' : '120px')}; */
  margin-top: ${({ offCanvas }) => (offCanvas ? '-30px' : 'initial')};
`;

export const NavLink = styled.li`
  display: flex;
  padding: 0.4rem 0rem;
`;

export const NavLinkChild = styled.li`
  display: block;
  padding: 0.5rem 0rem;
  background: #35363a;
`;
