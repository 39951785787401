import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Letter,
  Country,
  SwitchStatus,
  LabelStatus,
  TextStatus,
} from './countriesAvailable.styled';
import { getCountriesSelector } from '../../../store/selectors/navSelector';
import { useSelector } from 'react-redux';

const CountriesAvailable = (props) => {
  const { t, i18n } = useTranslation();
  const { availableCountries } = props;
  const [allCountries, setAllCountries] = useState([]);
  const [availableCountriesList, setAvailableCountriesList] = useState([]);
  const [cSelected, setCSelected] = useState([]);
  const countriesCache = useSelector(getCountriesSelector);
  const [onlyAvailable, setOnlyAvailable] = useState(false);

  useEffect(() => {
    const availableData = [];
    for (const country of countriesCache) {
      const available = availableCountries.find((uuid) => uuid === country.uuid);
      if (available) {
        availableData.push(country);
      }
    }
    setAvailableCountriesList(availableData);
    setAllCountries(countriesCache);
    setCSelected(countriesCache);
  }, [countriesCache]);

  const renderCountries = () => {
    let init = '';
    return (
      <>
        <div className="row">
          {cSelected.map((country, index) => {
            const first = country.translations[i18n.language].slice(0, 1).toLowerCase();
            const available = availableCountries.find((uuid) => uuid === country.uuid);
            if (first !== init) {
              init = first;
              return (
                <React.Fragment key={country.uuid}>
                  <Letter className="col-12 mb-1 mt-3">{init.toUpperCase()}</Letter>
                  <Country available={available ? true : false} className="col-6 col-md-4 col-lg-3">
                    {`${country.translations[i18n.language]}`}
                  </Country>
                </React.Fragment>
              );
            }
            return (
              <Country
                available={available ? true : false}
                key={country.uuid}
                className="col-6 col-md-4 col-lg-3"
              >
                {`${country.translations[i18n.language]}`}
              </Country>
            );
          })}
        </div>
      </>
    );
  };

  const handlerSelectOnlyAvailable = (only) => {
    setOnlyAvailable(only);
    if (only) {
      setCSelected(availableCountriesList);
    } else {
      setCSelected(allCountries);
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-12 px-3 py-4  px-md-5 py-md-4">
          <div className="row">
            <TextStatus className="col-12 col-md-12 col-lg-8">{t('onlyAvailableText')}.</TextStatus>
            <div className="col-12 col-md-12 col-lg-4 d-flex justify-content-center align-items-center justify-content-md-end">
              <div className="serviceStatusContainer2 form-switch">
                <SwitchStatus
                  statusCheck={onlyAvailable}
                  className="serviceStatus2 form-check-input"
                  type="checkbox"
                  id="selectUnilevel"
                  onChange={(event) => {
                    if (event.target.checked) {
                      handlerSelectOnlyAvailable(true);
                    } else {
                      handlerSelectOnlyAvailable(false);
                    }
                  }}
                  checked={onlyAvailable}
                />
                <LabelStatus className="form-check-label">{t('onlyAvailable')}</LabelStatus>
              </div>
            </div>
          </div>
          {cSelected && cSelected.length > 0 && renderCountries()}
        </div>
      </div>
    </>
  );
};

CountriesAvailable.propTypes = {
  availableCountries: PropTypes.array,
};

export default CountriesAvailable;
