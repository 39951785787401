import React, { Component } from 'react';

import TermConditions from './termAndConditions.view';

class TermConditionsContainer extends Component {
  render() {
    return <TermConditions onBack={this.props.onBack} />;
  }
}

TermConditionsContainer.propTypes = {};

export default TermConditionsContainer;
