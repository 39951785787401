import React from 'react';
import List from '../addressesList';
// import PropTypes from 'prop-types';

const AddressesListPage = (props) => {
  return (
    <>
      <div className="row">
        <div className="col-12">
          <List />
        </div>
      </div>
    </>
  );
};

AddressesListPage.propTypes = {};

export default AddressesListPage;
