import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import CustomPaginatedList from '../../../utils/CustomPaginatedList';
import ProductTableGrid from '../ProductTableGrid/productTable.view';
import { EmptyDataContainer } from './productsGrid.styled';
import ContentLoader from 'react-content-loader';
import { useSelector } from 'react-redux';

const ProductGridView = (props) => {
  const { t } = useTranslation();

  // const { products, addToShoppingCart, itemSelected, onChangeProduct, region } = props;

  const {
    onListProduct,
    showMoreInfo,
    region,
    addToShoppingCart,
    showPreview,
    numItemPage,
    hiddenPagination,
    customPagination,
    isSlider,
  } = props;

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'title',
      },
    ],
    []
  );

  const renderTableProduct = (options) => {
    return (
      <ProductTableGrid
        isSlider={isSlider}
        tableOptions={options}
        showMoreInfo={showMoreInfo}
        showPreview={showPreview}
        region={region}
        addToShoppingCart={addToShoppingCart}
      ></ProductTableGrid>
    );
  };

  const renderListLoader = () => {
    return (
      <div className="row">
        <div className="col-12 mt-4">
          <ContentLoader
            speed={2}
            viewBox="0 0 800 300"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            style={{ width: '100%' }}
          >
            <rect x="1" y="162" rx="3" ry="3" width="125" height="6" />
            <rect x="1" y="148" rx="3" ry="3" width="125" height="6" />
            <rect x="1" y="175" rx="3" ry="3" width="60" height="6" />
            <rect x="1" y="1" rx="0" ry="0" width="125" height="132" />
            <rect x="1" y="189" rx="3" ry="3" width="60" height="6" />
            <rect x="100" y="183" rx="0" ry="0" width="23" height="24" />
            <rect x="151" y="162" rx="3" ry="3" width="125" height="6" />
            <rect x="151" y="148" rx="3" ry="3" width="125" height="6" />
            <rect x="151" y="175" rx="3" ry="3" width="60" height="6" />
            <rect x="151" y="1" rx="0" ry="0" width="125" height="132" />
            <rect x="151" y="189" rx="3" ry="3" width="60" height="6" />
            <rect x="250" y="183" rx="0" ry="0" width="23" height="24" />
            <rect x="299" y="162" rx="3" ry="3" width="125" height="6" />
            <rect x="299" y="148" rx="3" ry="3" width="125" height="6" />
            <rect x="299" y="175" rx="3" ry="3" width="60" height="6" />
            <rect x="299" y="1" rx="0" ry="0" width="125" height="132" />
            <rect x="299" y="189" rx="3" ry="3" width="60" height="6" />
            <rect x="398" y="183" rx="0" ry="0" width="23" height="24" />
            <rect x="444" y="161" rx="3" ry="3" width="125" height="6" />
            <rect x="444" y="147" rx="3" ry="3" width="125" height="6" />
            <rect x="444" y="174" rx="3" ry="3" width="60" height="6" />
            <rect x="444" y="0" rx="0" ry="0" width="125" height="132" />
            <rect x="444" y="188" rx="3" ry="3" width="60" height="6" />
            <rect x="543" y="182" rx="0" ry="0" width="23" height="24" />
            <rect x="0" y="398" rx="3" ry="3" width="125" height="6" />
            <rect x="0" y="384" rx="3" ry="3" width="125" height="6" />
            <rect x="0" y="411" rx="3" ry="3" width="60" height="6" />
            <rect x="0" y="237" rx="0" ry="0" width="125" height="132" />
            <rect x="0" y="425" rx="3" ry="3" width="60" height="6" />
            <rect x="99" y="419" rx="0" ry="0" width="23" height="24" />
            <rect x="150" y="398" rx="3" ry="3" width="125" height="6" />
            <rect x="150" y="384" rx="3" ry="3" width="125" height="6" />
            <rect x="150" y="411" rx="3" ry="3" width="60" height="6" />
            <rect x="150" y="237" rx="0" ry="0" width="125" height="132" />
            <rect x="150" y="425" rx="3" ry="3" width="60" height="6" />
            <rect x="249" y="419" rx="0" ry="0" width="23" height="24" />
            <rect x="298" y="398" rx="3" ry="3" width="125" height="6" />
            <rect x="298" y="384" rx="3" ry="3" width="125" height="6" />
            <rect x="298" y="411" rx="3" ry="3" width="60" height="6" />
            <rect x="298" y="237" rx="0" ry="0" width="125" height="132" />
            <rect x="298" y="425" rx="3" ry="3" width="60" height="6" />
            <rect x="397" y="419" rx="0" ry="0" width="23" height="24" />
            <rect x="443" y="397" rx="3" ry="3" width="125" height="6" />
            <rect x="443" y="383" rx="3" ry="3" width="125" height="6" />
            <rect x="443" y="410" rx="3" ry="3" width="60" height="6" />
            <rect x="443" y="236" rx="0" ry="0" width="125" height="132" />
            <rect x="443" y="424" rx="3" ry="3" width="60" height="6" />
            <rect x="542" y="418" rx="0" ry="0" width="23" height="24" />
          </ContentLoader>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="row justify-content-center no-margin">
        <div className="col-12">
          <div className="row">
            <div className="col-12">
              <CustomPaginatedList
                isSlider={isSlider}
                defaultPageSize={numItemPage || 10}
                toList={onListProduct}
                renderTable={renderTableProduct}
                columns={columns}
                hiddenPagination={hiddenPagination || false}
                renderPagination={customPagination || undefined}
                contentLoader={renderListLoader}
              ></CustomPaginatedList>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

ProductGridView.propTypes = {
  onListProduct: PropTypes.func,
  showMoreInfo: PropTypes.func,
  showPreview: PropTypes.func,
  products: PropTypes.array,
  addToShoppingCart: PropTypes.func,
  itemSelected: PropTypes.object,
  onChangeProduct: PropTypes.func,
  region: PropTypes.object,
  numItemPage: PropTypes.number,
  hiddenPagination: PropTypes.bool,
  customPagination: PropTypes.any,
  isSlider: PropTypes.bool,
};

export default ProductGridView;
