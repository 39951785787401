import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as SubscriptionService from '../../../../services/SubscriptionService';
import * as ProductService from '../../../../services/ProductService';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Container, ImgItem, TitleItem, LinkButton } from './subscriptionsList.styled';
import { AbilityContext } from '../../../permissions/Can';
import { InfiniteList } from '@arcaitprod/react-complex-components';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import { getUserIsEuSelector } from '../../../../store/selectors/authSelector';
import ContentLoader from 'react-content-loader';
import { getTimeZoneSelector } from '../../../../store/selectors/navSelector';
import { Button } from '@arcaitprod/react-basic-components';
import { showDialog } from '@arcaitprod/react-utils-components';
import {
  PACKAGE_NITROX_CODE,
  PACKAGE_EXPERIENCE_CODE,
  PACKAGE_IGNITE_CODE,
  PACKAGE_XPLOSIVE_CODE,
  PACKAGE_SPARK_CODE,
  PACKAGE_FIRE_CODE,
  PACKAGE_FREE_CODE,
} from '../../../../constants/index';
import PATHS from '../../../../constants/paths';

class NotificationListContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      refreshData: false,
      info: {},
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.refreshData !== this.state.refreshData && this.state.refreshData === true) {
      this.resetRefreshData();
    }
  }

  resetRefreshData = () => {
    this.setState({ refreshData: false });
  };

  toList = async (filter) => {
    const result = await SubscriptionService.listFilter(filter);
    return result;
  };

  handlerRegisterSubscription = (activationToken) => {
    this.props.history.push(`/activation/account/nitrox/${activationToken}`);
  };

  handlerReactivateNitrox = (activationToken) => {
    this.props.history.push(`/reactivation/account/nitrox/${activationToken}`);
  };

  handlerGotoExternalApp = (code) => {
    let url = '';
    if (code === PACKAGE_NITROX_CODE) {
      url = 'https://nitrox.evolution.network/login';
    } else if (code === PACKAGE_EXPERIENCE_CODE) {
      url = 'http://www.evoexp.com';
    } else if (code === PACKAGE_IGNITE_CODE) {
      url = '';
      this.props.history.push(`${PATHS.IGNITE}`);
    } else if (
      code === PACKAGE_FIRE_CODE ||
      code === PACKAGE_SPARK_CODE ||
      code === PACKAGE_XPLOSIVE_CODE ||
      code === PACKAGE_FREE_CODE
    ) {
      url = '';
      this.props.history.push(`${PATHS.SHOCKWAVE}`);
    }

    if (url) {
      window.open(url, '_blank');
    }
  };

  handlerCancelSubscription = (subscriptionUuid) => {
    const { t } = this.props;
    const okAction = async () => {
      const result = await SubscriptionService.cancelSubscription(subscriptionUuid);
      if (result && result === 'OK') {
        showDialog(
          t('forms.mySubscription.message.cancel.title'),
          t('forms.mySubscription.message.cancel.success'),
          'success',
          false,
          'OK',
          false,
          undefined
        );
        this.setState({ refreshData: true });
      }
    };

    showDialog(
      t('forms.mySubscription.dialog.cancel.title'),
      t('forms.mySubscription.dialog.cancel.text'),
      'warning',
      true,
      t('forms.mySubscription.dialog.cancel.buttonOk'),
      false,
      okAction,
      t('forms.mySubscription.dialog.cancel.buttonNo')
    );
  };

  handlerActiveSubscription = (subscriptionUuid) => {
    const { t } = this.props;
    const okAction = async () => {
      const result = await SubscriptionService.activeSubscription(subscriptionUuid);
      if (result && result === 'OK') {
        showDialog(
          t('forms.mySubscription.message.active.title'),
          t('forms.mySubscription.message.active.success'),
          'success',
          false,
          'OK',
          false,
          undefined
        );
        this.setState({ refreshData: true });
      }
    };

    showDialog(
      t('forms.mySubscription.dialog.active.title'),
      t('forms.mySubscription.dialog.active.text'),
      'warning',
      true,
      t('forms.mySubscription.dialog.active.buttonOk'),
      false,
      okAction,
      t('forms.mySubscription.dialog.active.buttonNo')
    );
  };

  handlerAddShoppingCart = async (packageItem, subscriptionUuid) => {
    const { t } = this.props;
    const result = await ProductService.addProductRenovation(1, packageItem, subscriptionUuid);
    let myOffcanvas = document.getElementById('offcanvasRight');
    let bsOffcanvas = new window.bootstrap.Offcanvas(myOffcanvas);
    bsOffcanvas.show();
  };

  handlerReactivateSubscription = (subscriptionUuid) => {
    const { t } = this.props;
    const okAction = async () => {
      const result = await SubscriptionService.loadSubscriptionReactivation(subscriptionUuid);
      if (result) {
        this.handlerAddShoppingCart(result, subscriptionUuid);
      }
      // this.props.history.push(`/subscriptions/reactivate/${subscriptionUuid}`);
    };

    showDialog(
      t('forms.mySubscription.dialog.reactive.title'),
      t('forms.mySubscription.dialog.reactive.text'),
      'info',
      true,
      t('forms.mySubscription.dialog.reactive.buttonOk'),
      false,
      okAction,
      t('forms.mySubscription.dialog.reactive.buttonNo')
    );
  };

  handlerRenewSubscription = (subscriptionUuid) => {
    const { t } = this.props;
    const okAction = async () => {
      const result = await SubscriptionService.loadSubscriptionReactivation(subscriptionUuid);
      if (result) {
        this.handlerAddShoppingCart(result, subscriptionUuid);
      }
      // this.props.history.push(`/subscriptions/reactivate/${subscriptionUuid}`);
    };

    showDialog(
      t('forms.mySubscription.dialog.renew.title'),
      t('forms.mySubscription.dialog.renew.text'),
      'info',
      true,
      t('forms.mySubscription.dialog.renew.buttonOk'),
      false,
      okAction,
      t('forms.mySubscription.dialog.renew.buttonNo')
    );
  };

  renderRowExchange = (subs) => {
    const { timeZone, t } = this.props;
    const pPackage = subs.package;
    const activePackage = pPackage.status !== 'DISABLED';
    let showRegister = false;
    let showReActivate = false;
    let showCancel = false;
    let showActive = false;
    let showAccess = false;
    let earlyRenew = true;

    const current = moment(new Date()).tz('Europe/Madrid');
    const dateEnd = moment(subs.dateEnd).tz('Europe/Madrid');

    if (
      subs.package.code === PACKAGE_FIRE_CODE ||
      subs.package.code === PACKAGE_SPARK_CODE ||
      subs.package.code === PACKAGE_XPLOSIVE_CODE
    ) {
      earlyRenew = false;
    }

    if (
      subs.activationToken &&
      current.isBefore(dateEnd) &&
      !subs.dataService
      // subs.package.code === 5
    ) {
      showRegister = true;
    }

    if (
      (!subs.activationToken && current.isBefore(dateEnd) && subs.dataService) ||
      (subs.package.code === 7 && !subs.activationToken && current.isBefore(dateEnd))
    ) {
      showAccess = true;
    }

    if (subs.activationToken && current.isBefore(dateEnd) && subs.userService && subs.dataService) {
      showReActivate = true;
    }

    if (subs.nextRenovationDate && current.isBefore(dateEnd)) {
      showCancel = true;
    }

    if (!subs.nextRenovationDate && current.isBefore(dateEnd)) {
      showActive = true;
    }

    let buyback = false;
    if (
      !subs.active &&
      !subs.nextRenovationDate &&
      !current.isSameOrBefore(dateEnd) &&
      subs.package.code === PACKAGE_NITROX_CODE
    ) {
      buyback = true;
    }

    return (
      <div key={subs.uuid} className="row mb-3">
        <Container className="col-12">
          <div className="row">
            <div className="col-3 d-flex flex-column justify-content-center align-items-center">
              <ImgItem src={pPackage.imageMiniatureUrl} alt={pPackage.title}></ImgItem>
              <TitleItem>{pPackage.title}</TitleItem>
            </div>
            <div className="col-4 d-flex flex-column justify-content-center">
              <div>
                {`${t('forms.mySubscription.labels.status')}: `}
                <strong>
                  {`${
                    (subs.active && subs.nextRenovationDate && current.isSameOrBefore(dateEnd)) ||
                    (subs.active && !subs.nextRenovationDate && current.isSameOrBefore(dateEnd))
                      ? t('forms.mySubscription.labels.active')
                      : ''
                  }`}
                  {`${
                    !subs.active && subs.nextRenovationDate && current.isSameOrBefore(dateEnd)
                      ? t('forms.mySubscription.labels.pending')
                      : ''
                  }`}
                  {`${
                    !subs.active && !subs.nextRenovationDate && !current.isSameOrBefore(dateEnd)
                      ? t('forms.mySubscription.labels.expired')
                      : ''
                  }`}
                </strong>
              </div>
              <div>
                {`${t('forms.mySubscription.labels.dateInit')}: `}
                <strong>{`${moment(subs.dateInit)
                  .tz('Europe/Madrid')
                  .format('DD/MM/yyyy')}`}</strong>
              </div>
              <div>
                {`${t('forms.mySubscription.labels.dateEnd')}: `}
                <strong>{`${moment(subs.dateEnd)
                  .tz('Europe/Madrid')
                  .format('DD/MM/yyyy')}`}</strong>
              </div>

              <div>
                {`${t('forms.mySubscription.labels.nextRenovationDate')}: `}
                {subs.nextRenovationDate ? (
                  <strong>{`${moment(subs.nextRenovationDate)
                    .tz('Europe/Madrid')
                    .format('DD/MM/yyyy')}`}</strong>
                ) : (
                  <strong>{`${t('forms.mySubscription.labels.canceled')}`}</strong>
                )}
              </div>
              {subs.userService && (
                <div>
                  {`${t('forms.mySubscription.labels.userService')}: `}
                  <strong>{`${subs.userService}`}</strong>
                </div>
              )}
            </div>
            <div className="col-4  d-flex flex-column justify-content-center">
              {showRegister && activePackage && (
                <Button
                  type="button"
                  className="btn btn-sm btn-block"
                  variant="primary"
                  onClick={() => {
                    this.handlerRegisterSubscription(subs.activationToken);
                  }}
                >
                  {t('forms.mySubscription.buttons.register')}
                </Button>
              )}
              {showReActivate && activePackage && (
                <Button
                  type="button"
                  className="btn btn-sm btn-block"
                  variant="primary"
                  onClick={() => {
                    this.handlerReactivateNitrox(subs.activationToken);
                  }}
                >
                  {t('forms.mySubscription.buttons.reactivate')}
                </Button>
              )}
              {showCancel && activePackage && (
                <>
                  {showAccess && activePackage && (
                    <Button
                      type="button"
                      className="btn btn-sm btn-block"
                      variant="primary"
                      onClick={() => {
                        this.handlerGotoExternalApp(subs.package.code);
                      }}
                    >
                      {t(`forms.mySubscription.buttons.code${subs.package.code}`)}
                    </Button>
                  )}
                  {showAccess && activePackage && earlyRenew && (
                    <Button
                      type="button"
                      className="btn btn-sm btn-block"
                      variant="primary"
                      onClick={() => {
                        this.handlerRenewSubscription(subs.uuid);
                      }}
                    >
                      {t(`forms.mySubscription.buttons.renewPay`)}
                    </Button>
                  )}
                  <LinkButton
                    type="button"
                    className="btn btn-sm btn-block"
                    variant="secondary"
                    onClick={() => {
                      this.handlerCancelSubscription(subs.uuid);
                    }}
                  >
                    {t('forms.mySubscription.buttons.cancel')}
                  </LinkButton>
                </>
              )}

              {showActive && activePackage && (
                <>
                  {!showRegister && activePackage && (
                    <Button
                      type="button"
                      className="btn btn-sm btn-block"
                      variant="primary"
                      onClick={() => {
                        this.handlerGotoExternalApp(subs.package.code);
                      }}
                    >
                      {t(`forms.mySubscription.buttons.code${subs.package.code}`)}
                    </Button>
                  )}

                  <LinkButton
                    type="button"
                    className="btn btn-sm btn-block"
                    variant="secondary"
                    onClick={() => {
                      this.handlerActiveSubscription(subs.uuid);
                    }}
                  >
                    {t('forms.mySubscription.buttons.active')}
                  </LinkButton>
                </>
              )}

              {buyback && activePackage && (
                <>
                  <Button
                    type="button"
                    className="btn btn-sm btn-block"
                    variant="primary"
                    onClick={() => {
                      this.handlerReactivateSubscription(subs.uuid);
                    }}
                  >
                    {t(`forms.mySubscription.buttons.resetNitroxService`)}
                  </Button>
                </>
              )}
            </div>
          </div>
        </Container>
      </div>
    );
  };

  renderListLoader = () => {
    return (
      <div className="row">
        <div className="col-12 mt-4">
          <ContentLoader
            speed={2}
            viewBox="0 0 400 160"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            style={{ width: '100%' }}
          >
            <rect x="5" y="1" rx="3" ry="3" width="400" height="5" />
            <rect x="5" y="15" rx="3" ry="3" width="400" height="5" />
          </ContentLoader>
        </div>
      </div>
    );
  };

  render() {
    const { t, filters, className, currency } = this.props;
    return (
      <div className={className || ''}>
        <InfiniteList
          id="cashflows"
          renderItem={this.renderRowExchange}
          toList={this.toList}
          filters={filters}
          variantTitle="primary"
          // title={t('forms.cashflow.titleList')}
          forceRefresh={this.state.refreshData}
          endMessage={t('goToTop')}
          messageButtonMore={t('readMore')}
          variantButtonMore="simplePrimary"
          renderLoader={this.renderListLoader}
        />
      </div>
    );
  }
}

NotificationListContainer.contextType = AbilityContext;

NotificationListContainer.propTypes = {
  t: PropTypes.any,
  filters: PropTypes.object,
  className: PropTypes.string,
  walletService: PropTypes.string,
};

// export default withRouter(withTranslation()(NotificationListContainer));

// export default withTranslation()(SubscriptionsContainer);
const mapStateToProps = (state) => ({
  isEu: getUserIsEuSelector(state),
  timeZone: getTimeZoneSelector(state),
});

export default withRouter(
  connect(mapStateToProps, {})(withTranslation()(NotificationListContainer))
);
