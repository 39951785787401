import React from 'react';
import PropTypes from 'prop-types';

const CustomTable = (props) => {
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } = props;

  return (
    <>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>
                  {column.render('Header')}
                  <span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

CustomTable.propTypes = {
  getTableProps: PropTypes.any,
  getTableBodyProps: PropTypes.any,
  headerGroups: PropTypes.any,
  prepareRow: PropTypes.any,
  page: PropTypes.any,
};

export default CustomTable;
