import { store } from '../store/index';
import { getMyCampaign, getCampaignByToken } from '../store/actions/CampaignActions';

/**
 * List my campaign
 */
export const listMyCampaign = async () => {
  const result = await store.dispatch(getMyCampaign());
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};

/**
 * Get campaign by token
 */
export const getCampaign = async (token) => {
  const result = await store.dispatch(getCampaignByToken([token]));
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};
