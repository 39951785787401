import * as Yup from 'yup';

export const dataModel = {
  amount: 1,
  // item: '',
};

export const validationDataModel = Yup.object().shape({
  amount: Yup.number()
    .required('forms.validations.required')
    .min(1, {
      message: 'forms.validations.number.min',
      args: { min: 1 },
    }),
  // item: Yup.string().required('forms.validations.required'),
});

export const setInitialValues = (initialValues) => {
  const newValues = { ...dataModel };
  if (initialValues) {
    const items = Object.keys(newValues);
    items.forEach((item) => {
      if (initialValues[item]) {
        newValues[item] = initialValues[item];
      }
    });
    return newValues;
  }

  return dataModel;
};
