import { createSelector } from 'reselect';

const getStateAuthSelector = (state) => state.auth;

export const getAuthStatusSelector = createSelector(getStateAuthSelector, (auth) => {
  if (auth && auth.status) {
    return auth.status;
  }

  return undefined;
});

export const getAuthTokenSelector = createSelector(getAuthStatusSelector, (status) => {
  if (status && status.token) {
    return status.token;
  }

  return undefined;
});

export const getTFATimeSelector = createSelector(getAuthStatusSelector, (status) => {
  if (status && status.tfaTime) {
    return status.tfaTime;
  }

  return undefined;
});

export const getAuthInfoSelector = createSelector(getStateAuthSelector, (auth) => {
  if (auth && auth.info !== undefined) {
    return auth.info;
  }

  return undefined;
});

export const getRegisterPayedSelector = createSelector(getAuthInfoSelector, (info) => {
  if (info && info.registerPayed !== undefined) {
    return info.registerPayed;
  }

  return undefined;
});

export const getWaynanceIdSelector = createSelector(getAuthInfoSelector, (info) => {
  if (info && info.waynanceCode !== undefined) {
    return info.waynanceCode;
  }

  return undefined;
});

export const getWaynanceEmailSelector = createSelector(getAuthInfoSelector, (info) => {
  if (info && info.waynanceEmail !== undefined) {
    return info.waynanceEmail;
  }

  return undefined;
});

export const getHasWaynanceWalletSelector = createSelector(getAuthInfoSelector, (info) => {
  if (info && info.hasWaynanceWallet !== undefined) {
    return info.hasWaynanceWallet;
  }

  return undefined;
});

export const getNicknameSelector = createSelector(getAuthInfoSelector, (info) => {
  if (info && (info.nickname !== undefined || info.nicknameLocal)) {
    if (info.nickname) {
      return info.nickname;
    }
    if (info.nicknameLocal) {
      return info.nicknameLocal;
    }
  }

  return undefined;
});

export const getEmailSelector = createSelector(getAuthInfoSelector, (info) => {
  if (info && info.email !== undefined) {
    return info.email;
  }

  return undefined;
});

export const getRegisterPaymentSelector = createSelector(getAuthInfoSelector, (info) => {
  if (info && info.registerPayment !== undefined) {
    return info.registerPayment;
  }

  return undefined;
});

export const getUserRoleSelector = createSelector(getAuthInfoSelector, (info) => {
  if (info && info.role) {
    return info.role;
  }
  return undefined;
});

export const getUserPersonalInfoSelector = createSelector(getAuthInfoSelector, (info) => {
  if (info) {
    const data = {
      name: info.name,
      nickname: info.nickname,
      surname: info.surname,
      nameLocal: info.nameLocal,
      nicknameLocal: info.nicknameLocal,
      surnameLocal: info.surnameLocal,
      nationality: info.nationality,
      role: info.role,
      passedKYC: info.passedKYC,
      email: info.email,
      lang: info.lang ? info.lang : 'es',
      // hasWallet: info.hasWallet,
    };
    if (info.avatar) {
      data.avatar = info.avatar;
      data.avatarUrl = info.avatarUrl;
    }
    return data;
  }

  return undefined;
});

export const getUserTreeInfoSelector = createSelector(getAuthInfoSelector, (info) => {
  if (info) {
    const data = {
      name: info.name,
      surname: info.surname,
      nickname: info.nickname,
      role: info.role,
      active: info.active,
      uuid: info.uuid,
      email: info.email,
      treeLevel: info.treeLevel,
    };
    if (info.avatar) {
      data.avatar = info.avatar;
      data.avatarUrl = info.avatarUrl;
    }
    return info;
  }

  return undefined;
});

export const getDataBilling = createSelector(getAuthInfoSelector, (info) => {
  if (info && info.billingAccept) {
    const data = {
      billingAddress: info.billingAddress,
      billingProvince: info.billingProvince,
      billingCountryUuid: info.billingCountryUuid,
      billingCity: info.billingCity,
      billingCp: info.billingCp,
      billingAccept: info.billingAccept,
      billingContactName: info.billingContactName,
      billingDocument: info.billingDocument,
    };
    return data;
  }

  return undefined;
});

export const getUserTfaEnabledSelector = createSelector(getAuthInfoSelector, (info) => {
  if (info && info.tfaEnabled) {
    return info.tfaEnabled;
  }
  return undefined;
});

export const getKycPassedSelector = createSelector(getAuthInfoSelector, (info) => {
  if (info && info.passedKYC) {
    return info.passedKYC;
  }
  return undefined;
});

export const getKycRequiredSelector = createSelector(getAuthInfoSelector, (info) => {
  if (info && info.requiredKYC) {
    return info.requiredKYC;
  }
  return undefined;
});

export const getPreferendLanguageSelector = createSelector(getAuthInfoSelector, (info) => {
  if (info && info.lang !== undefined) {
    return info.lang;
  }

  return undefined;
});

export const getUserUuidSelector = createSelector(getAuthInfoSelector, (info) => {
  if (info && info.uuid) {
    return info.uuid;
  }

  return undefined;
});

export const getEvoStatusSelector = createSelector(getAuthInfoSelector, (info) => {
  if (info && info.evoStatus !== undefined) {
    return info.evoStatus;
  }

  return undefined;
});

export const getHasWalletSelector = createSelector(getAuthInfoSelector, (info) => {
  if (info && info.hasWallet !== undefined) {
    return info.hasWallet;
  }

  return undefined;
});

export const getHasWalletGalSelector = createSelector(getAuthInfoSelector, (info) => {
  if (info && info.hasWalletGal !== undefined) {
    return info.hasWalletGal;
  }

  return undefined;
});

export const getHasWalletTreSelector = createSelector(getAuthInfoSelector, (info) => {
  if (info && info.hasWalletTrevol !== undefined) {
    return info.hasWalletTrevol;
  }

  return undefined;
});

export const getUserIsEuSelector = createSelector(getAuthInfoSelector, (info) => {
  if (info && info.isEU !== undefined) {
    return info.isEU;
  }
  return undefined;
});

export const getUserRankSelector = createSelector(getAuthInfoSelector, (info) => {
  if (info && info.rank !== undefined) {
    return info.rank;
  }
  return undefined;
});

export const getUserHistoryRankSelector = createSelector(getAuthInfoSelector, (info) => {
  if (info && info.maxHistoryRank !== undefined) {
    return info.maxHistoryRank;
  }
  return undefined;
});

export const getAcceptContractPromoterSelector = createSelector(getAuthInfoSelector, (info) => {
  if (info && info.acceptPromoterContract) {
    return info.acceptPromoterContract;
  }
  return undefined;
});

export const getIsIgniteSelector = createSelector(getAuthInfoSelector, (info) => {
  if (info && info.isIgnite !== undefined) {
    return info.isIgnite;
  }
  return undefined;
});
