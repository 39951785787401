/* eslint-disable indent */
import React from 'react';
import NitroxActivation from '../../../components/others/nitroxActivation';
import Layout from '../../../components/layouts/simpleLayout';
import PropTypes from 'prop-types';

const NitroxActivationPage = (props) => {
  return (
    <Layout
      // showUserMenu={false}
      body={
        <>
          <NitroxActivation token={props.token} reactivation={props.reactivation || false} />
        </>
      }
    />
  );
};

NitroxActivationPage.propTypes = {
  token: PropTypes.string,
  reactivation: PropTypes.bool,
};

export default NitroxActivationPage;
