import { createSelector } from 'reselect';

const getStateNavSelector = (state) => state.nav;

export const getNavFromSelector = createSelector(getStateNavSelector, (nav) => {
  if (nav && nav.from) {
    return nav.from;
  }

  return undefined;
});

export const getTimeZoneSelector = createSelector(getStateNavSelector, (nav) => {
  if (nav && nav.timeZone) {
    return nav.timeZone;
  }

  return undefined;
});

export const getLangSelector = createSelector(getStateNavSelector, (nav) => {
  if (nav && nav.language) {
    return nav.language;
  }

  return undefined;
});

export const getRegionsSelector = createSelector(getStateNavSelector, (nav) => {
  if (nav && nav.regions) {
    return nav.regions;
  }

  return undefined;
});

export const getRegionSelector = createSelector(getStateNavSelector, (nav) => {
  if (nav && nav.region) {
    return nav.region;
  }

  return undefined;
});

export const getGroupsSelector = createSelector(getStateNavSelector, (nav) => {
  if (nav && nav.groups) {
    return nav.groups;
  }

  return undefined;
});

export const getTimeGroupsSelector = createSelector(getStateNavSelector, (nav) => {
  if (nav && nav.groupsTime) {
    return nav.groupsTime;
  }

  return undefined;
});

export const getCountriesSelector = createSelector(getStateNavSelector, (nav) => {
  if (nav && nav.countries) {
    return nav.countries;
  }

  return undefined;
});

export const getEvoRefSelector = createSelector(getStateNavSelector, (nav) => {
  if (nav && nav.evoRef) {
    return nav.evoRef;
  }

  return undefined;
});

export const getCampaignSelector = createSelector(getStateNavSelector, (nav) => {
  if (nav && nav.campaign) {
    return nav.campaign;
  }

  return undefined;
});
