import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
// import { CSSTransition } from 'react-transition-group';
import * as AuthService from '../../../../services/AuthService';
import PATHS from '../../../../constants/paths';
import { ROLE_SUPPORT } from '../../../../constants/index';
// import { Button } from '@arcaitprod/react-basic-components';
// import Menu, { Item as MenuItem } from 'rc-menu';
import 'rc-menu/assets/index.css';
import {
  IconStyled,
  ButtonOption,
  SubMenu,
  NavLink,
  SubButtonOption,
  NavLinkChild,
  NavLinkMenu,
  NavContainer,
  ButtonNav,
  IconNav,
  IconCloseStyled,
  UlNav,
  BrandImage,
  SubMenuContainer,
  SubButtonOptionImage,
  LogoButton,
  IconArrowLogin,
} from './styled';
import { showDialog } from '@arcaitprod/react-utils-components';
import { Can, AbilityContext } from '../../../permissions/Can';
import { USER_PERMISSIONS } from '../../../permissions/rolesPermission';
import ReactTooltip from 'react-tooltip';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { getDataBilling } from '../../../../store/selectors/authSelector';
import { useSelector } from 'react-redux';

const Sidebar = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dataBilling = useSelector(getDataBilling);

  const handlerSelectOption = async (key) => {
    switch (key) {
      case 'home':
        history.push(PATHS.HOME);
        break;
      case 'arca':
        history.push(PATHS.ABOUT_US);
        break;
      // case 'businessPlan':
      //   history.push(PATHS.BUSINESS_PLAN);
      //   break;
      // case 'contact':
      //   history.push(PATHS.CONTACT);
      //   break;
      // case 'faqs':
      //   // window.location.href = ``;
      //   window.open(
      //     'https://evolution.ladesk.com',
      //     '_blank' // <- This is what makes it open in a new window.
      //   );
      //   break;

      //   case 'close_session':
      //  localStorage.removeItem('botToken');
      //     await AuthService.logout();
      //     history.push(PATHS.LOGIN);
      //     break;
      default:
        break;
    }
  };

  return (
    <>
      {dataBilling?.billingAccept && (
        <NavContainer className="navbar navbar-expand-lg navBackground">
          <div className="container-fluid">
            <ButtonNav
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#evolutionNavbar"
              aria-controls="evolutionNavbar"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <div>
                <IconNav className="fa-solid fa-align-left"></IconNav>
                {t('menu')}
              </div>
              <IconArrowLogin className="fa-solid fa-angle-down"></IconArrowLogin>
            </ButtonNav>
            <div className="collapse navbar-collapse" id="evolutionNavbar">
              <UlNav className="navbar-nav ms-auto mb-2 mb-lg-0 me-0 me-lg-3">
                {/* <NavLink className="selectable nav-link">
                  <span>
                    <ButtonOption
                      active={true}
                      onClick={() => {
                        this.handlerSelectOption('businessPlan');
                      }}
                    >
                      {t('nav.businessPlan')}
                    </ButtonOption>
                  </span>
                </NavLink> */}
                <NavLink className="selectable nav-link">
                  <span>
                    <ButtonOption
                      active={true}
                      onClick={() => {
                        handlerSelectOption('arca');
                      }}
                    >
                      {t('nav.arca')}
                    </ButtonOption>
                  </span>
                </NavLink>
                {/* <NavLink className="selectable nav-link">
                  <span>
                    <ButtonOption
                      active={true}
                      onClick={() => {
                        this.handlerSelectOption('contact');
                      }}
                    >
                      {t('nav.contact')}
                    </ButtonOption>
                  </span>
                </NavLink> */}
              </UlNav>
            </div>
          </div>
        </NavContainer>
      )}
    </>
  );
};

Sidebar.propTypes = {
  nickname: PropTypes.string,
  offCanvas: PropTypes.bool,
  userStatus: PropTypes.string,
  role: PropTypes.string,
};

export default Sidebar;
