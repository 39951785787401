import { store } from '../store/index';
import {
  getNationalities,
  settingTimeZone,
  setLanguage,
  getStatus,
  getItem,
  getLocalItem,
  setRegion,
  geRegionsCountriesToBuy,
  getAllPackagesGroupsSideBar,
  setTimeCachePackagesGroups,
  getAllCountries,
  getLandings,
} from '../store/actions/GeneralActions';
import * as AuthService from './AuthService';
// import * as UserService from './UserService';
import * as NavService from './NavService';

/**
 * Get all nationalities
 */
export const getAllNationalities = async () => {
  const result = await store.dispatch(getNationalities());
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};

/**
 * Get platform status
 */
export const getPlatformStatus = async () => {
  const result = await store.dispatch(getStatus());
  if (result && result.payload && result.payload.data) {
    if (result.payload.data.maintenance) {
      AuthService.logout();
      // UserService.cleanUserData();
      NavService.setCleanNav();
      NavService.setCleanDashboard();
    }
    return result.payload.data;
  }
  return null;
};

/**
 * Set Time Zone
 */
export const setTimeZone = async (obj) => {
  store.dispatch(settingTimeZone(obj));
};

/**
 * Set preferend language
 */
export const setPreferendLanguage = async (obj) => {
  store.dispatch(setLanguage(obj));
};

/**
 * Set preferred region
 */
export const setPreferredRegion = async (obj) => {
  store.dispatch(setRegion(obj));
};

/**
 * Get item
 */
export const getItemTemp = async (token, type = 'smart') => {
  let result;
  if (type === 'local') {
    result = await store.dispatch(getLocalItem(token));
  } else {
    result = await store.dispatch(getItem(token));
  }
  if (result?.payload?.data?.item) {
    return result.payload.data.item;
  }
  return null;
};

/**
 * Get all nationalities
 */
export const getRegionsCountries = async () => {
  const result = await store.dispatch(geRegionsCountriesToBuy());
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};

/**
 * Get all packages group
 */
export const getPackageGroupsSidebar = async () => {
  const result = await store.dispatch(getAllPackagesGroupsSideBar());
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};

export const setTimeCachePackageGroups = async () => {
  await store.dispatch(setTimeCachePackagesGroups(new Date().getTime()));
};

/**
 *
 * @returns
 */
export const getAllCountriesOrder = async (lang) => {
  const result = await store.dispatch(getAllCountries([lang]));
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};

/**
 * Get all landings
 */
export const getAllLandings = async () => {
  const result = await store.dispatch(getLandings());
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};
