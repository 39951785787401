import styled from 'styled-components';

export const ButtonCurrency = styled.button`
  background: transparent;
  border: ${({ active }) => (active ? '1px solid #e7e7e7' : '0px solid #35363a')};
  margin-right: 15px;
  padding: 10px;
  border-radius: 50px;
  box-shadow: ${({ active }) =>
    active
      ? 'inset -4px -4px 8px rgb(255 255 255 / 50%), inset 5px 5px 6px rgb(0 0 0 / 10%)'
      : 'none'};

  &:hover {
    box-shadow: ${({ active }) =>
      active
        ? 'inset -4px -4px 8px rgb(255 255 255 / 50%), inset 5px 5px 6px rgb(0 0 0 / 10%)'
        : '-6px -6px 10px rgba(255, 255, 255, 0.8), 6px 6px 10px rgba(0, 0, 0, 0.2)'};
    border: ${({ active }) => (!active ? '1px solid #e7e7e7' : '1px solid #e7e7e7')};
  }
`;

export const ButtonCurrencyImg = styled.img`
  background: transparent;
  height: calc(1.5em + 0.75rem + 8px) !important;
`;
