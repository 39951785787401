import React from 'react';
import PropTypes from 'prop-types';
import { withFormik, Field } from 'formik';
import { withTranslation } from 'react-i18next';
import {
  InputDateFormik,
  InputSelectFormik,
  // Autocomplete,
} from '@arcaitprod/react-forms-components';
import { dataModel, validationDataModel, setInitialValues } from './commissionFilter.data.form';
import { Button } from '@arcaitprod/react-basic-components';
import { COMMISSIONS_TYPES } from '../../../../constants/index';
import { ButtonContainer } from './commissionFilter.style';

const CommissionFilterForm = (props) => {
  const { t, handleSubmit, filtered, onClean } = props; // onUserSearch

  // const renderUser = (user) => {
  //   return <>{`${user.name}`}</>;
  // };

  return (
    <>
      <form className="form-horizontal" onSubmit={handleSubmit} autoComplete="off">
        <div className="row justify-content-end">
          {/* <div className="col-12 col-md-2 col-lg-3">
            <Field
              id="fromUserUuid"
              className="form-control"
              divClassName="fontLite"
              // labelField={t('forms.commission.labels.dateInit')}
              name="fromUserUuid"
              component={Autocomplete}
              fieldType="text"
              placeholder={t('forms.commission.labels.fromUserUuid')}
              onSearch={onUserSearch}
              optionValue="uuid"
              optionLabel="name"
              textButtonReset={t('forms.commission.buttons.resetUser')}
              renderItems={renderUser}
              // onSelect={(user) => handlerOnSelectUser(user)}
              inArrayList
            />
          </div> */}
          <div className="col-12 col-md-3 col-lg-2">
            <Field
              id="dateInit"
              className="form-control"
              name="dateInit"
              component={InputDateFormik}
              language="es"
              dateFormat="MM/yyyy"
              defaultHours={0}
              defaultMinutes={0}
              showMonthYearPicker
              showFullMonthYearPicker
              showTwoColumnMonthYearPicker
              placeholder={t('forms.commission.labels.dateInit')}
            />
          </div>
          <div className="col-12 col-md-3 col-lg-2">
            <Field
              id="dateEnd"
              className="form-control"
              name="dateEnd"
              component={InputDateFormik}
              language="es"
              dateFormat="MM/yyyy"
              defaultHours={23}
              defaultMinutes={59}
              showMonthYearPicker
              showFullMonthYearPicker
              showTwoColumnMonthYearPicker
              placeholder={t('forms.commission.labels.dateEnd')}
            />
          </div>
          <div className="col-12 col-md-5 col-lg-4 d-flex justify-content-between">
            <Field
              id="type"
              name="type"
              component={InputSelectFormik}
              placeholder=""
              optionLabel="label"
              optionValue="code"
              options={COMMISSIONS_TYPES.map((item) => ({
                ...item,
                label: t(item.label),
              }))}
              classNamePrefix="dark"
              classNameContainer="flex-grow-1"
            />
            <ButtonContainer>
              <Button type="submit" className="btn btn-md me-2" variant="primary">
                <i className="fas fa-search"></i>
              </Button>
              {/* {filtered && ( */}
              <Button
                type="button"
                className="btn btn-md"
                variant="secondary"
                onClick={() => onClean(props.resetForm)}
                title={t('cleanFilter')}
              >
                <i className="fas fa-trash"></i>
              </Button>
            </ButtonContainer>
          </div>
        </div>
      </form>
    </>
  );
};

CommissionFilterForm.propTypes = {
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  onClean: PropTypes.func,
  filtered: PropTypes.bool,
  // onUserSearch: PropTypes.func,
};

const CommissionFilterFormF = withFormik({
  mapPropsToValues: (props) => {
    if (props.initialValues) {
      return setInitialValues(props.initialValues);
    }
    return dataModel;
  },
  validationSchema: validationDataModel,
  handleSubmit: (values, { props, resetForm }) => {
    props.onSubmit(values, resetForm);
  },
  enableReinitialize: true,
  displayName: 'CommissionFilterForm',
})(withTranslation()(CommissionFilterForm));

export default CommissionFilterFormF;
