import styled from 'styled-components';

export const RankContainer = styled.div`
  border: 1px solid #fff;
  width: 100%;
  height: 140px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  background: ${({ color1, color2 }) =>
    `-moz-radial-gradient(circle, ${color1} 0%,  ${color2} 100%)`};
  background: ${({ color1, color2 }) =>
    `-webkit-radial-gradient(circle, ${color1} 0%,  ${color2} 100%)`};
  background: ${({ color1, color2 }) => `radial-gradient(circle, ${color1} 0%,  ${color2} 100%)`};
`;

export const ValueLevel = styled.span`
  /* background: #fff; */
  color: #fff;
  margin-left: 5px;
`;

export const ImgMedal = styled.img`
  /* background: #fff; */
  /* height: 65px; */
  width: 100%;
  margin-top: 2px;
`;

export const ImgRank = styled.img`
  /* background: #fff; */
  height: 100px;
`;

export const MedalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 25px;
  width: 13%;
`;

export const LabelMedal = styled.span`
  color: #fff;
  font-size: 0.65rem;
  margin-top: -3px;
`;

export const LabelRank = styled.div`
  color: #fff;
  font-size: 0.8rem;
  letter-spacing: 1px;
  text-transform: uppercase;
`;

export const ImgFounder = styled.img`
  /* background: #fff; */
  height: 100px;
  margin-bottom: 10px;
`;
