import styled, { css } from 'styled-components';

export const BannerContainer = styled.div`
  /* min-height: 50vh; */
  /* padding-top: 8vh; */
  /* margin: 0px; */
  background: ${({ breakDevice }) =>
    breakDevice > 1
      ? `url('/assets/themes/${process.env.REACT_APP_THEME}/images/Banner_Gracias.jpg')`
      : '#000'};
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 30px 20px;
  min-height: 300px;
`;

export const ThanksText = styled.div`
  color: #fff;
  font-size: 2.5rem;
  letter-spacing: 1px;
  font-weight: 800;
  font-family: 'Gotham Book';
`;

export const ThanksText2 = styled.div`
  color: #fff;
  font-size: 1.6rem;
  font-weight: 600;
  letter-spacing: 1px;
  font-family: 'Gotham Light';
`;

export const ThanksText3 = styled.div`
  color: #fff;
  font-size: 1.1rem;
  font-weight: 800;
  letter-spacing: 1px;
  line-height: 1.5rem;
  font-family: 'Gotham Light';
`;

export const ThanksText4 = styled.div`
  color: #fff;
  font-size: 1.5rem;
  font-weight: 600;
  font-family: 'Gotham Book';
`;
