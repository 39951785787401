import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  ContainerNav,
  NavItem,
  NavIcon,
  ContainerMyWallet,
  ContainerBalanceText,
  ContainerBalance,
  ContainerPaymentText,
  ContainerTotal,
  SwitchPayment,
  LabelStatus,
  FinalPrice,
  ContainerPaymentExp,
} from './checkOut.styled';
import * as Exchange from '../../../utils/formats/exchange';
import { withFormik, Field } from 'formik';
import { InputSelectFormik } from '@arcaitprod/react-forms-components';
import { dataModel, validationDataModel, setInitialValues } from './checkOu.data.form';
import { Button } from '@arcaitprod/react-basic-components';
import Select from 'react-select';
import {
  SYMBOL_MONEY,
  DISCOUNT_PRODUCT,
  TREVOL_VALUE_GAL,
  CRYPTO_PAYMENT_ENABLED,
} from '../../../constants/index';
import axios from 'axios'; // Import axios for API calls

const CheckOutView = (props) => {
  const { t } = useTranslation();
  const {
    onPrev,
    onContinue,
    walletInfo,
    walletInfoTre,
    items,
    totalShipping,
    addressSelected,
    onDistinctAddress,
    addressDistinct,
    addresses,
    onChangeAddressFact,
    onchangePaymentMethod,
    paymentMethodSelected,
    handlerPaymentMixed,
    paymentMixed,
    region,
    hasWalletGal,
    hasWalletTre,
    onInitiateCryptoPayment,
    cryptoPaymentUrl,
  } = props;

  const paymentMethods = [];

  const hasTax = addressSelected?.countryData?.EU;
  // const TAX = addressSelected?.countryData?.tax || 0;
  // const totalShippingTax = totalShipping / TAX;
  // const taxShipping = totalShipping - totalShippingTax;

  let taxes = {};
  let numItems = 0;
  let subTotal = 0;
  let totals = 0;
  let discount = 0;
  let totalPurchase = 0;
  let tax = 0;
  let payWithTre = 0;

  for (const item of items) {
    if (item) {
      numItems += Number(item.amount);
      const basePrice = Number(
        Exchange.crypto2Satoshi('GAL', region?.EU ? item.unitPrice : item.unitPriceOutEU)
      );
      const baseDiscount = Number(
        Exchange.crypto2Satoshi('GAL', region?.EU ? item.directDiscount : item.directDiscountOutEU)
      );

      const subtotalItem = basePrice * item.amount;
      subTotal += subtotalItem;
      totals += subtotalItem;

      const tokens =
        // Exchange.crypto2Satoshi('GAL', hasTax ? item.unitPriceOutTax : item.unitPrice) *
        Exchange.crypto2Satoshi('GAL', region?.EU ? item.tokens : item.tokensOutEU) *
        Number(item.amount);

      const subtotalDiscount = baseDiscount * Number(item.amount);

      discount += subtotalDiscount;
      payWithTre += tokens;

      if (hasTax) {
        const priceToAppliedTax =
          paymentMethodSelected && paymentMethodSelected.value === 'wallets' && !paymentMixed
            ? subtotalItem - subtotalDiscount
            : subtotalItem;

        const partialTax = parseInt(
          (priceToAppliedTax * (item.taxType !== 'out' ? region[item.taxType] : 0)) / 100
        );
        if (taxes[`${item.taxType}`]) {
          taxes[`${item.taxType}`].value = taxes[`${item.taxType}`].value + partialTax;
        } else {
          taxes[`${item.taxType}`] = {
            tax: item.taxType !== 'out' ? region[item.taxType] : 0,
            value: partialTax,
          };
        }
        tax += partialTax;
      }
    }
  }

  let balance = 0;
  let balanceTre = 0;
  if (walletInfo) {
    balance = Exchange.crypto2Satoshi('GAL', walletInfo.cashFlowBalance);
  }
  if (walletInfoTre) {
    balanceTre = Exchange.crypto2Satoshi('TRE', walletInfoTre.cashFlowBalance);
  }

  // discount = parseInt((subTotal * DISCOUNT) / 100);

  totalPurchase =
    subTotal +
    (paymentMethodSelected && paymentMethodSelected.value === 'wallets' && !paymentMixed
      ? -discount
      : 0);

  // if (hasTax) {
  //   tax = parseInt((totalPurchase * TAX) / 100);
  // }
  const totalPayTreGl = payWithTre;
  const totalPayTre = (payWithTre * TREVOL_VALUE_GAL) / 100;

  const totalPay = totalPurchase + totalShipping + tax;
  let totalPayGal = totalPay;

  if (hasWalletGal) {
    paymentMethods.push({ value: 'wallet', label: t('forms.checkout.labels.onlyGallons') });
    if (
      hasWalletTre &&
      balanceTre > Exchange.crypto2Satoshi('TRE', totalPayTre) &&
      balance > totalPay
    ) {
      paymentMethods.push({ value: 'wallets', label: t('forms.checkout.labels.gallonsTrevol') });
    }
    paymentMethods.push({ value: 'card', label: t('forms.checkout.labels.creditCard') });
  } else {
    paymentMethods.push({ value: 'card', label: t('forms.checkout.labels.creditCard') });
  }

  // Add Crypto payment method if enabled
  if (CRYPTO_PAYMENT_ENABLED) {
    paymentMethods.push({ value: 'crypto', label: t('forms.checkout.labels.cryptoPayment') });
  }

  if (paymentMethodSelected && paymentMethodSelected.value === 'wallets') {
    totalPayGal += -totalPayTreGl;
  }

  const newBalance = balance - totalPayGal;

  const newBalanceTre = balanceTre - Exchange.crypto2Satoshi('TRE', totalPayTre);

  let disabledPaymentMethods = false;
  if (paymentMixed || (walletInfo && walletInfo.cashFlowBalance === 0)) {
    disabledPaymentMethods = true;
  }

  const renderTotalTax = () => {
    const taxs = Object.keys(taxes);
    return taxs.map((partialTax) => {
      return (
        <div key={`typeTax_${partialTax}`} className="row mb-2">
          <ContainerPaymentText className="col-7 col-md-5">
            <b>{`${t('forms.checkout.labels.tax')} (${taxes[partialTax].tax}%)`}</b>
          </ContainerPaymentText>
          <ContainerPaymentText className="col-5 col-md-7 text-end">
            <b>{`${
              tax === 0 ? '0' : Exchange.satoshi2Crypto('GAL', taxes[partialTax].value)
            } ${SYMBOL_MONEY}`}</b>
          </ContainerPaymentText>
        </div>
      );
    });
  };

  const handleCryptoPayment = async () => {
    if (paymentMethodSelected.value === 'crypto') {
      await onInitiateCryptoPayment(totalPay);
    } else {
      onContinue();
    }
  };

  return (
    <>
      <ContainerNav className="row justify-content-center align-items-center no-margin">
        <div className="col-10 ">
          <NavItem>{t('forms.checkout.step1')}</NavItem>
          <NavIcon className="fa-solid fa-angles-right"></NavIcon>
          <NavItem>{t('forms.checkout.step2')}</NavItem>
          <NavIcon className="fa-solid fa-angles-right"></NavIcon>
          <NavItem selected>{t('forms.checkout.step3')}</NavItem>
          <NavIcon className="fa-solid fa-angles-right"></NavIcon>
          <NavItem>{t('forms.checkout.step4')}</NavItem>
        </div>
      </ContainerNav>
      <div className="row justify-content-center no-margin">
        <div className="col-11 col-lg-3">
          <div className="row mt-3">
            <ContainerMyWallet className="col-12 mb-3">
              {t('forms.checkout.addressFact')}
            </ContainerMyWallet>
          </div>
          <div className="row">
            <div className="col-12">
              <div>
                <b>{addressSelected?.contactName}</b>
              </div>
              <div>{`${addressSelected?.address}${
                addressSelected?.addressAdc ? `, ${addressSelected?.addressAdc}` : ''
              }`}</div>
              <div>{`${addressSelected?.cp}, ${addressSelected?.city} ${
                addressSelected?.province ? `(${addressSelected?.province.toUpperCase()})` : ''
              }`}</div>
              <div>{addressSelected?.countryData?.visualName.toUpperCase()}</div>
              <div>{addressSelected?.phone}</div>
              <div>{addressSelected?.email}</div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                  checked={addressDistinct}
                  onChange={(event) => onDistinctAddress(event)}
                />
                <label className="form-check-label" htmlFor="flexCheckDefault">
                  {t('forms.checkout.checkAddress')}
                </label>
              </div>
            </div>
          </div>
          {!addressDistinct && (
            <div className="row mt-3">
              <div className="col-12">
                <Field
                  id="addressSelected"
                  labelField={t('forms.address.labels.myAddressesFact')}
                  name="addressSelected"
                  component={InputSelectFormik}
                  placeholder=""
                  optionLabel="alias"
                  optionValue="uuid"
                  options={addresses}
                  classNamePrefix="dark"
                  onChangeValue={onChangeAddressFact}
                />
              </div>
            </div>
          )}

          {/* <div className="col-4 d-flex align-items-center mt-3 no-padding">
              <Button type="button" variant="primary" onClick={() => onNewAddress()}>
                {t('forms.address.titleNew')}
              </Button>
            </div> */}
        </div>
        <div className="col-11 col-lg-6">
          <div className="row">
            <div className="col-12 col-lg-5">
              {hasWalletGal && (
                <>
                  <div className="row mt-3">
                    <ContainerMyWallet className="col-12">
                      {t('forms.checkout.labels.myWallet')}
                    </ContainerMyWallet>
                  </div>
                  <div className="row">
                    <ContainerBalanceText className="col-6">
                      {t('forms.checkout.labels.balance')}
                    </ContainerBalanceText>
                    <ContainerBalance className="col-6">
                      {`${walletInfo?.cashFlowBalance} GL`}
                    </ContainerBalance>
                  </div>
                  {hasWalletTre && (
                    <div className="row">
                      <ContainerBalanceText className="col-6">
                        {t('forms.checkout.labels.balanceTRE')}
                      </ContainerBalanceText>
                      <ContainerBalance className="col-6">
                        {`${walletInfoTre?.cashFlowBalance} TRE`}
                      </ContainerBalance>
                    </div>
                  )}
                </>
              )}

              <div className="row mt-3">
                <ContainerMyWallet className="col-12">
                  {t('forms.checkout.labels.paymentMethods')}
                </ContainerMyWallet>
              </div>
              <div className="row">
                <div className="col-10">
                  <Select
                    name="paymentMethods"
                    value={paymentMethodSelected}
                    onChange={(val, event) => onchangePaymentMethod(val, event)}
                    options={paymentMethods}
                    placeholder=""
                    classNamePrefix="dark"
                    isDisabled={disabledPaymentMethods}
                  />
                </div>
              </div>
              {/* <div className="row mt-4">
                <div className="col-10">
                  <div className="serviceStatusContainer form-switch">
                    <SwitchPayment
                      statusCheck={paymentMixed}
                      className="serviceStatus form-check-input"
                      type="checkbox"
                      id="selectUnilevel"
                      onChange={(event) => {
                        handlerPaymentMixed(event.target.checked);
                      }}
                      checked={paymentMixed}
                    />
                    <LabelStatus className="form-check-label">
                      {t('forms.checkout.useTrevol')}
                    </LabelStatus>
                  </div>
                </div>
              </div> */}
            </div>
            <div className="col-12 col-lg-6">
              <div className="row mt-5 mb-2">
                <ContainerPaymentText className="col-7 col-md-5">
                  {t('forms.checkout.labels.subtotals')}
                </ContainerPaymentText>
                <ContainerPaymentText className="col-5 col-md-7 text-end">
                  {`${Exchange.satoshi2Crypto('GAL', subTotal)} ${SYMBOL_MONEY}`}
                </ContainerPaymentText>
              </div>
              {paymentMethodSelected &&
                paymentMethodSelected.value === 'wallets' &&
                !paymentMixed &&
                discount > 0 && (
                  <div className="row mb-2">
                    <ContainerPaymentText className="col-7 col-md-5">
                      {t('forms.checkout.labels.discountWallet')}
                    </ContainerPaymentText>
                    <ContainerPaymentText className="col-5 col-md-7 text-end">
                      {`-${Exchange.satoshi2Crypto('GAL', discount)} ${SYMBOL_MONEY}`}
                    </ContainerPaymentText>
                  </div>
                )}
              {/* {addressSelected?.countryData?.EU && (
                <div className="row mb-2">
                  <ContainerPaymentText className="col-5">
                    {t('forms.checkout.labels.tax')}
                  </ContainerPaymentText>
                  <ContainerPaymentText className="col-5 col-md-7 text-end">
                    {`${Exchange.satoshi2Crypto('GAL', tax)} ${SYMBOL_MONEY}`}
                  </ContainerPaymentText>
                </div>
              )} */}
              <ContainerTotal className="row mb-2 pt-2">
                <div className="col-12">
                  <div className="row mb-2">
                    <ContainerPaymentText className="col-7 col-md-5">
                      <b>{t('forms.checkout.labels.totalPurchase')}</b>
                    </ContainerPaymentText>
                    <ContainerPaymentText className="col-5 col-md-7 text-end">
                      <b>{`${Exchange.satoshi2Crypto('GAL', totalPurchase)} ${SYMBOL_MONEY}`}</b>
                    </ContainerPaymentText>
                  </div>
                  {hasTax && <>{renderTotalTax()}</>}
                  {!hasTax && (
                    <div className="row mb-2">
                      <ContainerPaymentText className="col-7 col-md-5">
                        <b>{`${t('forms.checkout.labels.tax')}`}</b>
                      </ContainerPaymentText>
                      <ContainerPaymentText className="col-5 col-md-7 text-end">
                        <b>{`${'0'} ${SYMBOL_MONEY}`}</b>
                      </ContainerPaymentText>
                    </div>
                  )}

                  <div className="row">
                    <ContainerPaymentText className="col-7 col-md-5">
                      <b> {t('forms.checkout.labels.shipping')}</b>
                    </ContainerPaymentText>
                    <ContainerPaymentText className="col-5 col-md-7 text-end">
                      <b>
                        {`${
                          totalShipping === 0 ? '0' : Exchange.satoshi2Crypto('GAL', totalShipping)
                        } ${SYMBOL_MONEY}`}
                      </b>
                    </ContainerPaymentText>
                  </div>
                </div>
              </ContainerTotal>
              <ContainerTotal className="row mb-2 pt-2">
                <div className="col-12">
                  <div className="row mb-2">
                    <ContainerPaymentText className="col-7 col-md-5">
                      <FinalPrice>{t('forms.checkout.labels.totalPay')}</FinalPrice>
                    </ContainerPaymentText>
                    <ContainerPaymentText className="col-5 col-md-7 text-end">
                      <FinalPrice>{`${Exchange.satoshi2Crypto(
                        'GAL',
                        totalPay
                      )} ${SYMBOL_MONEY}`}</FinalPrice>
                    </ContainerPaymentText>
                  </div>
                </div>
              </ContainerTotal>
              {paymentMethodSelected &&
                (paymentMethodSelected.value === 'wallet' ||
                  paymentMethodSelected.value === 'wallets') && (
                  <>
                    <div className="row mt-5 mb-2">
                      <ContainerPaymentText className="col-7 col-md-5">
                        {t('forms.checkout.labels.balance')}
                      </ContainerPaymentText>
                      <ContainerPaymentText className="col-5 col-md-7 text-end">
                        {`${walletInfo?.cashFlowBalance ? walletInfo?.cashFlowBalance : '0'} GL`}
                      </ContainerPaymentText>
                    </div>
                    {paymentMixed && (
                      <div className="row mb-2 pt-2">
                        <ContainerPaymentText className="col-7 col-md-5">
                          {t('forms.checkout.labels.payCreditCard')}
                        </ContainerPaymentText>
                        <ContainerPaymentText className="col-5 col-md-7 text-end">
                          {`${Exchange.satoshi2Crypto('GAL', newBalance * -1)} ${SYMBOL_MONEY}`}
                        </ContainerPaymentText>
                      </div>
                    )}
                    {paymentMethodSelected.value === 'wallets' && (
                      <>
                        <div className="row pt-2">
                          <ContainerPaymentText className="col-7 col-md-5">
                            <b>{t('forms.checkout.labels.totalPayTRE')}</b>
                          </ContainerPaymentText>
                          <ContainerPaymentText className="col-5 col-md-7 text-end">
                            <b>{`-${totalPayTre} TRE`}</b>
                          </ContainerPaymentText>
                        </div>
                        <div className="row mb-1">
                          <ContainerPaymentText className="col-7 col-md-5"></ContainerPaymentText>
                          <ContainerPaymentExp className="col-5 col-md-7 text-end">
                            <b>{`(-${Exchange.satoshi2Crypto('GAL', totalPayTreGl)} GL)`}</b>
                          </ContainerPaymentExp>
                        </div>
                      </>
                    )}
                    <div className="row mb-2 pt-2">
                      <ContainerPaymentText className="col-7 col-md-5">
                        <b>{t('forms.checkout.labels.totalPay')}</b>
                      </ContainerPaymentText>
                      <ContainerPaymentText className="col-5 col-md-7 text-end">
                        <b>{`-${Exchange.satoshi2Crypto('GAL', totalPayGal)} GL`}</b>
                      </ContainerPaymentText>
                    </div>

                    <ContainerTotal className="row mb-2 pt-2">
                      <ContainerPaymentText className="col-7 col-md-5">
                        <FinalPrice>{t('forms.checkout.labels.newBalanceGal')}</FinalPrice>
                      </ContainerPaymentText>
                      <ContainerPaymentText className="col-5 col-md-7 text-end">
                        <FinalPrice>{`${
                          paymentMixed ? '0' : Exchange.satoshi2Crypto('GAL', newBalance)
                        } GL`}</FinalPrice>
                      </ContainerPaymentText>
                    </ContainerTotal>
                    {paymentMethodSelected.value === 'wallets' && (
                      <ContainerTotal className="row mb-2 pt-2">
                        <ContainerPaymentText className="col-7 col-md-5">
                          <FinalPrice>{t('forms.checkout.labels.newBalanceTre')}</FinalPrice>
                        </ContainerPaymentText>
                        <ContainerPaymentText className="col-5 col-md-7 text-end">
                          <FinalPrice>{`${
                            paymentMixed ? '0' : Exchange.satoshi2Crypto('TRE', newBalanceTre)
                          } TRE`}</FinalPrice>
                        </ContainerPaymentText>
                      </ContainerTotal>
                    )}
                  </>
                )}
            </div>
          </div>
        </div>
      </div>

      {newBalance < 0 && paymentMethodSelected && paymentMethodSelected.value === 'wallet' && (
        <>
          <div className="row justify-content-center mt-4  no-margin">
            <div className="col-6 text-center">{t('forms.checkout.error_founds_description')}</div>
          </div>
          <div className="row justify-content-center mt-4  no-margin">
            <div className="col-6 text-center">
              <div className="serviceStatusContainer form-switch">
                <SwitchPayment
                  statusCheck={paymentMixed}
                  className="serviceStatus form-check-input"
                  type="checkbox"
                  id="selectUnilevel"
                  onChange={(event) => {
                    handlerPaymentMixed(event.target.checked);
                  }}
                  checked={paymentMixed}
                />
                <LabelStatus className="form-check-label">{t('forms.checkout.mixed')}</LabelStatus>
              </div>
            </div>
          </div>
        </>
      )}
      <ContainerNav className="row justify-content-center align-items-center no-margin mt-5 mt-md-0">
        <div className="col-10 text-center">
          <div className="d-grid gap-2 d-md-block">
            <Button
              type="button"
              className="me-0 me-md-3"
              variant="primary"
              onClick={() => onPrev()}
            >
              {t('forms.checkout.buttons.previous')}
            </Button>
            {((newBalance > 0 &&
              paymentMethodSelected &&
              (paymentMethodSelected.value === 'wallet' ||
                paymentMethodSelected.value === 'wallets')) ||
              paymentMixed ||
              (paymentMethodSelected && 
                (paymentMethodSelected.value === 'card' || 
                 paymentMethodSelected.value === 'crypto'))) && (
              <Button type="button" variant="primary" onClick={handleCryptoPayment}>
                {t('forms.checkout.buttons.continue')}
              </Button>
            )}
          </div>
        </div>
      </ContainerNav>
      <div className="row justify-content-center mt-4">
        <div className="col-12 text-center">{t('infoGallons')}</div>
      </div>
    </>
  );
};

CheckOutView.propTypes = {
  onPrev: PropTypes.func,
  onContinue: PropTypes.func,
  walletInfo: PropTypes.object,
  items: PropTypes.array,
  totalShipping: PropTypes.number,
  addressSelected: PropTypes.any,
  onDistinctAddress: PropTypes.func,
  addressDistinct: PropTypes.bool,
  addresses: PropTypes.array,
  onChangeAddressFact: PropTypes.func,
  onchangePaymentMethod: PropTypes.func,
  paymentMethodSelected: PropTypes.any,
  handlerPaymentMixed: PropTypes.func,
  paymentMixed: PropTypes.bool,
  region: PropTypes.object,
  hasWalletGal: PropTypes.bool,
  walletInfoTre: PropTypes.object,
  hasWalletTre: PropTypes.bool,
  onInitiateCryptoPayment: PropTypes.func,
  cryptoPaymentUrl: PropTypes.string,
};

const CheckOutViewF = withFormik({
  mapPropsToValues: (props) => {
    if (props.initialValues) {
      return setInitialValues(props.initialValues);
    }
    return dataModel;
  },
  validationSchema: (props) => {
    return validationDataModel(props.edit);
  },
  handleSubmit: (values, { props, resetForm }) => {
    props.onSubmit(values, resetForm);
  },
  enableReinitialize: true,
  displayName: 'CheckOutView',
})(CheckOutView);

export default CheckOutViewF;
