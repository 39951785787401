import styled from 'styled-components';

export const LabelValue = styled.div`
  /* background: #fff; */
  color: #fff;
  font-size: 0.7rem;
  display: flex;
  align-items: center;
  margin-right: 15px;
  margin-top: 5px;
`;

export const ImageHeader = styled.img`
  height: 30px;
  margin-right: 10px;
`;

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  /* display: flex;
  align-items: center; */
`;

export const Status = styled.div`
  color: #fff;
  font-size: 0.65rem;
  display: flex;
  align-items: center;
  margin-top: 5px;
  margin-right: 30px;
`;
