import styled from 'styled-components';

export const HeaderContainer = styled.div`
  /* min-height: 5vh; */
  background: ${({ theme, type }) => (type === 'login' ? 'none' : theme.header.backgroundColor)};
  height: 65px;
`;

export const Logo = styled.img`
  height: 44px;
`;

export const LogoMobile = styled.img`
  height: 45px;
`;

export const SidebarButtonContainer = styled.div`
  display: flex;
  outline: 0px auto !important;
  color: #fff;
`;

export const SidebarButton = styled.button`
  outline: 0px auto !important;
  color: #575861;
  font-size: 1.5rem;
  padding: 0px 5px 0px 15px;
  background: transparent;
  border: 0px;
  margin-top: 2px;
`;

export const IconUser = styled.i`
  font-size: 0.775rem;
  margin-right: 5px;
  color: #a6a79f;
`;

export const IconArrowLogin = styled.i`
  font-size: 0.775rem;
  margin-left: 5px;
  color: #a6a79f;
`;

export const LabelLogin = styled.span`
  margin-right: 5px;
  margin-top: 3px;
  color: #a6a79f;
`;

export const LoginButton = styled.button`
  background: transparent;
  border: 0px;
  text-decoration: none;
  text-transform: capitalize;
  color: #a6a79f;
  font-size: 0.85rem;
  font-family: 'Lato', sans-serif !important;
  margin-right: 10px;
  display: flex;
  align-items: center;
`;

export const RegisterButton = styled.button`
  background: ${({ theme }) => theme.colors.primary};
  border: 0px;
  text-decoration: none;
  text-transform: uppercase;
  color: #fff;
  font-size: 0.875rem;
  font-family: 'Lato', sans-serif !important;
  margin-right: 20px;
  border-radius: 20px;
  padding: 4px 20px;
  height: 26px;
`;
