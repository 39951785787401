import styled, { css } from 'styled-components';
import InputNumber from '../../utils/InputNumber';

export const InputFake = styled.div`
  display: flex;
  align-items: center;
`;

export const InputAmount = styled(InputNumber)`
  height: 25px !important;
  font-size: 0.7rem !important;
  border-radius: 5px !important;
`;

export const NavItem = styled.span`
  font-size: ${({ selected }) => (selected ? '1rem' : '0.9rem')};
  font-weight: ${({ selected }) => (selected ? 'bold' : 'normal')};
  color: ${({ theme, selected }) => (selected ? theme.colors.primary : theme.colors.white)};
  text-decoration: ${({ selected }) => (selected ? 'underline' : 'none')};
`;

export const NavIcon = styled.span`
  font-size: 0.8rem;
  color: ${({ theme }) => theme.colors.primary};
  margin-left: 10px;
  margin-right: 10px;
`;

export const ContainerResume = styled.div`
  font-weight: bold;
  font-size: 1rem;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.white};
`;

export const AlertAddress = styled.div`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.primary};
`;

export const ContainerTotals = styled.div`
  font-weight: bold;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.primary};
`;

export const ContainerSubTotals = styled.div`
  font-weight: normal;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.white};
`;

export const ContainerItem = styled.div`
  border-bottom: 1px solid #fff;
  color: #fff;
`;

export const ContainerNav = styled.div`
  height: 100px;
  border-bottom: 1px solid #fff;
`;

export const Text04 = styled.span`
  font-weight: normal;
  font-size: 0.8rem;
`;

export const ImageItem = styled.img`
  width: 100%;
  /* max-height: 150px; */
`;

export const ButtonDeleteItem = styled.button`
  background: transparent !important;
  border: none !important;
  color: #469fa3;
`;

export const ContainerListItems = styled.div`
  max-height: 400px;
  overflow: auto !important;
`;

export const ContainerMyWallet = styled.div`
  font-weight: normal;
  font-size: 1.5rem;
  color: #fff;
`;

export const ContainerBalanceText = styled.div`
  font-weight: normal;
  font-size: 0.875rem;
`;

export const ContainerBalance = styled.div`
  font-weight: bold;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.primary};
`;

export const ContainerBalanceExp = styled.div`
  font-weight: bold;
  font-size: 0.7rem;
  color: ${({ theme }) => theme.colors.primary};
`;

export const ContainerPaymentText = styled.div`
  font-weight: normal;
  font-size: 0.875rem;
`;

export const ContainerPaymentExp = styled.div`
  font-weight: normal;
  font-size: 0.775rem;
`;

export const ContainerTotal = styled.div`
  border-top: 1px solid #ccc;
`;

export const SwitchPayment = styled.input`
  background-color: ${({ statusCheck }) =>
    statusCheck ? '#469fa3 !important' : '#fff !important'};
  border-color: ${({ statusCheck }) => (statusCheck ? '#469fa3 !important' : '#bfbfbf !important')};
  box-shadow: rgb(74 74 104 / 20%) 0px 2px 2px 0px inset !important;
  /* width: 3rem !important; */
`;

export const LabelStatus = styled.label`
  color: #469fa3;
  font-size: 0.85rem;
  margin-left: 10px;
`;

export const ShippingCostText = styled.div`
  font-weight: bold;
  font-size: 1.25rem;
  color: #469fa3;
`;

export const ContainerAddress = styled.div`
  border: 1px solid #469fa3;
  border-radius: 10px;
  padding: 0.8rem 1.2rem;
  color: #fff;
`;

export const FinalPrice = styled.b`
  color: #469fa3;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #fff;
`;
