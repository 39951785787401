import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  ContainerNav,
  NavItem,
  NavIcon,
  ContainerMyWallet,
  ContainerBalanceText,
  ContainerBalance,
  ContainerItem,
  ImageItem,
  ContainerResume,
  ContainerTotals,
  ContainerSubTotals,
  ContainerListItems,
  FinalPrice,
  Divider,
  ContainerBalanceExp,
  Text04,
} from './checkOut.styled';
import * as Exchange from '../../../utils/formats/exchange';
import { withFormik } from 'formik';
import { dataModel, validationDataModel, setInitialValues } from './checkOu.data.form';
import { Button } from '@arcaitprod/react-basic-components';
import { SYMBOL_MONEY, TREVOL_VALUE_GAL } from '../../../constants/index';

const CheckOutView = (props) => {
  const { t } = useTranslation();
  const {
    onPrev,
    finish,
    walletInfo,
    walletInfoTre,
    items,
    totalShipping,
    addressSelected,
    paymentMethodSelected,
    addressFactSelected,
    paymentMixed,
    region,
    hasWalletGal,
    hasWalletTre,
  } = props;

  const hasTax = addressFactSelected?.countryData?.EU;
  // const TAX = addressSelected?.countryData?.tax || 0;

  // const totalShippingTax = totalShipping / TAX;
  // const taxShipping = totalShipping - totalShippingTax;

  let taxes = {};
  let numItems = 0;
  let subTotal = 0;
  let totals = 0;
  let discount = 0;
  let totalPurchase = 0;
  let tax = 0;
  let payWithTre = 0;

  for (const item of items) {
    if (item) {
      numItems += Number(item.amount);
      const basePrice = Number(
        Exchange.crypto2Satoshi('GAL', region?.EU ? item.unitPrice : item.unitPriceOutEU)
      );
      const baseDiscount = Number(
        Exchange.crypto2Satoshi('GAL', region?.EU ? item.directDiscount : item.directDiscountOutEU)
      );

      const subtotalItem = basePrice * item.amount;
      subTotal += subtotalItem;
      totals += subtotalItem;

      const tokens =
        // Exchange.crypto2Satoshi('GAL', hasTax ? item.unitPriceOutTax : item.unitPrice) *
        Exchange.crypto2Satoshi('GAL', region?.EU ? item.tokens : item.tokensOutEU) *
        Number(item.amount);

      const subtotalDiscount = baseDiscount * Number(item.amount);

      discount += subtotalDiscount;
      payWithTre += tokens;

      if (hasTax) {
        const priceToAppliedTax =
          paymentMethodSelected && paymentMethodSelected.value === 'wallets' && !paymentMixed
            ? subtotalItem - subtotalDiscount
            : subtotalItem;

        const partialTax = parseInt(
          (priceToAppliedTax * (item.taxType !== 'out' ? region[item.taxType] : 0)) / 100
        );
        if (taxes[`${item.taxType}`]) {
          taxes[`${item.taxType}`].value = taxes[`${item.taxType}`].value + partialTax;
        } else {
          taxes[`${item.taxType}`] = {
            tax: item.taxType !== 'out' ? region[item.taxType] : 0,
            value: partialTax,
          };
        }
        tax += partialTax;
      }
    }
  }

  let balance = 0;
  let balanceTre = 0;
  if (walletInfo) {
    balance = Exchange.crypto2Satoshi('GAL', walletInfo.cashFlowBalance);
  }
  if (walletInfoTre) {
    balanceTre = Exchange.crypto2Satoshi('TRE', walletInfoTre.cashFlowBalance);
  }

  // discount = parseInt((subTotal * DISCOUNT) / 100);
  totalPurchase =
    subTotal +
    (paymentMethodSelected && paymentMethodSelected.value === 'wallets' && !paymentMixed
      ? -discount
      : 0);

  // if (hasTax) {
  //   tax = parseInt((totalPurchase * TAX) / 100);
  // }

  const renderItem = () => {
    return items.map((item, index) => {
      if (item) {
        numItems += Number(item.amount);
        let partialTax = 0;
        const itemPrice = Exchange.crypto2Satoshi(
          'GAL',
          region?.EU ? item.unitPrice : item.unitPriceOutEU
        );
        let subtotal = itemPrice * item.amount;

        if (region?.EU) {
          partialTax = parseInt(
            (itemPrice * (item.taxType !== 'out' ? region[item.taxType] : 0)) / 100
          );
          subtotal += partialTax * item.amount;
        }

        return (
          <ContainerItem key={`${item.packageUuid}_${index}`} className="row pb-3 pt-3">
            <div className="col-2 d-none d-md-block">
              <ImageItem src={item.image}></ImageItem>
            </div>
            <div className="col-12 col-md-10">
              <div className="row mb-1">
                <div className="col-10">
                  <b>{item.packageTitle}</b>
                </div>
                {/* <div className="col-2">
                  <ButtonDeleteItem type="button" onClick={() => onRemove(item, index)}>
                    <i className="fa-solid fa-trash-can"></i>
                  </ButtonDeleteItem>
                </div> */}
              </div>
              <div className="row mb-1"></div>
              <div className="row">
                <div className="col-12 col-md-3 d-flex">
                  <div className="me-1"> {`${t('forms.shoppingCart.labels.amount')}:`}</div>
                  <div>
                    <b>{` ${item.amount}`}</b>
                  </div>
                </div>
                <div className="col-12 col-md-4 d-flex flex-column">
                  <div className="d-flex">
                    <div className="me-1">{`${t('forms.shoppingCart.labels.price')}:`}</div>
                    <div>
                      <b>{`${
                        region?.EU ? item.unitPrice : item.unitPriceOutEU
                      } ${SYMBOL_MONEY}`}</b>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="me-1">{`${t('forms.shoppingCart.labels.tax')}${
                      region.EU ? `(${item.taxType !== 'out' ? region[item.taxType] : 0}%)` : ''
                    }:`}</div>
                    <div>
                      <b>{`${Exchange.satoshi2Crypto('GAL', partialTax)} ${SYMBOL_MONEY}`}</b>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-5 d-flex text-end">
                  <div className="me-1">{`${t('forms.shoppingCart.total')}:`}</div>
                  <div>
                    <b>{`${Exchange.satoshi2Crypto('GAL', subtotal)} ${SYMBOL_MONEY}`}</b>
                  </div>
                </div>
              </div>
            </div>
          </ContainerItem>
        );
      }
    });
  };

  const totalPayTreGl = payWithTre;
  const totalPayTre = (payWithTre * TREVOL_VALUE_GAL) / 100;

  const totalPay = totalPurchase + totalShipping + tax;
  let totalPayGal = totalPay;

  if (paymentMethodSelected && paymentMethodSelected.value === 'wallets') {
    totalPayGal += -totalPayTreGl;
  }

  const newBalance = balance - totalPayGal;
  const newBalanceTre = balanceTre - Exchange.crypto2Satoshi('TRE', totalPayTre);

  const renderTotalTax = () => {
    const taxs = Object.keys(taxes);
    return taxs.map((partialTax) => {
      return (
        <div
          key={`typeTax_${partialTax}`}
          className="row justify-content-center align-items-center mb-2"
        >
          <ContainerTotals className="col-5">{`${t('forms.checkout.labels.tax')} (${
            taxes[partialTax].tax
          }%)`}</ContainerTotals>
          <ContainerSubTotals className="col-4 text-end">
            {`${
              tax === 0 ? '0' : Exchange.satoshi2Crypto('GAL', taxes[partialTax].value)
            } ${SYMBOL_MONEY}`}
          </ContainerSubTotals>
        </div>
      );
    });
  };

  let showButtonFinish = false;
  if (
    newBalance > 0 ||
    paymentMixed ||
    paymentMethodSelected.value === 'card' ||
    paymentMethodSelected.value === 'crypto'
  ) {
    showButtonFinish = true;
  }

  return (
    <>
      <ContainerNav className="row justify-content-center align-items-center no-margin">
        <div className="col-10 ">
          <NavItem>{t('forms.checkout.step1')}</NavItem>
          <NavIcon className="fa-solid fa-angles-right"></NavIcon>
          <NavItem>{t('forms.checkout.step2')}</NavItem>
          <NavIcon className="fa-solid fa-angles-right"></NavIcon>
          <NavItem>{t('forms.checkout.step3')}</NavItem>
          <NavIcon className="fa-solid fa-angles-right"></NavIcon>
          <NavItem selected>{t('forms.checkout.step4')}</NavItem>
        </div>
      </ContainerNav>
      <div className="row justify-content-center no-margin">
        <div className="col-11 col-lg-4">
          <div className="row mt-3">
            <ContainerMyWallet className="col-12 mb-2 d-flex flex-column">
              {t('forms.checkout.addressSend')}
              <Text04>{t('forms.checkout.addressSend2')}</Text04>
            </ContainerMyWallet>
          </div>
          <div className="row">
            <div className="col-12">
              <div>
                <b>{addressSelected?.contactName}</b>
              </div>
              <div>{`${addressSelected?.address}${
                addressSelected?.addressAdc ? `, ${addressSelected?.addressAdc}` : ''
              }`}</div>
              <div>{`${addressSelected?.cp}, ${addressSelected?.city} ${
                addressSelected?.province ? `(${addressSelected?.province.toUpperCase()})` : ''
              }`}</div>
              <div>{addressSelected?.countryData?.visualName.toUpperCase()}</div>
              <div>{addressSelected?.phone}</div>
              <div>{addressSelected?.email}</div>
            </div>
          </div>
          <div className="row mt-3">
            <ContainerMyWallet className="col-12 mb-2">
              {t('forms.checkout.addressFact')}
            </ContainerMyWallet>
          </div>
          <div className="row">
            <div className="col-12">
              <div>
                <b>{addressFactSelected?.contactName}</b>
              </div>
              <div>{`${addressFactSelected?.address}${
                addressFactSelected?.addressAdc ? `, ${addressFactSelected?.addressAdc}` : ''
              }`}</div>
              <div>{`${addressFactSelected?.cp}, ${addressFactSelected?.city} ${
                addressFactSelected?.province
                  ? `(${addressFactSelected?.province.toUpperCase()})`
                  : ''
              }`}</div>
              <div>{addressFactSelected?.countryData?.visualName.toUpperCase()}</div>
              <div>{addressFactSelected?.phone}</div>
              <div>{addressFactSelected?.email}</div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="row mt-3">
                <ContainerMyWallet className="col-12 mb-2">
                  {t('forms.checkout.labels.paymentMode')}
                </ContainerMyWallet>
              </div>
              <div className="row">
                <ContainerBalanceText className="col-12 col-md-6 mt-2">
                  {paymentMixed
                    ? t('forms.checkout.labels.paymentMixed')
                    : paymentMethodSelected?.label || ''}
                </ContainerBalanceText>
              </div>
              {(paymentMethodSelected.value === 'wallet' ||
                paymentMethodSelected.value === 'wallets') && (
                <>
                  <div className="row mt-3">
                    <ContainerBalanceText className="col-7 col-md-6">
                      {t('forms.checkout.labels.payWalletGal')}
                    </ContainerBalanceText>
                    <ContainerBalance className="col-5 col-md-3 text-end">{`${
                      walletInfo?.cashFlowBalance && paymentMixed
                        ? walletInfo?.cashFlowBalance
                        : Exchange.satoshi2Crypto('GAL', totalPayGal)
                    } GL`}</ContainerBalance>
                  </div>
                  {paymentMethodSelected.value === 'wallets' && (
                    <>
                      <div className="row mt-3">
                        <ContainerBalanceText className="col-7 col-md-6">
                          {t('forms.checkout.labels.payWalletTre')}
                        </ContainerBalanceText>
                        <ContainerBalance className="col-5 col-md-3 text-end">{`${totalPayTre} TRE`}</ContainerBalance>
                      </div>
                      <div className="row">
                        <ContainerBalanceText className="col-7 col-md-6"></ContainerBalanceText>
                        <ContainerBalanceExp className="col-5 col-md-3 text-end">{`(${Exchange.satoshi2Crypto(
                          'GAL',
                          totalPayTreGl
                        )} GL)`}</ContainerBalanceExp>
                      </div>
                    </>
                  )}

                  {paymentMixed && (
                    <div className="row mt-3">
                      <ContainerBalanceText className="col-7 col-md-6">
                        {t('forms.checkout.labels.payCreditCard')}
                      </ContainerBalanceText>
                      <ContainerBalance className="col-5 col-md-3 text-end">{`${Exchange.satoshi2Crypto(
                        'GAL',
                        newBalance * -1
                      )} ${SYMBOL_MONEY}`}</ContainerBalance>
                    </div>
                  )}

                  <div className="row  mt-3">
                    <ContainerBalanceText className="col-7 col-md-6">
                      {t('forms.checkout.labels.balanceAfter')}
                    </ContainerBalanceText>
                    <ContainerBalance className="col-5 col-md-3 text-end">{`${
                      paymentMixed ? '0' : Exchange.satoshi2Crypto('GAL', newBalance)
                    } GL`}</ContainerBalance>
                  </div>
                  {paymentMethodSelected.value === 'wallets' && (
                    <div className="row  mt-3">
                      <ContainerBalanceText className="col-7 col-md-6">
                        {t('forms.checkout.labels.balanceAfterTre')}
                      </ContainerBalanceText>
                      <ContainerBalance className="col-5 col-md-3 text-end">{`${Exchange.satoshi2Crypto(
                        'TRE',
                        newBalanceTre
                      )} TRE`}</ContainerBalance>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          {/* <div className="col-4 d-flex align-items-center mt-3 no-padding">
              <Button type="button" variant="primary" onClick={() => onNewAddress()}>
                {t('forms.address.titleNew')}
              </Button>
            </div> */}
        </div>
        <div className="col-11 col-lg-5">
          <div className="row justify-content-center align-items-center mt-4">
            <ContainerResume className="col-11 mb-3">{t('forms.checkout.resume')}</ContainerResume>
          </div>
          <div className="row justify-content-center align-items-center mb-4">
            <ContainerListItems className="col-11">
              {items && items.length > 0 && renderItem()}
            </ContainerListItems>
          </div>
          <div className="row justify-content-center align-items-center mb-2 mt-5">
            <ContainerTotals className="col-5">{`${t(
              'forms.checkout.labels.subtotals'
            )}`}</ContainerTotals>
            <ContainerSubTotals className="col-4 text-end">{`${Exchange.satoshi2Crypto(
              'GAL',
              subTotal
            )} ${SYMBOL_MONEY}`}</ContainerSubTotals>
          </div>
          {paymentMethodSelected.value === 'wallets' && !paymentMixed && discount > 0 && (
            <div className="row justify-content-center align-items-center mb-2">
              <ContainerTotals className="col-5">{`${t(
                'forms.checkout.labels.discountWallet'
              )}`}</ContainerTotals>
              <ContainerSubTotals className="col-4 text-end">{`-${
                discount === 0 ? '0' : Exchange.satoshi2Crypto('GAL', discount)
              } ${SYMBOL_MONEY}`}</ContainerSubTotals>
            </div>
          )}
          <div className="row justify-content-center align-items-center">
            <div className="col-10">
              <Divider></Divider>
            </div>
          </div>
          <div className="row justify-content-center align-items-center mb-2 mt-2">
            <ContainerTotals className="col-5">{`${t(
              'forms.checkout.labels.totalPurchase'
            )}`}</ContainerTotals>
            <ContainerSubTotals className="col-4 text-end">{`${
              totalPurchase === 0 ? '0' : Exchange.satoshi2Crypto('GAL', totalPurchase)
            } ${SYMBOL_MONEY}`}</ContainerSubTotals>
          </div>
          {hasTax && <>{renderTotalTax()}</>}
          {!hasTax && (
            <div className="row justify-content-center align-items-center mb-2">
              <ContainerTotals className="col-5">{`${t(
                'forms.checkout.labels.tax'
              )}`}</ContainerTotals>
              <ContainerSubTotals className="col-4 text-end">
                {`0 ${SYMBOL_MONEY}`}
              </ContainerSubTotals>
            </div>
          )}

          <div className="row justify-content-center align-items-center mb-2">
            <ContainerTotals className="col-5">{`${t(
              'forms.checkout.labels.shipping'
            )}`}</ContainerTotals>
            <ContainerSubTotals className="col-4 text-end">{`${
              totalShipping === 0 ? '0' : Exchange.satoshi2Crypto('GAL', totalShipping)
            } ${SYMBOL_MONEY}`}</ContainerSubTotals>
          </div>
          <div className="row justify-content-center align-items-center">
            <div className="col-10">
              <Divider></Divider>
            </div>
          </div>
          <div className="row justify-content-center align-items-center mt-2">
            <ContainerTotals className="col-5">
              <FinalPrice>{`${t('forms.checkout.labels.totalPay')}`}</FinalPrice>
            </ContainerTotals>
            <ContainerTotals className="col-4 text-end">
              <FinalPrice>{`${Exchange.satoshi2Crypto(
                'GAL',
                totalPay
              )} ${SYMBOL_MONEY}`}</FinalPrice>
            </ContainerTotals>
          </div>
        </div>
      </div>
      {/* {newBalance < 0 && (
        <div className="row justify-content-center mt-4">
          <div className="col-6 text-center">{t('forms.checkout.error_founds')}</div>
        </div>
      )} */}
      <ContainerNav className="row justify-content-center align-items-center mt-5 no-margin">
        <div className="col-10 text-center">
          <div className="d-grid gap-2 d-md-block">
            <Button
              type="button"
              className="me-0 me-md-3"
              variant="primary"
              onClick={() => onPrev()}
            >
              {t('forms.checkout.buttons.previous')}
            </Button>
            {showButtonFinish && (
              <Button type="button" variant="primary" onClick={() => finish()}>
                {t('forms.checkout.buttons.finish')}
              </Button>
            )}
          </div>
        </div>
      </ContainerNav>
      <div className="row justify-content-center mt-4 pb-5">
        <div className="col-12 text-center">{t('infoGallons')}</div>
      </div>
    </>
  );
};

CheckOutView.propTypes = {
  onPrev: PropTypes.func,
  onContinue: PropTypes.func,
  walletInfo: PropTypes.object,
  items: PropTypes.array,
  totalShipping: PropTypes.number,
  addressSelected: PropTypes.any,
  addressFactSelected: PropTypes.any,
  addressDistinct: PropTypes.bool,
  addresses: PropTypes.array,
  onChangeAddressFact: PropTypes.func,
  finish: PropTypes.func,
  paymentMethodSelected: PropTypes.any,
  paymentMixed: PropTypes.bool,
};

const CheckOutViewF = withFormik({
  mapPropsToValues: (props) => {
    if (props.initialValues) {
      return setInitialValues(props.initialValues);
    }
    return dataModel;
  },
  validationSchema: (props) => {
    return validationDataModel(props.edit);
  },
  handleSubmit: (values, { props, resetForm }) => {
    props.onSubmit(values, resetForm);
  },
  enableReinitialize: true,
  displayName: 'CheckOutView',
})(CheckOutView);

export default CheckOutViewF;
