import React, { Component } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import MyProfileForm from './myProfile.view';
import { withTranslation } from 'react-i18next';
// import * as UserService from '../../../services/UserService';
import { getUserPersonalInfoSelector } from '../../../store/selectors/authSelector';
import * as UserService from '../../../services/UserService';
import { showDialog } from '@arcaitprod/react-utils-components';

class MyProfileContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      from: null,
    };
  }

  componentDidMount() {
    const query = queryString.parse(this.props.location.search);
    if (query && query.from) {
      this.setState({ from: query.from });
    }
  }

  handleSubmit = async (values) => {
    try {
      const result = await UserService.updateMyProfile(values);
      let title = '';
      let text = '';
      let icon = '';
      if (result && result === 'OK') {
        await UserService.getAuthUserProfile();
        title = this.props.t('forms.myProfile.messages.update.title');
        text = this.props.t('forms.myProfile.messages.update.success');
        icon = 'success';
      } else {
        title = 'Error';
        text = this.props.t('forms.myProfile.messages.update.error');
        icon = 'error';
      }

      showDialog(
        title,
        text,
        icon,
        false,
        'OK',
        false,
        undefined,
        undefined,
        undefined,
        '#E83C7E',
        '#12374e'
      );
    } catch (error) {
      //console.log('Error', error);
    }
  };

  render() {
    return (
      <MyProfileForm
        userInfo={this.props.info}
        initialValues={this.props.info}
        onSubmit={this.handleSubmit}
      />
    );
  }
}

MyProfileContainer.propTypes = {};

const mapStateToProps = (state) => ({
  info: getUserPersonalInfoSelector(state),
});

export default withRouter(connect(mapStateToProps, {})(withTranslation()(MyProfileContainer)));
