import React from 'react';
import FilterList from '../../../../components/user/order/orderFilterList';
import Layout from '../../../../components/layouts/simpleLayout';
import PropTypes from 'prop-types';

const OrderListPage = (props) => {
  return (
    <Layout
      showBanner
      bannerName="order.jpg"
      textBanner="order"
      bannerPosition="25"
      body={<FilterList general={props.general} />}
    />
  );
};

OrderListPage.propTypes = {};

export default OrderListPage;
