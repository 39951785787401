import { createAction } from 'redux-actions';
import { apiGet, apiPost, apiPut, apiDelete } from '../../api/index';

import { urlAddressSecured, urlSetDefaultAddressSecured } from '../../api/urls';

import {
  PAGE_SIZE_10,
  FILTER_ADDRESS,
  GET_ADDRESS,
  UPDATE_ADDRESS,
  SET_DEFAULT_ADDRESS,
  DELETE_ADDRESS,
  CREATE_ADDRESS,
} from '../../constants/index';

const generateFilterQuery = ({ filters, page, pageSize }) => {
  let filter = '?';

  page ? (filter += 'page=' + page) : (filter += 'page=1');
  pageSize ? (filter += '&pageSize=' + pageSize) : (filter += '&pageSize=' + PAGE_SIZE_10);

  if (filters) {
    if (filters.countryUuid) filter += '&countryUuid=' + filters.countryUuid;
    if (filters.uuid) filter += '&uuid=' + filters.uuid;
    if (filters.region) filter += '&region=' + filters.region;
  }

  return filter;
};

/**
 * Filter list Address.
 */
export const filterAddresses = createAction(FILTER_ADDRESS, (filter, param) =>
  apiGet(urlAddressSecured + generateFilterQuery(filter), param, true)()
);

/**
 * Get Address by uuid.
 */
export const getAddressByUuid = createAction(GET_ADDRESS, (param) =>
  apiGet(urlAddressSecured, param, true)()
);

/**
 * Create address.
 */
export const createAddress = createAction(CREATE_ADDRESS, (obj) =>
  apiPost(urlAddressSecured, obj, undefined, true)()
);

/**
 * Update Notification.
 */
export const modifyAddress = createAction(UPDATE_ADDRESS, (obj, param) =>
  apiPut(urlAddressSecured, obj, param, true)()
);

/**
 * Update Notification.
 */
export const setDefaultAddress = createAction(SET_DEFAULT_ADDRESS, (param) =>
  apiPut(urlSetDefaultAddressSecured, {}, param, true)()
);

/**
 * Delete address by uuid.
 */
export const removeAddress = createAction(DELETE_ADDRESS, (param) =>
  apiDelete(urlAddressSecured, param, true)()
);
