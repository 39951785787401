import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { connectModal } from 'redux-modal';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Button } from './ModalPreview.styled';

class ModalInfo extends Component {
  componentDidMount() {}

  render() {
    const { show, handleHide, body, className } = this.props;
    return (
      <Modal
        size="xl"
        show={show}
        onHide={handleHide}
        centered
        backdrop={true}
        keyboard={false}
        className="modal-product-preview"
      >
        <Modal.Body>
          <div className="row">
            <div className="col-12 text-end">
              <Button type="button" onClick={() => handleHide()}>
                <i className="fa-solid fa-xmark"></i>
              </Button>
            </div>
          </div>
          {body}
        </Modal.Body>
      </Modal>
    );
  }
}

ModalInfo.propTypes = {
  show: PropTypes.any,
  handleHide: PropTypes.func,
  body: PropTypes.any,
  className: PropTypes.string,
};

export default connectModal({ name: 'ModalPreview' })(withTranslation()(ModalInfo));
