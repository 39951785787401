import React from 'react';
import PropTypes from 'prop-types';
import { withFormik, Field } from 'formik';
import { withTranslation } from 'react-i18next';
import { dataModel, validationDataModel, setInitialValues } from './nitroxActivation.data.form';
import {
  CenterContainer,
  ButtonsContainer,
} from '../../../design/styleComponents/globalComponents';
import { Button, Title } from '@arcaitprod/react-basic-components';
import { InputTextFormik } from '@arcaitprod/react-forms-components';

const NitroxActivationForm = (props) => {
  const { t, handleSubmit, readOnly } = props;

  return (
    <CenterContainer className="row justify-content-center align-items-center mt-5">
      <div className="col-10 col-sm-8 col-lg-5">
        <div className="row">
          <div className="col-12">
            <div
              dangerouslySetInnerHTML={{
                __html: t('forms.activation.text01'),
              }}
            />
          </div>
        </div>
        <Title type="h3" className="text-center mt-5 mb-4" variant="primary">
          {t('forms.activation.title')}
        </Title>
        <div className="row">
          <div className="col-12 mb-4">
            <div
              dangerouslySetInnerHTML={{
                __html: t('forms.activation.text02'),
              }}
            />
          </div>
        </div>
        <form className="form-horizontal bordered-row" onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-12">
              <Field
                id="email"
                className="form-control"
                labelField={t('forms.activation.labels.user')}
                name="email"
                placeholder=""
                component={InputTextFormik}
                fieldType="text"
                mandatory
                readOnly={readOnly}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Field
                id="name"
                className="form-control"
                labelField={t('forms.activation.labels.name')}
                name="name"
                placeholder=""
                component={InputTextFormik}
                fieldType="text"
                mandatory
                readOnly={readOnly}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Field
                id="surname"
                className="form-control"
                labelField={t('forms.activation.labels.surname')}
                name="surname"
                placeholder=""
                component={InputTextFormik}
                fieldType="text"
                mandatory
                readOnly={readOnly}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Field
                id="password"
                className="form-control"
                labelField={t('forms.activation.labels.password')}
                name="password"
                component={InputTextFormik}
                fieldType="password"
                placeholder=""
                mandatory
                readOnly={readOnly}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Field
                id="passwordConfirm"
                className="form-control"
                labelField={t('forms.activation.labels.passwordConfirm')}
                name="passwordConfirm"
                component={InputTextFormik}
                fieldType="password"
                placeholder=""
                mandatory
                readOnly={readOnly}
              />
            </div>
          </div>
          {readOnly && (
            <div className="row">
              <div className="col-12">
                <div className="alert alert-warning" role="alert">
                  {t('forms.activation.alertRegister')}
                </div>
              </div>
            </div>
          )}
          <ButtonsContainer className="text-center">
            <Button type="submit" className="btn btn-lg btn-block" variant="primary">
              {readOnly
                ? t('forms.activation.buttons.registerContinue')
                : t('forms.activation.buttons.register')}
            </Button>
          </ButtonsContainer>
        </form>
      </div>
    </CenterContainer>
  );
};

NitroxActivationForm.propTypes = {
  handleSubmit: PropTypes.func,
  readOnly: PropTypes.object,
};

const NitroxActivationFormF = withFormik({
  mapPropsToValues: (props) => {
    if (props.initialValues) {
      return setInitialValues(props.initialValues);
    }
    return dataModel;
  },
  validationSchema: (props) => {
    return validationDataModel(props.readOnly);
  },
  handleSubmit: (values, { props }) => {
    props.onSubmit(values);
  },

  displayName: 'NitroxActivationForm',
})(withTranslation()(NitroxActivationForm));

export default NitroxActivationFormF;
