import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import CheckOutCompletedView from './checkOutCompleted.view';
import { useSelector, useDispatch } from 'react-redux';
import { getLastPurchaseSelector } from '../../../store/selectors/shoppingCartSelector';
import { getCampaignSelector } from '../../../store/selectors/navSelector';
import * as ProductService from '../../../services/ProductService';

import { getAuthInfoSelector, getAuthTokenSelector } from '../../../store/selectors/authSelector';
import { useTranslation } from 'react-i18next';
import * as FBCampaignService from '../../../services/FaceBookApiConversion';
import { show, hide } from 'redux-modal';
import PreviewProduct from '../../others/products/detail';

const CheckOutCompletedContainer = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const auth = useSelector(getAuthTokenSelector);
  const lastPurchase = useSelector(getLastPurchaseSelector);
  const userInfo = useSelector(getAuthInfoSelector);
  const campaign = useSelector(getCampaignSelector);
  const [dataRemarkable, setDataRemarkable] = useState([]);

  useEffect(() => {
    let cancel = false;

    const cleanCart = async () => {
      try {
        await ProductService.cleanShoppingCart();
      } catch (error) {
        console.error('Error cleaning shopping cart:', error);
      }
    };

    cleanCart();

    if (lastPurchase) {
      if (userInfo?.extra || campaign) {
        const campaignToken = userInfo?.extra ? userInfo.extra : campaign;
        FBCampaignService.sendTrackPurchaseEvent(
          campaignToken,
          location,
          lastPurchase.purchase.shippingAddress,
          lastPurchase.items
        );
      }
    }

    return () => {
      cancel = true;
    };
  }, [lastPurchase, userInfo, campaign, location]);

  const toListRemarkableProducts = async (filter, region) => {
    if (filter.filters) {
      filter.filters.isRelated = true;
      filter.filters.countryUuid = region.uuid;
    } else {
      filter.filters = { isRelated: true, countryUuid: region.uuid };
    }
    filter.sort = '-createdAt';
    const result = await ProductService.listFilter(filter);
    if (result.docs && result.docs.length > 0) {
      setDataRemarkable(result.docs);
    }
    return result;
  };

  const handlerOnClickItem = (item) => {
    history.push(`/products/detail/${item.uuid}`);
  };

  const handlerClosePopup = (popupName) => {
    dispatch(hide(popupName));
  };

  const handlerShowPreview = (item) => {
    dispatch(
      show('ModalPreview', {
        handleHide: () => handlerClosePopup('ModalPreview'),
        body: (
          <>
            <PreviewProduct
              uuid={item.uuid}
              preview
              closeModal={() => handlerClosePopup('ModalPreview')}
            ></PreviewProduct>
          </>
        ),
      })
    );
  };

  const handlerAddShoppingCart = async (values) => {
    if (values.amount > 0) {
      let product = dataRemarkable.find((item) => item.uuid === values.item);
      const result = await ProductService.addProduct(values.amount, product, auth, t);
      if (result !== 'noStock') {
        // if (userInfo?.extra || campaign) {
        //   const campaignToken = userInfo?.extra ? userInfo.extra : campaign;
        //   await FBCampaignService.sendTrackAddToCartEvent(campaignToken, product, location);
        // }
        let myOffcanvas = document.getElementById('offcanvasRight');
        let bsOffcanvas = new window.bootstrap.Offcanvas(myOffcanvas);
        bsOffcanvas.show();
      }
    }
  };

  return (
    <>
      <CheckOutCompletedView
        listRemarkableProducts={toListRemarkableProducts}
        showMoreInfo={handlerOnClickItem}
        showPreview={handlerShowPreview}
        addToShoppingCart={handlerAddShoppingCart}
      />
    </>
  );
};

CheckOutCompletedContainer.propTypes = {};

export default CheckOutCompletedContainer;
