import React from 'react';
import AddressForm from '../../../../components/user/addresses/addressForm';
import Layout from '../../../../components/layouts/settingsLayout';

const UserAddressesPage = (props) => {
  return (
    <Layout
      active="addresses"
      body={
        <>
          <AddressForm uuid={props.uuid} />
        </>
      }
    />
  );
};

UserAddressesPage.propTypes = {};

export default UserAddressesPage;
