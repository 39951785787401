/* eslint-disable indent */
import React from 'react';
import AboutUs from '../../../components/others/aboutUs';
import Layout from '../../../components/layouts/verticalLayout';

const AboutUsPage = (props) => {
  return (
    <Layout
      body={
        <>
          <AboutUs />
        </>
      }
    />
  );
};

AboutUsPage.propTypes = {};

export default AboutUsPage;
