import React from 'react';
import Header from '../commons/header';
import { CenterPageContent } from './styled';

const CenterLayout = ({ ...prop }) => (
  <div id="sb-site">
    <div id="page-wrapper-maintenance" className="container-fluid">
      <CenterPageContent id="page-content">{prop.body}</CenterPageContent>
    </div>
  </div>
);

export default CenterLayout;
