import React from 'react';
import List from '../orderList';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { SecctionTitleContainer } from '../../../../design/styleComponents/globalComponents';

const OrderListPage = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="row justify-content-center no-margin">
        <SecctionTitleContainer className="col-11 col-md-11 mt-5 mb-5">
          {t('forms.order.title')}
        </SecctionTitleContainer>
      </div>
      <div className="row justify-content-center no-margin">
        <div className="col-12 col-md-11">
          <List />
        </div>
      </div>
    </>
  );
};

OrderListPage.propTypes = {};

export default OrderListPage;
