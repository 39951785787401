import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  ItemContainer,
  ImageProduct,
  ProductContainer,
  ProductTitleContainer,
  ProductPriceContainer,
  ImageItem,
  InfoItem,
  ProductButton,
  ActionItemsContainer,
  ImageAddCart,
  ButtonAddCart,
  TaxStyled,
  ProductBasePriceContainer,
  GallonsContainer,
  NewContainer,
  LinkPreview,
  LinkMoreInfo,
  ShortDescription,
  InfoTitle,
  ImageProductItem,
  TextAfterOffer,
  TextPrice,
  OfferContainer,
} from './productTable.styled';
import { useTranslation } from 'react-i18next';
import * as exchange from '../../../../utils/formats/exchange';
import { Can, AbilityContext } from '../../../permissions/Can';
import { USER_PERMISSIONS } from '../../../permissions/rolesPermission';

const CustomTable = (props) => {
  const { t, i18n } = useTranslation();
  const { showMoreInfo, showPreview, region, addToShoppingCart, isSlider } = props;
  const { page } = props.tableOptions;
  const language = i18n.language;
  const context = useContext(AbilityContext);

  return (
    <>
      <div className="row">
        <div className={`col-12 ${isSlider ? 'px-5' : ''}`}>
          <div className="row row-cols-1 row-cols-md-3 row-cols-lg-5">
            {page.map((item, i) => {
              let buyAllowed = false;
              if (item.original.countriesAllowed && item.original.countriesAllowed.length > 0) {
                const exist = item.original.countriesAllowed.find((pais) => pais === region.uuid);
                buyAllowed = exist ? true : false;
              }
              let isNewItem = item.original.isNewItem;
              const taxType = item.original?.items[0].taxType;
              const taxApplied = region && region[taxType] ? region[taxType] : 0;

              const price = Number(
                exchange.crypto2Satoshi(
                  'GAL',
                  region?.EU ? item.original?.totalPrice : item.original?.totalPriceOutEU
                )
              );
              const tax = (price * taxApplied) / 100;

              const priceAfter = Number(
                exchange.crypto2Satoshi(
                  'GAL',
                  item.original?.isOffer ? item.original?.priceAfterOffer : 0
                )
              );
              const taxAfter = item.original?.isOffer ? (priceAfter * taxApplied) / 100 : 0;

              return (
                <ItemContainer
                  key={item.original.uuid}
                  className="col"
                  title={!buyAllowed ? t('region_not_available') : ''}
                >
                  <ProductContainer className="row">
                    <div className="col-12">
                      <div className="row">
                        <div className="col-12 no-padding bg-image hover-overlay ripple shadow-1-strong rounded">
                          <ProductButton>
                            <ImageItem image={item.original.imageUrl}>
                              <ImageProductItem
                                buyallowed={buyAllowed ? 'true' : 'false'}
                                src={item.original.imageUrl}
                                alt=""
                                effect="blur"
                                width={'100%'}
                                placeholderSrc={`/assets/themes/${process.env.REACT_APP_THEME}/images/placeHolder.png`}
                              />
                              {item.original?.isOffer && buyAllowed && (
                                <OfferContainer>{`${t('offer')}`}</OfferContainer>
                              )}
                              {isNewItem && buyAllowed && (
                                <NewContainer>{`${t('new')}`}</NewContainer>
                              )}
                              {buyAllowed && (
                                <GallonsContainer
                                  isNewItem={isNewItem}
                                >{`${item.original.totalGallons} P`}</GallonsContainer>
                              )}
                              <div
                                className="mask d-flex justify-content-center align-items-center flex-column text-center"
                                style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
                              >
                                <LinkPreview
                                  // type="button"
                                  className=""
                                  onClick={() => {
                                    showPreview(item.original);
                                  }}
                                >
                                  <p className="m-0">{t('forms.product.buttons.preview')}</p>
                                </LinkPreview>
                                <LinkPreview
                                  // type="button"
                                  className="mt-4"
                                  onClick={() => {
                                    showMoreInfo(item.original);
                                  }}
                                >
                                  <p className="m-0">{t('forms.product.buttons.more')}</p>
                                </LinkPreview>
                              </div>
                            </ImageItem>
                          </ProductButton>
                        </div>
                      </div>
                      <InfoTitle className="row mt-1">
                        <div className="col-12">
                          <div className="row">
                            <ProductTitleContainer className="col-12 no-padding">
                              <LinkMoreInfo
                                onClick={() => {
                                  showMoreInfo(item.original);
                                }}
                              >
                                {item.original.minifyData && item.original.minifyData[language]
                                  ? item.original.minifyData[language].title
                                  : item.original.title}
                              </LinkMoreInfo>
                            </ProductTitleContainer>
                          </div>
                          <div className="row">
                            <ProductTitleContainer className="col-12 no-padding">
                              <ShortDescription>
                                {item.original.minifyData && item.original.minifyData[language]
                                  ? item.original.minifyData[language].shortDescription
                                  : ''}
                              </ShortDescription>
                            </ProductTitleContainer>
                          </div>
                        </div>
                      </InfoTitle>
                      <InfoItem className="row">
                        <div className="col-9">
                          {/* <div className="row">
                            <ProductBasePriceContainer className="col-12 d-flex flex-column no-padding">
                              <TaxStyled>{`${t('forms.product.labels.basePrice')}`}</TaxStyled>
                              {`${
                                region?.EU
                                  ? item.original?.totalPrice
                                  : item.original?.totalPriceOutEU
                              } €`}
                            </ProductBasePriceContainer>
                          </div> */}
                          <div className="row">
                            <ProductPriceContainer className="col-12 d-flex flex-column no-padding">
                              {/* <TaxStyled>{`${t(
                                'forms.product.labels.taxPrice'
                              )} (${taxApplied}%)`}</TaxStyled> */}
                              {buyAllowed ? (
                                <>
                                  <TextPrice
                                    isOffer={item.original?.isOffer}
                                  >{`${exchange.satoshi2Crypto('GAL', price + tax)} €`}</TextPrice>
                                  {item.original?.isOffer && (
                                    <TextAfterOffer>{`(${t('afterOffer')} ${exchange.satoshi2Crypto(
                                      'GAL',
                                      Math.round(
                                        Number(
                                          exchange.satoshi2Crypto('GAL', priceAfter + taxAfter)
                                        )
                                      )
                                    )} €)`}</TextAfterOffer>
                                  )}
                                </>
                              ) : (
                                <TextPrice isOffer>{t('not_available')}</TextPrice>
                              )}
                            </ProductPriceContainer>
                          </div>
                          <div className="row mt-1">
                            <ProductTitleContainer className="col-12 no-padding">
                              {`${item.original.totalGallons} ${t('gallons')}`}
                            </ProductTitleContainer>
                          </div>
                        </div>
                        <ActionItemsContainer className="col-3">
                          {buyAllowed && ( //context.can(USER_PERMISSIONS.IS_MEMBER)
                            <ButtonAddCart
                              type="button"
                              onClick={() =>
                                addToShoppingCart({ amount: 1, item: item.original.uuid })
                              }
                            >
                              <ImageAddCart className="fa-solid fa-cart-plus"></ImageAddCart>
                              {/* <ImageAddCart
                                src={`/assets/themes/${process.env.REACT_APP_THEME}/images/MarketPlaceLogo.png`}
                              ></ImageAddCart> */}
                            </ButtonAddCart>
                          )}

                          {/* <ButtonAddCart type="button">
                            <ImageAddCart
                              src={`/assets/themes/${process.env.REACT_APP_THEME}/images/favorite.png`}
                            ></ImageAddCart>
                          </ButtonAddCart> */}
                        </ActionItemsContainer>
                      </InfoItem>
                    </div>
                  </ProductContainer>
                </ItemContainer>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

CustomTable.propTypes = {
  tableOptions: PropTypes.object.isRequired,
  showMoreInfo: PropTypes.func,
  showPreview: PropTypes.func,
  region: PropTypes.any,
  isSlider: PropTypes.bool,
};

export default CustomTable;
