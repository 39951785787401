import { createSelector } from 'reselect';

const getStateShoppingCartSelector = (state) => state.shoppingCart;

export const getItemsShoppingCartSelector = createSelector(
  getStateShoppingCartSelector,
  (shoppingCart) => {
    if (shoppingCart && shoppingCart.items) {
      return shoppingCart.items;
    }

    return undefined;
  }
);

export const getLastPurchaseSelector = createSelector(
  getStateShoppingCartSelector,
  (shoppingCart) => {
    if (shoppingCart && shoppingCart.lastPurchase) {
      return shoppingCart.lastPurchase;
    }

    return undefined;
  }
);

// New selector to get the shopping cart UUID
export const getShoppingCartUUID = createSelector(getStateShoppingCartSelector, (shoppingCart) => {
  if (shoppingCart && shoppingCart.uuid) {
    return shoppingCart.uuid;
  }

  return null; // or you could return undefined if you prefer
});
