import styled from 'styled-components';

export const Letter = styled.div`
  background-color: ${({ theme }) => theme.colors.terciary};
  text-align: center;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 60px;
  font-size: 1.2rem;
  font-weight: 700;
  color: #fff;
`;

export const Nickname = styled.div`
  font-size: 1.2rem;
  text-transform: capitalize;
  font-weight: 700;
  color: #469fa3;
`;

export const RefererLink = styled.div`
  font-size: 1rem;
  font-weight: normal;
  color: #fff;
  text-decoration: underline;
`;

export const Email = styled.div`
  font-size: 0.875rem;
  text-transform: capitalize;
  font-weight: 600;
  color: #469fa3;
`;

export const ButtonsContainer = styled.div`
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
`;
//#00a79d,  d9be00
