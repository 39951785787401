import styled, { css } from 'styled-components';
import { Button } from '@arcaitprod/react-basic-components';

export const LinkButton = styled(Button)`
  font-weight: normal;
  font-size: 0.8rem;
  border: 0px;
  background: transparent !important;
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.primary} !important;

  &:hover {
    background: transparent !important;
    color: ${({ theme }) => theme.colors.primary} !important;
    text-decoration: underline;
  }
`;

export const ButtonClose = styled.button`
  color: #fff !important;
  background-color: transparent !important;
  border: 0px solid #e1e1e1;
  font-size: 1.2rem;
`;

export const ShoppingCartButton = styled.button`
  /* z-index: 100; */
  background-color: #479aa2 !important;
  color: #fff !important;
  border: 0px solid #e1e1e1;
  font-size: 0.775rem;
  padding: 10px 10px 10px 20px;
  border-radius: 5px;

  &:hover {
    text-decoration: none !important;
    color: #479aa2;
  }
`;

export const TextButton = styled.div`
  width: 70px;
`;

export const ImageShoppingCart = styled.img`
  max-width: 100%;
  max-height: 35px;
  margin-right: 5px;
`;

export const ImageShoppingCart2 = styled.i`
  max-width: 100%;
  max-height: 35px;
  margin-right: 5px;
`;

export const ImageItem = styled.img`
  max-width: 100%;
  max-height: 90px;
`;

export const ButtonDeleteItem = styled.button`
  background: transparent !important;
  border: none !important;
  color: #479aa2;
`;

export const ContainerShopping = styled.div`
  height: calc(100vh - 330px) !important;
  overflow: auto;
`;
export const ContainerActions = styled.div`
  height: 200px;
  overflow: auto;
  border-top: 1px solid #ddd;
  margin-top: 20px;
`;

export const TextSubtotal = styled.div`
  font-size: 1rem;
  font-weight: bold;
  margin-right: 10px;
  color: #fff;
`;

export const TotalPrice = styled.div`
  font-size: 1rem;
  color: #479aa2;
`;

export const Header = styled.div`
  border-bottom: 1px solid #ddd;
`;
