import { createSelector } from 'reselect';
import { getAuthInfoSelector } from './authSelector';
import PATHS from '../../constants/paths';

const getStateUserSelector = (state) => state.user;

export const getUserInfoSelector = createSelector(getStateUserSelector, (user) => {
  if (user && user.info) {
    return user.info;
  }

  return undefined;
});

export const getUrlSponsorSelector = createSelector(getAuthInfoSelector, (info) => {
  if (info && info.uuid) {
    const url2 = {};
    const url = `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_HOST}${
      process.env.REACT_APP_PORT ? `:${process.env.REACT_APP_PORT}` : ''
    }${PATHS.SPONSOR}${info.uuid}`;

    if (process.env.REACT_APP_LANDING_URL_ES) {
      url2.es = `${process.env.REACT_APP_LANDING_URL_ES}${info.uuid}`;
    }
    if (process.env.REACT_APP_LANDING_URL_ES) {
      url2.en = `${process.env.REACT_APP_LANDING_URL_EN}${info.uuid}`;
    }

    return { url, url2 };
  }

  return undefined;
});

export const getTemporalUserRegisterSelector = createSelector(getStateUserSelector, (user) => {
  if (user && user.temporal) {
    return user.temporal;
  }

  return undefined;
});

export const getTemporalTokenSelector = createSelector(getTemporalUserRegisterSelector, (temp) => {
  if (temp && temp.token) {
    return temp.token;
  }

  return undefined;
});

export const getMedalsSelector = createSelector(getAuthInfoSelector, (info) => {
  if (info) {
    const medals = {
      bigBangSilver: info.bigBangSilver !== undefined ? info.bigBangSilver : 0,
      bigBangGold: info.bigBangGold !== undefined ? info.bigBangGold : 0,
      supernovaSilver: info.supernovaSilver !== undefined ? info.supernovaSilver : 0,
      supernovaGold: info.supernovaGold !== undefined ? info.supernovaGold : 0,
      nebulaSilver: info.nebulaSilver !== undefined ? info.nebulaSilver : 0,
      nebulaGold: info.nebulaGold !== undefined ? info.nebulaGold : 0,
      stellarLeaderSilver: info.stellarLeaderSilver !== undefined ? info.stellarLeaderSilver : 0,
      stellarLeaderGold: info.stellarLeaderGold !== undefined ? info.stellarLeaderGold : 0,
      comet: info.comet !== undefined ? info.comet : 0,
      quasar: info.quasar !== undefined ? info.quasar : 0,
      galactic: info.galactic !== undefined ? info.galactic : 0,
      founders: info.founders !== undefined ? info.founders : 0,
    };
    return medals;
  }

  return undefined;
});

export const getMyQualifySelector = createSelector(getAuthInfoSelector, (info) => {
  if (info) {
    const data = {
      pointsPersonal: info.pointsPersonal,
      accumulatedPersonalGallons: info.accumulatedPersonalGallons,
      pointsNLevel: info.pointsNLevel,
      pointsNLevel50p: info.pointsNLevel50p,
      firstLevelQualificationRanks: info.firstLevelQualificationRanks,
      rank: info.rank,
    };
    return data;
  }

  return undefined;
});
