import React from 'react';
import PropTypes from 'prop-types';
import { CardElement } from '@stripe/react-stripe-js';
import { Button, Title } from '@arcaitprod/react-basic-components';
import { useTranslation } from 'react-i18next';

const CreditCardForm = (props) => {
  const { handleSubmit, isLoading, stripe, elements, message, onBack } = props;
  const { t } = useTranslation();
  const CARD_ELEMENT_OPTIONS = {
    iconStyle: 'solid',
    style: {
      base: {
        iconColor: '#469fa3',
        color: '#32325d',
        fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
        fontSize: '16px',
        fontWeight: 500,
        fontSmoothing: 'antialiased',
        '::placeholder': {
          color: '#aab7c4',
        },
        ':-webkit-autofill': {
          color: '#fce883',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  };
  return (
    <>
      <form id="payment-form" onSubmit={handleSubmit} className="p-5">
        <div className="row">
          <div className="col-12 text-center">
            <Title type="h3" variant="primary">
              {t('forms.checkout.creditCardPaymentTitle')}
            </Title>
          </div>
        </div>
        <div className="row">
          <div className="col-12 mb-3">{t('forms.checkout.creditCardPaymentDescription')}</div>
        </div>
        <div className="row">
          <div className="col-12 text-center">
            <CardElement options={CARD_ELEMENT_OPTIONS} />
          </div>
        </div>
        {message && <div className="payment-message mt-2">{message}</div>}
        <div className="row">
          <div className="col-12 mt-3 text-center">
            <Button
              disabled={isLoading || !stripe || !elements}
              type="submit"
              variant="primary"
              className="btn btn-md btn-block"
            >
              {isLoading ? t('forms.checkout.buttons.sending') : t('forms.checkout.buttons.payNow')}
            </Button>
          </div>
        </div>
        <div className="row">
          <div className="col-12 mt-3 text-center">
            <Button
              disabled={isLoading || !stripe || !elements}
              type="button"
              variant="secondary"
              className="btn btn-md btn-block"
              onClick={() => onBack()}
            >
              {t('forms.checkout.buttons.cancel')}
            </Button>
          </div>
        </div>
      </form>
    </>
  );
};

CreditCardForm.propTypes = {
  handleSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
  stripe: PropTypes.any,
  elements: PropTypes.any,
  message: PropTypes.string,
  onBack: PropTypes.func,
};

export default CreditCardForm;
