import styled from 'styled-components';
import { Button } from '@arcaitprod/react-basic-components';

export const LinkButton = styled.button`
  font-weight: normal;
  font-size: 0.8rem;
  border: 0px;
  background: transparent !important;
  color: ${({ theme }) => theme.colors.primary} !important;

  &:hover {
    background: transparent !important;
    color: ${({ theme }) => theme.colors.secondary} !important;
  }
`;

export const Container = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.primary};
  padding: 15px 18px;
  border-radius: 10px;
`;

export const ImgItem = styled.img`
  width: 128px;
`;

export const TitleItem = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: bold;
  font-size: 1rem;
`;
