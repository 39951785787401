import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as OrderServices from '../../../../services/OrderService';
import { withTranslation } from 'react-i18next';
import {
  RowItem,
  ImageItem,
  ItemTitle,
  ImageContainer,
  HeadContainer,
  LabelHead,
  ValueHead,
  ItemsContainer,
  FooterContainer,
  ItemData,
  ValueAddress,
  ActionLink,
  ItemStatus,
} from './orderList.styled';
import { AbilityContext } from '../../../permissions/Can';
import moment from 'moment-timezone';
import { InfiniteList } from '@arcaitprod/react-complex-components';
// import { USER_PERMISSIONS } from '../../../permissions/rolesPermission';
import ContentLoader from 'react-content-loader';
import { checkBootstrapSizeBreak } from '../../../../utils/device/checkDevice';
import { connect } from 'react-redux';
import { getTimeZoneSelector } from '../../../../store/selectors/navSelector';
import { Button } from '@arcaitprod/react-basic-components';
import ReactTooltip from 'react-tooltip';
import { withRouter } from 'react-router-dom';
import { PRODUCT_STATUS, SYMBOL_MONEY, PRODUCT_STATUS_SENDED } from '../../../../constants/index';
import ItemReturnForm from '../itemReturn';

class OrderListContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      refreshData: false,
      refreshItem: null,
      itemReturn: null,
      breakDevice: checkBootstrapSizeBreak(),
    };
  }

  componentDidUpdate(prevState) {
    if (prevState.refreshData !== this.state.refreshData && this.state.refreshData === true) {
      this.resetRefreshData();
    }
  }

  resetRefreshData = () => {
    this.setState({ refreshData: false });
  };

  toList = async (filter) => {
    if (filter.filters) {
      filter.filters.status = 'CONFIRMED';
    } else {
      filter.filters = { status: 'CONFIRMED' };
    }
    let result = await OrderServices.listFilter(filter);
    return result;
  };

  goToLocation = async (url) => {
    window.open(url, '_blank');
  };

  renderItems = (items, billingAddress, order) => {
    const { t } = this.props;
    const numItems = items.length;
    return items.map((product, index) => {
      const status = PRODUCT_STATUS.find((prod) => prod.code === product.status);
      return (
        <RowItem
          isLast={index === numItems - 1}
          key={product.uuid}
          className="row justify-content-center no-margin"
        >
          <div key={product.uuid} className="col-12">
            <div className="row">
              <div className="col-9 d-flex">
                <ImageContainer>
                  <ImageItem src={product.image} alt={product.packageTitle} />
                </ImageContainer>
                <div className="d-flex flex-column">
                  <ItemStatus className="mb-2 text-center">{t(status.label)}</ItemStatus>
                  <ItemTitle>{product.packageTitle}</ItemTitle>
                  <ItemData>{`${t('forms.order.labels.itemPrice')}: ${
                    billingAddress.isEU ? product.unitPrice : product.unitPriceOutEU
                  } ${SYMBOL_MONEY}`}</ItemData>
                  <ItemData>{`${t('forms.order.labels.amount')}: ${product.amount}`}</ItemData>
                  {/* <ItemData>Ver artículo</ItemData> */}
                </div>
              </div>
              <div className="col-3 d-flex flex-column text-end align-items-center justify-content-center">
                {product.status === PRODUCT_STATUS_SENDED.code && product.urlTracking && (
                  <>
                    <div>{`${t('forms.order.labels.numTracking')}: ${
                      product.numTracking ? product.numTracking : ''
                    }`}</div>
                    <Button
                      type="button"
                      className="btn btn-sm btn-block"
                      variant="primary"
                      onClick={() => this.goToLocation(product.urlTracking)}
                    >
                      {t('forms.order.buttons.location')}
                    </Button>
                  </>
                )}
              </div>
            </div>
          </div>
        </RowItem>
      );
    });
  };

  renderOrder = (order) => {
    const { timeZone, t } = this.props;
    const date = moment(new Date(order.date));
    return (
      <div key={order.uuid}>
        <ReactTooltip
          id={`${order.uuid}_address`}
          type="dark"
          effect="solid"
          place="bottom"
          className="tooltipLightBorder"
          // delayHide={99999999999999999}
        >
          <div>{order.shippingAddress.contactName}</div>
          <div>{order.shippingAddress.address}</div>
          <div>{order.shippingAddress.addressAdc}</div>
          <div>{`${order.shippingAddress.city}, ${order.shippingAddress.province} ${order.shippingAddress.cp}`}</div>
          <div>{`${t('forms.order.labels.phone')}: ${order.shippingAddress.phone}`}</div>
        </ReactTooltip>
        <HeadContainer className="row">
          <div className="col-2 d-flex flex-column">
            <LabelHead>{t('forms.order.labels.orderPlaced')}</LabelHead>
            <ValueHead>{date.tz('Europe/Madrid').format('DD-MM-yyyy HH:mm')}</ValueHead>
          </div>
          <div className="col-2 d-flex flex-column">
            <LabelHead>{t('forms.order.labels.cost')}</LabelHead>
            <ValueHead>{`${order.totals} ${SYMBOL_MONEY}`}</ValueHead>
          </div>
          <div className="col-3 d-flex flex-column">
            <LabelHead>{t('forms.order.labels.sendTo')}</LabelHead>
            <ValueAddress data-tip data-for={`${order.uuid}_address`}>
              {order.shippingAddress.contactName}
            </ValueAddress>
          </div>
          <div className="col-5">
            <div className="row">
              <LabelHead className="col-12 text-end">{`${t('forms.order.labels.order')} N.º: ${
                order.orderNumber
              }`}</LabelHead>
            </div>
            <div className="row justify-content-end">
              <div className="col-6 text-end">
                <ActionLink type="button" onClick={() => this.goToDetail(order.uuid)}>
                  {t('forms.order.buttons.detail')}
                </ActionLink>
              </div>
              {/* <div className="col-2 text-end">Factura</div> */}
            </div>
          </div>
        </HeadContainer>
        <ItemsContainer className="row">
          {this.renderItems(order.shoppingCart.items, order.billingAddress, order)}
        </ItemsContainer>
        <FooterContainer className="row">
          {/* <div className="col-12"> {t('forms.order.buttons.archive')}</div> */}
        </FooterContainer>
      </div>
    );
  };

  goToDetail = (purchaseUuid) => {
    this.props.history.push(`/orders/detail/${purchaseUuid}`);
  };

  renderListLoader = () => {
    return (
      <div className="row">
        <div className="col-12 mt-4">
          <ContentLoader
            speed={2}
            viewBox="0 0 400 160"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            style={{ width: '100%' }}
          >
            <rect x="5" y="1" rx="3" ry="3" width="400" height="5" />
            <rect x="5" y="15" rx="3" ry="3" width="400" height="5" />
          </ContentLoader>
        </div>
      </div>
    );
  };

  handlerOnBack = () => {
    this.setState({ itemReturn: null });
  };

  render() {
    const { t, filters, className } = this.props;
    const { itemReturn } = this.state;
    return (
      <>
        {itemReturn ? (
          <div className={className || ''}>
            <ItemReturnForm onBack={this.handlerOnBack} data={itemReturn} />
          </div>
        ) : (
          <div className={className || ''}>
            <InfiniteList
              id="orders"
              renderItem={this.renderOrder}
              toList={this.toList}
              filters={filters}
              variantTitle="primary"
              forceRefresh={this.state.refreshData}
              itemRefresh={this.state.refreshItem}
              endMessage={t('goToTop')}
              messageButtonMore={t('readMore')}
              variantButtonMore="simplePrimary"
              idItem="uuid"
              renderLoader={this.renderListLoader}
            />
          </div>
        )}
      </>
    );
  }
}

OrderListContainer.contextType = AbilityContext;

OrderListContainer.propTypes = {
  t: PropTypes.any,
  filters: PropTypes.object,
  className: PropTypes.string,
  general: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  timeZone: getTimeZoneSelector(state),
});

export default withRouter(connect(mapStateToProps, {})(withTranslation()(OrderListContainer)));
