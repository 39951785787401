import styled from 'styled-components';

export const Label = styled.div`
  color: #a6a79f;
`;

export const Image = styled.img`
  margin-right: 0px;
  height: 15px;
`;

export const IconArrowLogin = styled.i`
  font-size: 0.775rem;
  margin-left: 5px;
  color: #a6a79f;
`;
