import React from 'react';
import FilterList from '../../../../components/user/commissions/commissionFilterList';
import Layout from '../../../../components/layouts/simpleLayout';

const CommissionsListPage = (props) => {
  return (
    <Layout
      showBanner
      bannerName="Movimientos.jpg"
      textBanner="cashflow"
      bannerPosition="50"
      body={<FilterList />}
    />
  );
};

CommissionsListPage.propTypes = {};

export default CommissionsListPage;
