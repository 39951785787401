import { BLOCK_LEVEL1, BLOCK_LEVEL2, BLOCK_LEVEL3 } from '../../constants/index';

export const USER_PERMISSIONS = {
  /* GENERATOR_INJECT_DOMAIN */
  TREE_VIEW: 'TREE_VIEW',
  NOTIFICATION_LIST: 'NOTIFICATION_LIST',
  NOTIFICATION_UPDATE: 'NOTIFICATION_UPDATE',
  WALLETS: 'WALLETS',
  UPDATE_WALLET: 'UPDATE_WALLET',
  WITHDRAW: 'WITHDRAW',
  HAS_WALLET: 'HAS_WALLET',
  HAS_WALLET_GALLONS: 'HAS_WALLET_GALLONS',
  HAS_WALLET_TREVOL: 'HAS_WALLET_TREVOL',
  DASHBOARD: 'DASHBOARD',
  EXCHANGE_GAL: 'EXCHANGE_GAL',
  EXCHANGE_BTC: 'EXCHANGE_BTC',
  PROMOTER: 'PROMOTER',
  IGNITE: 'IGNITE',
  IGNITE_EDIT: 'IGNITE_EDIT',
  INVOICE: 'INVOICE',
  BUY_FREE_PACKAGE: 'BUY_FREE_PACKAGE',
  IS_MEMBER: 'IS_MEMBER',
};

export function getPermisionsClientTestingMode(isStaff, can, extraData) {
  if (isStaff) {
  }
  can(USER_PERMISSIONS.NOTIFICATION_LIST);
  can(USER_PERMISSIONS.NOTIFICATION_UPDATE);
  if (extraData.isMember) {
    can(USER_PERMISSIONS.IS_MEMBER);
    can(USER_PERMISSIONS.DASHBOARD);
    can(USER_PERMISSIONS.TREE_VIEW);
    can(USER_PERMISSIONS.WALLETS);
  }
  // if (extraData.passedKYC) {
  can(USER_PERMISSIONS.UPDATE_WALLET);
  can(USER_PERMISSIONS.WITHDRAW);
  if (extraData.isPromoter) {
    can(USER_PERMISSIONS.PROMOTER);
  }
  if (extraData.isIgnite) {
    can(USER_PERMISSIONS.IGNITE);
    can(USER_PERMISSIONS.IGNITE_EDIT);
  }
  if (extraData.hasWallet) {
    can(USER_PERMISSIONS.HAS_WALLET);
  }
  if (extraData.hasWalletGallons) {
    can(USER_PERMISSIONS.EXCHANGE_GAL);
    can(USER_PERMISSIONS.EXCHANGE_BTC);
    can(USER_PERMISSIONS.HAS_WALLET_GALLONS);
  }
  if (extraData.hasWalletTrevol) {
    can(USER_PERMISSIONS.HAS_WALLET_TREVOL);
  }
}

export function getPermisionsClient(can, extraData) {
  can(USER_PERMISSIONS.NOTIFICATION_LIST);
  can(USER_PERMISSIONS.NOTIFICATION_UPDATE);
  if (extraData.isMember) {
    can(USER_PERMISSIONS.IS_MEMBER);
    can(USER_PERMISSIONS.DASHBOARD);
    can(USER_PERMISSIONS.TREE_VIEW);
    can(USER_PERMISSIONS.WALLETS);
  }
  // if (extraData.passedKYC) {
  can(USER_PERMISSIONS.UPDATE_WALLET);
  can(USER_PERMISSIONS.WITHDRAW);
  if (extraData.isPromoter) {
    can(USER_PERMISSIONS.PROMOTER);
  }
  if (extraData.isIgnite) {
    can(USER_PERMISSIONS.IGNITE);
    can(USER_PERMISSIONS.IGNITE_EDIT);
  }
  if (extraData.hasWallet) {
    can(USER_PERMISSIONS.HAS_WALLET);
  }
  if (extraData.hasWalletGallons) {
    can(USER_PERMISSIONS.EXCHANGE_GAL);
    can(USER_PERMISSIONS.EXCHANGE_BTC);
    can(USER_PERMISSIONS.HAS_WALLET_GALLONS);
  }
  if (extraData.hasWalletTrevol) {
    can(USER_PERMISSIONS.HAS_WALLET_TREVOL);
  }
  // }
}

export function getPermisionsSupport(can) {
  can(USER_PERMISSIONS.TREE_VIEW);
  can(USER_PERMISSIONS.NOTIFICATION_LIST);
  can(USER_PERMISSIONS.NOTIFICATION_UPDATE);
  can(USER_PERMISSIONS.WALLETS);
  can(USER_PERMISSIONS.HAS_WALLET);
  can(USER_PERMISSIONS.UPDATE_WALLET);
  can(USER_PERMISSIONS.WITHDRAW);
  can(USER_PERMISSIONS.DASHBOARD);
  can(USER_PERMISSIONS.IGNITE);
  can(USER_PERMISSIONS.INVOICE);
  can(USER_PERMISSIONS.PROMOTER);
  can(USER_PERMISSIONS.BUY_FREE_PACKAGE);
  can(USER_PERMISSIONS.IS_MEMBER);
}

export function getPermisionsSupportTestingMode(isStaff, can) {
  can(USER_PERMISSIONS.TREE_VIEW);
  can(USER_PERMISSIONS.NOTIFICATION_LIST);
  can(USER_PERMISSIONS.NOTIFICATION_UPDATE);
  can(USER_PERMISSIONS.WALLETS);
  can(USER_PERMISSIONS.UPDATE_WALLET);
  can(USER_PERMISSIONS.HAS_WALLET);
  can(USER_PERMISSIONS.WITHDRAW);
  can(USER_PERMISSIONS.DASHBOARD);
  can(USER_PERMISSIONS.IGNITE);
  can(USER_PERMISSIONS.INVOICE);
  can(USER_PERMISSIONS.PROMOTER);
  can(USER_PERMISSIONS.BUY_FREE_PACKAGE);
  can(USER_PERMISSIONS.IS_MEMBER);
}

export function getPermisionsAnonymous(can) {
  /* GENERATOR_INJECT_PERMISSSIONS */
}

export function getPermisionsBlockClient(can, blockedLevel, extraData) {
  switch (blockedLevel) {
    case BLOCK_LEVEL1.code:
      getPermisionsClient(can, extraData);
      break;
    case BLOCK_LEVEL2.code:
      getPermisionsClient(can, extraData);
      break;
    case BLOCK_LEVEL3.code:
      getPermisionsAnonymous(can);
      break;
    default:
      getPermisionsClient(can, extraData);
      break;
  }
}

export function getPermisionsBlockClientTestingMode(isStaff, can, blockedLevel, extraData) {
  switch (blockedLevel) {
    case BLOCK_LEVEL1.code:
      getPermisionsClientTestingMode(isStaff, can, extraData);
      break;
    case BLOCK_LEVEL2.code:
      getPermisionsClientTestingMode(isStaff, can, extraData);
      break;
    case BLOCK_LEVEL3.code:
      getPermisionsAnonymous(can);
      break;
    default:
      getPermisionsClientTestingMode(isStaff, can, extraData);
      break;
  }
}
