import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Dashboard from './dashboardUser.view';
import * as UserServices from '../../../services/UserService';
import { useSelector } from 'react-redux';
import { getUrlSponsorSelector } from '../../../store/selectors/userSelector';

const DashboardContainer = (props) => {
  const history = useHistory();
  const [upLine, setUpLine] = useState(null);
  const urlSponsor = useSelector(getUrlSponsorSelector);

  useEffect(() => {
    let cancel = false;
    UserServices.getAuthUserProfile();
    UserServices.getUserUpline().then((result) => {
      if (cancel) return;
      if (result?.data?.uuid) {
        setUpLine(result.data);
      }
    });

    return () => {
      cancel = true;
    };
  }, []);

  return (
    <div className="row no-margin">
      <div className="col-12 no-padding">
        <Dashboard upLine={upLine} urlSponsor={urlSponsor || ''} />
      </div>
    </div>
  );
};

DashboardContainer.propTypes = {};

export default DashboardContainer;
