import { createAction } from 'redux-actions';
import { apiGet } from '../../api/index';
import {
  urlGetNationalities,
  urlGetStatus,
  urlGetItem,
  urlGetItemLocal,
  urlGetEuCountries,
  urlGetPackagesGroupsSidebar,
  urlGetAllCountriesSorted,
  urlGetLandings,
} from '../../api/urls';
import {
  GET_NATIONALITIES,
  SET_TIME_ZONE,
  SET_LANGUAGE,
  GET_STATUS,
  GET_ITEM,
  SET_REGION,
  GET_EU_COUNTRIES,
  GET_PACKAGES_GROUPS,
  SET_TIME_PACKAGES_GROUPS,
  GET_ALL_COUNTRIES,
} from '../../constants/index';

/**
 * Get platform status.
 */
export const getStatus = createAction(GET_STATUS, () => apiGet(urlGetStatus, undefined, false)());

/**
 * Get nationalities.
 */
export const getNationalities = createAction(GET_NATIONALITIES, () =>
  apiGet(urlGetNationalities, undefined, false)()
);

/**
 * Set TimeZone.
 */
export const settingTimeZone = createAction(SET_TIME_ZONE, (data) => data);

/**
 * Set TimeZone.
 */
export const setLanguage = createAction(SET_LANGUAGE, (data) => data);

/**
 * Set region.
 */
export const setRegion = createAction(SET_REGION, (data) => data);

/**
 * Get item from smartnet.
 */
export const getItem = createAction(GET_ITEM, (token) => apiGet(urlGetItem, [token], false)());

/**
 * Get item local platform.
 */
export const getLocalItem = createAction(GET_ITEM, (token) =>
  apiGet(urlGetItemLocal, [token], false)()
);

/**
 * Get nationalities.
 */
export const geRegionsCountriesToBuy = createAction(GET_EU_COUNTRIES, () =>
  apiGet(urlGetNationalities, undefined, false)()
);

/**
 * Get package groups.
 */
export const getAllPackagesGroupsSideBar = createAction(GET_PACKAGES_GROUPS, () =>
  apiGet(urlGetPackagesGroupsSidebar, undefined, false)()
);

/**
 * Set time cache package groups.
 */
export const setTimeCachePackagesGroups = createAction(SET_TIME_PACKAGES_GROUPS, (data) => data);

/**
 * Get nationalities.
 */
export const getAllCountries = createAction(GET_ALL_COUNTRIES, (param) =>
  apiGet(urlGetAllCountriesSorted, param, false)()
);

/**
 * Get nationalities.
 */
export const getLandings = createAction('GET_LANDING', () =>
  apiGet(urlGetLandings, undefined, false)()
);
