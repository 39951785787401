import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import * as CampaignServices from '../../../../services/CampaignService';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

const FromCampaignContainer = (props) => {
  const { token } = props;
  const { search } = useLocation();

  useEffect(() => {
    let cancel = false;
    CampaignServices.getCampaign(token).then((campaign) => {
      const query = queryString.parse(search);
      let redirect;
      if (query && query.redirect) {
        redirect = query.redirect;
      }
      if (campaign) {
        let urlRedirect =
          redirect && campaign.redirectUrl[redirect]
            ? campaign.redirectUrl[redirect]
            : campaign.redirectUrl.reg;

        // console.log(urlRedirect);
        window.location.href = urlRedirect;
      }
    });

    return () => {
      cancel = true;
    };
  }, [token]);

  return <>Redireccionando...</>;
};

FromCampaignContainer.propTypes = {
  token: PropTypes.string,
};

export default FromCampaignContainer;
