import { store } from '../store/index';
import { loginSucess, logoutUser } from '../store/actions/AuthActions';

/**
 * Logout user of system
 */
export const logout = async () => {
  await store.dispatch(logoutUser());
};

/**
 * Confirm external Login
 * @param {Login} values
 * @returns
 */
export const confirmExternalLogin = async (token) => {
  const auth = {
    token: token,
    auth2fa: false,
  };
  await store.dispatch(loginSucess(auth));
  return auth;
};

/**
 * Login Impersonate
 * @param {Login} values
 * @returns
 */
export const loginImpersonate = async (token) => {
  const auth = {
    token: token,
    auth2fa: false,
  };
  await store.dispatch(loginSucess(auth));
  return auth;
};
