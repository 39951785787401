import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  ContainerItem,
  ImageItem,
  ButtonDeleteItem,
  ContainerNav,
  InputAmount,
  ContainerResume,
  ContainerTotals,
  ContainerSubTotals,
  NavItem,
  NavIcon,
  ContainerListItems,
  ContainerAddress,
  ShippingCostText,
  Divider,
} from './checkOut.styled';
import * as Exchange from '../../../utils/formats/exchange';
import { withFormik, Field } from 'formik';
import { InputSelectFormik } from '@arcaitprod/react-forms-components';
import { dataModel, validationDataModel, setInitialValues } from './checkOu.data.form';
import { Button } from '@arcaitprod/react-basic-components';
import { SYMBOL_MONEY } from '../../../constants/index';

const CheckOutView = (props) => {
  const { t } = useTranslation();
  const { onPrev, onContinue, items, totalShipping, addressSelected, region } = props;

  let numItems = 0;
  let totals = 0;
  let totalsFinal = 0;
  let totalTax = 0;

  const renderItem = () => {
    return items.map((item, index) => {
      if (item) {
        numItems += Number(item.amount);
        let partialTax = 0;
        const itemPrice = Exchange.crypto2Satoshi(
          'GAL',
          region?.EU ? item.unitPrice : item.unitPriceOutEU
        );
        let subtotal = itemPrice * item.amount;
        totals += subtotal;
        totalsFinal += subtotal;

        if (region?.EU) {
          partialTax = parseInt(
            (itemPrice * (item.taxType !== 'out' ? region[item.taxType] : 0)) / 100
          );
          subtotal += partialTax * item.amount;
          totalTax += partialTax * item.amount;
        }

        return (
          <ContainerItem key={`${item.packageUuid}_${index}`} className="row pb-3 pt-3">
            <div className="col-2 d-none d-md-block">
              <ImageItem src={item.image}></ImageItem>
            </div>
            <div className="col-12 col-md-10">
              <div className="row mb-1">
                <div className="col-12">{item.packageTitle}</div>
              </div>
              <div className="row mb-1"></div>
              <div className="row">
                <div className="col-3">
                  {`${t('forms.shoppingCart.labels.amount')}: ${item.amount}`}
                </div>
                <div className="col-4 d-flex flex-column">
                  <div>{`${t('forms.shoppingCart.labels.price')}: ${
                    region?.EU ? item.unitPrice : item.unitPriceOutEU
                  } ${SYMBOL_MONEY}`}</div>
                  <div>{`${t('forms.shoppingCart.labels.tax')} ${
                    region.EU ? `(${item.taxType !== 'out' ? region[item.taxType] : 0}%)` : ''
                  }: ${Exchange.satoshi2Crypto('GAL', partialTax)} ${SYMBOL_MONEY}`}</div>
                </div>
                <div className="col-5 ">{`${t(
                  'forms.shoppingCart.total'
                )}: ${Exchange.satoshi2Crypto('GAL', subtotal)} ${SYMBOL_MONEY}`}</div>
              </div>
            </div>
          </ContainerItem>
        );
      }
    });
  };

  if (totalShipping) {
    totalsFinal += totalShipping;
  }

  return (
    <>
      <ContainerNav className="row justify-content-center align-items-center no-margin">
        <div className="col-10 ">
          <NavItem>{t('forms.checkout.step1')}</NavItem>
          <NavIcon className="fa-solid fa-angles-right"></NavIcon>
          <NavItem selected>{t('forms.checkout.step2')}</NavItem>
          <NavIcon className="fa-solid fa-angles-right"></NavIcon>
          <NavItem>{t('forms.checkout.step3')}</NavItem>
          <NavIcon className="fa-solid fa-angles-right"></NavIcon>
          <NavItem>{t('forms.checkout.step4')}</NavItem>
        </div>
      </ContainerNav>
      <div className="row justify-content-center no-margin">
        <div className="col-12 col-md-12 col-lg-8">
          <div className="row mt-5">
            <div className="col-12 col-md-4 col-lg-4">
              <div className="row">
                <ContainerResume className="col-12">
                  {t('forms.checkout.addressSend')}
                </ContainerResume>
              </div>
              <div className="row mt-3">
                <div className="col-12">
                  <ContainerAddress>
                    <div>
                      <b>{addressSelected?.contactName}</b>
                    </div>
                    <div>{`${addressSelected?.address}${
                      addressSelected?.addressAdc ? `, ${addressSelected?.addressAdc}` : ''
                    }`}</div>
                    <div>{`${addressSelected?.cp}, ${addressSelected?.city} ${
                      addressSelected?.province
                        ? `(${addressSelected?.province.toUpperCase()})`
                        : ''
                    }`}</div>
                    <div>{addressSelected?.countryData?.visualName.toUpperCase()}</div>
                    <div>{addressSelected?.phone}</div>
                    <div>{addressSelected?.email}</div>
                  </ContainerAddress>
                </div>
              </div>
              {/* <div className="row mt-3">
                <div className="col-12">
                  <ShippingCostText>{`${t('forms.checkout.shippingCost')} ${
                    Exchange.satoshi2Crypto('GAL', totalShipping) || '0.00'
                  } ${SYMBOL_MONEY}`}</ShippingCostText>
                </div>
              </div> */}
            </div>
            <div className="col-12  col-md-8 col-lg-8">
              <div className="row justify-content-center align-items-center">
                <ContainerResume className="col-11 mb-3 mt-5 mt-md-0">
                  {t('forms.checkout.resume')}
                </ContainerResume>
              </div>
              <div className="row justify-content-center align-items-center mb-4">
                <ContainerListItems className="col-11 col-md-11">
                  {items && items.length > 0 && renderItem()}
                </ContainerListItems>
              </div>
              <div className="row justify-content-center align-items-center mb-2">
                <ContainerTotals className="col-5">{`${t(
                  'forms.shoppingCart.subtotal'
                )}`}</ContainerTotals>
                <ContainerSubTotals className="col-4 text-end">{`${Exchange.satoshi2Crypto(
                  'GAL',
                  totals
                )} ${SYMBOL_MONEY}`}</ContainerSubTotals>
              </div>
              <div className="row justify-content-center align-items-center mb-2">
                <ContainerTotals className="col-5">{`${t(
                  'forms.shoppingCart.totalIva'
                )}`}</ContainerTotals>
                <ContainerSubTotals className="col-4 text-end">{`${Exchange.satoshi2Crypto(
                  'GAL',
                  totalTax
                )} ${SYMBOL_MONEY}`}</ContainerSubTotals>
              </div>
              <div className="row justify-content-center align-items-center mb-2">
                <ContainerTotals className="col-5">{`${t(
                  'forms.checkout.shippingCost'
                )}`}</ContainerTotals>
                <ContainerSubTotals className="col-4 text-end">{`${
                  Exchange.satoshi2Crypto('GAL', totalShipping) || '0'
                } ${SYMBOL_MONEY}`}</ContainerSubTotals>
              </div>
              <Divider></Divider>
              <div className="row justify-content-center align-items-center mt-2">
                <ContainerTotals className="col-5">{`${t(
                  'forms.shoppingCart.total'
                )}`}</ContainerTotals>
                <ContainerTotals className="col-4 text-end">{`${Exchange.satoshi2Crypto(
                  'GAL',
                  totalsFinal + totalTax
                )} ${SYMBOL_MONEY}`}</ContainerTotals>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ContainerNav className="row justify-content-center align-items-center no-margin mt-5 mt-md-0">
        <div className="col-10 text-center">
          <div className="d-grid gap-2 d-md-block">
            <Button
              type="button"
              className="me-0 me-md-3"
              variant="primary"
              onClick={() => onPrev()}
            >
              {t('forms.checkout.buttons.previous')}
            </Button>
            <Button type="button" variant="primary" onClick={() => onContinue()}>
              {t('forms.checkout.buttons.continue')}
            </Button>
          </div>
        </div>
      </ContainerNav>
    </>
  );
};

CheckOutView.propTypes = {
  onPrev: PropTypes.func,
  onContinue: PropTypes.func,
  addressSelected: PropTypes.object,
  totalShipping: PropTypes.number,
  items: PropTypes.array,
  region: PropTypes.object,
};

const CheckOutViewF = withFormik({
  mapPropsToValues: (props) => {
    if (props.initialValues) {
      return setInitialValues(props.initialValues);
    }
    return dataModel;
  },
  validationSchema: (props) => {
    return validationDataModel(props.edit);
  },
  handleSubmit: (values, { props, resetForm }) => {
    props.onSubmit(values, resetForm);
  },
  enableReinitialize: true,
  displayName: 'CheckOutView',
})(CheckOutView);

export default CheckOutViewF;
