import React, { useEffect, useState } from 'react';
import MyCommission from './MyCommission.view';
import * as UserServices from '../../../../services/UserService';

const MyCommissionContainer = (props) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    let cancel = false;

    UserServices.getUserCommissions().then((result) => {
      if (cancel) return;
      if (result) {
        setData(result);
      }
    });

    return () => {
      cancel = true;
    };
  }, []);

  return <MyCommission data={data}></MyCommission>;
};

// MyCommissionContainer.propTypes = {};

export default MyCommissionContainer;
