import { sortBy } from 'lodash';
import { createAction } from 'redux-actions';
import { apiGet, apiPost, apiDelete, apiPut } from '../../api/index';

import {
  urlProductsSecured,
  urlShoppingCartSecured,
  urlSyncShoppingCartSecured,
  urlCleanShoppingCartSecured,
  urlNitroxActivation,
  urlProductsFreeSecured,
} from '../../api/urls';

import {
  PAGE_SIZE_10,
  FILTER_PRODUCT,
  GET_PRODUCT,
  ADD_SHOPPING_CART,
  REMOVE_SHOPPING_CART,
  CHANGE_AMOUNT_SHOPPING_CART,
  SYNC_SHOPPING_CART,
  LOCAL_SYNC_SHOPPING_CART,
  CLEAN_SHOPPING_CART,
  CLEAN_SHOPPING_CART_SERVER,
  SET_PURCHASE,
} from '../../constants/index';

const generateFilterQuery = ({ filters, page, pageSize, sort }) => {
  let filter = '?';

  page ? (filter += 'page=' + page) : (filter += 'page=1');
  pageSize ? (filter += '&pageSize=' + pageSize) : (filter += '&pageSize=' + PAGE_SIZE_10);

  if (filters) {
    if (filters.productLineType)
      filter += '&productLineType=' + filters.productLineType.toUpperCase();

    if (filters.title) filter += '&title=' + filters.title;

    if (filters.isNewItem !== undefined) filter += '&isNewItem=' + filters.isNewItem;

    if (filters.isRemarkable !== undefined) filter += '&isRemarkable=' + filters.isRemarkable;

    if (filters.isRelated !== undefined) filter += '&isRelated=' + filters.isRelated;

    if (filters.groups) filter += '&groups=' + filters.groups;

    if (filters.type) filter += '&type=' + filters.type;

    if (filters.countryUuid) filter += '&countryUuid=' + filters.countryUuid;
  }
  if (sort) {
    filter += '&sort=' + sort;
  }

  return filter;
};

/**
 * Filter list Product.
 */
export const filterProductFree = createAction('FILTER_PRODUCT_FREE', (filter, param) =>
  apiGet(urlProductsFreeSecured + generateFilterQuery(filter), param, true)()
);

/**
 * Filter list Product.
 */
export const filterProduct = createAction(FILTER_PRODUCT, (filter, param) =>
  apiGet(urlProductsSecured + generateFilterQuery(filter), param, true)()
);

/**
 * Filter list Product.
 */
export const filterAllProduct = createAction(FILTER_PRODUCT, (filter, param) =>
  apiGet(urlProductsSecured + '/all' + generateFilterQuery(filter), param, true)()
);

/**
 * Get Product by uuid.
 */
export const getProductByUuid = createAction(GET_PRODUCT, (param) =>
  apiGet(urlProductsSecured, param, true)()
);

/**
 * Add Item shopinng cart.
 */
export const addShoppingCart = createAction(ADD_SHOPPING_CART, (obj) =>
  apiPost(urlShoppingCartSecured, obj, undefined, true)()
);

/**
 * Add Item shopinng cart.
 */
export const addShoppingCartLocal = createAction(ADD_SHOPPING_CART, (data) => data);

/**
 * Remove Item shopinng cart.
 */
export const removeShoppingCartLocal = createAction(REMOVE_SHOPPING_CART, (data) => data);

/**
 * Change Amount Item shopinng cart.
 */
export const changeAmountShoppingCartLocal = createAction(
  CHANGE_AMOUNT_SHOPPING_CART,
  (data) => data
);

/**
 * Sync shopinng cart.
 */
export const syncShoppingCart = createAction(SYNC_SHOPPING_CART, (obj) =>
  apiPost(urlSyncShoppingCartSecured, obj, undefined, true)()
);

/**
 * Remove Item shopinng cart.
 */
export const localSyncShoppingCart = createAction(LOCAL_SYNC_SHOPPING_CART, (data) => data);

/**
 * Remove Item Product by uuid.
 */
export const removeProductItem = createAction(REMOVE_SHOPPING_CART, (param) =>
  apiDelete(urlShoppingCartSecured, param, true)()
);

/**
 * Change Amount shopping cart.
 */
export const changeAmountShoppingCart = createAction(CHANGE_AMOUNT_SHOPPING_CART, (obj, param) =>
  apiPut(urlShoppingCartSecured, obj, param, true)()
);

/**
 * Clear Shopping cart.
 */
export const clearShoppingCart = createAction(CLEAN_SHOPPING_CART, (data) => data);

/**
 * Clean shopping cart user.
 */
export const clearShoppingCartServer = createAction(CLEAN_SHOPPING_CART_SERVER, () =>
  apiPut(urlCleanShoppingCartSecured, undefined, undefined, true)()
);

/**
 * Clear Shopping cart.
 */
export const setPurchase = createAction(SET_PURCHASE, (data) => data);
