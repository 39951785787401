import React, { useState } from 'react';
import Header from '../commons/header';
import Sidebar from '../commons/sidebar';
import {
  checkBootstrapTypeBreak,
  checkBootstrapSizeBreak,
} from '../../../utils/device/checkDevice';
import { OffCanvas, SideBarContainer, BodyContainer, Container } from './styled';
import * as NotificationService from '../../../services/NotificationService';
import {
  getEvoStatusSelector,
  getNicknameSelector,
  getUserRoleSelector,
} from '../../../store/selectors/authSelector';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import SubNav from '../commons/subNav';
import Footer from '../commons/footer';
import Nav from '../commons/nav';

const VerticalLayout = ({ ...prop }) => {
  const breakDevice = checkBootstrapTypeBreak();
  const breakSizeDevice = checkBootstrapSizeBreak();
  const status = useSelector(getEvoStatusSelector);
  const role = useSelector(getUserRoleSelector);
  const nickName = useSelector(getNicknameSelector);
  const { t } = useTranslation();
  // NotificationService.getPendingNotification();
  const [toggle, setToggle] = useState(
    breakDevice === 'xs' || breakDevice === 'sm' || breakDevice === 'md' ? false : true
  );
  const hideShowSidebar = () => {
    setToggle(!toggle);
  };
  return (
    <>
      <OffCanvas
        className="offcanvas offcanvas-start d-flex justify-content-between flex-wrap flex-column"
        tabIndex="-1"
        id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel"
      >
        <Sidebar offCanvas userStatus={status} nickname={nickName} role={role} />
      </OffCanvas>

      <SideBarContainer
        className={`side-navbar ${toggle ? 'active-nav' : ''}  `} //d-flex  flex-wrap flex-column
        id="sidebar"
      >
        <Sidebar userStatus={status} nickname={nickName} role={role} />
      </SideBarContainer>

      <div className={`my-container  ${toggle ? 'active-cont' : ''}`}>
        <BodyContainer id="page-wrapper-back" className="container-fluid">
          <Container className="sticky-top">
            <Header
              showUserMenu={prop.showUserMenu !== undefined ? prop.showUserMenu : undefined}
              withSidebar={true}
              toggleFunc={() => {
                hideShowSidebar();
              }}
            />
            <SubNav />
            {breakSizeDevice < 3 && <Nav breakDevice={breakSizeDevice}></Nav>}
          </Container>
          <div id="page-content-wrapper">
            <div id="page-content">{prop.body}</div>
          </div>
        </BodyContainer>
        <Footer></Footer>
      </div>
    </>
  );
};

export default VerticalLayout;
