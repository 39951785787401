import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { connectModal } from 'redux-modal';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Loader from 'react-loader-spinner';

class LoadingModal extends Component {
  componentDidMount() {}

  render() {
    const { show, handleHide } = this.props;
    return (
      <Modal
        show={show}
        backdrop="static"
        onHide={handleHide}
        dialogClassName="modal-loading"
        bsPrefix="loadingModal"
        centered
      >
        <Modal.Body>
          <div className="modalContainerLoading">
            <Loader type="ThreeDots" color="#ffffff" height={100} width={100} />
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

LoadingModal.propTypes = {
  show: PropTypes.any,
  handleHide: PropTypes.func,
};

export default connectModal({ name: 'LoadingModal' })(withTranslation()(LoadingModal));
