import React from 'react';
import { useSelector } from 'react-redux';
import {
  getCountPersonalNotificationsSelector,
  getCountGeneralNotificationsSelector,
} from '../../../../../../store/selectors/notificationSelector';
import { ContainerIcon, ButtonIcon, Badge } from './styled';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import PATHS from '../../../../../../constants/paths';
import { useTranslation } from 'react-i18next';

const IconNotification = (props) => {
  const { general } = props;
  const { t } = useTranslation();
  let history = useHistory();
  const count = useSelector(
    general ? getCountGeneralNotificationsSelector : getCountPersonalNotificationsSelector
  );

  const goTo = () => {
    if (general) {
      history.push(PATHS.GENERAL_NOTIFICATION_LIST);
    } else {
      history.push(PATHS.NOTIFICATION_LIST);
    }
  };

  return (
    <ContainerIcon>
      <ButtonIcon
        type="button"
        className="btn position-relative"
        general={general}
        onClick={() => goTo()}
        title={general ? t('alertIcon.general') : t('alertIcon.personal')}
      >
        {general ? <i className="fas fa-globe"></i> : <i className="fas fa-bell"></i>}
        {count > 0 && (
          <Badge className="position-absolute start-100 translate-middle badge rounded-pill bg-danger">
            {count > 99 ? '+99' : count}
          </Badge>
        )}
      </ButtonIcon>
    </ContainerIcon>
  );
};

IconNotification.propTypes = { general: PropTypes.bool };

export default IconNotification;
