import React from 'react';
import PropTypes from 'prop-types';
import { PaginationContainer, ButtonAction, TextPage, IconArrow } from './DefaultPagination.styled';
import { useTranslation } from 'react-i18next';
import { checkBootstrapSizeBreak } from '../../../utils/device/checkDevice';

const DefaultPagination = (props) => {
  const {
    gotoPage,
    previousPage,
    nextPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
    pageCount,
    pageIndex,
    pageOptions,
    pageSize,
  } = props;
  const { t } = useTranslation();
  const breakDevice = checkBootstrapSizeBreak();
  return (
    <>
      <PaginationContainer>
        <div className="row justify-content-center mt-3">
          <div className="col-12 d-flex justify-content-center align-item-center">
            <ButtonAction onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
              <IconArrow className="fa-solid fa-angles-left"></IconArrow>
              {breakDevice > 1 && t('generic.tables.first')}
            </ButtonAction>
            <ButtonAction onClick={() => previousPage()} disabled={!canPreviousPage}>
              <IconArrow className="fa-solid fa-angle-left"></IconArrow>
              {breakDevice > 1 && t('generic.tables.previous')}
            </ButtonAction>
            <TextPage>
              {t('generic.tables.page')}
              <strong>{` ${pageIndex + 1} ${t('generic.tables.of')} ${pageOptions.length}`}</strong>
            </TextPage>
            <ButtonAction onClick={() => nextPage()} disabled={!canNextPage}>
              {breakDevice > 1 && t('generic.tables.next')}
              <IconArrow className="fa-solid fa-angle-right"></IconArrow>
            </ButtonAction>
            <ButtonAction onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
              {breakDevice > 1 && t('generic.tables.last')}
              <IconArrow className="fa-solid fa-angles-right"></IconArrow>
            </ButtonAction>
          </div>
        </div>
        <div className="row justify-content-center mt-1">
          <div className="col-12 d-flex justify-content-center align-item-center">
            <select
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {`${t('generic.tables.show')} ${pageSize}`}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* <span>
          | Go to page:{' '}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
          />
        </span> */}
      </PaginationContainer>
    </>
  );
};

DefaultPagination.propTypes = {
  gotoPage: PropTypes.func,
  previousPage: PropTypes.func,
  nextPage: PropTypes.func,
  setPageSize: PropTypes.func,
  canPreviousPage: PropTypes.any,
  canNextPage: PropTypes.any,
  pageCount: PropTypes.any,
  pageIndex: PropTypes.any,
  pageOptions: PropTypes.any,
  pageSize: PropTypes.any,
};

export default DefaultPagination;
