import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import * as AddressService from '../../../../services/AddressService';
import * as GeneralServices from '../../../../services/GeneralServices';
import { withTranslation } from 'react-i18next';
import AddressForm from './addressForm.view';
import PATHS from '../../../../constants/paths';
import { showDialog } from '@arcaitprod/react-utils-components';

class AddressContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      values: null,
      nationalities: undefined,
    };
  }

  componentDidMount() {
    if (this.props.uuid) {
      AddressService.getAddress(this.props.uuid).then((result) => {
        if (result?.uuid) {
          this.setState({ values: result, edit: true });
        }
      });
    }
    GeneralServices.getAllNationalities().then((result) => {
      if (result && result.length > 0) {
        this.setState({ nationalities: result });
      }
    });
  }

  handleSubmit = async (values, externalAction = undefined) => {
    try {
      if (values) {
        let result = undefined;
        if (this.state.edit && this.props.uuid) {
          result = await AddressService.updateAddress(values, this.props.uuid);
        } else {
          result = await AddressService.addAddress(values);
        }
        let title = '';
        let text = '';
        let icon = '';
        if (result && result.uuid) {
          if (this.state.edit) {
            title = this.props.t('forms.address.messages.update.title');
            text = this.props.t('forms.address.messages.update.success');
            icon = 'success';
          } else {
            title = this.props.t('forms.address.messages.create.title');
            text = this.props.t('forms.address.messages.create.success');
            icon = 'success';
          }
        } else {
          title = 'Error';
          text = this.props.t('forms.address.messages.update.error');
          icon = 'error';
        }
        let okAction = undefined;
        okAction = () => {
          if (externalAction) {
            externalAction(result || null);
          } else {
            this.props.history.push(PATHS.LIST_MY_ADDRESSES);
          }
        };
        showDialog(title, text, icon, false, 'OK', false, okAction);
      }
    } catch (error) {
      //console.log('Error', error);
    }
  };

  handleOnback = () => {
    this.props.history.push(PATHS.LIST_MY_ADDRESSES);
  };

  render() {
    const { cancelAction, externalAction } = this.props;

    return (
      <AddressForm
        edit={this.state.edit}
        initialValues={this.state.values}
        onSubmit={(values) => this.handleSubmit(values, externalAction)}
        onback={cancelAction ? cancelAction : this.handleOnback}
        nationalities={this.state.nationalities || []}
      />
    );
  }
}

AddressContainer.propTypes = {
  t: PropTypes.any,
};

export default withRouter(withTranslation()(AddressContainer));
