import React from 'react';
import ExchangeForm from '../../../components/user/exchange';
import Layout from '../../../components/layouts/simpleLayout';

const ExchangePage = (props) => {
  return (
    <Layout
      body={
        <>
          <ExchangeForm
            currencyFrom={props.currencyFrom || 'BTC'}
            currencyTo={props.currencyTo || 'BTC'}
          />
        </>
      }
    />
  );
};

ExchangePage.propTypes = {};

export default ExchangePage;
