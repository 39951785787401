import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  LabelLevel,
  RankContainer,
  ImgRank,
  ImgMedal,
  MedalContainer,
  LabelMedal,
  LabelRank,
  ImgFounder,
} from './RankMedals.styled';
// import { checkBootstrapSizeBreak } from '../../../../utils/device/checkDevice';

const RanksMedalsView = (props) => {
  const { medals, rank } = props;
  const { t } = useTranslation();
  // const breakDevice = checkBootstrapSizeBreak();
  return (
    <>
      <div className="row">
        {/* <div className="col-12 col-md-8"> */}
        {/* <div className="row">
            <div className="col-12 d-flex no-padding-right">
              <MedalContainer>
                <LabelMedal className="d-none d-md-block">{t('medals.bigbang')}</LabelMedal>
                <LabelMedal className="d-none d-md-block">{t('medals.silver')}</LabelMedal>
                <ImgMedal
                  src={`/assets/themes/${process.env.REACT_APP_THEME}/images/${
                    medals?.bigBangSilver ? 'Big_Bang_Silver.png' : 'Sin_Medalla.png'
                  }`}
                  alt="rango"
                />
              </MedalContainer>
              <MedalContainer>
                <LabelMedal className="d-none d-md-block">{t('medals.bigbang')}</LabelMedal>
                <LabelMedal className="d-none d-md-block">{t('medals.gold')}</LabelMedal>
                <ImgMedal
                  src={`/assets/themes/${process.env.REACT_APP_THEME}/images/${
                    medals?.bigBangGold ? 'Big_Bang_Gold.png' : 'Sin_Medalla.png'
                  }`}
                  alt="rango"
                />
              </MedalContainer>
              <MedalContainer>
                <LabelMedal className="d-none d-md-block">{t('medals.supernova')}</LabelMedal>
                <LabelMedal className="d-none d-md-block">{t('medals.silver1')}</LabelMedal>
                <ImgMedal
                  src={`/assets/themes/${process.env.REACT_APP_THEME}/images/${
                    medals?.supernovaSilver ? 'Supernova_Silver.png' : 'Sin_Medalla.png'
                  }`}
                  alt="rango"
                />
              </MedalContainer>
              <MedalContainer>
                <LabelMedal className="d-none d-md-block">{t('medals.supernova')}</LabelMedal>
                <LabelMedal className="d-none d-md-block">{t('medals.silver2')}</LabelMedal>
                <ImgMedal
                  src={`/assets/themes/${process.env.REACT_APP_THEME}/images/${
                    medals?.supernovaSilver && medals.supernovaSilver > 1
                      ? 'Supernova_Silver.png'
                      : 'Sin_Medalla.png'
                  }`}
                  alt="rango"
                />
              </MedalContainer>
              <MedalContainer>
                <LabelMedal className="d-none d-md-block">{t('medals.supernova')}</LabelMedal>
                <LabelMedal className="d-none d-md-block">{t('medals.gold')}</LabelMedal>
                <ImgMedal
                  src={`/assets/themes/${process.env.REACT_APP_THEME}/images/${
                    medals?.supernovaGold ? 'Supernova_Gold.png' : 'Sin_Medalla.png'
                  }`}
                  alt="rango"
                />
              </MedalContainer>
            </div>
          </div> */}
        {/* <div className="row mt-2">
            <div className="col-12 d-flex no-padding-right">
              <MedalContainer>
                <LabelMedal className="d-none d-md-block">{t('medals.nebula')}</LabelMedal>
                <LabelMedal className="d-none d-md-block">{t('medals.silver1')}</LabelMedal>
                <ImgMedal
                  src={`/assets/themes/${process.env.REACT_APP_THEME}/images/${
                    medals?.nebulaSilver ? 'Nebula_Silver.png' : 'Sin_Medalla.png'
                  }`}
                  alt="rango"
                />
              </MedalContainer>
              <MedalContainer>
                <LabelMedal className="d-none d-md-block">{t('medals.nebula')}</LabelMedal>
                <LabelMedal className="d-none d-md-block">{t('medals.silver2')}</LabelMedal>
                <ImgMedal
                  src={`/assets/themes/${process.env.REACT_APP_THEME}/images/${
                    medals?.nebulaSilver && medals.nebulaSilver > 1
                      ? 'Nebula_Silver.png'
                      : 'Sin_Medalla.png'
                  }`}
                  alt="rango"
                />
              </MedalContainer>
              <MedalContainer>
                <LabelMedal className="d-none d-md-block">{t('medals.nebula')}</LabelMedal>
                <LabelMedal className="d-none d-md-block">{t('medals.gold')}</LabelMedal>
                <ImgMedal
                  src={`/assets/themes/${process.env.REACT_APP_THEME}/images/${
                    medals?.nebulaGold ? 'Nebula_Gold.png' : 'Sin_Medalla.png'
                  }`}
                  alt="rango"
                />
              </MedalContainer>
              <MedalContainer>
                <LabelMedal className="d-none d-md-block">{t('medals.lstelar')}</LabelMedal>
                <LabelMedal className="d-none d-md-block">{t('medals.silver1')}</LabelMedal>
                <ImgMedal
                  src={`/assets/themes/${process.env.REACT_APP_THEME}/images/${
                    medals?.stellarLeaderSilver ? 'Clone_Silver.png' : 'Sin_Medalla.png'
                  }`}
                  alt="rango"
                />
              </MedalContainer>
              <MedalContainer>
                <LabelMedal className="d-none d-md-block">{t('medals.lstelar')}</LabelMedal>
                <LabelMedal className="d-none d-md-block">{t('medals.silver2')}</LabelMedal>
                <ImgMedal
                  src={`/assets/themes/${process.env.REACT_APP_THEME}/images/${
                    medals?.stellarLeaderSilver && medals.stellarLeaderSilver > 1
                      ? 'Clone_Silver.png'
                      : 'Sin_Medalla.png'
                  }`}
                  alt="rango"
                />
              </MedalContainer>
            </div>
          </div> */}
        {/* <div className="row mt-2">
            <div className="col-12 d-flex no-padding-right">
              <MedalContainer>
                <LabelMedal className="d-none d-md-block">{t('medals.lstelar')}</LabelMedal>
                <LabelMedal className="d-none d-md-block">{t('medals.gold')}</LabelMedal>
                <ImgMedal
                  src={`/assets/themes/${process.env.REACT_APP_THEME}/images/${
                    medals?.stellarLeaderGold ? 'Clone_Gold.png' : 'Sin_Medalla.png'
                  }`}
                  alt="rango"
                />
              </MedalContainer>
              <MedalContainer>
                <LabelMedal className="d-none d-md-block">{t('medals.comet')}</LabelMedal>
                <LabelMedal className="d-none d-md-block">&nbsp;</LabelMedal>
                <ImgMedal
                  src={`/assets/themes/${process.env.REACT_APP_THEME}/images/${
                    medals?.comet ? 'Comet.png' : 'Sin_Medalla.png'
                  }`}
                  alt="rango"
                />
              </MedalContainer>
              <MedalContainer>
                <LabelMedal className="d-none d-md-block">{t('medals.quazar')}</LabelMedal>
                <LabelMedal className="d-none d-md-block">&nbsp;</LabelMedal>
                <ImgMedal
                  src={`/assets/themes/${process.env.REACT_APP_THEME}/images/${
                    medals?.quasar ? 'Quazar.png' : 'Sin_Medalla.png'
                  }`}
                  alt="rango"
                />
              </MedalContainer>
              <MedalContainer>
                <LabelMedal className="d-none d-md-block">{t('medals.galactic')}</LabelMedal>
                <LabelMedal className="d-none d-md-block">&nbsp;</LabelMedal>
                <ImgMedal
                  src={`/assets/themes/${process.env.REACT_APP_THEME}/images/${
                    medals?.galactic ? 'Galactic.png' : 'Sin_Medalla.png'
                  }`}
                  alt="rango"
                />
              </MedalContainer>
            </div>
          </div> */}
        {/* </div> */}
        <div className="col-11 col-md-12 text-center no-padding-left mt-3 mt-md-0 pe-0 pe-md-3">
          <RankContainer color1={rank.color1} color2={rank.color2}>
            <ImgRank
              src={`/assets/themes/${process.env.REACT_APP_THEME}/images/ranks/${rank.image}.png`}
              alt="rango"
            />
          </RankContainer>
          <LabelRank>{t(rank.label)}</LabelRank>
          {medals?.founders > 0 && (
            <>
              <div className="row mt-4">
                <div className="col-12">
                  <ImgFounder
                    src={`/assets/themes/${process.env.REACT_APP_THEME}/images/Fundadores.png`}
                    alt="rango"
                  />
                  <LabelRank>{t('medals.founder')}</LabelRank>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

RanksMedalsView.propTypes = {
  medals: PropTypes.object,
  rank: PropTypes.object,
};

export default RanksMedalsView;
