import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  ContainerBalanceText,
  ContainerResume,
  ContainerListItems,
  ContainerItem,
  ImageItem,
  ContainerTotals,
  ContainerSubTotals,
  Divider,
  FinalPrice,
  ContainerBalance,
  ReturnButton,
  InvoiceContainer,
  Text04,
} from './orderDetails.styled';
import {
  METHOD_WALLET,
  METHOD_WALLETS,
  METHOD_MIXED,
  METHOD_CARD,
  SYMBOL_MONEY,
  PRODUCT_STATUS_RETURN_REQUEST,
  PRODUCT_STATUS_RETURN_PROCESS,
  PRODUCT_STATUS_CONFIRMED,
  PRODUCT_STATUS_CANCELED,
  PRODUCT_STATUS,
} from '../../../../constants/index';
import { Button } from '@arcaitprod/react-basic-components';

import * as Exchange from '../../../../utils/formats/exchange';
import { SecctionTitleContainer } from '../../../../design/styleComponents/globalComponents';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import { getTimeZoneSelector } from '../../../../store/selectors/navSelector';
import Invoice from './invoice.view';
import TotalInvoice from './totalInvoice.view';
import html2PDF from 'jspdf-html2canvas';
import { renderToString } from 'react-dom/server';
import { useDispatch } from 'react-redux';
import { show, hide } from 'redux-modal';
import InvoiceModal from '../../../utils/invoiceModal/invoiceModal';
import { Can, AbilityContext } from '../../../permissions/Can';
import { USER_PERMISSIONS } from '../../../permissions/rolesPermission';

const OrderListPage = (props) => {
  const { data, onBack, requestReturn } = props;
  const { t } = useTranslation();
  const timeZone = useSelector(getTimeZoneSelector);
  const dispatch = useDispatch();
  let taxes = {};
  let numItems = 0;
  let subTotal = 0;
  let totals = 0;
  let discount = 0;
  let totalPurchase = 0;
  let tax = 0;
  let payWithTre = 0;
  const context = useContext(AbilityContext);

  const generateInvoiceTemplate = (item, id) => {
    return (
      <>
        <InvoiceContainer>
          <div
            id={id}
            className="mt4"
            style={{
              backgroundColor: '#f5f5f5',
              width: '210mm',
              minHeight: '287mm',
              margin: 'auto',
            }}
          >
            <Invoice item={item} purchase={data}></Invoice>
          </div>
        </InvoiceContainer>
        <div className="row mt-3">
          <div className="col-12 text-center">
            <Button
              type="button"
              className="btn btn-sm"
              variant="primary"
              title={t('forms.order.buttons.return')}
              onClick={() => printInvoice(item, id)}
            >
              {t('forms.order.buttons.invoiceDownload')}
            </Button>
          </div>
        </div>
      </>
    );
  };

  const generateTotalInvoiceTemplate = (items, id) => {
    return (
      <>
        <InvoiceContainer>
          <div
            id={id}
            className="mt4"
            style={{
              backgroundColor: '#f5f5f5',
              width: '210mm',
              minHeight: '287mm',
              margin: 'auto',
            }}
          >
            <TotalInvoice purchase={data}></TotalInvoice>
          </div>
        </InvoiceContainer>
        <div className="row mt-3">
          <div className="col-12 text-center">
            <Button
              type="button"
              className="btn btn-sm"
              variant="primary"
              title={t('forms.order.buttons.return')}
              onClick={() => printInvoice('', id)}
            >
              {t('forms.order.buttons.invoiceDownload')}
            </Button>
          </div>
        </div>
      </>
    );
  };

  const printInvoice = async (item, id) => {
    const invoiceTemplate = document.getElementById(id);
    const pdf = await html2PDF(invoiceTemplate, {
      jsPDF: {
        format: 'a4',
      },
      imageType: 'image/jpeg',
      output: 'invoice.pdf',
    });
  };

  const showModalInvoice = (item, id) => {
    dispatch(
      show('InvoiceModal', {
        body: <>{generateInvoiceTemplate(item, id)}</>,
        className: 'invoiceModal',
      })
    );
  };

  const showModalTotalInvoice = (items, id) => {
    dispatch(
      show('InvoiceModal', {
        body: <>{generateTotalInvoiceTemplate(items, id)}</>,
        className: 'invoiceModal',
      })
    );
  };

  const renderItem = (countryData, items) => {
    return items.map((item, index) => {
      if (item) {
        const status = PRODUCT_STATUS.find((prod) => prod.code === item.status);
        let showReturn = false;
        let showDownloadInvoice = false;
        let showReturnStatus =
          item.status === PRODUCT_STATUS_RETURN_REQUEST.code ||
          item.status === PRODUCT_STATUS_RETURN_PROCESS.code;

        if (
          item.status !== PRODUCT_STATUS_CONFIRMED.code &&
          item.status !== PRODUCT_STATUS_CANCELED.code
        ) {
          showDownloadInvoice = true;
        }

        if (
          item.dateReturnLimit &&
          item.status !== PRODUCT_STATUS_RETURN_REQUEST.code &&
          item.status !== PRODUCT_STATUS_RETURN_PROCESS.code
        ) {
          const current = moment(new Date()).tz('Europe/Madrid');
          const dateLimitReturn = moment(item.dateReturnLimit).tz('Europe/Madrid');
          showReturn = current.isBefore(dateLimitReturn);
        }

        numItems += Number(item.amount);
        const basePrice = Number(
          Exchange.crypto2Satoshi('GAL', countryData?.EU ? item.unitPrice : item.unitPriceOutEU)
        );
        const baseDiscount = Number(
          Exchange.crypto2Satoshi(
            'GAL',
            countryData?.EU ? item.directDiscount : item.directDiscountOutEU
          )
        );
        const subtotalItem = basePrice * item.amount;
        subTotal += subtotalItem;
        totals += subtotalItem;

        const tokens =
          // Exchange.crypto2Satoshi('GAL', hasTax ? item.unitPriceOutTax : item.unitPrice) *
          Exchange.crypto2Satoshi('GAL', countryData?.EU ? item.tokens : item.tokensOutEU) *
          Number(item.amount);

        const subtotalDiscount = baseDiscount * Number(item.amount);

        discount += subtotalDiscount;
        payWithTre += tokens;

        if (countryData.EU) {
          const priceToAppliedTax =
            data.paymentMethod && data.paymentMethod === 'wallets' && data.paymentMethod === 'mixed'
              ? subtotalItem - subtotalDiscount
              : subtotalItem;
          const partialTax = parseInt(
            (priceToAppliedTax * (item.taxType !== 'out' ? countryData[item.taxType] : 0)) / 100
          );
          if (taxes[`${item.taxType}`]) {
            taxes[`${item.taxType}`].value = taxes[`${item.taxType}`].value + partialTax;
          } else {
            taxes[`${item.taxType}`] = {
              tax: item.taxType !== 'out' ? countryData[item.taxType] : 0,
              value: partialTax,
            };
          }
          tax += partialTax;
        }

        // const subtotal =
        //   Exchange.crypto2Satoshi('GAL', countryData.EU ? item.unitPrice : item.unitPriceOutEU) *
        //   item.amount;
        return (
          <ContainerItem key={`${item.packageUuid}_${index}`} className="row pb-3 pt-3">
            <div className="col-2 text-center">
              <ImageItem src={item.image}></ImageItem>
            </div>
            <div className="col-10">
              <div className="row mb-1">
                <div className="col-6">
                  <div>
                    {`${t('forms.order.labels.status')}: `}
                    <b>{t(status.label)}</b>
                  </div>

                  {item.dateReturnLimit && (
                    <div>
                      {`${t('forms.order.labels.dateReturnLimit')}: `}
                      <b>{moment(item.dateReturnLimit).tz('Europe/Madrid').format('DD-MM-yyyy')}</b>
                    </div>
                  )}
                </div>
                <div className="col-6 text-end">
                  {showReturn && (
                    <ReturnButton
                      type="button"
                      className="btn btn-sm"
                      variant="secondary"
                      title={t('forms.order.buttons.return')}
                      onClick={() => requestReturn(item, data)}
                    >
                      {t('forms.order.buttons.return')}
                    </ReturnButton>
                  )}
                  {showReturnStatus && (
                    <ReturnButton
                      type="button"
                      className="btn btn-sm"
                      variant="secondary"
                      title={t('forms.order.buttons.returnStatus')}
                      onClick={() => requestReturn(item, data)}
                    >
                      {t('forms.order.buttons.returnStatus')}
                    </ReturnButton>
                  )}
                  {/* {showDownloadInvoice && (
                    <ReturnButton
                      type="button"
                      className="btn btn-sm"
                      variant="secondary"
                      title={t('forms.order.buttons.invoice')}
                      onClick={() => showModalInvoice(item, `${item.packageUuid}_${index}_invoice`)}
                    >
                      {t('forms.order.buttons.invoice')}
                    </ReturnButton>
                  )} */}
                </div>
              </div>
              <div className="row mb-1">
                <div className="col-10">
                  <b>{item.packageTitle}</b>
                </div>
              </div>
              <div className="row mb-1"></div>
              <div className="row">
                <div className="col-3 d-flex">
                  <div className="me-1"> {`${t('forms.shoppingCart.labels.amount')}:`}</div>
                  <div>
                    <b>{` ${item.amount}`}</b>
                  </div>
                </div>
                <div className="col-4 d-flex">
                  <div className="me-1">{`${t('forms.shoppingCart.labels.price')}:`}</div>
                  <div>
                    <b>{`${
                      countryData.EU ? item.unitPrice : item.unitPriceOutEU
                    } ${SYMBOL_MONEY}`}</b>
                  </div>
                </div>
                <div className="col-5 d-flex">
                  <div className="me-1">{`${t('forms.shoppingCart.total')}:`}</div>
                  <div>
                    <b>{`${Exchange.satoshi2Crypto('GAL', subtotalItem)} ${SYMBOL_MONEY}`}</b>
                  </div>
                </div>
              </div>
            </div>
          </ContainerItem>
        );
      }
    });
  };

  const renderTotalTax = () => {
    const taxs = Object.keys(taxes);
    return taxs.map((partialTax) => {
      return (
        <div
          key={`typeTax_${partialTax}`}
          className="row justify-content-center align-items-center mb-2"
        >
          <ContainerTotals className="col-5">{`${t('forms.checkout.labels.tax')} (${
            taxes[partialTax].tax
          }%)`}</ContainerTotals>
          <ContainerSubTotals className="col-4 text-end">
            {`${
              tax === 0 ? '0' : Exchange.satoshi2Crypto('GAL', taxes[partialTax].value)
            } ${SYMBOL_MONEY}`}
          </ContainerSubTotals>
        </div>
      );
    });
  };

  return (
    <>
      <InvoiceModal />
      {data && (
        <>
          <div className="row justify-content-center no-margin">
            <div className="col-9">
              <SecctionTitleContainer className="col-11 col-md-11 mt-5 mb-5">
                {t('forms.order.labels.detail')}
              </SecctionTitleContainer>
            </div>
          </div>

          <div className="row justify-content-center no-margin mb-1">
            <div className="col-3">
              <div className="row ">
                <ContainerResume className="col-12 mb-2">
                  {t('forms.checkout.addressSend')}
                  <Text04>{t('forms.checkout.addressSend2')}</Text04>
                </ContainerResume>
              </div>
              <div className="row">
                <div className="col-12">
                  <div>
                    <b>{data.shippingAddress?.contactName}</b>
                  </div>
                  <div>{`${data.shippingAddress?.address}${
                    data.shippingAddress?.addressAdc ? `, ${data.shippingAddress?.addressAdc}` : ''
                  }`}</div>
                  <div>{`${data.shippingAddress?.cp}, ${data.shippingAddress?.city} ${
                    data.shippingAddress?.province
                      ? `(${data.shippingAddress?.province.toUpperCase()})`
                      : ''
                  }`}</div>
                  <div>{data.countryData?.visualName.toUpperCase()}</div>
                  <div>{data.shippingAddress?.phone}</div>
                  <div>{data.shippingAddress?.email}</div>
                </div>
              </div>
              <div className="row mt-5">
                <ContainerResume className="col-12 mb-2">
                  {t('forms.checkout.addressFact')}
                </ContainerResume>
              </div>
              <div className="row">
                <div className="col-12">
                  <div>
                    <b>{data.billingAddress?.contactName}</b>
                  </div>
                  <div>{`${data.billingAddress?.address}${
                    data.billingAddress?.addressAdc ? `, ${data.billingAddress?.addressAdc}` : ''
                  }`}</div>
                  <div>{`${data.billingAddress?.cp}, ${data.billingAddress?.city} ${
                    data.billingAddress?.province
                      ? `(${data.billingAddress?.province.toUpperCase()})`
                      : ''
                  }`}</div>
                  <div>{data.countryData?.visualName.toUpperCase()}</div>
                  <div>{data.billingAddress?.phone}</div>
                  <div>{data.billingAddress?.email}</div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="row mt-5">
                    <ContainerResume className="col-12">
                      {t('forms.checkout.labels.paymentMode')}
                    </ContainerResume>
                  </div>
                  <div className="row">
                    <ContainerBalanceText className="col-12 mt-2">
                      {t(`paymentMethod.${data.paymentMethod}`)}
                    </ContainerBalanceText>
                  </div>
                  {data.payments && (
                    <>
                      {data.payments.map((payment, index) => {
                        let text = '';
                        let textReturn = '';
                        let symbol = '';
                        const returned = payment.status === 'RETURNED';
                        if (payment.type === 'WITHDRAW') {
                          if (payment.currency === 'TRE') {
                            text = t('forms.checkout.labels.payedWalletTre');
                            textReturn = t('forms.checkout.labels.payedWalletTreReturn');
                            symbol = 'TRE';
                          } else {
                            text = t('forms.checkout.labels.payedWalletGal');
                            textReturn = t('forms.checkout.labels.payedWalletGalReturn');
                            symbol = 'GL';
                          }
                        } else {
                          text = t('forms.checkout.labels.payedCreditCard');
                          textReturn = t('forms.checkout.labels.payedCreditCardReturn');
                          symbol = '€';
                        }
                        return (
                          <>
                            <div key={`paymentAmount_${index}`} className="row mt-3">
                              <ContainerBalanceText className="col-8">{text}</ContainerBalanceText>
                              <ContainerBalance className="col-4 text-end">{`${payment.amount} ${symbol}`}</ContainerBalance>
                            </div>
                            {returned && (
                              <div key={`returnAmount_${index}`} className="row mt-3">
                                <ContainerBalanceText className="col-8">
                                  {textReturn}
                                </ContainerBalanceText>
                                <ContainerBalance className="col-4 text-end">{`-${payment.amount} ${symbol}`}</ContainerBalance>
                              </div>
                            )}
                          </>
                        );
                      })}
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="row justify-content-center align-items-center">
                <ContainerResume className="col-9 mb-3">
                  {t('forms.checkout.resume')}
                </ContainerResume>
                {/* {context.can(USER_PERMISSIONS.INVOICE) && ( */}
                {/* <ContainerResume className="col-3 mb-3 text-end">
                  <ReturnButton
                    type="button"
                    className="btn btn-sm"
                    variant="secondary"
                    title={t('forms.order.buttons.invoice')}
                    onClick={() => showModalTotalInvoice(data.shoppingCart.items, `gggggggg`)}
                  >
                    Factura Completa
                  </ReturnButton>
                </ContainerResume> */}
                {/* )} */}
              </div>
              <div className="row justify-content-center align-items-center mb-4">
                <ContainerListItems className="col-12">
                  {renderItem(data.countryData, data.shoppingCart.items)}
                </ContainerListItems>
              </div>
              <div className="row justify-content-center align-items-center mb-2 mt-5">
                <ContainerTotals className="col-5">{`${t(
                  'forms.checkout.labels.subtotals'
                )}`}</ContainerTotals>
                <ContainerSubTotals className="col-4 text-end">{`${data.subTotal} ${SYMBOL_MONEY}`}</ContainerSubTotals>
              </div>
              {data.paymentMethod === METHOD_WALLETS.code && (
                <div className="row justify-content-center align-items-center mb-2">
                  <ContainerTotals className="col-5">{`${t(
                    'forms.checkout.labels.discountWallet'
                  )}`}</ContainerTotals>
                  <ContainerSubTotals className="col-4 text-end">{`-${data.discountApplied} ${SYMBOL_MONEY}`}</ContainerSubTotals>
                </div>
              )}
              <div className="row justify-content-center align-items-center">
                <div className="col-10">
                  <Divider></Divider>
                </div>
              </div>
              <div className="row justify-content-center align-items-center mb-2 mt-2">
                <ContainerTotals className="col-5">{`${t(
                  'forms.checkout.labels.totalPurchase'
                )}`}</ContainerTotals>
                <ContainerSubTotals className="col-4 text-end">{`${data.totalPurchase} ${SYMBOL_MONEY}`}</ContainerSubTotals>
              </div>
              {renderTotalTax()}
              <div className="row justify-content-center align-items-center mb-2">
                <ContainerTotals className="col-5">{`${t(
                  'forms.checkout.labels.shipping'
                )}`}</ContainerTotals>
                <ContainerSubTotals className="col-4 text-end">{`${data.totalShipping} ${SYMBOL_MONEY}`}</ContainerSubTotals>
              </div>
              <div className="row justify-content-center align-items-center">
                <div className="col-10">
                  <Divider></Divider>
                </div>
              </div>
              <div className="row justify-content-center align-items-center mt-2">
                <ContainerTotals className="col-5">
                  <FinalPrice>{`${t('forms.checkout.labels.totalPay')}`}</FinalPrice>
                </ContainerTotals>
                <ContainerTotals className="col-4 text-end">
                  <FinalPrice>{`${data.totals} ${SYMBOL_MONEY}`}</FinalPrice>
                </ContainerTotals>
              </div>
            </div>
          </div>
          <div className="row justify-content-center no-margin mt-5 pb-5">
            <div className="col-4">
              <Button
                type="button"
                variant="secondary"
                className="btn btn-md btn-block"
                onClick={() => onBack()}
              >
                {`${t('forms.order.buttons.back')}`}
              </Button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

OrderListPage.propTypes = {
  data: PropTypes.object,
  onBack: PropTypes.func,
  requestReturn: PropTypes.func,
};

export default OrderListPage;
