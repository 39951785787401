import * as Yup from 'yup';

export const dataModel = {
  email: '',
  name: '',
  surname: '',
  password: '',
  passwordConfirm: '',
};

export const validationDataModel = (readOnly) => {
  if (readOnly) {
    return Yup.object().shape({
      email: Yup.string().email('forms.validations.email').required('forms.validations.required'),
      name: Yup.string().required('forms.validations.required'),
      surname: Yup.string().required('forms.validations.required'),
      password: Yup.string()
        .optional('forms.validations.required')
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?!.*\s)(?=.*[^\da-zA-Z]).{6,15}$/,
          'forms.validations.password'
        ),
      passwordConfirm: Yup.string()
        .optional('forms.validations.required')
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?!.*\s)(?=.*[^\da-zA-Z]).{6,15}$/,
          'forms.validations.password'
        )
        .oneOf([Yup.ref('password')], 'forms.validations.matchPassword'),
    });
  }

  return Yup.object().shape({
    email: Yup.string().email('forms.validations.email').required('forms.validations.required'),
    name: Yup.string().required('forms.validations.required'),
    surname: Yup.string().required('forms.validations.required'),
    password: Yup.string()
      .required('forms.validations.required')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?!.*\s)(?=.*[^\da-zA-Z]).{6,15}$/,
        'forms.validations.password'
      ),
    passwordConfirm: Yup.string()
      .required('forms.validations.required')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?!.*\s)(?=.*[^\da-zA-Z]).{6,15}$/,
        'forms.validations.password'
      )
      .oneOf([Yup.ref('password')], 'forms.validations.matchPassword'),
  });
};

export const setInitialValues = (initialValues) => {
  const newValues = { ...dataModel };
  if (initialValues) {
    const items = Object.keys(newValues);
    items.forEach((item) => {
      if (initialValues[item]) {
        newValues[item] = initialValues[item];
      }
    });
    return newValues;
  }

  return dataModel;
};
