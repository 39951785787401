import styled from 'styled-components';

export const Title = styled.h3`
  /* font-size: 1rem; */
  font-family: 'Gotham Light';
  font-weight: 600;
  color: #000;
  font-size: ${({ breakDevice }) => (breakDevice <= 1 ? '1.2rem' : '1.8rem')};
`;

export const ImgBanner = styled.img`
  /* height: 70px; */
  width: 100%;
`;

export const ImgMail = styled.img`
  /* height: 70px; */
  height: 70px;
`;

export const ContactAddress = styled.div`
  font-size: ${({ breakDevice }) => (breakDevice <= 1 ? '1.5rem' : '2.5rem')};
  color: #469fa3;
  margin: 0;
`;

export const Text = styled.div`
  font-size: 0.875rem;
  font-family: 'Gotham Light';
  color: #000;
  white-space: break-spaces;
  line-height: 1rem;
`;
