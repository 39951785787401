import { store } from '../store/index';
import {
  filterProduct,
  getProductByUuid,
  addShoppingCart,
  addShoppingCartLocal,
  removeShoppingCartLocal,
  changeAmountShoppingCartLocal,
  syncShoppingCart,
  localSyncShoppingCart,
  removeProductItem,
  changeAmountShoppingCart,
  clearShoppingCart,
  clearShoppingCartServer,
  getNitroxProductByToken,
  filterAllProduct,
  setPurchase,
  filterProductFree,
} from '../store/actions/ProductActions';
import { showDialog } from '@arcaitprod/react-utils-components';

/**
 * List Product
 */
export const listFilter = async (filter, hasFree = false) => {
  let result;
  if (hasFree) {
    result = await store.dispatch(filterProductFree(filter));
  } else {
    result = await store.dispatch(filterProduct(filter));
  }
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};

/**
 * List Product
 */
export const listFilterAll = async (filter) => {
  const result = await store.dispatch(filterAllProduct(filter));
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};

/**
 * Get Product
 */
export const getProduct = async (uuid) => {
  const param = [uuid];
  const result = await store.dispatch(getProductByUuid(param));
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};

const checkItemsProduct = (product) => {
  let allow = false;
  const state = store.getState();
  const { shoppingCart } = state;
  const finded = shoppingCart.items.find((item) => item.packageUuid === product.uuid);

  if (
    shoppingCart.items.length === 0 ||
    !finded ||
    (finded && finded.amount < product.maxShoppingCart)
  ) {
    allow = true;
  }
  return allow;
};

/**
 * Add Product to shopping cart
 */
export const addProduct = async (amount, product, auth, t) => {
  const objSend = { amount, packageUuid: product.uuid };
  let add = true;
  if (product.useStocks && product.stock < amount) {
    add = false;
    showDialog(t('warningStock'), t('noStock'), 'warning', false, 'OK', false, undefined);
    return 'noStock';
  }
  if (add) {
    let allowedAdd = false;
    if (product.maxShoppingCart) {
      allowedAdd = checkItemsProduct(product);
    } else {
      allowedAdd = true;
    }

    if (auth) {
      if (allowedAdd) {
        const result = await store.dispatch(addShoppingCart(objSend));
        if (result && result.payload && result.payload.data) {
          return result.payload.data;
        }
      }
    } else {
      if (allowedAdd) {
        await store.dispatch(
          addShoppingCartLocal({
            packageUuid: product.uuid,
            packageTitle: product.title,
            amount: amount,
            unitPrice: product.totalPrice,
            unitPriceWithDiscount: product.totalPriceDiscount,
            unitPriceOutEU: product.totalPriceOutEU,
            unitPriceWithDiscountOutEU: product.totalPriceDiscountOutEU,
            confirmToChange: product.confirmToChange,
            unitPriceDiscount: product.totalPrice,
            directDiscount: product.totalDiscount,
            tokens: product.totalDiscountTokens,
            directDiscountOutEU: product.totalDiscountOutEU,
            tokensOutEU: product.totalDiscountTokensOutEU,
            unitGallons: product.totalGallons,
            image: product.imageMiniatureUrl,
            allowedCountries: product.countriesAllowed,
            taxType: product.items[0].taxType ? product.items[0].taxType : null,
            packageCode: product.code,
            maxShoppingCart: product.maxShoppingCart,
            productCode: product.items[0].productCode,
          })
        );
      }
    }
  }

  return null;
};

/**
 * Add Product to shopping cart
 */
export const addProductRenovation = async (amount, product, subscriptionUuid) => {
  const objSend = { amount, packageUuid: product.uuid, subscriptionUuid };
  let allowedAdd = false;
  if (product.maxShoppingCart) {
    allowedAdd = checkItemsProduct(product);
  } else {
    allowedAdd = true;
  }
  if (allowedAdd) {
    const result = await store.dispatch(addShoppingCart(objSend));
    if (result && result.payload && result.payload.data) {
      return result.payload.data;
    }
  }

  return null;
};

/**
 * Remove Product to shopping cart
 */
export const removeProduct = async (item, index, auth) => {
  if (auth && item.uuid) {
    const result = await store.dispatch(removeProductItem([item.uuid]));
    if (result && result.payload && result.payload.data) {
      return result.payload.data;
    }
  } else {
    await store.dispatch(
      removeShoppingCartLocal({
        uuid: item.uuid ? item.uuid : null,
        packageUuid: item.packageUuid,
        index,
      })
    );
  }
  return null;
};

/**
 * Remove Product to shopping cart
 */
export const changeAmountItemProduct = async (value, item, index, auth) => {
  let allowChange = true;
  const objSend = { amount: value };
  if (item.maxShoppingCart && value > item.maxShoppingCart) {
    allowChange = false;
  }

  if (auth) {
    if (allowChange) {
      const result = await store.dispatch(changeAmountShoppingCart(objSend, [item.uuid]));
      if (result && result.payload && result.payload.data) {
        return result.payload.data;
      }
    }
  } else {
    if (allowChange) {
      await store.dispatch(
        changeAmountShoppingCartLocal({
          uuid: item.uuid ? item.uuid : null,
          packageUuid: item.packageUuid,
          index,
          value,
        })
      );
    }
  }
  return null;
};

/**
 * Add Product to shopping cart
 */
export const syncShoppingCartProductToServer = async (itemsInit) => {
  const items = itemsInit.map((item) => {
    const data = {
      packageUuid: item.packageUuid,
      packageTitle: item.packageTitle,
      amount: item.amount,
      unitPrice: item.unitPrice,
      image: item.image,
      unitPriceWithDiscount: item.unitPriceWithDiscount,
      unitPriceOutEU: item.unitPriceOutEU,
      unitPriceWithDiscountOutEU: item.unitPriceWithDiscountOutEU,
      unitPriceDiscount: item.unitPriceDiscount,
      directDiscount: item.directDiscount,
      tokens: item.tokens,
      directDiscountOutEU: item.directDiscountOutEU,
      tokensOutEU: item.tokensOutEU,
      unitGallons: item.unitGallons,
      confirmToChange: item.confirmToChange,
      allowedCountries: item.allowedCountries,
      taxType: item.taxType,
      packageCode: item.packageCode,
      maxShoppingCart: item.maxShoppingCart,
      productCode: item.productCode,
    };
    if (item.subscriptionUuid) {
      data.subscriptionUuid = item.subscriptionUuid;
    }
    return data;
  });
  const objSend = { items };
  const result = await store.dispatch(syncShoppingCart(objSend));
  if (result && result.payload && result.payload.data.items) {
    return result.payload.data.items;
  }
  return null;
};

/**
 * Add Product to shopping cart
 */
export const syncShoppingCartProductToLocal = async (items) => {
  const objSend = { items };
  const result = await store.dispatch(localSyncShoppingCart(objSend));
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};

/**
 * Add Product to shopping cart
 */
export const cleanShoppingCart = async () => {
  await store.dispatch(clearShoppingCart());
};

/**
 * Remove Product to shopping cart
 */
export const cleanShoppingCartOutPurchase = async (auth) => {
  if (auth) {
    await store.dispatch(clearShoppingCartServer());
  }
  await cleanShoppingCart();

  return null;
};

/**
 * Add Product to shopping cart
 */
export const addLastPurchase = async (purchase, items) => {
  await store.dispatch(setPurchase({ data: { purchase, items } }));
};
