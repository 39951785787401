import { createAction } from 'redux-actions';
import { apiPost, apiGet, apiPut } from '../../api/index';

import {
  urlPurchaseSecured,
  urlPurchaseShippingSecured,
  urlPurchaseRequestReturnItemSecured,
  urlPurchaseConfirmReturnItemSecured,
  urlPurchaseReturnOrderPrintedSecured,
} from '../../api/urls';

import { FINISH_PURCHASE, GET_SHIPPING_COST } from '../../constants/index';

/**
 * Add Item shopinng cart.
 */
export const finishPurchase = createAction(FINISH_PURCHASE, (obj) =>
  apiPost(urlPurchaseSecured, obj, undefined, true, true)()
);

/**
 * Filter list Product.
 */
export const costShippingPurchase = createAction(GET_SHIPPING_COST, (param) =>
  apiGet(urlPurchaseShippingSecured, param, true)()
);

/**
 * returnItem.
 */
export const returnItem = createAction('RETURN_ITEM', (obj) =>
  apiPost(urlPurchaseRequestReturnItemSecured, obj, undefined, true, true)()
);

/**
 * returnItem.
 */
export const confirmReturnItem = createAction('CONFIRM_RETURN_ITEM', (obj) =>
  apiPost(urlPurchaseConfirmReturnItemSecured, obj, undefined, true, true)()
);

/**
 * returnItem.
 */
export const returnOrderPrinted = createAction('RETURN_ORDER_PRINTED', (obj, param) =>
  apiPut(urlPurchaseReturnOrderPrintedSecured, obj, param, true, true)()
);
