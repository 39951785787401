/* eslint-disable indent */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

const RegisterContainer = (props) => {
  window.location.href = `${process.env.REACT_APP_SMARTNET_REGISTER_EVO}/arca/${props.token}`;

  return ``;
};

RegisterContainer.propTypes = {
  token: PropTypes.string,
};

export default RegisterContainer;
