import * as Yup from 'yup';

export const dataModel = {
  billingAddress: '',
  billingDocument: '',
  billingProvince: '',
  billingCountryUuid: '',
  billingCity: '',
  billingCp: '',
  billingAccept: false,
  billingContactName: '',
};

export const validationDataModel = (edit) => {
  return Yup.object().shape({
    billingAddress: Yup.string().required('forms.validations.required'),
    billingDocument: Yup.string().required('forms.validations.required'),
    billingProvince: Yup.string().required('forms.validations.required'),
    billingCountryUuid: Yup.string().required('forms.validations.required'),
    billingCity: Yup.string()
      .required('forms.validations.required')
      .max(100, {
        message: 'forms.validations.maxLength',
        args: { max: 100 },
      }),
    billingCp: Yup.string().required('forms.validations.required'),
    billingAccept: Yup.boolean()
      .required('forms.validations.required')
      .oneOf([true], 'forms.validations.accept'),
    billingContactName: Yup.string().required('forms.validations.required'),
  });
};

export const setInitialValues = (initialValues) => {
  const newValues = { ...dataModel };
  if (initialValues) {
    const items = Object.keys(newValues);
    items.forEach((item) => {
      if (initialValues[item]) {
        newValues[item] = initialValues[item];
      }
    });
    return newValues;
  }

  return dataModel;
};
