import React from 'react';
import Wallets from '../../../components/user/wallet';
import Layout from '../../../components/layouts/settingsLayout';
import { Can } from '../../../components/permissions/Can';
import { USER_PERMISSIONS } from '../../../components/permissions/rolesPermission';

const WalletPage = () => {
  return (
    <Layout
      showBanner
      bannerName="ajustes.jpg"
      textBanner="settings"
      bannerPosition="60"
      active="wallets"
      body={
        <>
          {/* <Can do={USER_PERMISSIONS.WALLETS}> */}
          <Wallets />
          {/* </Can> */}
        </>
      }
    />
  );
};

WalletPage.propTypes = {};

export default WalletPage;
