import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Flip } from 'react-toastify';
import {
  getAuthTokenSelector,
  getNicknameSelector,
  getPreferendLanguageSelector,
} from '../../../../store/selectors/authSelector';
import { getTimeZoneSelector, getEvoRefSelector } from '../../../../store/selectors/navSelector';
import SelectTimeZone from './components/selectTimeZone';
import Languages from './components/languages';
import Regions from './components/regions';
import Nav from '../../commons/nav';
import UserMenuHeader from './components/menu';
import LoadingModal from '../../../utils/modals/LoadingModal';
import {
  HeaderContainer,
  Logo,
  LogoMobile,
  SidebarButtonContainer,
  SidebarButton,
  LoginButton,
  RegisterButton,
  IconUser,
  LabelLogin,
  IconArrowLogin,
} from './styled';
import ModalInfo from '../../../utils/modalInfo/ModalInfo';
import ModalForm from '../../../utils/modalForm/ModalForm';
import ModalPreview from '../../../utils/modalPreview/ModalPreview';
import IconNotification from './components/iconNotification';
import { Can } from '../../../permissions/Can';
import { USER_PERMISSIONS } from '../../../permissions/rolesPermission';
import { Button } from '@arcaitprod/react-basic-components';
import PATHS from '../../../../constants/paths';
import { checkBootstrapSizeBreak } from '../../../../utils/device/checkDevice';
import ShoppingCartNav from '../shoppingCartNav';
import { getUrlSponsorSelector } from '../../../../store/selectors/userSelector';

class Header extends Component {
  componentDidMount() {
    const { showUserMenu } = this.props;
    if (
      (showUserMenu !== undefined && showUserMenu === true && this.props.token) ||
      (this.props.token && showUserMenu === undefined)
    ) {
      // let script = document.getElementById('HelpCFWidgetScript');
      // if (!script) {
      //   script = document.createElement('script');
      //   script.id = 'HelpCFWidgetScript';
      //   script.src =
      //     'https://my.abhisi.com:443/app/HelpWidget/HelpWidgetScript.js?connectionSettings=3063cfd3-a3ec-bb6f-5d22-82e0b4bff78d@abhisimail.com&frontendUrl=https://my.abhisi.com:443&backendUrl=https://abhisibackend.azurewebsites.net/api/';
      //   document.head.appendChild(script);
      // }
    } else {
      // const script = document.getElementById('HelpCFWidgetScript');
      // if (script) {
      //   script.remove();
      //   window.location.reload();
      // }
    }
  }

  gotoLogin = () => {
    if (this.props.evoRef) {
      this.props.history.push(`${PATHS.LOGIN}`);
    } else {
      this.props.history.push(`${PATHS.LOGIN}?forceAuth=true`);
    }
  };

  render() {
    const breakDevice = checkBootstrapSizeBreak();
    window.scrollTo(0, 0);
    const {
      urlSponsor,
      showUserMenu,
      type,
      nickname,
      preferendLanguage,
      withSidebar,
      timeZone,
      showLanguage,
    } = this.props;
    let showMenu = false;
    let showLanguageSelect = true;
    let showButtonLogin = false;

    if (
      (showUserMenu !== undefined && showUserMenu === true && this.props.token) ||
      (this.props.token && showUserMenu === undefined)
    ) {
      showMenu = true;
    }

    if (showLanguage !== undefined && showLanguage !== null) {
      showLanguageSelect = showLanguage;
    }

    if (!this.props.token) {
      showButtonLogin = true;
    }

    return (
      <>
        <LoadingModal />
        <ModalForm />
        <ModalInfo></ModalInfo>
        <ModalPreview></ModalPreview>
        {breakDevice < 3 && (
          <div className="row no-margin mt-2 mb-2">
            <div className="col-12 d-flex justify-content-between">
              <Logo
                loading="lazy"
                src={`/assets/themes/${process.env.REACT_APP_THEME}/images/simpleLogo.png`}
                alt=""
              />
              {withSidebar && (
                <>
                  <SidebarButtonContainer className="d-block d-lg-none">
                    <SidebarButton
                      type="button"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasExample"
                      aria-controls="offcanvasExample"
                    >
                      <i className="fas fa-bars" />
                    </SidebarButton>
                    {/* <SidebarButton
                      className="btn border-0"
                      onClick={() => {
                        toggleFunc();
                      }}
                    >
                      <i className="fas fa-bars" />
                    </SidebarButton> */}
                  </SidebarButtonContainer>
                </>
              )}
            </div>
          </div>
        )}
        <HeaderContainer
          type={type || 'default'}
          className={`row no-margin align-items-center ${
            type === 'login' ? 'justify-content-end' : 'justify-content-between'
          }`}
        >
          <div
            className={`${
              type === 'login' ? 'languages-login' : ''
            } col-12 col-md-12 col-lg-12 header-right-options align-self-center pe-4 pe-lg-4`}
          >
            {showLanguageSelect && (
              <>
                {breakDevice >= 3 && <Nav breakDevice={breakDevice}></Nav>}
                <Languages
                  preferendLanguage={preferendLanguage}
                  token={this.props.token}
                  breakDevice={breakDevice}
                />
                <Regions preferredRegion="eu" token={this.props.token} breakDevice={breakDevice} />
              </>
            )}
            {showButtonLogin && (
              <>
                <LoginButton
                  type="button"
                  variant="trasparent"
                  onClick={() => this.gotoLogin()}
                  title="Login"
                >
                  <IconUser className="fa-solid fa-user"></IconUser>
                  {breakDevice >= 2 ? <LabelLogin>{this.props.t('initSession')}</LabelLogin> : ''}
                  <IconArrowLogin className="fa-solid fa-angle-down"></IconArrowLogin>
                </LoginButton>
              </>
            )}
            {/* {showMenu ? (
              <> */}
            {showMenu && (
              <>
                <UserMenuHeader nickname={nickname} urlSponsor={urlSponsor || null} />
                {/* <SidebarButtonContainer className="d-block d-lg-none">
                  <SidebarButton
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasExample"
                    aria-controls="offcanvasExample"
                  >
                    <i className="fas fa-bars" />
                  </SidebarButton>
                </SidebarButtonContainer> */}
              </>
            )}
            {breakDevice < 3 && <ShoppingCartNav />}
          </div>
        </HeaderContainer>
        <ToastContainer
          position="top-right"
          autoClose={1500}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable={false}
          pauseOnHover={false}
          transition={Flip}
        />
      </>
    );
  }
}

Header.propTypes = {
  showUserMenu: PropTypes.bool,
  type: PropTypes.string,
  withSidebar: PropTypes.bool,
  toggleFunc: PropTypes.func,
};

const mapStateToProps = (state) => ({
  token: getAuthTokenSelector(state),
  nickname: getNicknameSelector(state),
  timeZone: getTimeZoneSelector(state),
  preferendLanguage: getPreferendLanguageSelector(state),
  urlSponsor: getUrlSponsorSelector(state),
  evoRef: getEvoRefSelector(state),
});

export default withRouter(connect(mapStateToProps, {})(withTranslation()(Header)));
