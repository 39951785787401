const colors = {
  primary: '#469fa3',
  primaryLight: '#57c3c9',
  secondary: '#555555',
  secondaryLight: '#414141',
  fourthy: '#E03A85',
  terciary: '#A820CE',
  terciaryLight: '#9C05C7',
  white: '#ffffff',
  grey: '#b4b4b4',
  red: '#ff0000',
  black: '#000000',
};

const theme = {
  platforms: {
    smartnet: colors.primary,
    evolution: colors.primary,
  },
  colors: {
    primary: colors.primary,
    primaryLight: colors.primaryLight,
    darkPrimary: '#00736c',
    secondary: colors.secondary,
    secondaryLight: colors.secondaryLight,
    terciary: colors.terciary,
    fourthy: colors.fourthy,
    white: '#ffffff',
    warrning: '#e3e600',
    yellow: '#e3e600',
    alert: '#721c24',
    negative: colors.red,
    grey: colors.grey,
  },
  paginations: {
    buttons: {
      disabled: '#fff',
      color: '#fff',
      hover: '#c5ad00',
      align: 'center',
    },
  },
  notifications: {
    personal: {
      color: colors.primary,
      colorHover: colors.white,
      backgroundColor: colors.white,
      backgroundColorHover: colors.primary,
      badge: colors.fourthy,
    },
    general: {
      colorHover: colors.white,
      color: colors.primary,
      backgroundColor: colors.white,
      backgroundColorHover: colors.primary,
      badge: colors.fourthy,
    },
  },
  sidebar: {
    colorLink: '#fff',
    colorLinkHover: colors.primary,
    backgroundColor: '#313131',
  },
  body: {
    backgroundColor: '#fdfdfd',
  },
  header: {
    backgroundColor: '#000',
  },
  buttons: {
    default: {
      backgroundColor: '#fff',
      backgroundColorHover: '#fff',
      color: '#000',
      colorHover: '#000',
      borderColor: '#000',
      borderColorHover: '#000',
    },
    primary: {
      backgroundColor: colors.primary,
      backgroundColorHover: colors.primaryLight,
      color: '#fff',
      colorHover: '#fff',
      borderColor: colors.primary,
      borderColorHover: colors.primaryLight,
    },
    black: {
      backgroundColor: colors.black,
      backgroundColorHover: colors.secondaryLight,
      color: '#fff',
      colorHover: '#fff',
      borderColor: colors.black,
      borderColorHover: colors.secondaryLight,
    },
    secondary: {
      backgroundColor: colors.secondary,
      backgroundColorHover: colors.secondaryLight,
      color: '#fff',
      colorHover: '#fff',
      borderColor: colors.secondary,
      borderColorHover: colors.secondary,
    },
    terciary: {
      backgroundColor: colors.terciary,
      backgroundColorHover: colors.terciaryLight,
      color: colors.white,
      colorHover: colors.white,
      borderColor: colors.terciary,
      borderColorHover: colors.terciaryLight,
    },
    alert: {
      backgroundColor: '#d00000',
      backgroundColorHover: '#e80000',
      color: '#fff',
      colorHover: '#fff',
      borderColor: '#d00000',
      borderColorHover: '#e80000',
    },
    trasparent: {
      backgroundColor: 'trasparent',
      backgroundColorHover: 'trasparent',
      color: '#fff',
      colorHover: '#fff',
      borderColor: 'trasparent',
      borderColorHover: 'trasparent',
    },
    simplePrimary: {
      backgroundColor: '#fff',
      backgroundColorHover: '#fff',
      color: colors.primary,
      colorHover: colors.primaryLight,
      borderColor: '#000',
      borderColorHover: '#000',
    },
  },
  links: {
    settings: {
      active: colors.primary,
      default: colors.primary,
      hover: colors.primaryLight,
    },
    default: {
      color: '#000',
      colorHover: '#000',
    },
    secondary: {
      color: '#fff',
      colorHover: '#fff',
    },
    primary: {
      color: colors.primary,
      colorHover: colors.primaryLight,
    },
  },
  text: {
    align: 'justify',
  },
  title: {
    h1: {
      size: '2rem',
      family: 'Lato, sans-serif',
    },
    h2: {
      size: '1.8rem',
      family: 'Lato, sans-serif',
    },
    h3: {
      size: '1.6rem',
      family: 'Lato, sans-serif',
    },
    h4: {
      size: '1.4rem',
      family: 'Lato, sans-serif',
    },
    h5: {
      size: '1.2rem',
      family: 'Lato, sans-serif',
    },
  },
  fonts: {
    family: 'Lato, sans-serif',
    size: {
      sm: '0.7rem',
      md: '0.875rem',
      lg: '1rem',
      xlg: '1.5rem',
      xxlg: '2rem',
    },
  },
  providers: {
    corp: '#231f20',
    guard: '#25aae1',
    intrepid: '#134363',
    corpexpert: '#231f20',
  },
};

export default theme;
