import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import * as UserService from '../../../services/UserService';
import * as WalletService from '../../../services/WalletService';
import { getHasWalletSelector } from '../../../store/selectors/authSelector';
import { showDialog } from '@arcaitprod/react-utils-components';
import ExchangeForm from './exchange.view';
import PATHS from '../../../constants/paths';
import PropTypes from 'prop-types';
import * as exchangeUtils from '../../../utils/formats/exchange';

class ExchangeContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      walletInfo: null,
      refreshData: false,
      dataExchange: null,
      formValues: null,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    const { hasWallet, currencyFrom } = this.props;
    if (hasWallet) {
      const result = await UserService.getStatusWallet(currencyFrom);
      if (result && result.data) {
        this.setState({ walletInfo: result.data });
      }
    }
  };

  handleSubmit = async (values) => {
    const { currencyTo, currencyFrom } = this.props;
    try {
      if (values.amount > 0) {
        const result = await WalletService.getExchangeCurrency(
          values.amount,
          currencyTo,
          currencyFrom
        );
        if (result.message) {
          this.setState({ dataExchange: result.message, formValues: values });
        }
        //   let title = '';
        //   let text = '';
        //   let icon = '';
        //   if (result && result === 'OK') {
        //     title = this.props.t('forms.wallet.messages.exchange.title');
        //     text = this.props.t('forms.wallet.messages.exchange.success');
        //     icon = 'success';
        //   } else {
        //     title = 'Error';
        //     text = this.props.t('forms.wallet.messages.exchange.error');
        //     icon = 'error';
        //   }
        //   const okAction = () => {
        //     this.props.history.push(PATHS.WALLETS);
        //   };
        //   showDialog(
        //     title,
        //     text,
        //     icon,
        //     false,
        //     'OK',
        //     false,
        //     okAction,
        //     undefined,
        //     undefined,
        //     '#e10a41',
        //     '#272727'
        //   );
      }
    } catch (error) {
      //console.log('Error', error);
    }
  };

  handleOnChangeAmount = () => {
    this.setState({ dataExchange: null, formValues: null });
  };

  handleOnConfirmExchange = async () => {
    const { currencyTo, currencyFrom } = this.props;
    const { formValues } = this.state;
    try {
      if (formValues.amount > 0) {
        const result = await WalletService.makeExchange(
          currencyTo,
          currencyFrom,
          formValues.amount
        );
        let title = '';
        let text = '';
        let icon = '';
        if (result && result === 'OK') {
          title = this.props.t('forms.wallet.messages.exchange.title');
          text = this.props.t('forms.wallet.messages.exchange.success');
          icon = 'success';
        } else {
          title = 'Error';
          text = this.props.t('forms.wallet.messages.exchange.error');
          icon = 'error';
        }
        const okAction = () => {
          this.props.history.push(PATHS.WALLETS);
        };
        showDialog(
          title,
          text,
          icon,
          false,
          'OK',
          false,
          okAction,
          undefined,
          undefined,
          '#e10a41',
          '#272727'
        );
      }
    } catch (error) {
      //console.log('Error', error);
    }
  };

  handleOnback = () => {
    this.props.history.push(PATHS.WALLETS);
  };

  render() {
    const { currencyTo, currencyFrom } = this.props;
    const dataInfo = { ...this.state.walletInfo };
    return (
      <div className="row no-margin">
        <div className="col-12">
          <ExchangeForm
            changeTo={currencyTo}
            changeFrom={currencyFrom}
            balanceFrom={
              dataInfo && dataInfo.balance
                ? exchangeUtils.crypto2Satoshi(currencyFrom, dataInfo.balance)
                : 0
            }
            dataExchange={this.state.dataExchange}
            onSubmit={this.handleSubmit}
            onback={this.handleOnback}
            onChangeAmount={this.handleOnChangeAmount}
            onConfirmExchange={this.handleOnConfirmExchange}
          />
        </div>
      </div>
    );
  }
}

ExchangeContainer.propTypes = {
  currencyFrom: PropTypes.string,
  currencyTo: PropTypes.string,
};

const mapStateToProps = (state) => ({
  hasWallet: getHasWalletSelector(state),
});

export default withRouter(connect(mapStateToProps, {})(withTranslation()(ExchangeContainer)));
