import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// import { withRouter } from 'react-router-dom';
// import * as AddressService from '../../../../services/AddressService';
// import * as GeneralServices from '../../../../services/GeneralServices';
import { useTranslation } from 'react-i18next';
import CreditCardForm from './creditCardPay.view';
// import PATHS from '../../../../constants/paths';
// import { showDialog } from '@arcaitprod/react-utils-components';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { type } from 'jquery';

const CreditCardFormContainer = (props) => {
  const { cancelAction, externalAction, userEmail, clientSecret, doubleAuth } = props;
  const stripe = useStripe();
  const elements = useElements();
  const { t } = useTranslation();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const handleOnBack = () => {
    if (cancelAction) {
      cancelAction();
    }
  };

  const handleSubmit = async (e, externalAction) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const result = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
      billing_details: {
        // Include any additional collected billing details.
        name: null,
        email: userEmail,
      },
    });

    if (result?.paymentMethod?.id) {
      externalAction(result.paymentMethod.id);
    } else if (result.error) {
      setMessage(result.error.message);
    }

    setIsLoading(false);
  };

  const handlerDoubleAuth = () => {
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    stripe.handleCardAction(clientSecret).then(handleStripeJsResult);
  };

  const handleStripeJsResult = (result) => {
    let paymentSuccess = false;
    let data = null;
    if (result.error) {
      data = result.error;
    } else {
      paymentSuccess = true;
      data = result;
    }
    externalAction(paymentSuccess, data);
  };

  return (
    <>
      {doubleAuth ? (
        <>
          {t('forms.checkout.waitingAuth')}
          {handlerDoubleAuth()}
        </>
      ) : (
        <div className="AppWrapper">
          <CreditCardForm
            handleSubmit={(e) => handleSubmit(e, externalAction)}
            isLoading={isLoading}
            stripe={stripe}
            elements={elements}
            message={message}
            onBack={handleOnBack}
          />
        </div>
      )}
    </>
  );
};

CreditCardFormContainer.propTypes = {
  t: PropTypes.any,
  userEmail: PropTypes.string,
  clientSecret: PropTypes.string,
  doubleAuth: PropTypes.bool,
};

export default CreditCardFormContainer;
