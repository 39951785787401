import { createAction } from 'redux-actions';
import {
  SET_NAV_FROM,
  CLEAN_NAV,
  CLEAN_DASHBOARD,
  SET_EVO_REF,
  CLEAN_EVO_REF,
  SET_CAMPAIGN,
} from '../../constants/index';

/**
 * Set navigation from.
 */
export const setFrom = createAction(SET_NAV_FROM, (data) => data);

/**
 * Clean navigation from.
 */
export const cleanNav = createAction(CLEAN_NAV, () => {});

/**
 * Clean navigation from.
 */
export const cleanDashboard = createAction(CLEAN_DASHBOARD, () => {});

/**
 * Set navigation from.
 */
export const setEvoRef = createAction(SET_EVO_REF, (data) => data);

/**
 * Set navigation from.
 */
export const setFromCampaign = createAction(SET_CAMPAIGN, (data) => data);

/**
 * Clean navigation evoref.
 */
export const cleanEvoRef = createAction(CLEAN_EVO_REF, () => {});
