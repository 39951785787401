import styled from 'styled-components';

export const Title = styled.h3`
  /* font-size: 1rem; */
  font-family: 'Gotham Light';
  font-weight: 600;
  color: #000;
`;

export const ImgBanner = styled.img`
  /* height: 70px; */
  width: 100%;
`;

export const Text = styled.p`
  font-size: 0.875rem;
  font-family: 'Gotham Light';
  color: #a9a9a9;
  white-space: break-spaces;
  line-height: 1rem;
`;
