import React from 'react';
import PropTypes from 'prop-types';
import { withFormik, Field } from 'formik';
import { withTranslation } from 'react-i18next';
import { dataModel, validationDataModel, setInitialValues } from './ignite.data.form';
import {
  CenterContainer,
  ButtonsContainer,
} from '../../../design/styleComponents/globalComponents';
import { TextForm } from './ignite.styled';
import { Button, Title } from '@arcaitprod/react-basic-components';
import { InputTextFormik, InputSelectFormik } from '@arcaitprod/react-forms-components';
import { IGNITE_DATA_TYPE_SHOW } from '../../../constants/index';

const IgniteForm = (props) => {
  const { t, handleSubmit } = props;

  return (
    <CenterContainer className="row justify-content-center align-items-center mb-5">
      <div className="col-12 ps-5">
        <div className="row">
          <div className="col-12">
            <Title type="h3" variant="primary">
              {t('forms.ignite.titleForm')}
            </Title>
          </div>
        </div>
        <div className="row">
          <div className="col-12 mb-4">
            <TextForm>{t('forms.ignite.textForm')}</TextForm>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <form className="form-horizontal bordered-row" onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-12">
                  <Field
                    id="name"
                    className="form-control"
                    labelField={t('forms.ignite.labels.name')}
                    name="name"
                    placeholder=""
                    component={InputTextFormik}
                    fieldType="text"
                    readOnly
                    mandatory
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <Field
                    id="nickName"
                    className="form-control"
                    labelField={t('forms.ignite.labels.nickName')}
                    name="nickName"
                    placeholder=""
                    readOnly
                    component={InputTextFormik}
                    fieldType="text"
                    mandatory
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <Field
                    id="email"
                    className="form-control"
                    labelField={t('forms.ignite.labels.email')}
                    name="email"
                    placeholder=""
                    component={InputTextFormik}
                    fieldType="text"
                    mandatory
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <Field
                    id="phone"
                    className="form-control"
                    labelField={t('forms.ignite.labels.phone')}
                    name="phone"
                    placeholder=""
                    component={InputTextFormik}
                    fieldType="text"
                    mandatory
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <Field
                    id="dataTypeShow"
                    name="dataTypeShow"
                    labelField={t('forms.ignite.labels.dataTypeShow')}
                    component={InputSelectFormik}
                    placeholder=""
                    optionLabel="label"
                    optionValue="code"
                    options={IGNITE_DATA_TYPE_SHOW.map((item) => ({
                      ...item,
                      label: t(item.label),
                    }))}
                    classNamePrefix="dark"
                  />
                </div>
              </div>

              <ButtonsContainer className="text-center">
                <Button type="submit" className="btn btn-lg btn-block" variant="primary">
                  {t('forms.ignite.buttons.save')}
                </Button>
              </ButtonsContainer>
            </form>
          </div>
        </div>
      </div>
    </CenterContainer>
  );
};

IgniteForm.propTypes = {
  handleSubmit: PropTypes.func,
};

const IgniteFormF = withFormik({
  mapPropsToValues: (props) => {
    if (props.initialValues) {
      return setInitialValues(props.initialValues);
    }
    return dataModel;
  },
  validationSchema: (props) => {
    return validationDataModel;
  },
  handleSubmit: (values, { props }) => {
    props.onSubmit(values);
  },
  enableReinitialize: true,
  displayName: 'IgniteForm',
})(withTranslation()(IgniteForm));

export default IgniteFormF;
