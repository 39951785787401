import * as Yup from 'yup';

export const dataModel = {
  nameLocal: '',
  surnameLocal: '',
  nicknameLocal: '',
};

export const validationDataModel = Yup.object().shape({
  // nameLocal: Yup.string().required('forms.validations.required'),
  // nicknameLocal: Yup.string().optional('forms.validations.required'),
  // surnameLocal: Yup.string().required('forms.validations.required'),
});

export const setInitialValues = (initialValues) => {
  const newValues = { ...dataModel };
  if (initialValues) {
    const items = Object.keys(newValues);
    items.forEach((item) => {
      if (initialValues[item]) {
        newValues[item] = initialValues[item];
      }
    });
    return newValues;
  }

  return dataModel;
};
