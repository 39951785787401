import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import CheckOutView from './checkOut.step1.view';
import ShippingView from './checkOut.step2.view';
import PaymentView from './checkOut.step3.view';
import ReviewView from './checkOut.step4.view';
import * as UserService from '../../../services/UserService';
import * as GeneralServices from '../../../services/GeneralServices';
import * as AuthService from '../../../services/AuthService';
import * as NavService from '../../../services/NavService';
import { useSelector, useDispatch } from 'react-redux';
import {
  getItemsShoppingCartSelector,
  getShoppingCartUUID,
} from '../../../store/selectors/shoppingCartSelector';
import {
  getHasWalletSelector,
  getHasWalletGalSelector,
  getHasWalletTreSelector,
  getEmailSelector,
} from '../../../store/selectors/authSelector';
import {
  getRegionSelector,
  getRegionsSelector,
  getCampaignSelector,
} from '../../../store/selectors/navSelector';
import * as ProductService from '../../../services/ProductService';
import * as AddressService from '../../../services/AddressService';
import * as PurchaseService from '../../../services/PurchaseService';
import * as SubscriptionService from '../../../services/SubscriptionService';

import { getAuthTokenSelector, getAuthInfoSelector } from '../../../store/selectors/authSelector';
import { show, hide } from 'redux-modal';
import NewAddress from '../addresses/addressForm';
import CreditCardPay from '../../others/creditCardPay';
import PATHS from '../../../constants/paths';
import { showDialog } from '@arcaitprod/react-utils-components';
import { useTranslation } from 'react-i18next';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { REGION_WORLD, EXPERIENCE_PRODUCT_CODE } from '../../../constants/index';
import * as FBCampaignService from '../../../services/FaceBookApiConversion';
import axios from 'axios';
import { createCoinbaseTransaction, exchange } from '../../../store/actions/WalletActions';
import { resetCoinbaseTransaction } from '../../../store/actions/WalletActions';

const stripePromise = loadStripe(process.env.REACT_APP_PUBLIC_STRIPE);

const CheckOutContainer = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const items = useSelector(getItemsShoppingCartSelector);
  const shoppingCartUuid = useSelector(getShoppingCartUUID);
  const auth = useSelector(getAuthTokenSelector);
  const regions = useSelector(getRegionsSelector);
  const region = useSelector(getRegionSelector);
  const hasWallet = useSelector(getHasWalletSelector);
  const hasWalletGal = useSelector(getHasWalletGalSelector);
  const hasWalletTre = useSelector(getHasWalletTreSelector);
  const userEmail = useSelector(getEmailSelector);
  const [initialValues, setInitialValues] = useState(null);
  const [addresses, setAddresses] = useState([]);
  const [addressSelected, setAddressSelected] = useState(null);
  const [addressFactSelected, setAddressFactSelected] = useState(null);
  const [step, setStep] = useState(1);
  const [walletInfo, setWalletInfo] = useState(null);
  const [walletInfoTre, setWalletInfoTre] = useState(null);
  const [totalShipping, setTotalShipping] = useState(0);
  const [addressDistinct, setAddressDistinct] = useState(true);
  const [paymentMethod, setPaymentMethod] = useState({
    value: 'wallet',
    label: t('forms.checkout.labels.onlyGallons'),
  });
  const [paymentMixed, setPaymentMixed] = useState(false);
  const [regionSelected, setRegionSelected] = useState(null);
  const userInfo = useSelector(getAuthInfoSelector);
  const campaign = useSelector(getCampaignSelector);

  const {
    createCoinbaseTransactionStatus,
    createCoinbaseTransactionData,
    createCoinbaseTransactionError,
  } = useSelector((state) => state.wallet || {});

  const [coinbasePaymentUrl, setCoinbasePaymentUrl] = useState(null);

  const appearance = {
    theme: 'stripe',
  };
  const options = {
    appearance,
  };

  useEffect(() => {
    // Reset Coinbase transaction state when component mounts
    dispatch(resetCoinbaseTransaction());

    // Reset Coinbase transaction state when component unmounts
    return () => {
      dispatch(resetCoinbaseTransaction());
    };
  }, [dispatch]);

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    loadDataWhenChangeRegion();
  }, [region]);

  const loadDataWhenChangeRegion = async () => {
    if (step === 1) {
      await loadData();
    }
  };

  const loadData = async () => {
    const filters = {};
    if (region && region.EU) {
      filters.filters = { countryUuid: region.uuid };
    } else {
      filters.filters = { region: region?.EU ? 'eu' : 'world' };
    }

    const result = await AddressService.listFilter(filters);
    if (result?.docs && result.docs.length > 0) {
      const address = result.docs.find((adr) => adr.default);
      if (address) {
        address.addressSelected = address.uuid;
        address.email = userEmail;
        setAddressSelected(address);
        setAddressFactSelected(address);
        setInitialValues(address);
        const regionSelect = regions.find((reg) => reg.uuid === address.countryUuid);
        if (regionSelect) {
          setRegionSelected(regionSelect);
        } else {
          const otherRegion = regions.find((reg) => reg.uuid === REGION_WORLD.code);
          setRegionSelected(otherRegion);
        }
      } else {
        const firstAddress = result.docs[0];
        firstAddress.addressSelected = firstAddress.uuid;
        firstAddress.email = userEmail;
        setAddressSelected(firstAddress);
        setAddressFactSelected(firstAddress);
        setInitialValues(firstAddress);
        const regionSelect = regions.find((reg) => reg.uuid === firstAddress.countryUuid);
        if (regionSelect) {
          setRegionSelected(regionSelect);
        } else {
          const otherRegion = regions.find((reg) => reg.uuid === REGION_WORLD.code);
          setRegionSelected(otherRegion);
        }
      }
      setAddresses(result.docs);
    } else {
      setAddressSelected(null);
      setAddressFactSelected(null);
      setInitialValues({ addressSelected: '' });
      setAddresses([]);
      setRegionSelected(null);
    }
  };

  const handlerRemoveItem = async (item, index, shipping = false) => {
    await ProductService.removeProduct(item, index, auth);
    if (shipping) {
      try {
        const cost = await calculateShippingCost();
        setTotalShipping(cost);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handlerOnchangeAmountItem = async (value, item, index, shipping = false) => {
    if (value !== '' && value >= 0) {
      await ProductService.changeAmountItemProduct(value, item, index, auth);
      if (shipping) {
        try {
          const cost = await calculateShippingCost();
          setTotalShipping(cost);
        } catch (error) {
          console.log(error);
        }
      }
    }
  };

  const handlerPaymentMixed = async (value) => {
    setPaymentMixed(value);
  };

  const handlerOnchangeAddress = async (value) => {
    const address = addresses.find((adr) => adr.uuid === value);
    if (address) {
      address.addressSelected = address.uuid;
      address.email = userEmail;
      setAddressSelected(address);
      setAddressFactSelected(address);
      setInitialValues(address);
      const regionSelect = regions.find((reg) => reg.uuid === address.countryUuid);
      if (regionSelect) {
        setRegionSelected(regionSelect);
      } else {
        const otherRegion = regions.find((reg) => reg.uuid === REGION_WORLD.code);
        setRegionSelected(otherRegion);
      }
    }
  };

  const handlerOnchangeAddressFact = async (value) => {
    const address = addresses.find((adr) => adr.uuid === value);
    if (address && address.countryUuid === addressSelected.countryUuid) {
      setAddressFactSelected(address);
    } else {
      const okAction = () => {
        setAddressDistinct(true);
      };

      showDialog(
        t('forms.checkout.errorAddressFact'),
        t('forms.checkout.errorAddressFactDescr'),
        'warning',
        false,
        'OK',
        false,
        okAction
      );
    }
  };

  const handlerOnChangePaymentMethod = async (value) => {
    setPaymentMethod(value);
  };

  const handlerClosePopup = (popupName) => {
    dispatch(hide(popupName));
  };

  const handlerNewAddress = async () => {
    dispatch(
      show('ModalForm', {
        body: (
          <>
            <NewAddress
              cancelAction={() => handlerClosePopup('ModalForm')}
              externalAction={async (address = undefined) => {
                handlerClosePopup('ModalForm');
                let newAddresses = [];
                let regionSelect = regions.find((reg) => reg.uuid === address.countryUuid);
                if (!regionSelect) {
                  regionSelect = regions.find((reg) => reg.uuid === REGION_WORLD.code);
                }
                setRegionSelected(regionSelect);

                if (address.countryUuid === region.uuid) {
                  newAddresses = [...addresses];
                  newAddresses.push({ ...address });
                } else {
                  const filter01 = {
                    filters: {
                      countryUuid: address.countryUuid,
                    },
                  };
                  const addressesResult = await AddressService.listFilter(filter01);
                  newAddresses = [...addressesResult.docs];
                  GeneralServices.setPreferredRegion({ region: regionSelect });
                  showDialog(
                    t('region'),
                    t('warningRegionNew'),
                    'info',
                    false,
                    'OK',
                    false,
                    undefined,
                    undefined,
                    undefined,
                    '#e10a41',
                    '#272727'
                  );
                }

                setAddresses(newAddresses);
                address.addressSelected = address.uuid;
                address.email = userEmail;
                setAddressSelected(address);
                setAddressFactSelected(address);
                setInitialValues(address);
              }}
            />
          </>
        ),
      })
    );
  };

  const getStatusWallets = async () => {
    const currency = 'GAL';
    const currencyTre = 'TRE';
    if (hasWalletGal) {
      const result = await UserService.getStatusWallet(currency);
      if (hasWalletTre) {
        const resultTre = await UserService.getStatusWallet(currencyTre);
        if (resultTre && resultTre.data) {
          setWalletInfoTre(resultTre.data);
        }
      }
      if (result && result.data) {
        setWalletInfo(result.data);
        if (result.data.cashFlowBalance === 0) {
          setPaymentMethod({ value: 'card', label: t('forms.checkout.labels.creditCard') });
        } else {
          setPaymentMethod({ value: 'wallet', label: t('forms.checkout.labels.onlyGallons') });
        }
      }
    } else {
      setPaymentMethod({ value: 'card', label: t('forms.checkout.labels.creditCard') });
    }
  };

  const handlerDistinctAddressFact = async (event) => {
    setAddressDistinct(event.target.checked);
    if (event.target.checked === true) {
      setAddressFactSelected(addressSelected);
    }
  };

  const calculateShippingCost = async () => {
    const result = await PurchaseService.getCostShipping(addressSelected.countryUuid);
    let cost = 0;
    if (result && result.shippingCost !== undefined) {
      cost = result.shippingCost;
    } else {
      throw new Error('No shipping cost');
    }
    return cost;
  };

  const checkItemsCountry = () => {
    let allowed = true;
    const country = addressSelected.countryUuid;
    const itemsNotAllowed = [];
    for (const item of items) {
      const exist = item.allowedCountries.includes(country);
      if (!exist) {
        itemsNotAllowed.push(item);
      }
    }
    if (itemsNotAllowed.length > 0) {
      allowed = false;
      dispatch(
        show('ModalInfo', {
          body: (
            <>
              <div className="row">
                <div className="col-12 mb-3 mt-4">
                  {t('forms.checkout.messages.productNotAllowed.title')}
                </div>
              </div>
              {itemsNotAllowed.map((item) => {
                return (
                  <div className="row">
                    <div className="col-12">{`- ${item.packageTitle}`}</div>
                  </div>
                );
              })}
            </>
          ),
        })
      );
      throw new Error('Product not allowed');
    }
    return allowed;
  };

  const checkExperiences = async () => {
    let allowed = true;
    const itemExp = items.find((item) => item.productCode === EXPERIENCE_PRODUCT_CODE);
    if (itemExp) {
      const result = await SubscriptionService.listFilter({
        filters: { active: true, packageUuid: itemExp.packageUuid },
      });
      if (result?.docs && result.docs.length > 0) {
        showDialog(
          t('forms.checkout.errorExperience'),
          t('forms.checkout.errorExperienceDescr'),
          'warning',
          false,
          'OK',
          false,
          undefined
        );
        throw new Error('Product not allowed');
      }
    }
    return allowed;
  };

  const handlerOnContinue = async () => {
    let next = step + 1;
    let allowedNext = true;
    switch (next) {
      case 2:
        try {
          const experience = await checkExperiences();
          const canBuy = checkItemsCountry();
          const cost = await calculateShippingCost();
          setTotalShipping(cost);
        } catch (error) {
          allowedNext = false;
        }
        if (userInfo?.extra || campaign) {
          const campaignToken = userInfo?.extra ? userInfo.extra : campaign;
          FBCampaignService.sendTrackInitCheckoutEvent(
            campaignToken,
            location,
            addressSelected,
            items
          );
        }
        break;
      case 3:
        await getStatusWallets();
        break;
      case 4:
        break;
    }
    if (allowedNext) {
      setStep(next);
    }
  };

  const handlerOnPrev = async () => {
    let prev = step - 1;
    setStep(prev);
  };

  const handlerFinish = async () => {
    if ((paymentMethod.value === 'wallet' || paymentMethod.value === 'wallets') && !paymentMixed) {
      sendFinishPurchase(paymentMethod.value);
    } else if (paymentMethod.value === 'card' || paymentMixed) {
      payWithCreditCard();
    } else if (paymentMethod.value === 'crypto') {
      // The payment should already be initiated, so we just need to wait for confirmation
      // You might want to add some UI to indicate that the payment is pending
    }
  };

  const sendFinishPurchase = async (type, paymentMethodId = null) => {
    let result = null;
    let actionSuccess = false;

    if (type === 'card' && paymentMethodId) {
      result = await PurchaseService.finishBuy(
        addressSelected,
        addressFactSelected,
        paymentMixed ? 'mixed' : paymentMethod.value,
        paymentMethodId
      );
    } else {
      result = await PurchaseService.finishBuy(
        addressSelected,
        addressFactSelected,
        paymentMethod.value
      );
    }

    let title = '';
    let text = '';
    let icon = '';
    if (result?.requires_action) {
      await stripeAuth(result.clientSecret);
    } else if (result?.purchase?.uuid) {
      actionSuccess = true;
    } else {
      title = t('forms.checkout.messages.error.title');
      text = t('forms.checkout.messages.error.success');
      icon = 'error';
    }

    if (!result.requires_action) {
      if (actionSuccess) {
        const productmember = items.find(
          (item) => item.packageUuid === 'm3m6er37-76fd-7syg-gtsy-os6d5g399st5'
        );
        await UserService.getAuthUserProfile();
        await ProductService.addLastPurchase(result.purchase, items);
        await ProductService.cleanShoppingCart();
        if (productmember) {
          title = t('forms.checkout.messages.member.title');
          text = t('forms.checkout.messages.member.success');
          icon = 'success';
          const okAction = async () => {
            localStorage.removeItem('evoToken');
            await AuthService.logout();
            await NavService.setCleanDashboard();
            history.push(`${PATHS.LOGIN}?forceAuth=true`);
          };
          showDialog(title, text, icon, false, 'OK', false, okAction);
        } else {
          history.push(PATHS.CHECKOUT_COMPLETED);
        }
      } else {
        showDialog(title, text, icon, false, 'OK', false);
      }
    }
  };

  const confirmPayment = async (paymentSuccess, data) => {
    let result = null;
    let title = '';
    let text = '';
    let icon = '';
    let okAction = undefined;

    if (paymentSuccess) {
      const { paymentIntent } = data;

      result = await PurchaseService.finishBuy(
        addressSelected,
        addressFactSelected,
        paymentMixed ? 'mixed' : paymentMethod.value,
        undefined,
        paymentIntent.id
      );
      title = t('forms.checkout.messages.purchase.title');
      text = t('forms.checkout.messages.purchase.success');
      icon = 'success';
      okAction = () => {
        ProductService.cleanShoppingCart();
        history.push(PATHS.HOME);
      };
    } else {
      title = t('forms.checkout.messages.error.title');
      text = data.message;
      icon = 'error';
    }

    showDialog(title, text, icon, false, 'OK', false, okAction);
  };

  const stripeAuth = async (clientSecret) => {
    dispatch(
      show('ModalForm', {
        body: (
          <>
            <Elements options={options} stripe={stripePromise}>
              <CreditCardPay
                doubleAuth
                clientSecret={clientSecret}
                userEmail={userEmail}
                cancelAction={() => handlerClosePopup('ModalForm')}
                externalAction={async (paymentSuccess, data) => {
                  handlerClosePopup('ModalForm');
                  confirmPayment(paymentSuccess, data);
                }}
              />
            </Elements>
          </>
        ),
      })
    );
  };

  const payWithCreditCard = async () => {
    dispatch(
      show('ModalForm', {
        body: (
          <>
            <Elements options={options} stripe={stripePromise}>
              <CreditCardPay
                userEmail={userEmail}
                cancelAction={() => handlerClosePopup('ModalForm')}
                externalAction={async (paymentMethodId = undefined) => {
                  handlerClosePopup('ModalForm');
                  sendFinishPurchase('card', paymentMethodId);
                }}
              />
            </Elements>
          </>
        ),
      })
    );
  };

  const initiateCryptoPayment = async (amount) => {
    try {
      if (!shoppingCartUuid) {
        console.error('Shopping cart UUID not found');
        return;
      }

      const paymentData = {
        // amount: (amount / 100).toFixed(2),
        email: userEmail,
        shoppingCartUuid: shoppingCartUuid,
      };

      dispatch(createCoinbaseTransaction(paymentData));
    } catch (error) {
      console.error('Error initiating Coinbase transaction:', error);
      showDialog(
        t('forms.checkout.cryptoPaymentError'),
        t('forms.checkout.cryptoPaymentErrorDescription'),
        'error',
        false,
        'OK',
        false
      );
    }
  };

  useEffect(() => {
    if (createCoinbaseTransactionStatus === 'success' && createCoinbaseTransactionData) {
      if (createCoinbaseTransactionData.hosted_url) {
        setCoinbasePaymentUrl(createCoinbaseTransactionData.hosted_url);
        setStep(step + 1);
      } else {
        console.error('Hosted URL not found in Coinbase transaction data');
        showDialog(
          t('forms.checkout.cryptoPaymentError'),
          t('forms.checkout.cryptoPaymentErrorDescription'),
          'error',
          false,
          'OK',
          false
        );
      }
    } else if (createCoinbaseTransactionStatus === 'error') {
      showDialog(
        t('forms.checkout.cryptoPaymentError'),
        t('forms.checkout.cryptoPaymentErrorDescription'),
        'error',
        false,
        'OK',
        false,
        () => {
          setPaymentMethod({ value: 'card', label: t('forms.checkout.labels.creditCard') });
        }
      );
    }
  }, [
    createCoinbaseTransactionStatus,
    createCoinbaseTransactionData,
    createCoinbaseTransactionError,
  ]);

  const handleFinalize = () => {
    if (paymentMethod.value === 'crypto' && coinbasePaymentUrl) {
      window.open(coinbasePaymentUrl, '_blank');
      showDialog(
        t('forms.checkout.finalizePaymentOnCoinbaseCommerce'),
        t('forms.checkout.finalizePaymentOnCoinbaseCommerceDescription'),
        'info',
        false,
        'OK',
        false,
        () => {
          // Proceed with order finalization after user confirms
          handlerFinish();
        }
      );
    } else {
      // For other payment methods, proceed directly
      handlerFinish();
    }
  };

  return (
    <>
      {step === 1 && (
        <CheckOutView
          initialValues={initialValues}
          addresses={addresses}
          addressSelected={addressSelected}
          items={items}
          onRemove={handlerRemoveItem}
          onChangeAmount={handlerOnchangeAmountItem}
          onChangeAddress={handlerOnchangeAddress}
          onNewAddress={handlerNewAddress}
          onContinue={handlerOnContinue}
          region={region}
        />
      )}
      {step === 2 && (
        <ShippingView
          onPrev={handlerOnPrev}
          onContinue={handlerOnContinue}
          addressSelected={addressSelected}
          totalShipping={totalShipping}
          items={items}
          region={regionSelected}
        />
      )}
      {step === 3 && (
        <PaymentView
          initialValues={{ addressSelected: addressFactSelected.uuid }}
          items={items}
          addresses={addresses}
          walletInfo={walletInfo}
          walletInfoTre={walletInfoTre}
          addressSelected={addressFactSelected}
          onPrev={handlerOnPrev}
          onContinue={handlerOnContinue}
          totalShipping={totalShipping}
          onDistinctAddress={handlerDistinctAddressFact}
          addressDistinct={addressDistinct}
          onChangeAddressFact={handlerOnchangeAddressFact}
          onchangePaymentMethod={handlerOnChangePaymentMethod}
          paymentMethodSelected={paymentMethod}
          handlerPaymentMixed={handlerPaymentMixed}
          paymentMixed={paymentMixed}
          region={regionSelected}
          hasWalletGal={hasWalletGal}
          hasWalletTre={hasWalletTre}
          onInitiateCryptoPayment={initiateCryptoPayment}
          cryptoPaymentUrl={coinbasePaymentUrl}
        />
      )}
      {step === 4 && (
        <ReviewView
          addressSelected={addressSelected}
          addressFactSelected={addressFactSelected}
          items={items}
          walletInfo={walletInfo}
          walletInfoTre={walletInfoTre}
          totalShipping={totalShipping}
          onPrev={handlerOnPrev}
          finish={handleFinalize}
          paymentMethodSelected={paymentMethod}
          paymentMixed={paymentMixed}
          region={regionSelected}
          hasWalletGal={hasWalletGal}
          hasWalletTre={hasWalletTre}
        />
      )}
    </>
  );
};

CheckOutContainer.propTypes = {};

export default CheckOutContainer;
