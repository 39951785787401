import { store } from '../store/index';
import {
  listTransactions,
  sendWithdraw,
  exchange,
  makeExchangeCurrency,
  getChangeCurrencies,
} from '../store/actions/WalletActions';
import * as exchangeUtils from '../utils/formats/exchange';

const translations = {
  ADA: 'ADA',
  ATOM: 'ATOM',
  BCH: 'BCH',
  BSV: 'BSV',
  DASH: 'DASH',
  EOS: 'EOS',
  GNO: 'GNO',
  KFE: 'FEEE',
  QTUM: 'QTUM',
  USDT: 'USDT',
  DAO: 'XDAO',
  ETC: 'XETC',
  ETH: 'XETH',
  ICN: 'XICN',
  LTC: 'XLTC',
  MLN: 'XMLN',
  NMC: 'XNMC',
  REP: 'XREP',
  XTZ: 'XTZ',
  BTC: 'XXBT',
  XDG: 'XXDG',
  XLM: 'XXLM',
  XMR: 'XXMR',
  XRP: 'XXRP',
  XVN: 'XXVN',
  ZEC: 'XZEC',
  CAD: 'ZCAD',
  EUR: 'ZEUR',
  GBP: 'ZGBP',
  JPY: 'ZJPY',
  KRW: 'ZKRW',
  USD: 'ZUSD',
};

export const withdraw = async (values) => {
  const objSend = { ...values };
  if (objSend.amount) {
    objSend.amount = exchangeUtils.crypto2Satoshi(objSend.currency, `${objSend.amount}`);
  }
  const result = await store.dispatch(sendWithdraw(objSend));
  if (result?.payload?.data) {
    return 'OK';
  }
  return null;
};

export const getExchangeCurrency = async (amount, currencyTo, currencyFrom) => {
  const objSend = {
    amount: exchangeUtils.crypto2Satoshi(currencyFrom, `${amount}`),
    currencyTo,
    currencyFrom,
  };
  const result = await store.dispatch(getChangeCurrencies(objSend));
  if (result?.payload?.data) {
    return result.payload.data;
  }
  return {
    currency: 'GAL',
    finalValue: '0.07',
    available: '0.01',
  };

  return null;
};

export const makeExchange = async (currencyTo, currencyFrom, amount) => {
  const objSend = {
    currencyTo,
    currencyFrom,
    amount: exchangeUtils.crypto2Satoshi(currencyFrom, `${amount}`),
  };
  const result = await store.dispatch(makeExchangeCurrency(objSend));
  if (result?.payload?.data) {
    return 'OK';
  }
  return null;
};

/**
 * List BotConfiguration
 */
export const listFilterTransactions = async (filter, address) => {
  const param = [address];
  const result = await store.dispatch(listTransactions(filter, param));
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};

export const getExchange = async (values) => {
  const filter = { currency: values.fiat, crypto: values.crypto };
  const result = await store.dispatch(exchange(filter));
  if (result?.payload) {
    const resultExchange = result.payload.result;
    const priceCrypto =
      resultExchange[translations[values.crypto] + translations[values.fiat]].a[0];
    return priceCrypto ? priceCrypto : null;
  }
  return null;
};

/**
 * Number is float
 *
 * @param  {String|Number} n
 * @return {Number}
 */
const isFloat = (n) => n % 1 !== 0;

/**
 * Delete Zero
 *
 * @param  {String|Number} n
 * @return {Number}
 */
const deleteZero = (n) => (parseInt(n.toString().split('.')[1]) === 0 ? parseInt(n) : n);

export const fiat2crypto = (currency, cryptoValue) => {
  const res = isFloat(currency / cryptoValue)
    ? deleteZero((currency / cryptoValue).toFixed(8))
    : currency / cryptoValue;

  return `${res}`;
};
