import styled from 'styled-components';

export const WaynanCeAlert = styled.div`
  border: 3px solid #57c3c9;
  border-radius: 3px;
  background-color: #1af4ff2f;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
