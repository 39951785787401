import styled from 'styled-components';
import { checkBootstrapSizeBreak } from '../../utils/device/checkDevice';
import { Title } from '@arcaitprod/react-basic-components';

const breakPoint = checkBootstrapSizeBreak();

export const StyledApp = styled.div`
  font-family: ${({ theme }) => theme.fonts.family} !important;
  font-size: ${({ theme }) => theme.fonts.size.md};
`;

export const CenterContainer = styled.div`
  height: 80%;
`;

export const ButtonsContainer = styled.div`
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
`;

export const SecctionTitleContainer = styled.div`
  height: 80%;
  /* background-color: ${({ theme }) => theme.colors.primary}; */
  color: ${({ theme }) => theme.colors.primary};
  font-size: ${() => (breakPoint <= 2 ? '1.2rem' : '1.6rem')};
  /* padding: 8px 20px; */
  font-weight: bold;
  letter-spacing: 2px;
  text-transform: uppercase;
  /* -webkit-box-shadow: 10px 9px 28px -12px rgb(0 0 0 / 75%); */
  /* -moz-box-shadow: 10px 9px 28px -12px rgba(0, 0, 0, 0.75); */
  /* box-shadow: 10px 9px 28px -12px rgb(0 0 0 / 75%); */
  /* border: 1px solid #00a79d; */
  /* border-radius: 5px 20px; */
`;

export const CustomTiTle = styled(Title)`
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 5px;
  text-shadow: 1px 1px 2px black;
`;

export const ContainerTitle = styled.div`
  /* background: rgb(18, 55, 78);
  background: linear-gradient(
    333deg,
    rgba(18, 55, 78, 1) 0%,
    rgba(38, 171, 225, 1) 100%,
    rgba(38, 171, 225, 1) 100%
  ); */
  /* background: rgb(59, 194, 248);
  background: linear-gradient(149deg, rgba(59, 194, 248, 1) 35%, rgba(232, 60, 126, 1) 100%); */
  /* background: rgb(59, 194, 248);
  background: linear-gradient(130deg, rgba(59, 194, 248, 1) 21%, rgba(255, 255, 255, 1) 100%); */
  background: rgb(18, 55, 78);
  background: linear-gradient(130deg, #35363a 0%, #00a79d 30%, rgba(255, 255, 255, 1) 100%);
  width: 100%;
`;
