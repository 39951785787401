import React from 'react';
import CheckOutCompleted from '../../../components/user/checkOutCompleted';
import Layout from '../../../components/layouts/verticalLayout';

const CheckOutCompletedPage = () => {
  return (
    <Layout
      body={
        <>
          <CheckOutCompleted />
        </>
      }
    />
  );
};

CheckOutCompletedPage.propTypes = {};

export default CheckOutCompletedPage;
