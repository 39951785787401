import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  BannerContainer,
  ThanksText,
  ThanksText2,
  ThanksText3,
  ThanksText4,
} from './checkOutCompleted.styled';
// import ReactPlayer from 'react-player';
import { checkBootstrapSizeBreak } from '../../../utils/device/checkDevice';
import ProductGrid from '../../others/products/productsGrid/productsGrid.view';
import { useSelector } from 'react-redux';
import { getRegionSelector } from '../../../store/selectors/navSelector';

const CheckOutCompletedView = (props) => {
  const { addToShoppingCart, listRemarkableProducts, showMoreInfo, showPreview } = props;
  const { t } = useTranslation();
  const breakDevice = checkBootstrapSizeBreak();
  const region = useSelector(getRegionSelector);

  return (
    <>
      <div className="row no-margin">
        <div className="col-12">
          <BannerContainer breakDevice={breakDevice} className="row no-margin">
            <div className="col-12 text-center">
              <div className="row mt-5">
                <ThanksText className="col-12">{t('forms.checkoutCompleted.text01')}</ThanksText>
              </div>
              <div className="row mt-5">
                <ThanksText2 className="col-12">{t('forms.checkoutCompleted.text02')}</ThanksText2>
              </div>
              {/* <div className="row mt-4 mb-4 align-items-center">
                <ThanksText3 className="col-12 col-md-12 col-lg-6">
                  {t('forms.checkoutCompleted.text03')}
                </ThanksText3>
              </div> */}
            </div>
            <div className="col-12 col-md-6 col-lg-4 order-first order-md-last">
              {/* <ReactPlayer
                className="ratio ratio-16x9 videoShow"
                controls
                url="https://youtu.be/OvUwCVaWBWI"
              ></ReactPlayer> */}
            </div>
          </BannerContainer>
          <div className="row mt-5 mb-4">
            <ThanksText4 className="col-12 text-center">
              {t('forms.checkoutCompleted.text04')}
            </ThanksText4>
          </div>
          <div className="row justify-content-center no-margin mt-3 mb-5">
            <div className="col-12">
              <ProductGrid
                numItemPage={6}
                onListProduct={listRemarkableProducts}
                showMoreInfo={showMoreInfo}
                showPreview={showPreview}
                addToShoppingCart={addToShoppingCart}
                region={region}
                hiddenPagination
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

CheckOutCompletedView.propTypes = {
  // items: PropTypes.array,
};

export default CheckOutCompletedView;
