import React, { Component, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTable, usePagination } from 'react-table';
import DefaultPagination from './defaultPagination.view';
import DefaultTable from './defaultTable.view';
import { useTranslation } from 'react-i18next';
import { EmptyDataContainer } from './CustomPaginatedList.styled';
import { getRegionSelector } from '../../../store/selectors/navSelector';
import { useSelector } from 'react-redux';

const CustomTable = ({
  columns,
  data,
  hiddenPagination,
  fetchData,
  defaultPageSize,
  renderTable,
  renderPagination,
  isSlider,
  contentLoader,
  loading,
  pageCount: controlledPageCount,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    // Get the state from the instance
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: defaultPageSize },
      manualPagination: true,
      pageCount: controlledPageCount,
    },
    usePagination
  );

  const region = useSelector(getRegionSelector);
  const { t } = useTranslation();

  // Listen for changes in pagination and use the state to fetch our new data
  React.useEffect(() => {
    fetchData({ pageIndex, pageSize, region });
  }, [fetchData, pageIndex, pageSize, region]);

  // Render the UI for your tableñp'`¡

  return (
    <>
      {data && data.length > 0 ? (
        <>
          {renderTable ? (
            renderTable({ getTableProps, getTableBodyProps, headerGroups, prepareRow, page })
          ) : (
            <DefaultTable
              getTableProps={getTableProps}
              getTableBodyProps={getTableBodyProps}
              headerGroups={headerGroups}
              prepareRow={prepareRow}
              page={page}
            />
          )}

          {!hiddenPagination && (canPreviousPage || canNextPage) && (
            <>
              {renderPagination ? (
                <>
                  {React.cloneElement(renderPagination, {
                    gotoPage,
                    previousPage,
                    nextPage,
                    setPageSize,
                    canPreviousPage,
                    canNextPage,
                    pageCount,
                    pageIndex,
                    pageOptions,
                    pageSize,
                  })}
                </>
              ) : (
                <DefaultPagination
                  gotoPage={gotoPage}
                  previousPage={previousPage}
                  nextPage={nextPage}
                  setPageSize={setPageSize}
                  canPreviousPage={canPreviousPage}
                  canNextPage={canNextPage}
                  pageCount={pageCount}
                  pageIndex={pageIndex}
                  pageOptions={pageOptions}
                  pageSize={pageSize}
                />
              )}
            </>
          )}
        </>
      ) : (
        <>
          {loading && contentLoader ? (
            contentLoader()
          ) : (
            <div className="row justify-content-center">
              <div className="col-12">
                <EmptyDataContainer>{t('no-products')}</EmptyDataContainer>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

CustomTable.propTypes = {
  columns: PropTypes.any,
  data: PropTypes.any,
  fetchData: PropTypes.any,
  defaultPageSize: PropTypes.any,
  pageCount: PropTypes.any,
  renderTable: PropTypes.any,
  renderPagination: PropTypes.any,
  hiddenPagination: PropTypes.bool,
  isSlider: PropTypes.bool,
  loading: PropTypes.bool,
  contentLoader: PropTypes.func,
};

export default CustomTable;
