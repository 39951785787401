/* eslint-disable indent */
import React from 'react';
import Contact from '../../../components/others/contact';
import Layout from '../../../components/layouts/verticalLayout';

const ContactPage = (props) => {
  return (
    <Layout
      body={
        <>
          <Contact />
        </>
      }
    />
  );
};

ContactPage.propTypes = {};

export default ContactPage;
