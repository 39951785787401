import React from 'react';
import BillingAddress from '../../../components/user/myBillingAddress';
import Layout from '../../../components/layouts/simpleLayout';

const UserSettingsPage = () => {
  return (
    <Layout
      showBanner
      bannerName="ajustes.jpg"
      textBanner="settings"
      bannerPosition="60"
      body={
        <>
          <div className="row justify-content-center mt-4">
            <div className="col-11 col-md-8 col-lg-6">
              <BillingAddress />
            </div>
          </div>
        </>
      }
    />
  );
};

UserSettingsPage.propTypes = {};

export default UserSettingsPage;
