import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button } from '@arcaitprod/react-basic-components';
import {
  InfoContainer,
  LabelInfo,
  NormalInfoText,
  AmountInfoText,
  NoInfoText,
  ContainerList,
} from './wallet.styled';
import { Can, AbilityContext } from '../../permissions/Can';
import { USER_PERMISSIONS } from '../../permissions/rolesPermission';
// import ContentLoader from 'react-content-loader';
import { WALLET_SERVICE } from '../../../constants/index';
// import AddressWallet from './addressWallet';

const WalletInfo = (props) => {
  const {
    walletInfo,
    hasWallet,
    wallet,
    requestWithdraw,
    currency,
    hasWalletGal,
    requestExchangeGal,
    requestExchangeBTC,
  } = props;
  const { t } = useTranslation();
  const context = useContext(AbilityContext);

  let showExtaInfo = false;
  let addressExtaInfo = false;
  if (currency === 'BTC') {
    showExtaInfo = true;
    addressExtaInfo = true;
  } else if (currency === 'GAL') {
    showExtaInfo = false;
    addressExtaInfo = false;
  } else if (currency === 'TRE') {
    showExtaInfo = false;
    addressExtaInfo = false;
  }
  // const renderListPendingDeposit = (deposits) => {
  //   return deposits.map((deposit) => {
  //     return (
  //       <>
  //         <ContainerList key={deposit.txId} className="row">
  //           <div className="col-2 mt-2 mb-2">
  //             <div>{`+ ${deposit.amount} BTC`}</div>
  //           </div>
  //           <div className="col-2 mt-2 mb-2">
  //             <div>{deposit.block}</div>
  //           </div>
  //           <div className="col-6 mt-2 mb-2">
  //             <div>{`${deposit.txId}`}</div>
  //           </div>
  //         </ContainerList>
  //       </>
  //     );
  //   });
  // };

  // const renderListPendingWithdraw = (withdraws) => {
  //   return withdraws.map((withdraw) => {
  //     return (
  //       <>
  //         <ContainerList key={withdraw.block} className="row">
  //           <div className="col-2 mt-2 mb-2">
  //             <div>{`-${withdraw.amount} BTC`}</div>
  //           </div>
  //           <div className="col-2 mt-2 mb-2">
  //             <div>{`-${withdraw.feeBilled} BTC`}</div>
  //           </div>
  //           <div className="col-6 mt-2 mb-2">
  //             <div>{`${withdraw.toAddress}`}</div>
  //           </div>
  //         </ContainerList>
  //       </>
  //     );
  //   });
  // };

  return (
    <>
      {/* {addressExtaInfo && <AddressWallet walletInfo={walletInfo} />} */}

      <div className="row justify-content-center">
        <div className="col-12 col-md-12">
          <InfoContainer>
            <div className="row">
              <div className="col-12 col-lg-4 d-flex flex-column justify-content-center mb-3">
                <LabelInfo>{`${t('forms.wallet.labels.balance')}`}</LabelInfo>
                <AmountInfoText>{`${
                  walletInfo && walletInfo.cashFlowBalance ? walletInfo.cashFlowBalance : '0.0'
                } PUNTOS`}</AmountInfoText>
                {/* {hasWallet && wallet === WALLET_SERVICE['BTC'] && (
                  <Can do={USER_PERMISSIONS.WITHDRAW}>
                    <div className="row">
                      <div className="col-11 mt-2">
                        <Button
                          type="button"
                          className="btn btn-sm"
                          variant="primary"
                          onClick={() => requestWithdraw()}
                        >
                          {t('forms.wallet.requestWithdraw')}
                        </Button>
                      </div>
                    </div>
                  </Can>
                )} */}
                {/* {hasWalletGal && wallet === WALLET_SERVICE['BTC'] && (
                  <>
                    <Can do={USER_PERMISSIONS.EXCHANGE_GAL}>
                      <div className="row">
                        <div className="col-11 mt-2">
                          <Button
                            type="button"
                            className="btn btn-sm"
                            variant="primary"
                            onClick={() => requestExchangeGal()}
                          >
                            {t('forms.wallet.requestExchangeGL')}
                          </Button>
                        </div>
                      </div>
                    </Can>
                  </>
                )} */}
                {/* {hasWalletGal && wallet === WALLET_SERVICE['GAL'] && (
                  <>
                    <Can do={USER_PERMISSIONS.EXCHANGE_BTC}>
                      <div className="row">
                        <div className="col-11 mt-2">
                          <Button
                            type="button"
                            className="btn btn-sm"
                            variant="primary"
                            onClick={() => requestExchangeBTC()}
                          >
                            {t('forms.wallet.requestExchangeBTC')}
                          </Button>
                        </div>
                      </div>
                    </Can>
                  </>
                )} */}
              </div>

              {/* {showExtaInfo && (
                <div className="col-12 col-lg-8 d-flex flex-column justify-content-center">
                  <div className="row">
                    <div className="col-12 align-items-center">
                      <div className="row">
                        <div className="col-12 col-lg-7 text-start text-lg-end">
                          <LabelInfo>{`${t(
                            'forms.wallet.labels.amountPendingDeposit'
                          )}: `}</LabelInfo>
                        </div>
                        <div className="col-12 col-lg-5">
                          <NormalInfoText>
                            {`${
                              walletInfo?.amountPendingDeposit
                                ? walletInfo.amountPendingDeposit
                                : '0.00000000'
                            } BTC`}
                          </NormalInfoText>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 mt-1">
                      <div className="row">
                        <div className="col-12 col-lg-7 text-start text-lg-end">
                          <LabelInfo>{`${t(
                            'forms.wallet.labels.amountPendingWithdraw'
                          )}: `}</LabelInfo>
                        </div>
                        <div className="col-12 col-lg-5">
                          <NormalInfoText>
                            {`${
                              walletInfo?.amountPendingWithdraw
                                ? `${walletInfo.amountPendingWithdraw}`
                                : '0.00000000'
                            } BTC`}
                          </NormalInfoText>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 mt-1">
                      <div className="row">
                        <div className="col-12 col-lg-7 text-start text-lg-end">
                          <LabelInfo>{`${t(
                            'forms.wallet.labels.feePendingWithdraw'
                          )}: `}</LabelInfo>
                        </div>
                        <div className="col-12 col-lg-5">
                          <NormalInfoText>
                            {`${
                              walletInfo?.amountPendingFee
                                ? `${walletInfo.amountPendingFee}`
                                : '0.00000000'
                            } BTC`}
                          </NormalInfoText>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )} */}
            </div>
            {/* {showExtaInfo && (
              <>
                <hr />
                <div className="row">
                  <div className="col-12 d-flex flex-column justify-content-center mb-4">
                    <LabelInfo>{`${t('forms.wallet.labels.movinPending')}`}</LabelInfo>
                    {walletInfo &&
                      walletInfo.listPendingDeposit &&
                      walletInfo.listPendingDeposit.length > 0 && (
                        <>
                          <ContainerList className="row">
                            <div className="col-2 mt-2 mb-2">
                              <div>{t('forms.wallet.labels.headAmount')}</div>
                            </div>
                            <div className="col-2 mt-2 mb-2">
                              <div>{t('forms.wallet.labels.bloq')}</div>
                            </div>
                            <div className="col-6 mt-2 mb-2">
                              <div>{t('forms.wallet.labels.transactionId')}</div>
                            </div>
                          </ContainerList>
                          {renderListPendingDeposit(walletInfo.listPendingDeposit)}
                        </>
                      )}
                    {walletInfo &&
                      walletInfo.listPendingDeposit &&
                      walletInfo.listPendingDeposit.length === 0 && (
                        <NoInfoText>{t('forms.wallet.labels.noPending')}</NoInfoText>
                      )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 d-flex flex-column justify-content-center">
                    <LabelInfo>{`${t('forms.wallet.labels.movinPending2')}`}</LabelInfo>
                    {walletInfo &&
                      walletInfo.listPendingWithdraw &&
                      walletInfo.listPendingWithdraw.length > 0 && (
                        <>
                          <ContainerList className="row">
                            <div className="col-2 mt-2 mb-2">
                              <div>{t('forms.wallet.labels.headAmount')}</div>
                            </div>
                            <div className="col-2 mt-2 mb-2">
                              <div>{t('forms.wallet.labels.comission')}</div>
                            </div>
                            <div className="col-6 mt-2 mb-2">
                              <div>{t('forms.wallet.labels.toAddress')}</div>
                            </div>
                          </ContainerList>
                          {renderListPendingWithdraw(walletInfo.listPendingWithdraw)}
                        </>
                      )}
                    {walletInfo &&
                      walletInfo.listPendingWithdraw &&
                      walletInfo.listPendingWithdraw.length === 0 && (
                        <NoInfoText>{t('forms.wallet.labels.noPending')}</NoInfoText>
                      )}
                  </div>
                </div>
              </>
            )} */}
          </InfoContainer>
        </div>
      </div>
    </>
  );
};

WalletInfo.propTypes = {
  walletInfo: PropTypes.object,
  hasWallet: PropTypes.bool,
  wallet: PropTypes.string,
  requestWithdraw: PropTypes.func,
  currency: PropTypes.string,
  requestExchangeGal: PropTypes.func,
  requestExchangeBTC: PropTypes.func,
};

export default WalletInfo;
