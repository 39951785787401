import { createAction } from 'redux-actions';
import { apiGet } from '../../api/index';

import { urlCommissionSecured, urlGetResumeCommissionSecured } from '../../api/urls';
import moment from 'moment-timezone';
import {
  PAGE_SIZE_10,
  FILTER_COMMISSION,
  GET_RESUME_FILTER_COMMISSION,
} from '../../constants/index';

const generateFilterQuery = ({ filters, page, pageSize }) => {
  let filter = '?';

  page ? (filter += 'page=' + page) : (filter += 'page=1');
  pageSize ? (filter += '&pageSize=' + pageSize) : (filter += '&pageSize=' + PAGE_SIZE_10);

  if (filters) {
    if (filters.fromUserUuid) {
      filter += '&fromUserUuid=' + filters.fromUserUuid;
    }

    if (filters.dateInit) {
      const dateInit = moment(filters.dateInit);
      filter += '&dateInit=' + dateInit.valueOf();
    }

    if (filters.dateEnd) {
      const dateEnd = moment(filters.dateEnd);
      filter += '&dateEnd=' + dateEnd.valueOf();
    }

    if (filters.type !== undefined && filters.type !== -1) {
      filter += '&type=' + filters.type;
    }
  }

  return filter;
};

/**
 * Filter list commsission.
 */
export const filterCommission = createAction(FILTER_COMMISSION, (filter, param) =>
  apiGet(urlCommissionSecured + generateFilterQuery(filter), param, true)()
);

/**
 * Filter list commsission.
 */
export const getResumeCommission = createAction(GET_RESUME_FILTER_COMMISSION, (filter, param) =>
  apiGet(urlGetResumeCommissionSecured + generateFilterQuery(filter), param, true)()
);
