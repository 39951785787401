import * as Yup from 'yup';

export const dataModel = {
  address: '',
  addressAdc: '',
  province: '',
  countryUuid: '',
  city: '',
  cp: '',
  default: true,
  contactName: '',
  phone: '',
  alias: '',
};

export const validationDataModel = (edit) => {
  if (edit) {
    return Yup.object().shape({
      alias: Yup.string()
        .required('forms.validations.required')
        .max(150, {
          message: 'forms.validations.maxLength',
          args: { max: 150 },
        }),
      address: Yup.string()
        .required('forms.validations.required')
        .max(150, {
          message: 'forms.validations.maxLength',
          args: { max: 150 },
        }),
      addressAdc: Yup.string()
        .optional('')
        .max(150, {
          message: 'forms.validations.maxLength',
          args: { max: 150 },
        }),
      province: Yup.string()
        .optional('')
        .max(150, {
          message: 'forms.validations.maxLength',
          args: { max: 150 },
        }),
      countryUuid: Yup.string().required('forms.validations.required'),
      city: Yup.string()
        .required('forms.validations.required')
        .max(100, {
          message: 'forms.validations.maxLength',
          args: { max: 100 },
        }),
      cp: Yup.string()
        .required('forms.validations.required')
        .max(15, {
          message: 'forms.validations.maxLength',
          args: { max: 15 },
        }),
      default: Yup.boolean().optional(''),
      contactName: Yup.string()
        .required('forms.validations.required')
        .max(150, {
          message: 'forms.validations.maxLength',
          args: { max: 150 },
        }),
      phone: Yup.string()
        .required('forms.validations.required')
        .max(20, {
          message: 'forms.validations.maxLength',
          args: { max: 20 },
        }),
    });
  }

  return Yup.object().shape({
    alias: Yup.string()
      .required('forms.validations.required')
      .max(150, {
        message: 'forms.validations.maxLength',
        args: { max: 150 },
      }),
    address: Yup.string()
      .required('forms.validations.required')
      .max(150, {
        message: 'forms.validations.maxLength',
        args: { max: 150 },
      }),
    addressAdc: Yup.string()
      .optional('')
      .max(150, {
        message: 'forms.validations.maxLength',
        args: { max: 150 },
      }),
    province: Yup.string()
      .optional('')
      .max(150, {
        message: 'forms.validations.maxLength',
        args: { max: 150 },
      }),
    countryUuid: Yup.string().required('forms.validations.required'),
    city: Yup.string()
      .required('forms.validations.required')
      .max(100, {
        message: 'forms.validations.maxLength',
        args: { max: 100 },
      }),
    cp: Yup.string()
      .required('forms.validations.required')
      .max(15, {
        message: 'forms.validations.maxLength',
        args: { max: 15 },
      }),
    default: Yup.boolean().optional(''),
    contactName: Yup.string()
      .required('forms.validations.required')
      .max(150, {
        message: 'forms.validations.maxLength',
        args: { max: 150 },
      }),
    phone: Yup.string()
      .required('forms.validations.required')
      .max(20, {
        message: 'forms.validations.maxLength',
        args: { max: 20 },
      }),
  });
};

export const setInitialValues = (initialValues) => {
  const newValues = { ...dataModel };
  if (initialValues) {
    const items = Object.keys(newValues);
    items.forEach((item) => {
      if (initialValues[item]) {
        newValues[item] = initialValues[item];
      }
    });
    return newValues;
  }

  return dataModel;
};
