import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Text } from '@arcaitprod/react-basic-components';
import { Can, AbilityContext } from '../../permissions/Can';
import { USER_PERMISSIONS } from '../../permissions/rolesPermission';

const RequestWalletForm = (props) => {
  const { requestWallet, currency } = props;
  const { t } = useTranslation();
  const context = useContext(AbilityContext);

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-12 col-md-11 text-center">
          <div
            dangerouslySetInnerHTML={{
              __html: t(`forms.wallet.description.${currency}`),
            }}
          />
        </div>
      </div>
      {currency !== 'TRE' && (
        <Can do={USER_PERMISSIONS.UPDATE_WALLET}>
          <div className="row justify-content-center">
            <div className="col-12 col-md-11 text-center mt-4  mb-5">
              <Button
                type="button"
                className="btn btn-md"
                variant="primary"
                onClick={() => requestWallet(currency)}
              >
                {t(`forms.wallet.buttons.get${currency}`)}
              </Button>
            </div>
          </div>
        </Can>
      )}
      {currency === 'TRE' && (
        <div className="row justify-content-center">
          <div className="col-12 col-md-11 text-center">
            <div
              dangerouslySetInnerHTML={{
                __html: t(`forms.wallet.promoterTrevol`),
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};

RequestWalletForm.propTypes = {
  requestWallet: PropTypes.func,
  currency: PropTypes.string,
};

export default RequestWalletForm;
