import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import PATHS from '../constants/paths';
import GridProductPage from '../pages/others/products/grid';
import DetailProductPage from '../pages/others/products/detail';
import CustomRoute from './CustomRoute';

class ProductsRoutesContainer extends Component {
  componentDidMount() {}

  render() {
    return (
      <Switch>
        <CustomRoute
          exact
          path={PATHS.DETAIL_PRODUCT}
          component={(props) => <DetailProductPage uuid={props.match.params.uuid} />}
        />

        {/* <CustomRoute
          exact
          path={PATHS.PRODUCTS_GROUP}
          component={(props) => <GridProductPage productLine={props.match.params.uuid} group />}
        />

        <CustomRoute
          exact
          path={PATHS.PRODUCTS_HOME}
          component={(props) => <GridProductPage productLine={props.match.params.productLine} />}
        /> */}

        {/* GENERATOR_INJECT_FORM_ROUTE */}
      </Switch>
    );
  }
}

ProductsRoutesContainer.propTypes = {};

export default ProductsRoutesContainer;
