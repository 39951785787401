import React, { useState } from 'react';
import List from '../commissionList';
import Filter from '../commissionFilter';
import { useTranslation } from 'react-i18next';
import { SecctionTitleContainer } from '../../../../design/styleComponents/globalComponents';
// import * as UserService from '../../../../services/UserService';
import { useSelector } from 'react-redux';
import { getHasWaynanceWalletSelector } from '../../../../store/selectors/authSelector';
import { WaynanCeAlert } from './commissionList.styled';
import { Button, Title, Text } from '@arcaitprod/react-basic-components';
import { useHistory } from 'react-router-dom';
import PATHS from '../../../../constants/paths';

const CommissionListPage = (props) => {
  const { t } = useTranslation();
  const [filters, setFilters] = useState(undefined);
  const hasWaynance = useSelector(getHasWaynanceWalletSelector);
  let history = useHistory();

  const handlerOnSubmitFilter = (values) => {
    let newFilter = {};
    const valuesFilters = Object.entries(values);
    valuesFilters.forEach((value) => {
      if (value[1] !== undefined && value[1].toString().length > 0) {
        newFilter[value[0]] = value[1];
      }
    });

    setFilters(newFilter);
  };

  const handlerCleanFilter = async (resetForm) => {
    resetForm();
    setFilters(undefined);
  };

  const goToWallet = async () => {
    history.push(PATHS.WALLETS_SETTING);
  };

  // const handlerOnChangeUserSearch = async (value, onLoad = false) => {
  //   if (value) {
  //     const filters = { filters: { name: value } };
  //     if (onLoad) {
  //       filters.filters = { uuid: value };
  //     }
  //     const suggestions = await UserService.listFilterMyNetwork(filters);
  //     if (suggestions && suggestions.length > 0) {
  //       return suggestions;
  //     }
  //   }
  //   return [];
  // };

  return (
    <>
      <div className="row no-margin">
        <div className="col-12">
          <div className="row justify-content-center ">
            <SecctionTitleContainer className="col-11 col-md-11 mt-5 mb-5">
              {t('forms.commission.title')}
            </SecctionTitleContainer>
          </div>
          {!hasWaynance && (
            <div className="row justify-content-center mb-5">
              <div className="col-11 col-md-11">
                <WaynanCeAlert>
                  {t('forms.wallet.waynance.alert')}
                  <Button
                    type="button"
                    className="btn btn-md mt-3"
                    variant="primary"
                    onClick={() => goToWallet()}
                  >
                    {t('waynanceWallet')}
                  </Button>
                </WaynanCeAlert>
              </div>
            </div>
          )}
          <div className="row justify-content-center ">
            <div className="col-12 col-md-11">
              <div className="row justify-content-center justify-content-lg-end">
                <div className="col-12 col-md-11 col-lg-12 text-end">
                  <Filter
                    onSubmit={handlerOnSubmitFilter}
                    onClean={handlerCleanFilter}
                    // onUserSearch={handlerOnChangeUserSearch}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 col-md-11 ">
              <List className="marg-top-1rem" filters={filters} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

CommissionListPage.propTypes = {};

export default CommissionListPage;
