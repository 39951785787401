import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { getUserTreeInfoSelector } from '../../../store/selectors/authSelector';
import * as UserService from '../../../services/UserService';
import UnilevelTree from './unilevelTree.view';

class UniTreeNodesContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: undefined,
      nodeVisited: [],
      viewType: 'simple',
    };
  }

  componentDidMount() {
    const { user } = this.props;
    if (user && user.uuid) {
      UserService.getUnilevelTreeNodes(user.uuid).then((result) => {
        this.setState({ data: result });
      });
    }
  }

  getNodes = (uuid) => {
    const { user } = this.props;
    if (this.state.viewType === 'simple') {
      if (uuid?.info?.userUuid) {
        const firstLevel = user.treeLevel;
        const levelArrows = firstLevel % 2;
        const visited = this.state.nodeVisited.includes(uuid.info.userUuid);
        if (uuid.info.level % 2 === levelArrows && visited === false) {
          UserService.getUnilevelTreeNodes(uuid.info.userUuid).then((result) => {
            const newData = [...this.state.data];
            let news = false;
            if (result) {
              result.forEach((element) => {
                const item = newData.find((ele) => ele.userUuid === element.userUuid);
                if (item === undefined) {
                  newData.push(element);
                  news = true;
                }
              });
            }
            const n = [...this.state.nodeVisited];
            if (!n.includes(uuid.info.userUuid)) {
              n.push(uuid.info.userUuid);
            }
            if (news) {
              this.setState({ data: newData, nodeVisited: n });
            } else {
              this.setState({ nodeVisited: n });
            }
          });
        }
      }
    } else {
      if (uuid) {
        UserService.getUnilevelTreeNodes(uuid).then((result) => {
          const newData = [...this.state.data];
          let news = false;
          result.forEach((element) => {
            const item = newData.find((ele) => ele.userUuid === element.userUuid);
            if (item === undefined) {
              newData.push(element);
              news = true;
            }
          });
          const n = [...this.state.nodeVisited];
          if (!n.includes(uuid)) {
            n.push(uuid);
          }
          if (news) {
            this.setState({ data: newData, nodeVisited: n });
          } else {
            this.setState({ nodeVisited: n });
          }
        });
      }
    }
  };

  handlerOnchangeViewType = (type) => {
    this.setState({ viewType: type });
  };

  render() {
    const { user, t } = this.props;

    return (
      <>
        <UnilevelTree
          data={this.state.data || []}
          getNodes={this.getNodes}
          parentNode={user}
          nodeVisited={this.state.nodeVisited}
          viewType={this.state.viewType}
          onChangeType={this.handlerOnchangeViewType}
          t={t}
        />
      </>
    );
  }
}

UniTreeNodesContainer.propTypes = {};

const mapStateToProps = (state) => ({
  user: getUserTreeInfoSelector(state),
});

export default withRouter(connect(mapStateToProps, {})(withTranslation()(UniTreeNodesContainer)));
