import { handleActions } from 'redux-actions';

import { USER_INFO, CLEAN_USER, SET_TEMPORAL } from '../../constants/index';

export const user = handleActions(
  {
    [CLEAN_USER]: () => ({}),
    [USER_INFO]: (state, action) => ({ ...state, info: action.payload }),
    [SET_TEMPORAL]: (state, action) => ({
      ...state,
      temporal: action.payload,
    }),
  },
  {}
);
