import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  ContainerItem,
  ImageItem,
  ButtonDeleteItem,
  ContainerNav,
  InputAmount,
  ContainerResume,
  ContainerTotals,
  ContainerSubTotals,
  NavItem,
  NavIcon,
  ContainerListItems,
  Divider,
  AlertAddress,
  InputFake,
} from './checkOut.styled';
import * as Exchange from '../../../utils/formats/exchange';
import { withFormik, Field } from 'formik';
import { InputSelectFormik } from '@arcaitprod/react-forms-components';
import { dataModel, validationDataModel, setInitialValues } from './checkOu.data.form';
import { Button } from '@arcaitprod/react-basic-components';
import { SYMBOL_MONEY } from '../../../constants/index';
import InputNumber from '../../utils/InputNumber';

const CheckOutView = (props) => {
  const { t } = useTranslation();
  const {
    items,
    onRemove,
    onChangeAmount,
    addresses,
    addressSelected,
    onChangeAddress,
    onNewAddress,
    onContinue,
    region,
  } = props;
  const [address, setAddress] = useState(addressSelected);
  useEffect(() => {
    setAddress(addressSelected);
  }, [addressSelected]);

  let numItems = 0;
  let totals = 0;
  let totalTax = 0;
  const renderItem = () => {
    return items.map((item, index) => {
      if (item) {
        numItems += Number(item.amount);
        let partialTax = 0;
        const itemPrice = Exchange.crypto2Satoshi(
          'GAL',
          region?.EU ? item.unitPrice : item.unitPriceOutEU
        );
        let subtotal = itemPrice * item.amount;
        totals += subtotal;

        if (region?.EU) {
          partialTax = parseInt(
            (itemPrice * (item.taxType !== 'out' ? region[item.taxType] : 0)) / 100
          );
          subtotal += partialTax * item.amount;
          totalTax += partialTax * item.amount;
        }

        return (
          <ContainerItem key={`${item.packageUuid}_${index}`} className="row pb-3 pt-3">
            <div className="col-2 d-none d-md-block">
              <ImageItem src={item.image}></ImageItem>
            </div>
            <div className="col-12 col-md-10">
              <div className="row mb-1">
                <div className="col-10">{item.packageTitle}</div>
                <div className="col-2">
                  <ButtonDeleteItem type="button" onClick={() => onRemove(item, index)}>
                    <i className="fa-solid fa-trash-can"></i>
                  </ButtonDeleteItem>
                </div>
              </div>
              <div className="row mb-1"></div>
              <div className="row">
                <div className="col-3 col-md-3">
                  {/* <InputAmount
                    type="number"
                    className="form-control"
                    name="amount"
                    value={item.amount}
                    onChange={(event) => onChangeAmount(event.target.value, item, index)}
                  /> */}
                  <form autoComplete="off">
                    <InputAmount
                      id={`${item.packageUuid}_${index}_input`}
                      className="form-control"
                      autocomplete="off"
                      name="amount"
                      allowNegative={false}
                      buttonsClassName="numberButtons"
                      // showButtons
                      value={item.amount ? item.amount : 0}
                      mandatory
                      decimals={0}
                      min={1}
                      max={item.maxShoppingCart ? item.maxShoppingCart : undefined}
                      onChangeValue={async (dataValue) => {
                        if (item.maxShoppingCart && dataValue > item.maxShoppingCart) {
                          onChangeAmount(item.maxShoppingCart, item, index);
                        } else {
                          onChangeAmount(dataValue, item, index);
                        }
                      }}
                    />
                  </form>
                </div>
                <div className="col-4 col-md-4 d-flex flex-column">
                  <div>{`${t('forms.shoppingCart.labels.price')}: ${
                    region?.EU ? item.unitPrice : item.unitPriceOutEU
                  } ${SYMBOL_MONEY}`}</div>
                  <div>{`${t('forms.shoppingCart.labels.tax')} ${
                    region.EU ? `(${item.taxType !== 'out' ? region[item.taxType] : 0}%)` : ''
                  }: ${Exchange.satoshi2Crypto('GAL', partialTax)} ${SYMBOL_MONEY}`}</div>
                </div>
                <div className="col-5 col-md-5">{`${t(
                  'forms.shoppingCart.total'
                )}: ${Exchange.satoshi2Crypto('GAL', subtotal)} ${SYMBOL_MONEY}`}</div>
              </div>
            </div>
          </ContainerItem>
        );
      }
    });
  };

  return (
    <>
      <ContainerNav className="row justify-content-center align-items-center no-margin">
        <div className="col-10 ">
          <NavItem selected>{t('forms.checkout.step1')}</NavItem>
          <NavIcon className="fa-solid fa-angles-right"></NavIcon>
          <NavItem>{t('forms.checkout.step2')}</NavItem>
          <NavIcon className="fa-solid fa-angles-right"></NavIcon>
          <NavItem>{t('forms.checkout.step3')}</NavItem>
          <NavIcon className="fa-solid fa-angles-right"></NavIcon>
          <NavItem>{t('forms.checkout.step4')}</NavItem>
        </div>
      </ContainerNav>
      <div className="row justify-content-center no-margin">
        <div className="col-12 col-md-10">
          <div className="row mt-5">
            <div className="col-12 col-md-12 col-lg-6">
              <div className="row justify-content-center">
                <div className="col-11">
                  <div className="row mb-3">
                    <ContainerResume className="col-12">
                      {t('forms.checkout.addressSend')}
                    </ContainerResume>
                  </div>
                  <div className="row mb-3">
                    <AlertAddress className="col-12">
                      {t('forms.checkout.importantRegion')}
                    </AlertAddress>
                  </div>
                  <div className="row">
                    <div className="col-8">
                      <Field
                        id="addressSelected"
                        labelField={t('forms.address.labels.myAddresses')}
                        name="addressSelected"
                        component={InputSelectFormik}
                        placeholder=""
                        optionLabel="alias"
                        optionValue="uuid"
                        options={addresses}
                        classNamePrefix="dark"
                        mandatory
                        onChangeValue={onChangeAddress}
                        // addOptionEmpty
                      />
                    </div>
                    <div className="col-4 d-flex align-items-center mt-3 no-padding">
                      <Button type="button" variant="primary" onClick={() => onNewAddress()}>
                        {t('forms.address.titleNew')}
                      </Button>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <label className="control-label">
                          {t('forms.address.labels.contactName')}
                        </label>
                        <InputFake className="form-control" readOnly>
                          {address?.contactName}
                        </InputFake>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <label className="control-label">{t('forms.address.labels.address')}</label>
                        <InputFake className="form-control" readOnly>
                          {address?.address || t('forms.address.labels.placeHolderLine1')}
                        </InputFake>
                      </div>
                      <div className="form-group">
                        <InputFake className="form-control" readOnly>
                          {address?.addressAdc || t('forms.address.labels.placeHolderLine2')}
                        </InputFake>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <div className="form-group">
                        <InputFake className="form-control" readOnly>
                          {address?.countryData?.visualName || t('forms.address.labels.country')}
                        </InputFake>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <InputFake className="form-control" readOnly>
                          {address?.province || t('forms.address.labels.province')}
                        </InputFake>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <div className="form-group">
                        <InputFake className="form-control" readOnly>
                          {address?.city || t('forms.address.labels.city')}
                        </InputFake>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <InputFake className="form-control" readOnly>
                          {address?.cp || t('forms.address.labels.cp')}
                        </InputFake>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <div className="form-group">
                        <InputFake className="form-control" readOnly>
                          {address?.phone || t('forms.address.labels.phone')}
                        </InputFake>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <InputFake className="form-control" readOnly>
                          {address?.email || t('forms.address.labels.email')}
                        </InputFake>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {!address && (
                <div className="row justify-content-center mt-2">
                  <div className="col-11">
                    <div className="alert alert-danger" role="alert">
                      {t('forms.checkout.noAddress')}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="col-12 col-md-12 col-lg-6">
              <div className="row justify-content-center align-items-center">
                <ContainerResume className="col-11 mb-3 mt-5 mt-lg-0">
                  {t('forms.checkout.resume')}
                </ContainerResume>
              </div>
              <div className="row justify-content-center align-items-center mb-4">
                <ContainerListItems className="col-11">
                  {items && items.length > 0 && renderItem()}
                </ContainerListItems>
              </div>
              <div className="row justify-content-center align-items-center mb-2">
                <ContainerTotals className="col-5">{`${t(
                  'forms.shoppingCart.subtotal'
                )}`}</ContainerTotals>
                <ContainerSubTotals className="col-4 text-end">{`${Exchange.satoshi2Crypto(
                  'GAL',
                  totals
                )} ${SYMBOL_MONEY}`}</ContainerSubTotals>
              </div>
              {/* {totalTax && ( */}
              <div className="row justify-content-center align-items-center mb-2">
                <ContainerTotals className="col-5">{`${t(
                  'forms.shoppingCart.totalIva'
                )}`}</ContainerTotals>
                <ContainerSubTotals className="col-4 text-end">{`${Exchange.satoshi2Crypto(
                  'GAL',
                  totalTax
                )} ${SYMBOL_MONEY}`}</ContainerSubTotals>
              </div>
              {/* )} */}
              <Divider></Divider>
              <div className="row justify-content-center align-items-center mb-2 mt-2">
                <ContainerTotals className="col-5">{`${t(
                  'forms.shoppingCart.total'
                )}`}</ContainerTotals>
                <ContainerSubTotals className="col-4 text-end">{`${Exchange.satoshi2Crypto(
                  'GAL',
                  totalTax + totals
                )} ${SYMBOL_MONEY}`}</ContainerSubTotals>
              </div>
              {/* <div className="row justify-content-center align-items-center">
                <ContainerTotals className="col-5">{`${t(
                  'forms.shoppingCart.total'
                )}`}</ContainerTotals>
                <ContainerTotals className="col-4 text-end">{`${Exchange.satoshi2Crypto(
                  'GAL',
                  totals
                )} ${SYMBOL_MONEY}`}</ContainerTotals>
              </div> */}
              {(!items || items.length <= 0) && (
                <div className="row justify-content-center mt-4">
                  <div className="col-11">
                    <div className="alert alert-danger" role="alert">
                      {t('forms.checkout.noItems')}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {addressSelected && items && items.length > 0 && (
        <ContainerNav className="row justify-content-center align-items-center no-margin">
          <div className="col-10 text-center">
            <Button type="button" variant="primary" onClick={() => onContinue()}>
              {t('forms.checkout.buttons.continue')}
            </Button>
          </div>
        </ContainerNav>
      )}
    </>
  );
};

CheckOutView.propTypes = {
  items: PropTypes.array,
  addresses: PropTypes.array,
  addressSelected: PropTypes.any,
  onChangeAddress: PropTypes.func,
  onNewAddress: PropTypes.func,
  onContinue: PropTypes.func,
  region: PropTypes.object,
};

const CheckOutViewF = withFormik({
  mapPropsToValues: (props) => {
    if (props.initialValues) {
      return setInitialValues(props.initialValues);
    }
    return dataModel;
  },
  validationSchema: (props) => {
    return validationDataModel(props.edit);
  },
  handleSubmit: (values, { props, resetForm }) => {
    props.onSubmit(values, resetForm);
  },
  enableReinitialize: true,
  displayName: 'CheckOutView',
})(CheckOutView);

export default CheckOutViewF;
