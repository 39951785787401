import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withFormik, Field } from 'formik';

import { withTranslation } from 'react-i18next';
import { InputTextFormik, InputSelectFormik } from '@arcaitprod/react-forms-components';

import { dataModel, validationDataModel, setInitialValues } from './exchange.data.form';

import { ButtonsContainer } from '../../../design/styleComponents/globalComponents';
import { Button, Title, Text } from '@arcaitprod/react-basic-components';
import {
  SpanMaxAmountStyled,
  MaxAmount,
  ResumenContainer,
  ErrorStyled,
  DataExchange,
  // SpanExchangeAmountStyled,
  // MaxAmount2,
} from './exchange.styled';
// import { EXCHANGE_TYPE } from '../../../constants/index';
import * as exchangeUtils from '../../../utils/formats/exchange';
// import * as numberFormat from '../../../utils/formats/numbers';

const ExchangeForm = (props) => {
  const {
    t,
    handleSubmit,
    onback,
    balanceFrom,
    changeTo,
    changeFrom,
    dataExchange,
    onChangeAmount,
    onConfirmExchange,
  } = props;

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-12 col-md-8 col-lg-6">
          <div className="row mt-5">
            <div className="col-12">
              <Title type="h3" variant="primary">
                {changeTo === 'GAL'
                  ? t('forms.wallet.changeGallons')
                  : t('forms.wallet.titleExchange')}
              </Title>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12 d-flex">
              <MaxAmount>{`${t(
                'forms.wallet.balance' + (changeTo === 'BTC' ? 'P' : '')
              )} ${exchangeUtils.satoshi2Crypto(
                changeFrom,
                balanceFrom
              )} ${changeFrom}`}</MaxAmount>
            </div>
          </div>

          <div className="row">
            <div className="col-12 mt-4">
              <form className="form-horizontal bordered-row" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-12">
                    <Field
                      id="amount"
                      className="form-control"
                      component={InputTextFormik}
                      labelField={t('forms.wallet.labels.amountToChange', { currency: changeFrom })}
                      name="amount"
                      fieldType="number"
                      placeholder=""
                      onChangeValue={(event) => {
                        if (dataExchange) {
                          onChangeAmount();
                        }
                      }}
                      mandatory
                    />
                  </div>
                </div>
                <div className="row justify-content-center">
                  <ButtonsContainer className="col-10 col-sm-10 text-center containerButtons">
                    {!dataExchange && (
                      <Button
                        type="button"
                        className="btn btn-md"
                        variant="secondary"
                        onClick={() => onback()}
                      >
                        {t('forms.wallet.buttons.back')}
                      </Button>
                    )}
                    <Button
                      type="submit"
                      className="btn btn-md"
                      variant="primary"
                      disabled={dataExchange ? true : false}
                    >
                      {changeTo === 'GAL'
                        ? t('forms.wallet.buttons.exchange')
                        : t('forms.wallet.titleExchange')}
                    </Button>
                  </ButtonsContainer>
                </div>
              </form>
            </div>
          </div>
          {dataExchange && (
            <>
              {/* <div className="row mt-3">
                <div className="col-12">
                  <MaxAmount>{t('forms.wallet.availableExchange')}</MaxAmount>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-12">
                  <div className="progress" style={{ height: '25px' }}>
                    <div
                      className="progress-bar progress-bar-striped"
                      role="progressbar"
                      style={{ width: `${dataExchange.percentageExchangeAvailable}%` }}
                      aria-valuenow={dataExchange.percentageExchangeAvailable}
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      {`${dataExchange.percentageExchangeAvailable}%`}
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="row mt-5">
                <DataExchange className="col-12 text-center">
                  {t('forms.wallet.aproxAmount')}
                </DataExchange>
              </div>
              <div className="row mt-1">
                <DataExchange className="col-12 text-center">{`${dataExchange.finalValue}${dataExchange.currency}`}</DataExchange>
              </div>
              <div className="row justify-content-center">
                <ButtonsContainer className="col-10 col-sm-10 text-center containerButtons">
                  <Button
                    type="button"
                    className="btn btn-md"
                    variant="secondary"
                    onClick={() => onback()}
                  >
                    {t('forms.wallet.buttons.back')}
                  </Button>
                  <Button
                    type="button"
                    className="btn btn-md"
                    variant="primary"
                    onClick={() => onConfirmExchange()}
                  >
                    {t('forms.wallet.buttons.confirmExchange')}
                  </Button>
                </ButtonsContainer>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

ExchangeForm.propTypes = {
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  onback: PropTypes.func,
  balanceFrom: PropTypes.any,
  changeTo: PropTypes.string,
  changeFrom: PropTypes.string,
  dataExchange: PropTypes.any,
  onChangeAmount: PropTypes.func,
  onConfirmExchange: PropTypes.func,
};

const ExchangeFormF = withFormik({
  mapPropsToValues: (props) => {
    if (props.initialValues) {
      return setInitialValues(props.initialValues);
    }
    return dataModel;
  },
  validationSchema: (props) => {
    return validationDataModel(props.balanceFrom, props.changeFrom);
  },
  handleSubmit: (values, { props, resetForm }) => {
    props.onSubmit(values, resetForm);
  },
  enableReinitialize: true,
  displayName: 'ExchangeForm',
})(withTranslation()(ExchangeForm));

export default ExchangeFormF;
