import { Ability, AbilityBuilder } from '@casl/ability';
import { store } from '../../store';
import {
  ROLE_CLIENT,
  ROLE_SUPPORT,
  KYC_STATUS_APPROVED,
  ROLE_STAFF,
  ROLE_PROMOTER,
} from '../../constants/index';

import {
  getPermisionsClient,
  getPermisionsSupport,
  getPermisionsAnonymous,
  getPermisionsBlockClient,
  getPermisionsSupportTestingMode,
  getPermisionsBlockClientTestingMode,
  getPermisionsClientTestingMode,
} from './rolesPermission';

function subjectName(item) {
  if (!item || typeof item === 'string') {
    return item;
  }
  return item.__type;
}

const ability = new Ability([], { subjectName });

export const defineRulesFor = (auth) => {
  const { can, rules } = new AbilityBuilder(Ability);
  if (auth.role) {
    const enabledTestingMode = process.env.REACT_APP_ENABLED_TEST_MODE === 'true';
    let isStaff = false;
    let isPromoter = false;

    const special = auth.roles.findIndex((role) => role === 'SPECIAL');

    if (enabledTestingMode && auth.roles) {
      const roleStaff = auth.roles.find((role) => role === ROLE_STAFF);
      if (roleStaff) {
        isStaff = true;
      }
    }

    if (auth.roles) {
      const rolePromoter = auth.roles.find((role) => role === ROLE_PROMOTER);
      if (rolePromoter) {
        isPromoter = true;
      }
    }

    if (auth.role === ROLE_CLIENT) {
      let passedKYC = true;
      let hasWallet = false;
      let hasWalletGallons = false;
      let hasWalletTrevol = false;
      let isIgnite = false;
      let isMember = false;

      // if (auth.passedKYC === KYC_STATUS_APPROVED) {
      //   passedKYC = true;
      // }
      if (auth.hasWallet) {
        hasWallet = true;
      }
      if (auth.hasWalletGal) {
        hasWalletGallons = true;
      }
      if (auth.hasWalletTrevol) {
        hasWalletTrevol = true;
      }
      if (auth.isIgnite) {
        isIgnite = true;
      }
      const currentDate = new Date().getTime();
      const finishDate = new Date(auth.dateFinishMembership).getTime();

      if ((auth.isMember && currentDate < finishDate) || special > 0) {
        isMember = true;
      }

      const extraData = {
        passedKYC,
        hasWallet,
        hasWalletGallons,
        hasWalletTrevol,
        isPromoter,
        isIgnite,
        isMember,
      };

      if (enabledTestingMode) {
        if (auth.blockedLevel) {
          getPermisionsBlockClientTestingMode(isStaff, can, auth.blockedLevel, extraData);
        } else {
          getPermisionsClientTestingMode(isStaff, can, extraData);
        }
      } else {
        if (auth.blockedLevel) {
          getPermisionsBlockClient(can, auth.blockedLevel, extraData);
        } else {
          getPermisionsClient(can, extraData);
        }
      }
    }
    if (auth.role === ROLE_SUPPORT) {
      if (enabledTestingMode) {
        getPermisionsSupportTestingMode(isStaff, can);
      } else {
        getPermisionsSupport(can);
      }
    }
    getPermisionsAnonymous(can);
  } else {
    getPermisionsAnonymous(can);
  }
  return rules;
};

let currentAuth;
store.subscribe(() => {
  const prevAuth = currentAuth;
  currentAuth = store.getState().auth.info;
  if (prevAuth !== currentAuth) {
    ability.update(defineRulesFor(currentAuth ? currentAuth : 'anonymous'));
  }
});

export const buildAbilityFor = (role) => {
  return ability.update(defineRulesFor(currentAuth ? currentAuth : 'anonymous'));
};
