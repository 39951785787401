import React, { Component, useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Home from './clientHome.view';
import { PRODUCT_LINES } from '../../../constants/index';
import * as ProductService from '../../../services/ProductService';
import { show, hide } from 'redux-modal';
import { useDispatch } from 'react-redux';
import PreviewProduct from '../../others/products/detail';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getAuthTokenSelector, getAuthInfoSelector } from '../../../store/selectors/authSelector';
import ReactPlayer from 'react-player';
import PATHS from '../../../constants/paths';
import * as FBCampaignService from '../../../services/FaceBookApiConversion';
import { useLocation } from 'react-router-dom';
import { getCampaignSelector } from '../../../store/selectors/navSelector';

const HomeContainer = (props) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const auth = useSelector(getAuthTokenSelector);
  const campaign = useSelector(getCampaignSelector);
  const userInfo = useSelector(getAuthInfoSelector);

  const handlerGotoProductLine = (productLine) => {
    history.push(`/products/${productLine.toLocaleLowerCase()}`);
  };
  const [data, setData] = useState([]);
  const [dataRemarkable, setDataRemarkable] = useState([]);

  const toListNewProducts = async (filter, region) => {
    if (filter.filters) {
      filter.filters.isNewItem = true;
      filter.filters.countryUuid = region ? region.uuid : null;
    } else {
      filter.filters = { isNewItem: true, countryUuid: region ? region.uuid : null };
    }
    filter.sort = '-createdAt';
    const result = await ProductService.listFilter(filter);
    if (result.docs && result.docs.length > 0) {
      setData(result.docs);
    }
    return result;
  };

  const toListRemarkableProducts = async (filter, region) => {
    if (filter.filters) {
      filter.filters.isRemarkable = true;
      filter.filters.countryUuid = region ? region.uuid : null;
    } else {
      filter.filters = { isRemarkable: true, countryUuid: region ? region.uuid : null };
    }
    filter.sort = '-createdAt';
    const result = await ProductService.listFilter(filter);
    if (result.docs && result.docs.length > 0) {
      setDataRemarkable(result.docs);
    }
    return result;
  };

  const handlerOnClickItem = (item) => {
    history.push(`/products/detail/${item.uuid}`);
  };

  const handlerClosePopup = (popupName) => {
    dispatch(hide(popupName));
  };

  const handlerShowPreview = (item) => {
    dispatch(
      show('ModalPreview', {
        handleHide: () => handlerClosePopup('ModalPreview'),
        body: (
          <>
            <PreviewProduct
              uuid={item.uuid}
              preview
              closeModal={() => handlerClosePopup('ModalPreview')}
            ></PreviewProduct>
          </>
        ),
      })
    );
  };

  const handlerAddShoppingCart = async (values) => {
    if (values.amount > 0) {
      let product = data.find((item) => item.uuid === values.item);
      if (!product) {
        product = dataRemarkable.find((item) => item.uuid === values.item);
      }
      const result = await ProductService.addProduct(values.amount, product, auth, t);
      if (result !== 'noStock') {
        if (userInfo?.extra || campaign) {
          const campaignToken = userInfo?.extra ? userInfo.extra : campaign;
          await FBCampaignService.sendTrackAddToCartEvent(campaignToken, product, location);
        }
        let myOffcanvas = document.getElementById('offcanvasRight');
        let bsOffcanvas = new window.bootstrap.Offcanvas(myOffcanvas);
        bsOffcanvas.show();
      }
    }
  };

  const handlerShowVideo = () => {
    dispatch(
      show('ModalPreview', {
        handleHide: () => handlerClosePopup('ModalPreview'),
        body: (
          <>
            <ReactPlayer
              className="ratio ratio-16x9 videoShow"
              controls
              url="https://youtu.be/GLGlVEaiepM"
            ></ReactPlayer>
          </>
        ),
      })
    );
  };

  const handlerGoToBusinessPlan = () => {
    history.push(PATHS.BUSINESS_PLAN);
  };

  return (
    <>
      <Home
        productLines={PRODUCT_LINES}
        gotoProductLine={handlerGotoProductLine}
        listNewProducts={toListNewProducts}
        listRemarkableProducts={toListRemarkableProducts}
        showMoreInfo={handlerOnClickItem}
        showPreview={handlerShowPreview}
        addToShoppingCart={handlerAddShoppingCart}
        showVideo={handlerShowVideo}
        goToBusinessPlan={handlerGoToBusinessPlan}
      />
    </>
  );
};

HomeContainer.propTypes = {};

export default HomeContainer;
