import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// import CustomPaginatedList from '../../../utils/CustomPaginatedList';
// import ProductTableGrid from '../ProductTableGrid/productTable.view';
import * as exchange from '../../../../utils/formats/exchange';
import {
  PrincipalImage,
  Title,
  ShortDescription,
  Price,
  Tax,
  Gallons,
  Description,
  FormShoppingCart,
  TermsConditions,
  Additional,
  TitleAdditional,
  ImgButton,
  AvailableCountriesButton,
  Proximamente,
  TextAfterOffer,
  TextOffer,
  NotAvailable,
  ButtonShare,
  ShareIcon,
  LinkCopy,
  GalExplain,
} from './detail.styled';
import { withFormik, Field } from 'formik';
import { dataModel, validationDataModel, setInitialValues } from './detail.data.form';
import { InputNumberFormik } from '@arcaitprod/react-forms-components';
import { Button } from '@arcaitprod/react-basic-components';
import SliderImage from '../../../utils/SliderImage/SliderImage';
import { checkBootstrapSizeBreak } from '../../../../utils/device/checkDevice';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { Can, AbilityContext } from '../../../permissions/Can';
import { USER_PERMISSIONS } from '../../../permissions/rolesPermission';

const ProductDetailView = (props) => {
  const { t, i18n } = useTranslation();
  const breakDevice = checkBootstrapSizeBreak();
  const location = useLocation();
  // const { products, addToShoppingCart, itemSelected, onChangeProduct, region } = props;
  const context = useContext(AbilityContext);

  const {
    data,
    handleSubmit,
    productLine,
    onListProduct,
    onClickItem,
    region,
    showAvailableCountry,
    userInfo,
  } = props;
  // console.log(data);

  let buyAllowed = false;
  if (data?.countriesAllowed && data?.countriesAllowed.length > 0 && region) {
    const exist = data.countriesAllowed.find((pais) => pais === region.uuid);
    buyAllowed = exist ? true : false;
  }

  const taxType = data?.items[0].taxType;
  const taxApplied = region && region[taxType] ? region[taxType] : 0;

  const price = Number(
    exchange.crypto2Satoshi('GAL', region?.EU ? data?.totalPrice : data?.totalPriceOutEU)
  );
  const tax = (price * taxApplied) / 100;

  const priceAfter = Number(
    exchange.crypto2Satoshi('GAL', data?.isOffer ? data?.priceAfterOffer : 0)
  );
  const taxAfter = data?.isOffer ? (priceAfter * taxApplied) / 100 : 0;

  let slider = data?.imageUrl ? [{ image: data?.imageUrl }] : [];
  if (data?.images && data?.images.length > 0) {
    for (const image of data?.images) {
      slider.push({ image: image });
    }
  }
  if (data?.videos && data?.videos.length > 0) {
    for (const video of data?.videos) {
      slider.push({ video: video });
    }
  }

  const extraInfo = i18n.getResource(i18n.language, 'productDetail', `additionalInfo`);
  const terms =
    data?.items[0].productItem &&
    data?.items[0].productItem.terms &&
    data?.items[0].productItem.terms[i18n.language]
      ? data.items[0].productItem.terms[i18n.language]
      : '';

  const link = `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_HOST}/products/detail/${
    data.uuid
  }?evoRef=${userInfo ? userInfo.uuid : null}`;

  const copyToClipBoard = () => {
    navigator.clipboard.writeText(link);
    toast(t('linkCopied'), {
      position: 'top-right',
      autoClose: 1500,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    });
  };

  return (
    <>
      <div className="row justify-content-center no-margin pb-5 mt-5">
        <div className="col-12 col-md-11">
          {userInfo && userInfo.uuid && (
            <div className="row">
              <div className="col-12 text-end">
                {/* <div className="dropdown"> */}
                <ButtonShare
                  className="btn btn-secondary "
                  type="button"
                  // id="dropdownMenuButton1"
                  onClick={() => copyToClipBoard()}
                  // data-bs-toggle="dropdown"
                  // aria-expanded="false"
                >
                  {t('share')}
                  <ShareIcon className="fa-solid fa-share-nodes"></ShareIcon>
                </ButtonShare>
                {/* <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    <li>
                      <CopyToClipboard
                        text={link}
                        onCopy={() => {
                          toast(t('linkCopied'), {
                            position: 'top-right',
                            autoClose: 1500,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: false,
                            progress: undefined,
                          });
                        }}
                      >
                        <LinkCopy>{t('copyShare')}</LinkCopy>
                      </CopyToClipboard> */}
                {/* <a
                        className="dropdown-item"
                        href="#"
                        onClick={() => copyProductLink(userInfo.uuid)}
                      >
                        {t('copyShare')}
                      </a> */}
                {/* </li>
                  </ul> */}
                {/* </div> */}
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-12 col-md-5 col-lg-5">
              {slider && slider.length > 0 && (
                <SliderImage
                  data={slider}
                  showDescription={false}
                  direction={breakDevice <= 2 ? 'none' : 'right'}
                  width="100%"
                />
              )}
              {/* <PrincipalImage src={data?.imageUrl} alt="" /> */}
            </div>
            <div className="col-12 col-md-7 col-lg-7 mt-4 mt-md-0">
              <div className="row mb-2">
                <Title className="col-12">{t('productDetail:title')}</Title>
              </div>
              <div className="row mb-2">
                <ShortDescription className="col-12">
                  {t('productDetail:shortDescription')}
                </ShortDescription>
              </div>
              <div className="row">
                <div className="col-12">
                  {buyAllowed ? (
                    <>
                      <Price isOffer={data?.isOffer}>
                        {data?.isOffer && <TextOffer>{`${t('offer')}`}</TextOffer>}
                        {`${exchange.satoshi2Crypto('GAL', price + tax)} €`}
                      </Price>
                      {data?.isOffer && (
                        <TextAfterOffer>{`(${t('afterOffer')} ${exchange.satoshi2Crypto(
                          'GAL',
                          Math.round(Number(exchange.satoshi2Crypto('GAL', priceAfter + taxAfter)))
                        )} €)`}</TextAfterOffer>
                      )}
                      <Tax>{`${t('pages.wellness.reishi.taxPrice')} (${taxApplied}%)`}</Tax>
                    </>
                  ) : (
                    <NotAvailable isOffer>{`${t('region_not_available')}`}</NotAvailable>
                  )}
                </div>
              </div>
              {buyAllowed && (
                <div className="row">
                  <div className="col-12 mb-2">
                    <Tax>
                      {`${t('pages.wellness.reishi.basePrice')}: `}
                      {`${region?.EU ? data?.totalPrice : data?.totalPriceOutEU} €`}
                    </Tax>
                  </div>
                </div>
              )}

              <div className="row">
                <Gallons className="col-12" title={t('galTitle')}>
                  {' '}
                  {`${data?.totalGallons} ${t('gallons')}*`}
                </Gallons>
              </div>
              <div className="row mt-4">
                <div className="col-12">
                  <Description
                    dangerouslySetInnerHTML={{
                      __html: t('productDetail:description'),
                    }}
                  />
                </div>
              </div>
              {buyAllowed ? ( // context.can(USER_PERMISSIONS.IS_MEMBER)
                <div className="row mt-3">
                  <FormShoppingCart className="col-12">
                    <form className="form-horizontal bordered-row" onSubmit={handleSubmit}>
                      <div className="row align-items-center">
                        <div className="col-12 col-md-12 col-lg-3">
                          <Field
                            id="amount"
                            className="form-control limited50"
                            component={InputNumberFormik}
                            labelField={t('forms.product.labels.amount')}
                            name="amount"
                            placeholder=""
                            buttonsClassName="numberButtonsDetail"
                            showButtons
                            mandatory
                            decimals={0}
                            allowNegative={false}
                          />
                        </div>
                        <div className="col-12 col-md-12 col-lg-6 mt-2">
                          <Button
                            type="submit"
                            className="btn btn-lg d-flex justify-content-center align-items-center"
                            variant="black"
                            expand
                          >
                            <ImgButton
                              src={`/assets/themes/${process.env.REACT_APP_THEME}/images/MarketPlaceLogo_white.png`}
                              alt=""
                            />
                            {t('addCart')}
                          </Button>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 mt-3">
                          <AvailableCountriesButton
                            type="button"
                            onClick={() => showAvailableCountry(data.countriesAllowed)}
                          >
                            {t('countryAvailable')}
                          </AvailableCountriesButton>
                        </div>
                      </div>
                      {terms && (
                        <div className="row">
                          <div className="col-12 mt-3 mb-4">
                            <TermsConditions href={terms} target="_blank">
                              {t('viewTerms')}
                            </TermsConditions>
                          </div>
                        </div>
                      )}
                    </form>
                  </FormShoppingCart>
                </div>
              ) : (
                ''
                // <div className="row mt-3">
                //   <Proximamente className="col-12">{t('comingSoon')}</Proximamente>
                // </div>
              )}
            </div>
          </div>

          {extraInfo && (
            <>
              <div className="row mb-2 mt-5">
                <TitleAdditional className="col-12">{t('additional')}</TitleAdditional>
              </div>
              <div className="row mt-3">
                <div className="col-12">
                  <Additional
                    dangerouslySetInnerHTML={{
                      __html: extraInfo,
                    }}
                  />
                </div>
              </div>
            </>
          )}

          <div className="row mt-5">
            <GalExplain className="col-12 text-center">{t('galExplain')}</GalExplain>
          </div>
        </div>
      </div>
    </>
  );
};

ProductDetailView.propTypes = {
  // onListProduct: PropTypes.func,
  // onClickItem: PropTypes.func,
  // products: PropTypes.array,
  // addToShoppingCart: PropTypes.func,
  // itemSelected: PropTypes.object,
  // onChangeProduct: PropTypes.func,
  region: PropTypes.object,
  data: PropTypes.object,
  showAvailableCountry: PropTypes.func,
  userInfo: PropTypes.object,
};

const ProductDetailViewF = withFormik({
  mapPropsToValues: (props) => {
    if (props.initialValues) {
      return setInitialValues(props.initialValues);
    }
    return dataModel;
  },
  validationSchema: (props) => {
    return validationDataModel;
  },
  handleSubmit: (values, { props, resetForm }) => {
    props.onSubmit(values, resetForm);
  },
  enableReinitialize: true,
  displayName: 'ProductDetailView',
})(ProductDetailView);

export default ProductDetailViewF;
