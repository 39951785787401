import styled from 'styled-components';
import { Backend } from 'i18next-xhr-backend';

export const TextInvoice = styled.span`
  font-weight: normal;
  font-size: 3rem;
`;

export const ImgLogo = styled.img`
  height: 80px;
`;

export const NormaText = styled.span`
  font-weight: normal;
  font-size: 0.8rem;
`;

export const TitleText = styled.span`
  font-weight: normal;
  font-size: 0.875rem;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.primary};
`;

export const ContainerLineTitle = styled.div`
  border-top: 2px solid ${({ theme }) => theme.colors.primary};
  border-bottom: 2px solid ${({ theme }) => theme.colors.primary};
  padding-top: 3px;
  padding-bottom: 3px;
`;

export const BlockContainer = styled.div`
  background: transparent;
  position: absolute;
  top: 0px;
  left: 35px;
  width: 210mm;
  height: 500px;
`;
