import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  LabelText,
  LabelValue,
  IconItem,
  IconStar,
  IconRank,
  IconMyRank,
  LabelTextRank,
} from './MyQualify.styled';
import { RANKS } from '../../../../constants/index';

const MyQualify = (props) => {
  const { data, currentRank } = props;
  const { t } = useTranslation();

  const myCurrentRank = RANKS[`L${currentRank}`];

  const renderDirectRank = () => {
    return (
      <div className="row mt-1">
        <div className="col-12 d-flex justify-content-center">
          {data.firstLevelQualificationRanks.map((rank, index) => {
            const dataRank = RANKS[`L${rank}`];

            return (
              <div key={`directRank_${rank}_${index}`}>
                <IconRank
                  src={`/assets/themes/${process.env.REACT_APP_THEME}/images/ranks/${dataRank.image}.png`}
                ></IconRank>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  let networkGallons = data.pointsNLevel;

  // if (data.rank > 4) {
  //   networkGallons = data.pointsNLevel50p;
  // }

  return (
    <>
      {/* <div className="row">
        <div className="col-12 mb-3">
          <LabelTextRank>{t('currentRank')}:</LabelTextRank>
          <IconMyRank
            src={`/assets/themes/${process.env.REACT_APP_THEME}/images/ranks/${myCurrentRank.image}.png`}
          ></IconMyRank>
        </div>
      </div> */}
      <div className="row">
        <div className="col-12 d-flex flex-column">
          {/* <IconItem
            src={`/assets/themes/${process.env.REACT_APP_THEME}/images/Galon_Personal.png`}
            alt=""
          /> */}
          <LabelText>{t('personalGallons')}:</LabelText>
          <LabelValue>{data.pointsPersonal}</LabelValue>
        </div>
      </div>
      {/* <div className="row mt-3">
        <div className="col-12">
          <IconItem
            src={`/assets/themes/${process.env.REACT_APP_THEME}/images/Galon_Acumulado.png`}
            alt=""
          />
          <LabelText>{t('acummulatedGallons')}:</LabelText>
          <LabelValue>{data.accumulatedPersonalGallons}</LabelValue>
        </div>
      </div> */}
      <div className="row mt-3">
        <div className="col-12 d-flex flex-column">
          {/* <IconItem
            src={`/assets/themes/${process.env.REACT_APP_THEME}/images/Galon_Comisiones.png`}
            alt=""
          /> */}
          <LabelText>{t('networklGallons')}:</LabelText>
          <LabelValue>{networkGallons}</LabelValue>
        </div>
      </div>
      {/* <div className="row mt-3">
        <div className="col-12">
          <IconStar src={`/assets/themes/${process.env.REACT_APP_THEME}/images/star.png`} alt="" />
          <LabelText>{t('ranksDirect')}:</LabelText>
        </div>
      </div>
      {data.firstLevelQualificationRanks &&
        data.firstLevelQualificationRanks.length > 0 &&
        renderDirectRank()} */}
    </>
  );
};

MyQualify.propTypes = {
  data: PropTypes.object,
};

export default MyQualify;
