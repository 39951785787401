import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
// import { useSelector } from 'react-redux';
import MyWallet from './myWallets.view';
// import { getUserUuidSelector } from '../../../../store/selectors/authSelector';
import * as UserServices from '../../../../services/UserService';
import ContentLoader from 'react-content-loader';

const MyWalletContainer = (props) => {
  // const myUuid = useSelector(getUserUuidSelector);
  const [data, setData] = useState(null);

  useEffect(() => {
    let cancel = false;

    UserServices.getDashboardMyWallet().then((result) => {
      if (cancel) return;
      if (result) {
        setData(result);
      }
    });

    return () => {
      cancel = true;
    };
  }, []);

  const renderListLoader = () => {
    return (
      <div className="row">
        <div className="col-12 mt-4">
          <ContentLoader
            speed={1}
            viewBox="0 0 400 160"
            backgroundColor="#0000005c"
            foregroundColor="#1919195c"
            style={{ width: '100%' }}
          >
            <rect x="5" y="1" rx="3" ry="3" width="300" height="5" />
            <rect x="5" y="15" rx="3" ry="3" width="350" height="5" />
          </ContentLoader>
        </div>
      </div>
    );
  };

  return <>{data ? <MyWallet data={data}></MyWallet> : <>{renderListLoader()}</>}</>;
};

// MyWalletContainer.propTypes = {};

export default MyWalletContainer;
