import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import OrderDetail from './orderDetails.view';
import * as OrderService from '../../../../services/OrderService';
import { useHistory } from 'react-router-dom';
import PATHS from '../../../../constants/paths';

const OrderListPage = (props) => {
  const { uuid } = props;
  const { t } = useTranslation();
  const [data, setData] = useState(null);
  let history = useHistory();

  useEffect(() => {
    let cancel = false;

    OrderService.getOrder(uuid).then((result) => {
      if (cancel) return;
      if (result?.uuid) {
        // setItemSelected(result.docs[0]);
        setData(result);
      }
    });

    return () => {
      cancel = true;
    };
  }, [uuid]);

  const onBack = () => {
    history.push(PATHS.MY_ORDER_LIST);
  };

  const requestReturn = async (product, order) => {
    history.push(`/orders/return/${order.uuid}/${product.uuid}`);
  };

  return (
    <>
      <OrderDetail data={data} onBack={onBack} requestReturn={requestReturn}></OrderDetail>
    </>
  );
};

OrderListPage.propTypes = {
  uuid: PropTypes.string,
};

export default OrderListPage;
