import styled from 'styled-components';

export const LabelLevel = styled.div`
  /* background: #fff; */
  color: #fff;
  width: 100%;
  font-size: 0.9rem;
  text-transform: uppercase;

  /* border: 1px solid #363f5a; */
`;

export const ValueLevel = styled.span`
  /* background: #fff; */
  color: #fff;
  margin-left: 10px;
  font-size: 0.9rem;
  /* margin-left: 5px; */
`;

export const LabelValue = styled.div`
  /* background: #fff; */
  color: #fff;
  width: 100%;
  font-size: 0.7rem;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  /* border: 1px solid #363f5a; */
`;

export const ImageHeader = styled.img`
  height: 25px;
`;

export const Total = styled.div`
  /* background: #fff; */
  color: #fff;
  /* margin-left: 5px; */
`;
