import { createSelector } from 'reselect';

const getStateDashboardSelector = (state) => state.dashboard;

export const getInfoSelector = createSelector(getStateDashboardSelector, (dash) => {
  if (dash && dash.info) {
    return dash.info;
  }

  return undefined;
});

export const getRankUserSelector = createSelector(getInfoSelector, (info) => {
  if (info && info.rank) {
    return info.rank;
  }

  return undefined;
});

export const getUniLevelUserSelector = createSelector(getInfoSelector, (info) => {
  if (info && info.unilevelTreeInfo) {
    return info.unilevelTreeInfo;
  }

  return undefined;
});

export const getDataForWithdrawSelector = createSelector(getInfoSelector, (info) => {
  if (info) {
    const data = {
      withdrawInvest: info.withdrawInvest,
      totalPersonalInvest: info.totalPersonalInvest,
    };
    return data;
  }

  return undefined;
});

export const getUserLevelsSelector = createSelector(getStateDashboardSelector, (dash) => {
  if (dash && dash.userLevel) {
    return dash.userLevel;
  }

  return undefined;
});

export const getUserStatusSelector = createSelector(getStateDashboardSelector, (dash) => {
  const dataStatus = {
    registered: 0,
    client: 0,
    actives: 0,
    noQualified: 0,
    n1: 0,
    n2: 0,
    n3: 0,
    n4: 0,
    n5: 0,
    n6: 0,
    n7: 0,
    n8: 0,
    n9: 0,
    n10: 0,
  };
  if (dash && dash.userLevel) {
    const keys = Object.keys(dash.userLevel);
    for (const level of keys) {
      const dataLevel = dash.userLevel[level];
      dataStatus.registered += dataLevel.countUser || 0;
      dataStatus.client += dataLevel.countClient || 0;
      dataStatus.actives += dataLevel.countPrePromoter || 0;
      dataStatus.noQualified += dataLevel.countOldPromoter || 0;
      dataStatus.n1 += dataLevel.countRank1 || 0;
      dataStatus.n2 += dataLevel.countRank2 || 0;
      dataStatus.n3 += dataLevel.countRank3 || 0;
      dataStatus.n4 += dataLevel.countRank4 || 0;
      dataStatus.n5 += dataLevel.countRank5 || 0;
      dataStatus.n6 += dataLevel.countRank6 || 0;
      dataStatus.n7 += dataLevel.countRank7 || 0;
      dataStatus.n8 += dataLevel.countRank8 || 0;
      dataStatus.n9 += dataLevel.countRank9 || 0;
      dataStatus.n10 += dataLevel.countRank10 || 0;
    }
  }

  return dataStatus;
});
