import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import WalletInfo from './wallet.info.view';
import { withTranslation } from 'react-i18next';
import * as UserService from '../../../services/UserService';
import * as WalletService from '../../../services/WalletService';
import {
  getHasWalletSelector,
  getHasWalletGalSelector,
  getHasWalletTreSelector,
} from '../../../store/selectors/authSelector';
import { showDialog } from '@arcaitprod/react-utils-components';
import { Can, AbilityContext } from '../../permissions/Can';
import { USER_PERMISSIONS } from '../../permissions/rolesPermission';
import WalletRequest from './wallet.request.view';
import { Button, Title, Text } from '@arcaitprod/react-basic-components';
import { InfiniteList } from '@arcaitprod/react-complex-components';
import moment from 'moment-timezone';
import ContainerExpandable from '../../utils/commons/containerExpandable';
import {
  TextList,
  TransactionLink,
  HeaderList,
  RowList,
  ButtonCurrencyImg,
  ButtonCurrency,
  ContainerIconWallet,
} from './wallet.styled';
import PATH from '../../../constants/paths';
import { WALLET_SERVICE } from '../../../constants/index';
import ContentLoader from 'react-content-loader';
import { SecctionTitleContainer } from '../../../design/styleComponents/globalComponents';
import { getTimeZoneSelector } from '../../../store/selectors/navSelector';

class WalletContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      walletInfo: null,
      refreshData: false,
      wallet: WALLET_SERVICE['GAL'],
      currency: 'GAL',
    };
  }

  componentDidMount() {
    this.loadData();
  }

  // componentDidUpdate(prevProps, prevState) {
  //   if (prevState.currency && prevState.currency !== this.state.currency) {
  //     this.loadData();
  //   }
  // }

  loadData = async () => {
    // const { hasWallet, hasWalletGal, hasWalletTre } = this.props;
    const { currency } = this.state;
    // if (
    //   (hasWallet && currency === 'BTC') ||
    //   (hasWalletGal && currency === 'GAL') ||
    //   (hasWalletTre && currency === 'TRE')
    // ) {
    const result = await UserService.getStatusWallet(this.state.currency);
    if (result && result.data) {
      this.setState({ walletInfo: result.data });
    }
    // }
  };

  // requestGetWallet = async (currency) => {
  //   const result = await UserService.getAddressWallet(currency);
  //   if (result) {
  //     await UserService.getAuthUserProfile();
  //     this.loadData();
  //     // this.setState({ walletInfo: result });
  //   }
  // };

  // toList = async (filter) => {
  //   let result = [];
  //   if (this.state.walletInfo && this.state.walletInfo.walletAddress) {
  //     if (!filter) {
  //       filter = {
  //         filters: {
  //           page: 1,
  //           pageSize: 10,
  //         },
  //       };
  //     }
  //     filter.sort = '-uuid';
  //     result = await WalletService.listFilterTransactions(
  //       filter,
  //       this.state.walletInfo.walletAddress
  //     );
  //   }
  //   return result;
  // };

  renderHeaderRow = (trans) => {
    const date = moment(new Date(trans.createdAt));
    const { t, timeZone } = this.props;
    return (
      <RowList className="row align-items-center">
        <div className="col-3 text-center">
          <TextList>{date.tz('Europe/Madrid').format('DD-MM-yyyy HH:mm')}</TextList>
        </div>
        <div className="col-2 text-center">
          <TextList>{`${
            trans.type === -1
              ? t('forms.wallet.labels.widthdraw')
              : t('forms.wallet.labels.deposit')
          }`}</TextList>
        </div>
        <div className="col-2 text-center">
          <TextList>{trans.feeBilled > 0 ? `-${trans.feeBilled} BTC` : '0.0 BTC'}</TextList>
        </div>
        <div className="col-2 text-center">
          <TextList>{`${trans.type === -1 ? '-' : ''}${trans.amount} BTC`}</TextList>
        </div>
        <div className="col-3 text-center">
          <TextList>{`${trans.finalAmount} BTC`}</TextList>
        </div>
      </RowList>
    );
  };

  renderHeaderList = () => {
    const { t } = this.props;
    return (
      <HeaderList className="row align-items-center mt-4">
        <div className="col-3 text-center">{t('forms.wallet.table.header.date')}</div>
        <div className="col-2 text-center">{t('forms.wallet.table.header.type')}</div>
        <div className="col-2 text-center">{t('forms.wallet.table.header.comission')}</div>
        <div className="col-2 text-center">{t('forms.wallet.table.header.amount')}</div>
        <div className="col-3 text-center">{t('forms.wallet.table.header.total')}</div>
      </HeaderList>
    );
  };

  renderBodyRow = (trans) => {
    const { t } = this.props;
    if (trans.txId) {
      return (
        <div id={`${trans.date}_expanded`} className="row align-items-end pb-3 pt-2">
          <div className="col-12 d-flex">
            <div>
              <strong>{`${t('forms.wallet.labels.trans')}: `}</strong>
            </div>
            <div>
              <TransactionLink href="#">{trans.txId}</TransactionLink>
            </div>
          </div>
        </div>
      );
    }

    return '';
  };

  renderRowBot = (trans) => {
    if (trans) {
      return (
        <ContainerExpandable
          key={trans.date}
          header={this.renderHeaderRow(trans)}
          body={this.renderBodyRow(trans)}
          classNameHeader=""
        />
      );
    }
    return '';
  };

  requestWithdraw = () => {
    this.props.history.push(PATH.WITHDRAW);
  };

  // requestExchangeGal = () => {
  //   this.props.history.push('/wallet/exchange/BTC/GAL');
  // };

  // requestExchangeBTC = () => {
  //   this.props.history.push('/wallet/exchange/GAL/BTC');
  // };

  renderListLoader = () => {
    return (
      <div className="row">
        <div className="col-12 mt-4">
          <ContentLoader
            speed={2}
            viewBox="0 0 400 160"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            style={{ width: '100%' }}
          >
            <rect x="5" y="1" rx="3" ry="3" width="400" height="5" />
            <rect x="5" y="15" rx="3" ry="3" width="400" height="5" />
          </ContentLoader>
        </div>
      </div>
    );
  };

  handlerSelectCurrency = async (currency) => {
    this.setState({ wallet: WALLET_SERVICE[currency], currency: currency, walletInfo: null });
  };

  render() {
    const { t, hasWallet, hasWalletGal, hasWalletTre } = this.props;
    const { wallet, currency } = this.state;

    return (
      <>
        <div className="row justify-content-center">
          <div className="col-12 mb-5 d-flex justify-content-start align-items-center">
            <h3>{t('myWallet')}</h3>
            {/* {this.context.can(USER_PERMISSIONS.WALLET_EUR) && ( */}
            <>
              {/* <ContainerIconWallet active={wallet === WALLET_SERVICE['BTC']}>
                <ButtonCurrency
                  onClick={() => this.handlerSelectCurrency('BTC')}
                  title={t('forms.wallet.wallet.BTC')}
                >
                  <ButtonCurrencyImg
                    src={`/assets/themes/${process.env.REACT_APP_THEME}/images/BTC-icon.png`}
                    alt="BTC"
                  />
                </ButtonCurrency>
              </ContainerIconWallet> */}
              {/* <ContainerIconWallet active={wallet === WALLET_SERVICE['GAL']}>
                <ButtonCurrency
                  onClick={() => this.handlerSelectCurrency('GAL')}
                  title={t('forms.wallet.wallet.GAL')}
                >
                  <ButtonCurrencyImg
                    src={`/assets/themes/${process.env.REACT_APP_THEME}/images/Galon_Personal.png`}
                    alt="GAL"
                  />
                </ButtonCurrency>
              </ContainerIconWallet> */}
              {/* <ContainerIconWallet active={wallet === WALLET_SERVICE['TRE']}>
                <ButtonCurrency
                  onClick={() => this.handlerSelectCurrency('TRE')}
                  title={t('forms.wallet.wallet.TRE')}
                >
                  <ButtonCurrencyImg
                    src={`/assets/themes/${process.env.REACT_APP_THEME}/images/Trevol_Icon3.png`}
                    alt="TRE"
                  />
                </ButtonCurrency>
              </ContainerIconWallet> */}
            </>
            {/* )} */}
          </div>
        </div>

        {/* </div> */}
        {/* {(wallet === WALLET_SERVICE['BTC'] && !hasWallet) ||
        (wallet === WALLET_SERVICE['GAL'] && !hasWalletGal) ||
        (wallet === WALLET_SERVICE['TRE'] && !hasWalletTre) ? (
          <WalletRequest requestWallet={this.requestGetWallet} currency={currency} />
        ) : ( */}
        {/* <> */}
        <WalletInfo
          walletInfo={this.state.walletInfo}
          hasWallet={hasWallet}
          hasWalletGal={hasWalletGal}
          wallet={WALLET_SERVICE[currency]}
          currency={currency}
          // requestWithdraw={this.requestWithdraw}
          // requestExchangeGal={this.requestExchangeGal}
          // requestExchangeBTC={this.requestExchangeBTC}
        />
        {/* <div className="row justify-content-center mt-5 mb-5">
          <div className="col-12 col-md-11">
            <InfiniteList
              id="transactionsList"
              renderItem={this.renderRowBot}
              toList={this.toList}
              filters={{}}
              variantTitle="primary"
              title={t('forms.wallet.titleList')}
              forceRefresh={this.state.refreshData}
              endMessage={t('goToTop')}
              messageButtonMore={t('readMore')}
              iconAdd="fas fa-plus"
              classNameLinkAdd="actionAdd"
              floatButton
              customClassName="row"
              variantButtonMore="simplePrimary"
              headerComponentList={this.renderHeaderList}
              renderLoader={this.renderListLoader}
            />
          </div>
        </div> */}
        {/* </> */}
      </>
    );
  }
}

WalletContainer.contextType = AbilityContext;

WalletContainer.propTypes = {};

const mapStateToProps = (state) => ({
  hasWallet: getHasWalletSelector(state),
  hasWalletGal: getHasWalletGalSelector(state),
  hasWalletTre: getHasWalletTreSelector(state),
  timeZone: getTimeZoneSelector(state),
});

export default withRouter(connect(mapStateToProps, {})(withTranslation()(WalletContainer)));
