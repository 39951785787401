import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as CashFlowService from '../../../../services/CashFlowService';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import {
  FinalAmount,
  PartialAmount,
  FeeAmount,
  RowText,
  ValueData,
  IconLink,
  ExternalLink,
  SubTitleInfo,
  IconInfo,
  RowTextUuid,
  ButtonAnchor,
  HeaderList,
} from './cashflowsList.styled';
import { AbilityContext } from '../../../permissions/Can';
import ContainerExpandable from '../../../utils/commons/containerExpandable';
import { InfiniteList } from '@arcaitprod/react-complex-components';
import moment from 'moment-timezone';
import {
  CASHFLOW_TYPE_DEPOSIT,
  CASHFLOW_TYPE_ERROR_WITHDRAW,
  CASHFLOW_TYPE_WITHDRAW,
  CASHFLOW_STATUS,
  CASH_STATUS_WITH_SENT,
  CASH_STATUS_WITH_SENT_ERROR,
  CASH_STATUS_WITH_CONFIRMED,
  CASHFLOW_TYPE_WITHDRAW_EXCHANGE,
  WALLET_SERVICE,
} from '../../../../constants/index';
import * as exchangeUtil from '../../../../utils/formats/exchange';
import PATHS from '../../../../constants/paths';
import { connect } from 'react-redux';
import { getUserIsEuSelector } from '../../../../store/selectors/authSelector';
import ContentLoader from 'react-content-loader';
import { getTimeZoneSelector } from '../../../../store/selectors/navSelector';

class NotificationListContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      refreshData: false,
      info: {},
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.refreshData !== this.state.refreshData && this.state.refreshData === true) {
      this.resetRefreshData();
    }
    if (prevProps.walletService && prevProps.walletService !== this.props.walletService) {
      this.forceRefreshData();
    }
  }

  forceRefreshData = () => {
    this.setState({ refreshData: true });
  };

  resetRefreshData = () => {
    this.setState({ refreshData: false });
  };

  renderActions = (obj) => {
    return (
      <>
        {/* <div className="text-center">
          <Can do={USER_PERMISSIONS.NOTIFICATION_LIST}>
            <LinkActionStyled variant="primary" onClick={() => this.handlerRead(obj.original.uuid)}>
              <i className="far fa-bookmark" title="Marcar" />
            </LinkActionStyled>
          </Can>
        </div> */}
      </>
    );
  };

  // handlerRead = async (value) => {
  //   const result = await NotificationServices.readedNotification(value);
  //   if (result && result.uuid) {
  //     NotificationServices.getPendingNotification();
  //     this.setState({ refreshData: true });
  //   }
  // };

  toList = async (filter) => {
    if (filter.filters) {
      filter.filters.walletService = WALLET_SERVICE['GAL'];
    } else {
      filter.filters = {
        walletService: WALLET_SERVICE['GAL'],
      };
    }

    // const { walletService } = this.props;
    // if (filter?.filters?.walletService) {
    //   filter.filters.walletService = WALLET_SERVICE[filter.filters.walletService];
    // } else if (filter.filters && !filter.filters.walletService) {
    //   filter.filters.walletService = walletService;
    // } else if (!filter.filters) {
    //   filter.filters = { walletService };
    // }
    const result = await CashFlowService.listFilter(filter);
    return result;
  };

  renderRowExchange = (mov) => {
    return (
      <ContainerExpandable
        key={mov.uuid}
        header={this.renderHeaderRow(mov)}
        // body={this.renderBodyRow(mov)}
        classNameHeader=""
        onClickActionOpen={() => this.getDataInfoCashFlow(mov)}
      />
    );
  };

  getDataInfoCashFlow = async (cashflow) => {
    const newInfo = { ...this.state.info };
    if (!newInfo[cashflow.uuid]) {
      const result = await CashFlowService.moreInfo(cashflow.uuid);
      if (result) {
        newInfo[cashflow.uuid] = result;
        this.setState({ info: newInfo });
      }
    }
  };

  renderWithDraw = (uuid, data) => {
    const { t, timeZone } = this.props;
    let status = CASHFLOW_STATUS.find((sta) => sta.code === data.status);
    if (data.relationCashFlow && data.status === CASH_STATUS_WITH_SENT.code) {
      status = CASH_STATUS_WITH_SENT_ERROR;
    }
    return (
      <div id={`${uuid}_expanded`} className="row justify-content-center pb-3 pt-2">
        <div className="col-11">
          <div className="row">
            <div className="col-12 mt-1">
              {`${t('forms.cashflow.labels.status')}: `}
              <ValueData>{status ? t(status.label) : data.status}</ValueData>
            </div>
            <div className="col-12 mt-1">
              {`${t('forms.cashflow.labels.addressTo')}: `} <ValueData>{data.addressTo}</ValueData>
            </div>
            <div className="col-12 mt-1">
              {`${t('forms.cashflow.labels.txId')}: `}
              {data.txId ? (
                <ExternalLink
                  href={`${process.env.REACT_APP_URL_BLOCKCHAIN_TXID}${data.txId}`}
                  target="_blank"
                  variant="primary"
                >
                  {data.txId}
                  <IconLink className="fas fa-external-link-alt"></IconLink>
                </ExternalLink>
              ) : (
                '--'
              )}
            </div>
          </div>
          {data.relationCashFlow && (
            <>
              <div className="row mt-2">
                <div className="col-12 mt-1">
                  <SubTitleInfo>{t('forms.cashflow.labels.cashflowRelation')}</SubTitleInfo>
                </div>
              </div>
              <div className="row">
                <div className="col-12 mt-1">
                  {`${t('forms.cashflow.labels.uuid')}: `}
                  <ButtonAnchor onClick={() => this.scrollToAnchor(data.relationCashFlow.uuid)}>
                    <ValueData>{data.relationCashFlow.uuid}</ValueData>
                    <i className="fas fa-paperclip"></i>
                  </ButtonAnchor>
                </div>
              </div>
              <div className="row">
                <div className="col-12 mt-1">
                  {`${t('forms.cashflow.labels.date')}: `}
                  <ValueData>
                    {moment(data.relationCashFlow.date)
                      .tz('Europe/Madrid')
                      .format('DD-MM-yyyy HH:mm')}
                  </ValueData>
                </div>
              </div>
              <div className="row">
                <div className="col-12 mt-1">
                  {`${t('forms.cashflow.labels.totalReturn')}: `}
                  <ValueData>
                    {`${exchangeUtil.satoshi2Crypto(
                      data.relationCashFlow.currency,
                      (data.relationCashFlow.fee ? data.relationCashFlow.fee : 0) +
                        data.relationCashFlow.amount
                    )} ${data.relationCashFlow.currency}`}
                  </ValueData>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    );
  };

  renderErrorWithDraw = (uuid, data) => {
    const { t, timeZone } = this.props;
    let status = CASH_STATUS_WITH_CONFIRMED;
    return (
      <div id={`${uuid}_expanded`} className="row justify-content-center pb-3 pt-2">
        <div className="col-11">
          <div className="row">
            <div className="col-12 mt-1">
              {`${t('forms.cashflow.labels.status')}: `}
              <ValueData>{status ? t(status.label) : data.status}</ValueData>
            </div>
          </div>
          {data.relationCashFlow && (
            <>
              <div className="row mt-2">
                <div className="col-12 mt-1">
                  <SubTitleInfo>{t('forms.cashflow.labels.cashflowRelationWithdraw')}</SubTitleInfo>
                </div>
              </div>
              <div className="row">
                <div className="col-12 mt-1">
                  {`${t('forms.cashflow.labels.uuid')}: `}
                  <ButtonAnchor onClick={() => this.scrollToAnchor(data.relationCashFlow.uuid)}>
                    <ValueData>{data.relationCashFlow.uuid}</ValueData>
                    <i className="fas fa-paperclip"></i>
                  </ButtonAnchor>
                </div>
              </div>
              <div className="row">
                <div className="col-12 mt-1">
                  {`${t('forms.cashflow.labels.date')}: `}
                  <ValueData>
                    {moment(data.relationCashFlow.date)
                      .tz('Europe/Madrid')
                      .format('DD-MM-yyyy HH:mm')}
                  </ValueData>
                </div>
              </div>
              <div className="row">
                <div className="col-12 mt-1">
                  {`${t('forms.cashflow.labels.addressTo')}: `}
                  <ValueData>{data.relationCashFlow.addressTo}</ValueData>
                </div>
              </div>
              <div className="row">
                <div className="col-12 mt-1">
                  {`${t('forms.cashflow.labels.totalWithdraw')}: `}
                  <ValueData>
                    {`${exchangeUtil.satoshi2Crypto(
                      data.relationCashFlow.currency,
                      (data.relationCashFlow.fee ? data.relationCashFlow.fee : 0) +
                        data.relationCashFlow.amount
                    )} ${data.relationCashFlow.currency}`}
                  </ValueData>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    );
  };

  renderDeposit = (uuid, data) => {
    const { t } = this.props;
    let status = CASH_STATUS_WITH_CONFIRMED;
    return (
      <div id={`${uuid}_expanded`} className="row justify-content-center pb-3 pt-2">
        <div className="col-11">
          <div className="row">
            <div className="col-12 mt-1">
              {`${t('forms.cashflow.labels.status')}: `}
              <ValueData>{status ? t(status.label) : data.status}</ValueData>
            </div>
            <div className="col-12 mt-1">
              {`${t('forms.cashflow.labels.txId')}: `}
              {data.txId ? (
                <ExternalLink
                  // href={`${process.env.REACT_APP_URL_BLOCKCHAIN_TXID}${data.txId}`}
                  // target="_blank"
                  variant="primary"
                >
                  {data.txId}
                  {/* <IconLink className="fas fa-external-link-alt"></IconLink> */}
                </ExternalLink>
              ) : (
                '--'
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderBodyRow = (cashflow) => {
    const { t } = this.props;
    const data = this.state.info[cashflow.uuid];
    if (data) {
      switch (data.type) {
        case CASHFLOW_TYPE_DEPOSIT.code:
          return this.renderDeposit(cashflow.uuid, data);
        case CASHFLOW_TYPE_WITHDRAW.code:
          return this.renderWithDraw(cashflow.uuid, data);
        case CASHFLOW_TYPE_ERROR_WITHDRAW.code:
          return this.renderErrorWithDraw(cashflow.uuid, data);
        default:
          break;
      }
    } else {
      return <div></div>;
    }
  };

  scrollToAnchor = (anchorName) => {
    if (anchorName) {
      let anchorElement = document.getElementById(anchorName);
      let anchorFocus = document.getElementById(`idLabel_${anchorName}`);

      if (anchorElement) {
        anchorElement.scrollIntoView();

        if (document.selection) {
          const range = document.body.createTextRange();
          range.moveToElementText(anchorFocus);
          range.select();
        } else if (window.getSelection) {
          const range = document.createRange();
          range.selectNode(anchorFocus);
          window.getSelection().removeAllRanges();
          window.getSelection().addRange(range);
        }
      }
    }
  };

  renderHeaderRow = (mov) => {
    const { t, isEu, timeZone } = this.props;
    const date = moment(mov.date);
    let operationSigne = '';
    let special = false;
    if (
      mov.type === CASHFLOW_TYPE_WITHDRAW.code ||
      mov.type === CASHFLOW_TYPE_WITHDRAW_EXCHANGE.code
    ) {
      operationSigne = '-';
      if (mov.type === CASHFLOW_TYPE_WITHDRAW.code && mov.relationCashFlowUuid) {
        special = true;
      }
    } else if (mov.type === CASHFLOW_TYPE_ERROR_WITHDRAW.code) {
      special = true;
    }
    return (
      <div id={mov.uuid} className="row align-items-center">
        <div className="col-4 col-md-3 col-lg-3 d-flex flex-column">
          <RowText>{date.tz('Europe/Madrid').format('DD-MM-yyyy HH:mm')}</RowText>
          <RowTextUuid id={`idLabel_${mov.uuid}`} className="d-none d-lg-block">
            {mov.uuid}
          </RowTextUuid>
        </div>
        <div className="col-3 col-md-3 col-lg-2 d-flex">
          <RowText>{`${t(mov.concept)}`}</RowText>
          {special && <IconInfo className="fas fa-info-circle"></IconInfo>}
        </div>
        <div className="col-2 text-center d-none d-lg-block">
          <FeeAmount signe={operationSigne}>
            {/*&& mov.type !== CASHFLOW_TYPE_WITHDRAW.code*/}
            {mov.fee
              ? `${operationSigne}${exchangeUtil.satoshi2Crypto(mov.currency, mov.fee)} Puntos`
              : '-'}
          </FeeAmount>
        </div>
        <div className="col-3 col-md-3 col-lg-2 text-center">
          <PartialAmount signe={operationSigne}>{`${operationSigne}${exchangeUtil.satoshi2Crypto(
            mov.currency,
            mov.amount
          )} Puntos`}</PartialAmount>
        </div>
        <div className="col-2 col-md-3 text-center d-none d-md-block">
          <FinalAmount>{`${exchangeUtil.satoshi2Crypto(
            mov.currency,
            mov.finalAmount
          )} Puntos`}</FinalAmount>
        </div>
      </div>
    );
  };

  renderHeaderList = () => {
    const { t } = this.props;
    return (
      <HeaderList className="row align-items-center mt-2">
        <div className="col-4 col-md-3 col-lg-3 text-start">
          {t('forms.wallet.table.header.date')}
        </div>
        <div className="col-3 col-md-3 col-lg-2 text-start">
          {t('forms.wallet.table.header.type')}
        </div>
        <div className="col-2 text-center d-none d-lg-block">
          {t('forms.wallet.table.header.comission')}
        </div>
        <div className="col-3 col-md-3 col-lg-2 text-center">
          {t('forms.wallet.table.header.amount')}
        </div>
        <div className="col-2 col-md-3 col-lg-3 text-center d-none d-md-block">
          {t('forms.wallet.table.header.total')}
        </div>
      </HeaderList>
    );
  };

  renderListLoader = () => {
    return (
      <div className="row">
        <div className="col-12 mt-4">
          <ContentLoader
            speed={2}
            viewBox="0 0 400 160"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            style={{ width: '100%' }}
          >
            <rect x="5" y="1" rx="3" ry="3" width="400" height="5" />
            <rect x="5" y="15" rx="3" ry="3" width="400" height="5" />
          </ContentLoader>
        </div>
      </div>
    );
  };

  render() {
    const { t, filters, className, currency } = this.props;
    return (
      <div className={className || ''}>
        <InfiniteList
          id="cashflows"
          renderItem={this.renderRowExchange}
          toList={this.toList}
          filters={filters}
          variantTitle="primary"
          // title={t('forms.cashflow.titleList')}
          forceRefresh={this.state.refreshData}
          endMessage={t('goToTop')}
          messageButtonMore={t('readMore')}
          variantButtonMore="simplePrimary"
          headerComponentList={this.renderHeaderList}
          renderLoader={this.renderListLoader}
        />
      </div>
    );
  }
}

NotificationListContainer.contextType = AbilityContext;

NotificationListContainer.propTypes = {
  t: PropTypes.any,
  filters: PropTypes.object,
  className: PropTypes.string,
  walletService: PropTypes.string,
};

// export default withRouter(withTranslation()(NotificationListContainer));

// export default withTranslation()(SubscriptionsContainer);
const mapStateToProps = (state) => ({
  isEu: getUserIsEuSelector(state),
  timeZone: getTimeZoneSelector(state),
});

export default withRouter(
  connect(mapStateToProps, {})(withTranslation()(NotificationListContainer))
);
