import { handleActions } from 'redux-actions';
import {
  CREATE_COINBASE_TRANSACTION,
  CREATE_COINBASE_TRANSACTION_SUCCESS,
  CREATE_COINBASE_TRANSACTION_FAILURE,
  RESET_COINBASE_TRANSACTION,
  // Include other action types as needed
} from '../../constants/index';

const initialState = {
  createCoinbaseTransactionStatus: null,
  createCoinbaseTransactionData: null,
  createCoinbaseTransactionError: null,
  // Add other initial state properties as needed
};

export const walletReducer = handleActions(
  {
    [CREATE_COINBASE_TRANSACTION]: (state) => ({
      ...state,
      createCoinbaseTransactionStatus: 'pending',
      createCoinbaseTransactionError: null,
    }),
    [CREATE_COINBASE_TRANSACTION_SUCCESS]: (state, action) => ({
      ...state,
      createCoinbaseTransactionStatus: 'success',
      createCoinbaseTransactionData: action.payload,
      createCoinbaseTransactionError: null,
    }),
    [CREATE_COINBASE_TRANSACTION_FAILURE]: (state, action) => ({
      ...state,
      createCoinbaseTransactionStatus: 'error',
      createCoinbaseTransactionError: action.payload,
    }),
    [RESET_COINBASE_TRANSACTION]: (state) => ({
      ...state,
      createCoinbaseTransactionStatus: null,
      createCoinbaseTransactionData: null,
      createCoinbaseTransactionError: null,
    }),
    // Handle other actions as needed
  },
  initialState
);

export default walletReducer;