import { store } from '../store/index';
import {
  registerNitrox,
  getNitroxProductByToken,
  filterSubscriptions,
  cancelService,
  activeService,
  loadSubsReactivation,
  reactivateNitrox,
  updateDataUserIgnite,
} from '../store/actions/SubscriptionActions';

/**
 * List subscriptions
 */
export const listFilter = async (filter) => {
  const result = await store.dispatch(filterSubscriptions(filter));
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};

/**
 * Register nitrox
 */
export const registerNitroxAccount = async (
  values,
  token,
  readOnly,
  lang,
  reactivation = false
) => {
  const objSend = { ...values };
  delete objSend.passwordConfirm;
  objSend.lang = lang ? lang : 'es';
  if (reactivation) {
    delete objSend.email;
    delete objSend.name;
    delete objSend.surname;
  } else {
    if (readOnly) {
      delete objSend.password;
    }
  }
  const param = [token];
  let result;
  if (reactivation) {
    result = await store.dispatch(reactivateNitrox(objSend, param));
  } else {
    result = await store.dispatch(registerNitrox(objSend, param));
  }
  if (result && result.payload && result.payload.data !== undefined) {
    return result.payload.data;
  }
  return null;
};

/**
 * Get Product activation
 */
export const getNitroxActivation = async (token) => {
  const param = [token];
  const result = await store.dispatch(getNitroxProductByToken(param));
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};

/**
 * Cancel subscription
 */
export const cancelSubscription = async (uuid) => {
  const param = [uuid];
  const result = await store.dispatch(cancelService(param));
  if (result && result.payload && result.payload.data.uuid) {
    return 'OK';
  }
  return null;
};

/**
 * Active subscription
 */
export const activeSubscription = async (uuid) => {
  const param = [uuid];
  const result = await store.dispatch(activeService(param));
  if (result && result.payload && result.payload.data.uuid) {
    return 'OK';
  }
  return null;
};

/**
 * load subscription
 */
export const loadSubscriptionReactivation = async (uuid) => {
  const result = await store.dispatch(loadSubsReactivation([uuid]));
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};
