import { createAction } from 'redux-actions';
import { apiGet } from '../../api/index';

import { urlPurchaseSecured } from '../../api/urls';

import { PAGE_SIZE_10, FILTER_ORDER } from '../../constants/index';

const generateFilterQuery = ({ filters, page, pageSize }) => {
  let filter = '?';

  page ? (filter += 'page=' + page) : (filter += 'page=1');
  pageSize ? (filter += '&pageSize=' + pageSize) : (filter += '&pageSize=' + PAGE_SIZE_10);

  if (filters) {
    if (filters.uuid) filter += '&uuid=' + filters.uuid;
    if (filters.status) filter += '&status=' + filters.status;
  }

  return filter;
};

/**
 * Filter list order.
 */
export const filterOrder = createAction(FILTER_ORDER, (filter, param) =>
  apiGet(urlPurchaseSecured + generateFilterQuery(filter), param, true)()
);

/**
 * Filter list order.
 */
export const getOrderByUuid = createAction(FILTER_ORDER, (param) =>
  apiGet(urlPurchaseSecured, param, true)()
);
