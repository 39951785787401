import styled from 'styled-components';

export const ImgShowSelected = styled.img`
  height: 15px !important;
`;

export const LabelRegion = styled.div`
  color: #a6a79f;
  margin-top: 2px;
`;
