import styled from 'styled-components';

export const ContainerSelect = styled.div`
  display: flex;
  height: 39px;
  color: #fff;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
`;

export const LinkTimeZone = styled.a`
  color: #fff;

  &:hover {
    color: #fff;
    text-decoration: none;
  }
`;

export const IconTimeZone = styled.i`
  margin-right: 5px;
`;
