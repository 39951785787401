import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Button, Title } from '@arcaitprod/react-basic-components';

class TermConditions extends Component {
  render() {
    const { t, onBack } = this.props;
    return (
      <>
        <div className="container">
          <div className="row">
            <div className="col-12 marg-top-40px">
              <Title type="h3" variant="white">
                {t('forms.termConditions.title')}
              </Title>
            </div>
          </div>
          <div className="row ">
            <div className="col-12 term-and-conditions">
              <div
                dangerouslySetInnerHTML={{
                  __html: t('forms.termConditions.text'),
                }}
              />
            </div>
          </div>
          <div className="row ">
            <div className="col-12 term-and-conditions text-center margBottom15">
              <Button
                type="button"
                className="btn btn-lg"
                variant="primary"
                onClick={() => onBack()}
              >
                {t('forms.termConditions.buttons.back')}
              </Button>
            </div>
          </div>
        </div>
      </>
    );
  }
}

TermConditions.propTypes = {
  onBack: PropTypes.func,
};

export default withTranslation()(TermConditions);
