import styled from 'styled-components';

export const WalletIcon = styled.img`
  height: 30px;
  margin-right: 15px;
  margin-left: 15px;
`;
export const WalletName = styled.div`
  color: #fff;
  text-transform: uppercase;
`;
