import React from 'react';
import PropTypes from 'prop-types';
import { withFormik, Field } from 'formik';

import { withTranslation } from 'react-i18next';
import {
  InputTextFormik,
  InputSelectFormik,
  InputCheckFormik,
} from '@arcaitprod/react-forms-components';

import { dataModel, validationDataModel, setInitialValues } from './addressForm.data.form';

import { ButtonsContainer } from '../../../../design/styleComponents/globalComponents';
import { Button, Title } from '@arcaitprod/react-basic-components';

const AddressForm = (props) => {
  const { t, handleSubmit, onback, edit, nationalities, setFieldValue } = props;

  const handleOnChangeCountry = (value, values) => {
    const countrySelected = nationalities.find((nat) => nat.uuid === value);
    if (
      countrySelected &&
      countrySelected.postalCodes &&
      countrySelected.postalCodes.length > 0 &&
      values.cp
    ) {
      const initCp = values.cp.substring(0, 2);
      const existCp = countrySelected.postalCodes.find((cp) => cp === initCp);
      if (!existCp) {
        setFieldValue(`cp`, '', true);
      }
    }
  };

  const handleOnChangeCp = (value) => {
    const { countryUuid } = props.values;
    if (countryUuid && value.length == 2) {
      const countrySelected = nationalities.find((nat) => nat.uuid === countryUuid);
      if (
        countrySelected &&
        countrySelected.postalCodes &&
        countrySelected.postalCodes.length > 0
      ) {
        const existCp = countrySelected.postalCodes.find((cp) => cp === value);
        if (!existCp) {
          //BUSCAR pais
          const co = nationalities.find(
            (nat) => nat.iso3 === 'ESP' && nat.postalCodes.find((cp) => cp === value)
          );
          if (co) {
            setFieldValue(`countryUuid`, co.uuid, true);
          }
        }
      }
    }
  };

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-12 col-md-11">
          <div className="row">
            <div className="col-12">
              <Title type="h3" variant="primary">
                {edit ? t('forms.address.titleEdit') : t('forms.address.titleNew')}
              </Title>
            </div>
          </div>
          <form className="form-horizontal bordered-row" onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-12">
                <Field
                  id="alias"
                  className="form-control"
                  labelField={t('forms.address.labels.alias')}
                  name="alias"
                  placeholder=""
                  component={InputTextFormik}
                  fieldType="text"
                  mandatory
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <Field
                  id="contactName"
                  className="form-control"
                  labelField={t('forms.address.labels.contactName')}
                  name="contactName"
                  placeholder=""
                  component={InputTextFormik}
                  fieldType="text"
                  mandatory
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <Field
                  id="phone"
                  className="form-control"
                  labelField={t('forms.address.labels.phone')}
                  name="phone"
                  placeholder=""
                  component={InputTextFormik}
                  fieldType="text"
                  mandatory
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <Field
                  id="address"
                  className="form-control"
                  labelField={t('forms.address.labels.address')}
                  name="address"
                  placeholder={t('forms.address.labels.placeHolderLine1')}
                  component={InputTextFormik}
                  fieldType="text"
                  mandatory
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <Field
                  id="addressAdc"
                  className="form-control"
                  labelField={t('forms.address.labels.addressAdc')}
                  name="addressAdc"
                  placeholder={t('forms.address.labels.placeHolderLine2')}
                  component={InputTextFormik}
                  fieldType="text"
                  mandatory
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <Field
                  id="countryUuid"
                  labelField={t('forms.address.labels.country')}
                  name="countryUuid"
                  component={InputSelectFormik}
                  placeholder=""
                  optionLabel="visualName"
                  optionValue="uuid"
                  options={nationalities}
                  classNamePrefix="dark"
                  mandatory
                  onChangeValue={(value) => handleOnChangeCountry(value, props.values)}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <Field
                  id="cp"
                  className="form-control"
                  labelField={t('forms.address.labels.cp')}
                  name="cp"
                  placeholder=""
                  component={InputTextFormik}
                  fieldType="text"
                  mandatory
                  onChangeValue={(value) => handleOnChangeCp(value)}
                />
              </div>
              <div className="col-8">
                <Field
                  id="city"
                  className="form-control"
                  labelField={t('forms.address.labels.city')}
                  name="city"
                  placeholder=""
                  component={InputTextFormik}
                  fieldType="text"
                  mandatory
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <Field
                  id="province"
                  className="form-control"
                  labelField={t('forms.address.labels.province')}
                  name="province"
                  placeholder=""
                  component={InputTextFormik}
                  fieldType="text"
                  mandatory
                />
              </div>
            </div>
            {!edit && (
              <div className="row">
                <div className="col-12">
                  <Field
                    id="default"
                    labelField={t('forms.address.labels.default')}
                    name="default"
                    component={InputCheckFormik}
                  />
                </div>
              </div>
            )}

            <div className="row justify-content-center">
              <ButtonsContainer className="col-10 col-sm-10 text-center containerButtons">
                <Button
                  type="button"
                  className="btn btn-lg"
                  variant="secondary"
                  onClick={() => onback()}
                >
                  {t('forms.address.buttons.back')}
                </Button>
                <Button type="submit" className="btn btn-lg" variant="primary">
                  {t('forms.address.buttons.save')}
                </Button>
              </ButtonsContainer>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

AddressForm.propTypes = {
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  onback: PropTypes.func,
  edit: PropTypes.bool,
  nationalities: PropTypes.array,
};

const AddressFormF = withFormik({
  mapPropsToValues: (props) => {
    if (props.initialValues) {
      return setInitialValues(props.initialValues);
    }
    return dataModel;
  },
  validationSchema: (props) => {
    return validationDataModel(props.edit);
  },
  handleSubmit: (values, { props, resetForm }) => {
    props.onSubmit(values, resetForm);
  },
  enableReinitialize: true,
  displayName: 'AddressForm',
})(withTranslation()(AddressForm));

export default AddressFormF;
