import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  DesertAnts,
  DesertAntImage,
  DesertAntTet,
  DesertAntText01,
  DesertAntText02,
  DesertAntText03,
  ButtonDesert,
  ShockText,
  ShockText01,
  ShockText02,
  ButtonShock,
  ButtonOther,
  OtherText01,
  OtherText,
  BusinessContainer,
  BackdropBusiness,
} from './clientHome.styled';
import ProductGrid from '../../others/products/productsGrid/productsGrid.view';
import { useSelector } from 'react-redux';
import { getRegionSelector } from '../../../store/selectors/navSelector';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import SliderPagination from '../../utils/CustomPaginatedList/SliderPagination.view';
import { checkBootstrapSizeBreak } from '../../../utils/device/checkDevice';
import { useHistory } from 'react-router-dom';
import PATHS from '../../../constants/paths';

const Home = (props) => {
  const { t } = useTranslation();
  const {
    addToShoppingCart,
    listNewProducts,
    listRemarkableProducts,
    showMoreInfo,
    showPreview,
    goToBusinessPlan,
    showVideo,
  } = props;
  const region = useSelector(getRegionSelector);
  const breakDevice = checkBootstrapSizeBreak();
  let history = useHistory();

  const goToDesertAnts = () => {
    history.push(PATHS.DESERT_ANTS);
  };

  const goToMembership = () => {
    history.push(PATHS.REQUEST_MEMBERSHIP);
  };

  return (
    <>
      <div className="row justify-content-center no-margin">
        <DesertAnts className="col-12 col-md-12 mt-3">
          <DesertAntImage
            breakDevice={breakDevice}
            src={`/assets/themes/${process.env.REACT_APP_THEME}/images/home/Banner-DesertAnts.jpg`}
            alt="img01"
          />
          <DesertAntTet className="row">
            <div className="col-5">
              <DesertAntText01>CRYPTO</DesertAntText01>
              <DesertAntText02>MINING</DesertAntText02>
              <DesertAntText03>MACHINES</DesertAntText03>
              <div>
                <ButtonDesert type="button" onClick={() => goToDesertAnts()}>
                  IR AHORA
                </ButtonDesert>
              </div>
            </div>
          </DesertAntTet>
        </DesertAnts>
      </div>
      <div className="row justify-content-center no-margin">
        <DesertAnts className="col-12 col-md-6 mt-4">
          <DesertAntImage
            src={`/assets/themes/${process.env.REACT_APP_THEME}/images/home/Banner-Shockwave.jpg`}
            alt="img01"
          />
          <ShockText className="row justify-content-end">
            <div className="col-10 col-md-7 text-center">
              <ShockText01>¡RECLUTAMIENTO AUTOMÁTICO!</ShockText01>
              <ShockText02>
                El sistema de marketing más explosivo para el crecimiento de tu negocio
              </ShockText02>
              <div>
                <ButtonShock type="button">PROXIMAMENTE</ButtonShock>
              </div>
            </div>
          </ShockText>
        </DesertAnts>
        <DesertAnts className="col-12 col-md-6 mt-4">
          <DesertAntImage
            src={`/assets/themes/${process.env.REACT_APP_THEME}/images/home/Banner-member.jpg`}
            alt="img01"
          />
          <ShockText className="row justify-content-end">
            <div className="col-10 col-md-12 text-center">
              <ShockText01>MEMBRESÍA ANUAL</ShockText01>
              <ShockText02>¡Inicia tu negocio hoy mismo!</ShockText02>
              <div>
                <ButtonOther type="button" onClick={() => goToMembership()}>
                  Quiero ganar más
                </ButtonOther>
              </div>
            </div>
          </ShockText>
        </DesertAnts>
      </div>
      <div className="row justify-content-center no-margin">
        <DesertAnts className="col-12 col-md-3 mt-4 mb-0 mb-md-4">
          <DesertAntImage
            src={`/assets/themes/${process.env.REACT_APP_THEME}/images/home/Banner-Financiero.jpg`}
            alt="img01"
          />
          <OtherText className="row">
            <div className="col-11">
              <OtherText01>SERVICIOS FINANCIEROS</OtherText01>
              <div>
                <ButtonOther type="button">PROXIMAMENTE</ButtonOther>
              </div>
            </div>
          </OtherText>
        </DesertAnts>
        <DesertAnts className="col-12 col-md-3 mt-4 mb-0 mb-md-4">
          <DesertAntImage
            src={`/assets/themes/${process.env.REACT_APP_THEME}/images/home/Banner-Netshipping.jpg`}
            alt="img01"
          />
          <OtherText className="row">
            <div className="col-11">
              <OtherText01>NETSHIPPING</OtherText01>
              <div>
                <ButtonOther type="button">PROXIMAMENTE</ButtonOther>
              </div>
            </div>
          </OtherText>
        </DesertAnts>
        <DesertAnts className="col-12 col-md-3 mt-4 mb-0 mb-md-4">
          <DesertAntImage
            src={`/assets/themes/${process.env.REACT_APP_THEME}/images/home/Banner-Ignite.jpg`}
            alt="img01"
          />
          <OtherText className="row">
            <div className="col-11">
              <OtherText01>CONTENIDO SOCIAL MEDIA</OtherText01>
              <div>
                <ButtonOther type="button">PROXIMAMENTE</ButtonOther>
              </div>
            </div>
          </OtherText>
        </DesertAnts>
        <DesertAnts className="col-12 col-md-3 mt-4 mb-4">
          <DesertAntImage
            src={`/assets/themes/${process.env.REACT_APP_THEME}/images/home/Banner-Plan.jpg`}
            alt="img01"
          />
          <OtherText className="row">
            <div className="col-11">
              <OtherText01>BOTS DE TRADING</OtherText01>
              <div>
                <ButtonOther type="button">PROXIMAMENTE</ButtonOther>
              </div>
              {/* <div>
                <ButtonOther type="button">PROXIMAMENTE</ButtonOther>
              </div> */}
            </div>
          </OtherText>
        </DesertAnts>
      </div>
      {/* <div className="row justify-content-center no-margin mt-3 mt-md-2">
        <div className="col-12">
          <VideoContainer className="d-flex flex-column justify-content-center align-items-center">
            <Backdrop>
              <Text01>{t('forms.home.text')}</Text01>
              <Text02>{t('forms.home.register')}</Text02>
              <ButtonVideo type="button" onClick={() => showVideo()}>
                {t('forms.home.showVideo')}
              </ButtonVideo>
            </Backdrop>
          </VideoContainer>
        </div>
      </div> */}

      {/* <div className="row justify-content-center no-margin mt-5">
        <div className="col-12 text-center">
          <TitleSection>{t('forms.home.moreProducts')}</TitleSection>
        </div>
      </div>
      <div className="row justify-content-center no-margin mt-2">
        <div className="col-12 text-center">
          <SubTitleSection>{t('forms.home.moreProductsShort')}</SubTitleSection>
        </div>
      </div>
      <div className="row justify-content-center no-margin mt-3 mb-5">
        <div className="col-12">
          <ProductGrid
            numItemPage={10}
            onListProduct={listRemarkableProducts}
            showMoreInfo={showMoreInfo}
            showPreview={showPreview}
            addToShoppingCart={addToShoppingCart}
            region={region}
            hiddenPagination
          />
        </div>
      </div>
      <div className="row justify-content-center no-margin mt-3 mt-md-2">
        <div className="col-12">
          <BusinessContainer className="d-flex flex-column justify-content-center align-items-center">
            <BackdropBusiness>
              <Text01>{t('forms.home.text2')}</Text01>
              <Text02>{t('forms.home.plan')}</Text02>
              <ButtonVideo type="button" onClick={() => goToBusinessPlan()}>
                {t('forms.home.more')}
              </ButtonVideo>
            </BackdropBusiness>
          </BusinessContainer>
        </div>
      </div>
      <div className="row justify-content-center no-margin mt-5">
        <div className="col-12 text-center">
          <TitleSection>{t('forms.home.newProducts')}</TitleSection>
        </div>
      </div>
      <div className="row justify-content-center no-margin mt-2">
        <div className="col-12 text-center">
          <SubTitleSection>{t('forms.home.newProductsShort')}</SubTitleSection>
        </div>
      </div>
      <div className="row justify-content-center no-margin mt-3 mb-5">
        <div className="col-12">
          <ProductGrid
            numItemPage={10}
            onListProduct={listNewProducts}
            showMoreInfo={showMoreInfo}
            showPreview={showPreview}
            addToShoppingCart={addToShoppingCart}
            region={region}
            isSlider
            customPagination={<SliderPagination />}
          />
        </div>
      </div> */}

      {/* <div className="row justify-content-center no-margin mt-3 mb-5">
        <div className="col-12">
          <Carousel
            additionalTransfrom={0}
            arrows={false}
            autoPlay
            autoPlaySpeed={0}
            customTransition="all 5s linear"
            infinite
            minimumTouchDrag={50}
            slidesToSlide={1}
            swipeable
            transitionDuration={5000}
            responsive={{
              desktop: {
                breakpoint: {
                  max: 3000,
                  min: 1024,
                },
                items: 4,
                partialVisibilityGutter: 40,
              },
              mobile: {
                breakpoint: {
                  max: 464,
                  min: 0,
                },
                items: 2,
                partialVisibilityGutter: 30,
              },
              tablet: {
                breakpoint: {
                  max: 1024,
                  min: 464,
                },
                items: 3,
                partialVisibilityGutter: 30,
              },
            }}
          >
            <div>
              <PartnerImage
                src={`/assets/themes/${process.env.REACT_APP_THEME}/images/providers/provider_cronos.png`}
                alt=""
              />
            </div>
            <div>
              <PartnerImage
                src={`/assets/themes/${process.env.REACT_APP_THEME}/images/providers/provider_experience.png`}
                alt=""
              />
            </div>
            <div>
              <PartnerImage
                src={`/assets/themes/${process.env.REACT_APP_THEME}/images/providers/provider_nitrox.png`}
                alt=""
              />
            </div>
            <div>
              <PartnerImage
                src={`/assets/themes/${process.env.REACT_APP_THEME}/images/providers/provider_plant.png`}
                alt=""
              />
            </div>
            <div>
              <PartnerImage
                src={`/assets/themes/${process.env.REACT_APP_THEME}/images/providers/provider_tecnoxx.png`}
                alt=""
              />
            </div>
            <div>
              <PartnerImage
                src={`/assets/themes/${process.env.REACT_APP_THEME}/images/providers/provider_reishi.png`}
                alt=""
              />
            </div>
            <div>
              <PartnerImage
                src={`/assets/themes/${process.env.REACT_APP_THEME}/images/providers/provider_valquer.png`}
                alt=""
              />
            </div>
            <div>
              <PartnerImage
                src={`/assets/themes/${process.env.REACT_APP_THEME}/images/providers/provider_gridinlux.png`}
                alt=""
              />
            </div>
            <div>
              <PartnerImage
                src={`/assets/themes/${process.env.REACT_APP_THEME}/images/providers/provider_hyper.png`}
                alt=""
              />
            </div>
          </Carousel>
        </div>
      </div> */}
    </>
  );
};

Home.propTypes = {
  listNewProducts: PropTypes.func,
  showMoreInfo: PropTypes.func,
  showPreview: PropTypes.func,
  addToShoppingCart: PropTypes.func,
  showVideo: PropTypes.func,
  goToBusinessPlan: PropTypes.func,
  listRemarkableProducts: PropTypes.func,
};

export default Home;
