import styled from 'styled-components';

export const PaginationContainer = styled.div`
  width: 100%;
  /* display: flex; */
  list-style: none;
  border-radius: 0.25rem;
  /* justify-content: center;
  align-items: center; */
`;

export const TextPage = styled.span`
  font-size: 0.875rem;
  margin-left: 5px;
  margin-right: 5px;
`;

export const ButtonAction = styled.button`
  font-size: 0.875rem;
  margin-left: 5px;
  margin-right: 5px;
  background: transparent;
  border: 0px;
  color: #fff;
  opacity: ${({ disabled }) => (disabled ? '0.3' : '1')};

  &:hover {
    color: ${({ disabled }) => (disabled ? '#6c6c6c' : '#469fa3')};
  }
`;

export const IconArrow = styled.i`
  font-size: 0.775rem;
  margin-left: 5px;
  margin-right: 5px;
`;
