import * as Yup from 'yup';

export const dataModel = {
  email: '',
  phone: '',
  name: '',
  nickName: '',
  dataTypeShow: '',
};

export const validationDataModel = Yup.object().shape({
  email: Yup.string().email('forms.validations.email').required('forms.validations.required'),
  phone: Yup.string().optional('forms.validations.required'),
  name: Yup.string().required('forms.validations.required'),
  nickName: Yup.string().required('forms.validations.required'),
  dataTypeShow: Yup.string().required('forms.validations.required'),
});

export const setInitialValues = (initialValues) => {
  const newValues = { ...dataModel };
  if (initialValues) {
    const items = Object.keys(newValues);
    items.forEach((item) => {
      if (initialValues[item]) {
        newValues[item] = initialValues[item];
      }
    });
    return newValues;
  }

  return dataModel;
};
