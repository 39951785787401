import styled, { css } from 'styled-components';

export const LogoEvo = styled.img`
  width: 100%;
  max-width: 120px;
`;

export const SocialButton = styled.button`
  background: transparent;
  border: 0px;
`;

export const IconSocial = styled.i`
  color: #fff;
  font-size: 0.875rem;
`;

export const TitleSection = styled.div`
  font-size: 0.85rem;
  color: #459ca5;
  font-weight: bold;
`;

export const LinkSection = styled.a`
  font-size: 0.75rem;
  color: #848484;
  margin-top: 4px;
  margin-bottom: 4px;

  &:hover {
    color: #459ca5;
    text-decoration: none;
  }
`;

export const SocialRazon = styled.div`
  font-size: 0.7rem;
  color: #848484;
  margin-top: 4px;
  margin-bottom: 4px;
  line-height: 14px;
`;

export const ContainerFooter = styled.div`
  padding-top: 50px;
  padding-bottom: 40px;
  background: #242223;
`;

export const CopyRight = styled.span`
  color: #848484;
  font-size: 0.7rem;
  margin-right: 3px;
`;
