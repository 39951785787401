import React, { Component } from 'react';
import { Switch } from 'react-router-dom';
import AuthRoute from './AuthRoute';
import PATHS from '../constants/paths';
// import NotificationFormPage from '../pages/notification/form';
import CommissionListPage from '../pages/user/commission/list';
/* GENERATOR_INJECT_IMPORT_PAGE */

class CommissionRoutesContainer extends Component {
  componentDidMount() {}

  render() {
    return (
      <Switch>
        <AuthRoute path={PATHS.MY_COMMISSIONS_LIST} component={CommissionListPage} />
        {/* GENERATOR_INJECT_LIST_ROUTE */}
        {/* <AuthRoute exact path={PATHS.CREATE_NOTIFICATION} component={NotificationFormPage} />
        <AuthRoute
          exact
          path={PATHS.UPDATE_NOTIFICATION}
          component={(props) => <NotificationFormPage uuid={props.match.params.uuid} />}
        /> */}

        {/* GENERATOR_INJECT_FORM_ROUTE */}
      </Switch>
    );
  }
}

CommissionRoutesContainer.propTypes = {};

export default CommissionRoutesContainer;
