import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import RequestMemberForm from './requestMembership.view';
import PATHS from '../../../constants/paths';
import * as ProductService from '../../../services/ProductService';
import { getAuthTokenSelector } from '../../../store/selectors/authSelector';

const MembershipFormContainer = (props) => {
  const { t } = useTranslation();
  const auth = useSelector(getAuthTokenSelector);

  const history = useHistory();

  const handleSubmit = async () => {
    const filter = {
      filters: {
        productLineType: 'MEMBER',
      },
    };
    ProductService.listFilter(filter).then((products) => {
      if (products.docs && products.docs.length === 1) {
        const product = products.docs[0];
        ProductService.addProduct(1, product, auth, t).then((products) => {
          history.push(PATHS.PAYMENT_CHECKOUT);
        }); //
      }
    });
  };

  return (
    <div className="row no-margin">
      <div className="col-12">
        <RequestMemberForm buyMembership={() => handleSubmit()} />
      </div>
    </div>
  );
};

MembershipFormContainer.propTypes = {};

export default MembershipFormContainer;
