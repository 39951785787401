import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withFormik, Field } from 'formik';

import { withTranslation } from 'react-i18next';
import { InputTextFormik, InputSelectFormik } from '@arcaitprod/react-forms-components';

import { dataModel, validationDataModel, setInitialValues } from './withdraw.data.form';

import { ButtonsContainer } from '../../../design/styleComponents/globalComponents';
import { Button, Title, Text } from '@arcaitprod/react-basic-components';
import { SpanMaxAmountStyled, MaxAmount, ResumenContainer, ErrorStyled } from './withdraw.styled';
import { CURRENCIES } from '../../../constants/index';

const WithdrawForm = (props) => {
  const { t, handleSubmit, onback, walletInfo, minFee, percentageFee } = props;
  const feePercentage = percentageFee / 100;
  const feeInversePercentage = percentageFee + 1;
  const [feeToPay, setfeeToPay] = useState(minFee.toFixed(8));

  useEffect(() => {
    setfeeToPay(minFee.toFixed(8));
  }, [minFee]);

  const balance = walletInfo && walletInfo.balance ? walletInfo.balance : 0;
  const { values } = props;
  let errorAmount = true;

  const maxFee = parseFloat(balance) - parseFloat(balance) / feeInversePercentage;

  let maxWithdraw = balance
    ? parseFloat(balance) -
      (parseFloat(maxFee) > parseFloat(minFee) ? parseFloat(maxFee) : parseFloat(minFee))
    : 0;

  let totalOperation = parseFloat(values?.amount || 0) + parseFloat(feeToPay);

  let showAlert = false;

  if (balance !== 0 && balance <= minFee) {
    showAlert = true;
  }

  if (totalOperation <= parseFloat(balance) && totalOperation > parseFloat(feeToPay)) {
    errorAmount = false;
  }

  const changeFee = (value) => {
    if (value) {
      const fee = parseFloat(value) * feePercentage;

      if (fee > parseFloat(minFee)) {
        setfeeToPay(fee.toFixed(8));
      }
    } else {
      setfeeToPay(minFee.toFixed(8));
    }
  };

  const disableElement = () => {
    if (balance !== 0 && balance <= minFee) {
      return true;
    }
    return false;
  };

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-12 col-md-11">
          <div className="row mt-5">
            <div className="col-12">
              <Title type="h3" variant="primary">
                {t('forms.wallet.titleWithdraw')}
              </Title>
            </div>
          </div>
          {showAlert && maxWithdraw <= minFee && (
            <div className="row">
              <div className="col-12 mt-2">
                <div className="alert alert-danger" role="alert">
                  {t('forms.wallet.labels.enoughtMoneyWithdraw')}
                </div>
              </div>
            </div>
          )}
          {!disableElement() ? (
            <>
              <div className="row">
                <div className="col-12 col-md-12 col-lg-7 mt-4">
                  <form className="form-horizontal bordered-row" onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-12">
                        <Field
                          id="currency"
                          labelField={t('forms.wallet.labels.currency')}
                          name="currency"
                          component={InputSelectFormik}
                          placeholder=""
                          optionLabel="label"
                          optionValue="code"
                          options={CURRENCIES.map((item) => ({
                            ...item,
                            label: t(item.label),
                          }))}
                          classNamePrefix="dark"
                          mandatory
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <Field
                          id="amount"
                          className="form-control"
                          component={InputTextFormik}
                          labelField={t('forms.wallet.labels.amount')}
                          name="amount"
                          fieldType="text"
                          placeholder=""
                          onChangeValue={(value) => {
                            changeFee(value);
                          }}
                          mandatory
                        />
                        {errorAmount &&
                          values?.amount &&
                          parseFloat(values.amount ? values.amount : 0) > 0 && (
                            <div className="row">
                              <div className="col-12 d-flex justify-content-end">
                                <ErrorStyled>{`${t(
                                  'forms.wallet.labels.errorAmount'
                                )}`}</ErrorStyled>
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <Field
                          id="address"
                          className="form-control"
                          component={InputTextFormik}
                          labelField={t('forms.wallet.labels.address')}
                          name="address"
                          fieldType="text"
                          placeholder=""
                          mandatory
                        />
                      </div>
                    </div>
                    <div className="row justify-content-center">
                      <ButtonsContainer className="col-10 col-sm-10 text-center containerButtons">
                        <Button
                          type="button"
                          className="btn btn-lg"
                          variant="secondary"
                          onClick={() => onback()}
                        >
                          {t('forms.wallet.buttons.back')}
                        </Button>
                        <Button
                          type="submit"
                          className="btn btn-lg"
                          variant="primary"
                          disabled={errorAmount}
                        >
                          {t('forms.wallet.buttons.save')}
                        </Button>
                      </ButtonsContainer>
                    </div>
                  </form>
                </div>
                <div className="col-12 col-md-12 col-lg-5 mb-4 order-first order-lg-last">
                  <ResumenContainer>
                    <div className="row">
                      <div className="col-12 d-flex justify-content-center">
                        <Title type="h5" variant="primary">
                          {t('forms.wallet.labels.resume')}
                        </Title>
                      </div>
                    </div>
                    <div className="row mt-2 mb-2">
                      <SpanMaxAmountStyled className="col-12 d-flex justify-content-end">
                        <div>{`${t('forms.wallet.labels.currentBalance')}:`}</div>
                        <MaxAmount>{`${balance} BTC`}</MaxAmount>
                      </SpanMaxAmountStyled>
                    </div>
                    <hr />
                    <div className="row mt-2 mb-2">
                      <SpanMaxAmountStyled className="col-12 d-flex justify-content-end">
                        <div>{t('forms.wallet.labels.maxAmountWithdraw')}</div>
                        <MaxAmount>{`${maxWithdraw.toFixed(8)} BTC`}</MaxAmount>
                      </SpanMaxAmountStyled>
                    </div>
                    <div className="row mt-2 mb-2">
                      <SpanMaxAmountStyled className="col-12 d-flex justify-content-end">
                        <div>{t('forms.wallet.labels.amount')}</div>
                        <MaxAmount>{`${(values?.amount ? parseFloat(values.amount) : 0).toFixed(
                          8
                        )} BTC`}</MaxAmount>
                      </SpanMaxAmountStyled>
                    </div>
                    <div className="row mt-2 mb-2">
                      <SpanMaxAmountStyled className="col-12 d-flex justify-content-end">
                        <div>{t('forms.wallet.labels.feeToPay')}</div>
                        <MaxAmount>{`${parseFloat(feeToPay).toFixed(8)} BTC`}</MaxAmount>
                      </SpanMaxAmountStyled>
                    </div>
                    <hr />
                    <div className="row mt-2 mb-2">
                      <SpanMaxAmountStyled className="col-12 d-flex justify-content-end">
                        <div>{t('forms.wallet.labels.operationCost')}</div>
                        <MaxAmount>{`${totalOperation.toFixed(8)} BTC`}</MaxAmount>
                      </SpanMaxAmountStyled>
                    </div>
                    <div className="row mt-5 mb-2">
                      <SpanMaxAmountStyled className="col-12 d-flex justify-content-center">
                        <div>{t('forms.wallet.labels.operationCost2')}</div>
                      </SpanMaxAmountStyled>
                    </div>
                  </ResumenContainer>
                </div>
              </div>
            </>
          ) : (
            <div className="row justify-content-center">
              <ButtonsContainer className="col-10 col-sm-10 text-center containerButtons">
                <Button
                  type="button"
                  className="btn btn-lg"
                  variant="secondary"
                  onClick={() => onback()}
                >
                  {t('forms.wallet.buttons.back')}
                </Button>
              </ButtonsContainer>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

WithdrawForm.propTypes = {
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  onback: PropTypes.func,
  walletInfo: PropTypes.object,
  minFee: PropTypes.number,
};

const WithdrawFormF = withFormik({
  mapPropsToValues: (props) => {
    if (props.initialValues) {
      return setInitialValues(props.initialValues);
    }
    return dataModel;
  },
  validationSchema: (props) => {
    return validationDataModel;
  },
  handleSubmit: (values, { props, resetForm }) => {
    props.onSubmit(values, resetForm);
  },
  enableReinitialize: true,
  displayName: 'WithdrawForm',
})(withTranslation()(WithdrawForm));

export default WithdrawFormF;
