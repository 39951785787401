import styled from 'styled-components';

export const Container = styled.div`
  margin: 0;
  /* border: 1px solid #d8d5db; */
  /* border-width: 0px 1px 1px 1px; */
  /* display: flex; */
  flex-direction: column;
  /* width: 100%; */
  align-items: center;

  &:nth-of-type(1) {
    /* border-top-width: 1px; */
  }
`;

export const ContainerData = styled.div`
  width: 100%;
  /* display: flex;
  justify-content: space-around;
  align-items: center; */
`;

export const ContainerDataHidden = styled.div`
  width: 100%;
  padding: 10px;
`;

export const ContainerVisible = styled.div`
  font-size: 18px;
  color: #3e4e50;
  font-weight: 500;
  display: flex;
  min-height: 65px;
  align-items: center;
  padding: 10px;

  &:hover {
    cursor: ${({ expandable }) => (expandable ? 'pointer' : 'normal')};
  }
`;

export const ContainerHiddenEmpty = styled.div`
  overflow: hidden;
  max-height: ${({ ariaExpanded }) => (ariaExpanded ? '0px' : '1000px')};
  transition: ${({ ariaExpanded }) =>
    ariaExpanded ? 'max-height 0.5s cubic-bezier(0, 1, 0, 1)' : 'max-height 1s ease-in-out'};
  background: #fff;
  border: 1px solid #b4b4b4;
  /* min-height: ${({ ariaExpanded }) => (ariaExpanded ? '0px' : '60px')}; */
  display: flex;
  align-items: center;
`;

export const ContainerHidden = styled.div`
  overflow: hidden;
  max-height: ${({ ariaExpanded }) => (ariaExpanded ? '0px' : '1000px')};
  transition: ${({ ariaExpanded }) =>
    ariaExpanded ? 'max-height 0.5s cubic-bezier(0, 1, 0, 1)' : 'max-height 1s ease-in-out'};
  background: #fff;
  border: 1px solid #b4b4b4;
  /* min-height: ${({ ariaExpanded }) => (ariaExpanded ? '0px' : '60px')}; */
  display: flex;
  align-items: center;
`;

export const IconDown = styled.i`
  width: 16px;
  height: 16px;
  align-self: center;
  margin-left: auto;
  transition: all 0.35s ease;
  transform: ${({ ariaExpanded }) => (ariaExpanded ? 'rotateZ(90deg)' : 'rotateZ(0deg)')};
  color: ${({ theme }) => theme.colors.primary}; ;
`;
