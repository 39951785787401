import { store } from '../store/index';
import {
  setFrom,
  cleanNav,
  cleanDashboard,
  setEvoRef,
  cleanEvoRef,
  setFromCampaign,
} from '../store/actions/NavActions';
/**
 * Set from page navigation
 */
export const setFromPage = async (from) => {
  store.dispatch(setFrom(from));
};

/**
 * Clean from page navigation
 */
export const setCleanNav = async () => {
  store.dispatch(cleanNav());
};

/**
 * Clean from page navigation
 */
export const setCleanDashboard = async () => {
  store.dispatch(cleanDashboard());
};

/**
 * Set evoRef
 */
export const setEvolutionRef = async (ref) => {
  store.dispatch(setEvoRef(ref));
};

/**
 * Set evoRef
 */
export const setCampaignRef = async (ref) => {
  store.dispatch(setFromCampaign(ref));
};

/**
 * Clean from page navigation
 */
export const setCleanEvoRef = async () => {
  store.dispatch(cleanEvoRef());
};
