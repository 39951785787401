import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import UsersLevels from './UsersLevels.view';
import UsersVolume from './UsersLevelsVol.view';
import { getUserLevelsSelector } from '../../../../store/selectors/dashboardSelector';
import ContentLoader from 'react-content-loader';
import * as UserServices from '../../../../services/UserService';

const UsersLevelsContainer = (props) => {
  const { volume } = props;
  const dataCache = useSelector(getUserLevelsSelector);
  const [data, setData] = useState(null);

  useEffect(() => {
    let cancel = false;

    // if (!volume && !dataCache) {
    UserServices.getDashboardUserLevels('actual').then((result) => {
      if (cancel) return;
      if (result) {
        setData(result);
      }
    });
    // } else if (dataCache) {
    //   setData(dataCache);
    // }

    return () => {
      cancel = true;
    };
  }, []);

  // useEffect(() => {
  //   let cancel = false;

  //   if (volume) {
  //     setData(dataCache);
  //   }

  //   return () => {
  //     cancel = true;
  //   };
  // }, [dataCache]);

  const renderListLoader = () => {
    return (
      <div className="row">
        <div className="col-12 mt-4">
          <ContentLoader
            speed={1}
            viewBox="0 0 400 160"
            backgroundColor="#0000005c"
            foregroundColor="#1919195c"
            style={{ width: '100%' }}
          >
            <rect x="5" y="1" rx="3" ry="3" width="300" height="5" />
            <rect x="5" y="15" rx="3" ry="3" width="350" height="5" />
          </ContentLoader>
        </div>
      </div>
    );
  };

  return (
    <>
      {data ? (
        volume ? (
          <UsersVolume data={data}></UsersVolume>
        ) : (
          <UsersLevels data={data}></UsersLevels>
        )
      ) : (
        <>{renderListLoader()}</>
      )}
    </>
  );
};

UsersLevelsContainer.propTypes = {
  volume: PropTypes.bool,
};

export default UsersLevelsContainer;
