import React from 'react';
import PropTypes from 'prop-types';
import { Title } from '@arcaitprod/react-basic-components';
import { useTranslation } from 'react-i18next';
import {
  MemberContainerContainer,
  DataContainer,
  MemberTitle,
  ImgMember,
  MemberText,
  MemberList,
  Points,
} from './requestMembership.style';
const RequestMemberShipForm = (props) => {
  const { buyMembership } = props;
  const { t } = useTranslation();

  return (
    <>
      <MemberContainerContainer>
        <div className="row no-margin justify-content-center">
          <DataContainer className="col-11 col-md-10 col-lg-8 mt-4 mt-lg-5 mb-4">
            <div className="row">
              <div className="col-12 col-md-5">
                <ImgMember
                  loading="lazy"
                  src={`/assets/themes/${process.env.REACT_APP_THEME}/images/BoxMembresia.png`}
                  alt="Membresia"
                />
              </div>
              <div className="col-12 col-md-7">
                <MemberTitle className="mt-4 mb-2">Membresía Anual</MemberTitle>
                <MemberTitle>35€</MemberTitle>
                <Points className="mb-4">(25 Puntos)</Points>
                <MemberText className="mb-5">
                  <p>
                    Ser miembro de ARCA permite a nuestros clientes convertirse en promotores
                    durante 1 año y así poder acceder a nuestro plan de compensación.
                  </p>
                  <p>Incluye:</p>
                  <MemberList>
                    <li className="mb-2">
                      - Link de referido con el cual pueden afiliar a nuevos clientes y promotores.
                    </li>
                    <li className="mb-2">- Acceso a calificación de rangos.</li>
                    <li className="mb-2">
                      - Acceso al BackOffice de promotor, donde podrá consultar su estructura
                      comercial y comisiones.
                    </li>
                    <li className="mb-2">
                      - Acceso a recursos de marketing para desarrollar su negocio.
                    </li>
                    <li className="mb-2">- Acceso a formación exclusiva.</li>
                    <li className="mb-2">
                      - Acceso preferente a eventos, viajes, promociones y premios.
                    </li>
                  </MemberList>
                  <p>
                    ¡Adquiere hoy tu membresía y comienza a generar ingresos adicionales con tan
                    solo recomendar nuestros productos y servicios!
                  </p>
                </MemberText>
                <button type="button" className="btn btn-primary" onClick={() => buyMembership()}>
                  Comprar membresia
                </button>
              </div>
            </div>
          </DataContainer>
        </div>
      </MemberContainerContainer>
    </>
  );
};

RequestMemberShipForm.propTypes = {
  buyMembership: PropTypes.func,
};

export default RequestMemberShipForm;
