import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { InfoContainer, LabelInfo, AmountInfoText } from './wallet.styled';
import { Can, AbilityContext } from '../../permissions/Can';
import { USER_PERMISSIONS } from '../../permissions/rolesPermission';
import { withFormik, Field } from 'formik';
import { InputTextFormik } from '@arcaitprod/react-forms-components';
import { dataModel, validationDataModel, setInitialValues } from './waynance.data.form';
import { Button, Title, Text } from '@arcaitprod/react-basic-components';
import { ImgWaynance } from './wallet.styled';

const WaynanceWallet = (props) => {
  const { t } = useTranslation();
  const { handleSubmit } = props;

  const context = useContext(AbilityContext);

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-12 col-md-12 mb-5">
          <InfoContainer>
            <div className="row">
              <div className="col-12 mb-3">
                <div className="row">
                  <div className="col-12 mb-3">{t('forms.wallet.waynance.text01')}</div>
                </div>
                <div className="row">
                  <div className="col-12 mb-3 text-center">
                    <a href="https://waynance.app/registro?comercial=-439" target="_blank">
                      {t('forms.wallet.waynance.link')}
                    </a>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 mb-3">{t('forms.wallet.waynance.text02')}</div>
                </div>
                <div className="row">
                  <div className="col-12 mb-3 ps-5">
                    <ul>
                      <li>{t('forms.wallet.waynance.text03')}</li>
                      <li>{t('forms.wallet.waynance.text04')}</li>
                    </ul>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 mb-3">{t('forms.wallet.waynance.text05')}</div>
                </div>
                <div className="row">
                  <div className="col-12 mb-3">{t('forms.wallet.waynance.text06')}</div>
                </div>

                <div className="row">
                  <div className="col-12 mb-3">
                    <ImgWaynance
                      src={`/assets/themes/${process.env.REACT_APP_THEME}/images/waynance.jpg`}
                      alt=""
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 mt-4">
                    <form className="form-horizontal bordered-row" onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-12">
                          <Field
                            id="waynanceCode"
                            className="form-control"
                            component={InputTextFormik}
                            labelField={t('forms.wallet.waynance.waynanceCode')}
                            name="waynanceCode"
                            fieldType="text"
                            placeholder=""
                            mandatory
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <Field
                            id="email"
                            className="form-control"
                            labelField={t('forms.ignite.labels.email')}
                            name="email"
                            placeholder=""
                            component={InputTextFormik}
                            fieldType="text"
                            mandatory
                          />
                        </div>
                      </div>
                      <div className="row justify-content-center mt-4 mb-5">
                        <div className="col-10 col-sm-10 text-center">
                          <Button type="submit" className="btn btn-lg" variant="primary">
                            {t('forms.wallet.waynance.waynanceSave')}
                          </Button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </InfoContainer>
        </div>
      </div>
    </>
  );
};

WaynanceWallet.propTypes = {};

const WaynanceWalletF = withFormik({
  mapPropsToValues: (props) => {
    if (props.initialValues) {
      return setInitialValues(props.initialValues);
    }
    return dataModel;
  },
  validationSchema: (props) => {
    return validationDataModel;
  },
  handleSubmit: (values, { props, resetForm }) => {
    props.onSubmit(values, resetForm);
  },
  enableReinitialize: true,
  displayName: 'WaynanceWallet',
})(WaynanceWallet);

export default WaynanceWalletF;
