import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  TextStyled,
  RowText,
  RowGraph,
  AmountStyled,
  IconStyled,
  IconTooltipStyled,
} from './GraphAmountMaxMinItem.styled';
import { curveCatmullRom } from 'd3-shape';
import { formatMoney } from '../../../utils/formats/numbers';
import * as exchangeUtils from '../../../utils/formats/exchange';
import {
  FlexibleXYPlot,
  XAxis,
  YAxis,
  HorizontalGridLines,
  VerticalGridLines,
  LineSeries,
  LineMarkSeries,
  AreaSeries,
  Hint,
  VerticalBarSeries,
  Line,
} from 'react-vis';
import 'react-vis/dist/style.css';

const GraphAmountItems = (props) => {
  const { t } = useTranslation();
  const {
    totalAmount,
    textTitle,
    grahpType,
    data,
    itemNameX,
    itemNameY,
    axisNameX,
    axisNameY,
    unit,
    showTotalAmount,
    grahpHeight,
    hintXTranslate,
    hintXTranslateValue,
    hintXTranslateLabel,
    renderGraphValueTooltip,
    showAxisYvalues,
    showDefaultAxisYvalues,
    emptyAxisValue,
    multiLine,
  } = props;
  const [value1, setValue1] = useState(false);
  const xTickValues = [1];
  const yTickValues = [0];
  let grahpData = [{ x: 1, y: 0 }];
  const grahpCero = [{ x: 1, y: 0 }];
  let max = 0;
  let min = 0;

  const generateData = (dataGrahp) => {
    const points = [{ x: 1, y: 0 }];
    if (dataGrahp && dataGrahp.length >= 1) {
      dataGrahp.forEach((item, index) => {
        xTickValues.push(index + 2);
        yTickValues.push(item[itemNameY]);
        points.push({ x: index + 2, y: item[itemNameY] });
        grahpCero.push({ x: index + 2, y: 0 });
        if (item[itemNameY] > max || max === 0) {
          max = item[itemNameY];
        }
        if (item[itemNameY] < min) {
          min = item[itemNameY];
        }
      });
    }
    return points;
  };

  if (multiLine) {
    grahpData = data.map((line) => {
      if (line.points && line.points.length > 0) {
        return generateData(line.points);
      }
    });
  } else {
    grahpData = generateData(data);
  }

  const marginMin = min - (max - min) / 3;
  const marginMax = max + (max - min) / 3;
  const grahpData3 = [{ x: 1, y: marginMax }];
  const grahpData2 = [{ x: 1, y: marginMin }];

  const getLine = (pointForLine, colorLine, id = 'line') => {
    if (grahpType === 'area') {
      return (
        <AreaSeries
          key={id}
          className="area-series-example"
          // curve="curveNatural"
          color="#b5447b"
          fill="#7f3056"
          data={pointForLine}
          onNearestX={(value) => {
            setValue1(value);
          }}
        />
      );
    } else if (grahpType === 'markLine') {
      return (
        <LineMarkSeries
          key={id}
          className="fourth-series"
          color={colorLine}
          // curve={curveCatmullRom.alpha(0.5)}
          data={pointForLine}
          size={2}
          onValueMouseOver={(value, event) => {
            setValue1(value);
          }}
        />
      );
    } else if (grahpType === 'bar') {
      return (
        <VerticalBarSeries
          key={id}
          className="fourth-series"
          color={colorLine}
          // curve={curveCatmullRom.alpha(0.5)}
          data={pointForLine}
          onValueMouseOver={(value, event) => {
            setValue1(value);
          }}
        />
      );
    } else {
      return (
        <LineSeries
          key={id}
          className="fourth-series"
          color={colorLine}
          // curve={curveCatmullRom.alpha(0.5)}
          data={pointForLine}
          onNearestX={(value) => {
            setValue1(value);
          }}
        />
      );
    }
  };

  const renderGrahp = () => {
    if (multiLine) {
      return grahpData.map((line, index) => {
        return getLine(line, data[index].colorLine, data[index].id);
      });
    } else {
      return getLine(grahpData, '#3897d2');
    }
  };

  const getAxisData = (dataGraph, index, axis, transalate) => {
    if (dataGraph && dataGraph[index - 1]) {
      if (axis === 'x') {
        const val = dataGraph[index - 1][itemNameX];
        if (transalate && hintXTranslateValue && hintXTranslateLabel && hintXTranslate) {
          const tran = hintXTranslate.find((item) => `${item[hintXTranslateValue]}` === `${val}`);
          if (tran) {
            return t(tran[hintXTranslateLabel]);
          } else {
            return val;
          }
        } else {
          return val;
        }
      } else {
        let values;
        if (showAxisYvalues && !emptyAxisValue) {
          values = dataGraph[index - 1][itemNameY];
        }
        return values;
      }
    }
  };

  const getAxisName = (index, axis, transalate = false) => {
    if (index === 0) {
      return '0';
    }
    if (multiLine) {
      // return data.map((line) => {
      // console.log(line);
      for (const lines of data) {
        if (lines.points && lines.points.length > 0) {
          return getAxisData(lines.points, index, axis, transalate);
        }
      }
      // });
    } else {
      return getAxisData(data, index, axis, transalate);
    }
  };

  return (
    <>
      <RowText className="row align-items-center">
        <div className="col-12">
          <div className="row">
            <div className="col-12 text-center">
              <TextStyled>{textTitle}</TextStyled>
            </div>
          </div>
          {showTotalAmount && (
            <div className="row">
              <div className="col-12 text-center">
                <AmountStyled>
                  {`${formatMoney(totalAmount)} `}
                  <IconStyled>{unit || ''}</IconStyled>
                </AmountStyled>
              </div>
            </div>
          )}
        </div>
      </RowText>
      <RowGraph className="row align-items-center" grahpHeight={grahpHeight}>
        <div className="col-12">
          <FlexibleXYPlot
            height={grahpHeight || 200}
            onMouseLeave={() => {
              setValue1(false);
            }}
          >
            <HorizontalGridLines style={{ stroke: '#6d6d6d' }} />
            <VerticalGridLines style={{ stroke: '#6d6d6d' }} />
            <XAxis
              title={axisNameX}
              style={{
                line: { stroke: '#dadada' },
                text: { fill: '#dadada', fontSize: '0.5rem' },
              }}
              tickFormat={(v, index) => getAxisName(index, 'x', hintXTranslate ? true : false)}
              tickLabelAngle={-25}
              tickValues={xTickValues}
            />
            <YAxis
              title={axisNameY}
              style={{
                line: { stroke: '#dadada' },
                text: { fill: '#dadada', fontSize: '0.5rem' },
              }}
              tickFormat={
                showAxisYvalues && showDefaultAxisYvalues === false
                  ? (v, index) => getAxisName(index, 'y')
                  : undefined
              }
              tickValues={
                showAxisYvalues && showDefaultAxisYvalues === false ? yTickValues : undefined
              }
            />
            <LineSeries
              className="marginMin"
              color="#ccc"
              // curve={curveCatmullRom.alpha(0.5)}
              data={grahpData2}
            />
            <LineSeries
              className="marginMax"
              color="#ccc"
              // curve={curveCatmullRom.alpha(0.5)}
              data={grahpData3}
            />

            <LineSeries
              className="ceroLine"
              // color="#fe0"
              style={{
                // note that this can not be translated to the canvas version
                strokeDasharray: '10 10',
                stroke: '#ff7b00',
                opacity: '0.7',
              }}
              // curve={curveCatmullRom.alpha(0.5)}
              data={grahpCero}
            />
            {renderGrahp()}

            {value1 ? (
              <Hint value={value1}>
                <div className="tooltip-chart">
                  {/* <div className="tooltip-title-chart">
                    {hintXTranslate
                      ? getAxisName(value1.x - 1, 'x', true)
                      : getAxisName(value1.x - 1, 'x', false)}
                  </div> */}
                  <div className="tooltip-body-chart">
                    {renderGraphValueTooltip
                      ? renderGraphValueTooltip(unit, value1.y)
                      : `${value1.y}`}
                    <IconTooltipStyled>{unit || ''}</IconTooltipStyled>
                  </div>
                </div>
              </Hint>
            ) : null}
          </FlexibleXYPlot>
        </div>
      </RowGraph>
    </>
  );
};

GraphAmountItems.propTypes = {
  totalAmount: PropTypes.number,
  textTitle: PropTypes.string,
  grahpType: PropTypes.string,
  data: PropTypes.array,
  itemNameX: PropTypes.string.isRequired,
  itemNameY: PropTypes.string.isRequired,
  axisNameX: PropTypes.string,
  axisNameY: PropTypes.string,
  unit: PropTypes.string,
  showTotalAmount: PropTypes.bool,
  grahpHeight: PropTypes.number,
  hintXTranslate: PropTypes.array,
  hintXTranslateValue: PropTypes.string,
  hintXTranslateLabel: PropTypes.string,
  renderGraphValueTooltip: PropTypes.func,
  showAxisYvalues: PropTypes.bool,
  showDefaultAxisYvalues: PropTypes.bool,
  emptyAxisValue: PropTypes.bool,
  multiLine: PropTypes.bool,
};

export default GraphAmountItems;
