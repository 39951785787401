import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import MyDesertAnt from './my.view';
import * as UserService from '../../../../services/UserService';
import { useHistory } from 'react-router-dom';

const MyDesertAnts = (props) => {
  const { uuid } = props;
  const { t } = useTranslation();
  const [data, setData] = useState(null);
  let history = useHistory();

  const getpackage = (mch) => {
    let pac = '';
    if (mch.owner.productName.includes('1/128')) {
      pac = '128';
    }
    if (mch.owner.productName.includes('1/64')) {
      pac = '64';
    }
    if (mch.owner.productName.includes('1/32')) {
      pac = '32';
    }
    if (mch.owner.productName.includes('1/16')) {
      pac = '16';
    }
    if (mch.owner.productName.includes('1/8')) {
      pac = '8';
    }
    if (mch.owner.productName.includes('1/4')) {
      pac = '4';
    }
    if (mch.owner.productName.includes('1/2')) {
      pac = '2';
    }
    if (mch.owner.productName.includes('Comple')) {
      pac = '1';
    }
    return pac;
  };

  useEffect(() => {
    let cancel = false;

    UserService.getDataDesertAnts().then((result) => {
      if (cancel) return;
      const dataMachine = {};
      if (result?.dataMachine && result.dataMachine.length > 0) {
        for (const mch of result.dataMachine) {
          const pac = getpackage(mch);
          if (dataMachine[mch.uuid]) {
            dataMachine[mch.uuid].slots = dataMachine[mch.uuid].slots + 1;
            dataMachine[mch.uuid][pac] = dataMachine[mch.uuid][pac] + 1;
          } else {
            dataMachine[mch.uuid] = {
              name: mch.name,
              serials: mch.serial,
              product: mch.owner.productName,
              slots: 1,
              128: 0,
              64: 0,
              32: 0,
              16: 0,
              8: 0,
              4: 0,
              2: 0,
              1: 0,
            };
            dataMachine[mch.uuid][pac] = 1;
          }
        }
        setData(dataMachine);
      }
    });

    return () => {
      cancel = true;
    };
  }, [uuid]);

  return (
    <>
      <MyDesertAnt data={data}></MyDesertAnt>
    </>
  );
};

MyDesertAnts.propTypes = {
  uuid: PropTypes.string,
};

export default MyDesertAnts;
