import React, { Component } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';

import Login from './pages/user/login';
import TermConditions from './pages/others/termAndConditions';
import UnilevelTree from './pages/user/unilevelTree';
import ClientHome from './pages/user/clientHome';
import PATHS from './constants/paths';
// import * as GeneralServices from './services/GeneralServices';
// import moment from 'moment-timezone';
import AuthRoute from './routes/AuthRoute';
import CustomRoute from './routes/CustomRoute';
import MaintenancePage from './pages/others/maintenance';
import WalletsPage from './pages/user/wallet';
import WalletSettingsPage from './pages/user/wallet/wallet';
import { AbilityContext } from './components/permissions/Can';
import { buildAbilityFor } from './components/permissions/ability';
import NotificationRoutes from './routes/NotificationRoutes';
import LoginImpersonate from './pages/user/loginImpersonate';
import WithdrawPage from './pages/user/withdraw';
import NewsDetailsPage from './pages/others/news';
import UserSettings from './pages/user/userSettings';
import AddressesRoutes from './routes/AddressesRoutes';
import ProductsRoutes from './routes/ProductsRoutes';
import CheckOut from './pages/user/checkOut';
import OrderRoutes from './routes/OrderRoutes';
import AboutUsPage from './pages/others/aboutUs';
import DesertAntsPage from './pages/others/desertAnts';
import CashFlowsRoutes from './routes/CashFlowsRoutes';
import DashboardPage from './pages/user/dashboard';
import NitroxActivation from './pages/others/nitrox';
import SubscriptionsRoutes from './routes/SubscriptionsRoutes';
import RequestMemberShipPage from './pages/user/requestMembership';
import ExchangePage from './pages/user/exchangeGl';
import BusinessPlanPage from './pages/others/businessPlan';
import ContactPage from './pages/others/contact';
import DocsRoutes from './routes/DocsRoutes';
import CommissionRoutes from './routes/CommissionRoutes';
import RegisterPage from './pages/user/register';
import IgnitePage from './pages/others/ignite';
import ShockwavePage from './pages/others/shockwave';
import FromCampaignAction from './pages/others/shockwave/fromCampaign';
import CheckOutCompleted from './pages/user/checkOutCompleted';
import FreeCoursePage from './pages/others/freeCourse';
import BillingAddressSettings from './pages/user/billingAddressSettings';
import BillingConfigAddressSettings from './pages/user/billingAddress';
import MyDesertAntsPage from './pages/user/desertAnts';

/* GENERATOR_INJECT_IMPORT_ROUTES */

const ability = buildAbilityFor();

class App extends Component {
  constructor(props) {
    super(props);
    // this.getStatus = this.getStatus.bind(this);
    // const status = this.getStatus().then((result) => {
    //   return result;
    // });
    // console.log(status);
    this.state = {
      dataTFA: undefined,
      maintenance: false,
    };
  }

  componentDidMount() {
    // GeneralServices.setTimeZone({ timeZone: moment.tz.guess() });
    // this.getStatus();
  }

  // getStatus = async () => {
  //   const result = await GeneralServices.getPlatformStatus();
  //   if (result?.maintenance !== undefined && result?.maintenance === true) {
  //     this.setState({ maintenance: result.maintenance });
  //   }
  // };

  render() {
    const { maintenance } = this.state;
    return (
      <>
        <AbilityContext.Provider value={ability}>
          {maintenance ? (
            <Router>
              <MaintenancePage />
            </Router>
          ) : (
            <Router>
              <Switch>
                <AuthRoute
                  exact
                  path={PATHS.REQUIRED_BILLING_ADDRESS}
                  component={BillingConfigAddressSettings}
                />
                <AuthRoute
                  exact
                  path={PATHS.MY_BILLING_ADDRESS}
                  component={BillingAddressSettings}
                />
                {/* <CustomRoute
                  exact
                  path={PATHS.LOGIN_IMPERSONATE}
                  component={(props) => <LoginImpersonate token={props.match.params.token} />}
                /> */}
                {/* <CustomRoute
                  exact
                  path={PATHS.FREE_COURSE}
                  component={(props) => <FreeCoursePage />}
                /> */}
                <CustomRoute
                  exact
                  path={PATHS.REGISTER}
                  component={(props) => <RegisterPage token={props.match.params.sponsorUId} />}
                />
                {/* <CustomRoute
                  exact
                  path={PATHS.NEWS_DETAILS}
                  component={(props) => <NewsDetailsPage uuid={props.match.params.uuid} />}
                /> */}
                <CustomRoute
                  exact
                  path={PATHS.EXTERNAL_CONFIRM_LOGIN}
                  component={(props) => <Login token={props.match.params.token} />}
                />
                {/* <CustomRoute
                  exact
                  path={PATHS.NITROX_REACTIVATION}
                  component={(props) => (
                    <NitroxActivation token={props.match.params.token} reactivation />
                  )}
                /> */}
                {/* <CustomRoute
                  exact
                  path={PATHS.NITROX_ACTIVATION}
                  component={(props) => <NitroxActivation token={props.match.params.token} />}
                /> */}

                {/* <CustomRoute
                  exact
                  path={PATHS.FROM_CAMPAIGN}
                  component={(props) => <FromCampaignAction token={props.match.params.token} />}
                /> */}
                {/* <CustomRoute exact path={PATHS.IGNITE} component={(props) => <IgnitePage />} /> */}
                {/* <CustomRoute
                  exact
                  path={PATHS.SHOCKWAVE}
                  component={(props) => <ShockwavePage />}
                /> */}

                <CustomRoute
                  exact
                  path={PATHS.REQUEST_MEMBERSHIP}
                  component={RequestMemberShipPage}
                />
                {/* <CustomRoute exact path={PATHS.CONTACT} component={ContactPage} /> */}
                {/* <CustomRoute exact path={PATHS.BUSINESS_PLAN} component={BusinessPlanPage} /> */}
                <CustomRoute exact path={PATHS.ABOUT_US} component={AboutUsPage} />
                <CustomRoute exact path={PATHS.DESERT_ANTS} component={DesertAntsPage} />
                <AuthRoute exact path={PATHS.MY_DESERT_ANTS} component={MyDesertAntsPage} />
                <CustomRoute exact path={PATHS.LOGIN} component={Login} />
                {/* <AuthRoute
                  exact
                  path={PATHS.EXCHANGE_GAL}
                  component={(props) => (
                    <ExchangePage
                      currencyFrom={props.match.params.from}
                      currencyTo={props.match.params.to}
                    />
                  )}
                /> */}

                {/* <AuthRoute exact path={PATHS.WITHDRAW} component={WithdrawPage} /> */}
                <AuthRoute exact path={PATHS.WALLETS} component={WalletsPage} />
                <AuthRoute exact path={PATHS.WALLETS_SETTING} component={WalletSettingsPage} />
                <AuthRoute path={PATHS.UNI_TREENODES} component={UnilevelTree} />

                <CustomRoute path={PATHS.HOME} component={ClientHome} />
                {/* <CustomRoute path={PATHS.CLIENT_HOME} component={ClientHome} /> */}
                <CustomRoute path={PATHS.TERM_AND_CONDITIONS} component={TermConditions} />
                <AuthRoute exact path={PATHS.MY_SETTINGS} component={UserSettings} />
                <AuthRoute path={PATHS.MY_ADDRESSES} component={AddressesRoutes} />
                {/* <AuthRoute path={PATHS.NOTIFICATION} component={NotificationRoutes} /> */}
                <AuthRoute path={PATHS.MY_ORDER} component={OrderRoutes} />
                <CustomRoute path={PATHS.PRODUCTS} component={ProductsRoutes} />
                <AuthRoute path={PATHS.CHECKOUT_COMPLETED} component={CheckOutCompleted} />
                <AuthRoute path={PATHS.PAYMENT_CHECKOUT} component={CheckOut} />
                <AuthRoute path={PATHS.MY_CASHFLOW} component={CashFlowsRoutes} />
                <AuthRoute path={PATHS.MY_DASHBOARD} component={DashboardPage} />
                {/* <AuthRoute path={PATHS.MY_SUBSCRIPTIONS} component={SubscriptionsRoutes} /> */}
                {/* <AuthRoute path={PATHS.DOCS} component={DocsRoutes} /> */}
                <AuthRoute path={PATHS.MY_COMMISSIONS} component={CommissionRoutes} />
                {/* GENERATOR_INJECT_ROUTES */}

                {/* <CustomRoute exact path={PATHS.LOGOUT} component={LogoutContainer} />
        <CustomRoute exact path={PATHS.ERROR} component={ErrorPage} />  */}
                <Route path={PATHS.INIT} render={() => <Redirect to={PATHS.HOME} />} />
              </Switch>
            </Router>
          )}
        </AbilityContext.Provider>
      </>
    );
  }
}

App.propTypes = {};

export default App;
