import * as Yup from 'yup';
import * as exchangeUtils from '../../../utils/formats/exchange';

export const dataModel = {
  amount: '',
};

export const validationDataModel = (balanceFrom, currencyFrom) => {
  Yup.addMethod(Yup.number, 'amountBalance', function (errorMessage) {
    const test = this.test(`test-amountBalance`, errorMessage, function (value) {
      const { path, createError, parent } = this;
      let result = true;
      const valueSatoshi = exchangeUtils.crypto2Satoshi(currencyFrom, value);
      if (valueSatoshi > balanceFrom) {
        result = createError({
          path,
          message: 'forms.wallet.errorEnoughtBalance',
          args: {
            min: 'BTC',
          },
        });
      }

      return result;
    });
    return test;
  });

  return Yup.object().shape({
    amount: Yup.number().amountBalance().required('forms.validations.required'),
  });
};

export const setInitialValues = (initialValues) => {
  const newValues = { ...dataModel };
  if (initialValues) {
    const items = Object.keys(newValues);
    items.forEach((item) => {
      if (initialValues[item]) {
        newValues[item] = initialValues[item];
      }
    });
    return newValues;
  }

  return dataModel;
};
