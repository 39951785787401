import React from 'react';
import TermAndConditions from '../../../components/others/termAndConditions';
import Layout from '../../../components/layouts/simpleLayout';

const TermAndConditionsPage = () => {
  return (
    <Layout
      showUserMenu={false}
      body={
        <>
          <TermAndConditions />
        </>
      }
    />
  );
};

TermAndConditionsPage.propTypes = {};

export default TermAndConditionsPage;
