import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { ButtonCountPlus, ButtonCountMinus, ContainerButtons } from './styled';

class InputNumber extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value !== undefined && this.props.value !== null ? this.props.value : '',
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setValue(this.props.value);
    }
  }

  setValue(newValue) {
    this.setState({ value: newValue });
  }

  handlerOnchangeValue(newValue) {
    const { onChangeValue } = this.props;
    this.setValue(newValue);
    if (onChangeValue) {
      onChangeValue(newValue);
    }
  }

  handlerPressButton = (add) => {
    const { allowNegative, min } = this.props;
    let newValue = this.state.value + (add ? 1 : -1);
    if (
      newValue >= 0 ||
      (newValue < 0 && (allowNegative === undefined || allowNegative === true))
    ) {
      this.handlerOnchangeValue(min !== undefined && newValue < min ? min : newValue);
    }
  };

  render() {
    const {
      id,
      name,
      labelField,
      placeholder,
      className,
      divClassName,
      readOnly,
      mandatory,
      maxLength,
      visible,
      tooltip,
      decimals,
      classNameContainer,
      showButtons,
      buttonsClassName,
      allowNegative,
      min,
      max,
    } = this.props;

    return (
      <>
        {/* // <div
      //   id={`${id}_Container`}
      //   className={`${
      //     visible !== undefined && visible === false ? 'field-hidden' : ''
      //   } form-group ${classNameContainer || ''}`}
      // > */}
        {/* {labelField && (
          <div className={`labelDiv ${divClassName} no-padding`} title={tooltip || labelField}>
            <label
              id={`${id}_labelField`}
              className={mandatory ? 'control-label mandatory' : 'control-label'}
              htmlFor={id}
            >
              {`${labelField}${mandatory ? ' *' : ''}`}
            </label>
          </div>
        )} */}
        <div className={`${divClassName} no-padding ${showButtons ? 'd-flex' : ''}`}>
          <NumberFormat
            id={id}
            name={name}
            maxLength={maxLength || 255}
            placeholder={placeholder}
            className={`inputForm inputText ${className}`}
            readOnly={readOnly}
            allowNegative={allowNegative !== undefined ? allowNegative : true}
            decimalScale={decimals !== undefined ? decimals : undefined}
            value={this.state.value}
            onChange={async (event) => {
              if (event.target.value !== this.state.value) {
                if (min !== undefined && event.target.value < min) {
                  this.handlerOnchangeValue(min);
                } else if (max !== undefined && event.target.value > max) {
                  this.handlerOnchangeValue(max);
                } else {
                  this.handlerOnchangeValue(event.target.value ? event.target.value : 0);
                }
              }
            }}
          />
          {showButtons && (
            <ContainerButtons>
              <ButtonCountPlus
                type="button"
                className={`${buttonsClassName ? `${buttonsClassName}` : ''}`}
                onClick={() => this.handlerPressButton(true)}
              >
                +
              </ButtonCountPlus>
              <ButtonCountMinus
                type="button"
                className={`${buttonsClassName ? `${buttonsClassName}` : ''}`}
                onClick={() => this.handlerPressButton(false)}
              >
                -
              </ButtonCountMinus>
            </ContainerButtons>
          )}
        </div>
        {/* </div> */}
      </>
    );
  }
}

InputNumber.propTypes = {
  name: PropTypes.any,
  value: PropTypes.any,
  placeholder: PropTypes.string,
  id: PropTypes.string.isRequired,
  labelField: PropTypes.string,
  className: PropTypes.string,
  divClassName: PropTypes.string,
  readOnly: PropTypes.bool,
  mandatory: PropTypes.bool,
  maxLength: PropTypes.number,
  visible: PropTypes.bool,
  tooltip: PropTypes.string,
  format: PropTypes.string,
  decimals: PropTypes.number,
  onChangeValue: PropTypes.func,
  classNameContainer: PropTypes.string,
  showButtons: PropTypes.bool,
  buttonsClassName: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  allowNegative: PropTypes.bool,
  min: PropTypes.number,
};

export default InputNumber;
