import React from 'react';
import { useTranslation } from 'react-i18next';
// import PropTypes from 'prop-types';
import {
  Title,
  Text,
  ContainerNewAge,
  TitleNewAge,
  ImgNewAge,
  LabelNewAge,
  ContainerBoss,
  ContainerBoss01,
  BossImage,
  BossImgContainer,
  ContainerBossItemLeft,
  ContainerBossItemRight,
  BossTextContainer,
  BossName,
  BossStatus,
  BossText,
  BossImage02,
  ImgBanner,
  BannerMember,
  BannerText,
  MemberText01,
  MemberText02,
  ButtonOther,
} from './aboutUs.styled';
import ReactPlayer from 'react-player';
import { useHistory } from 'react-router-dom';
import PATHS from '../../../constants/paths';
import { checkBootstrapSizeBreak } from '../../../utils/device/checkDevice';

const AboutUs = (props) => {
  const { t, i18n } = useTranslation();
  let history = useHistory();
  const breakDevice = checkBootstrapSizeBreak();

  const language = i18n.language;
  let srcVideo = '';
  let srcVideo2 = '';

  if (language === 'es') {
    srcVideo = 'https://www.youtube.com/watch?v=Zb5Hopjl86k';
  } else {
    srcVideo = 'https://www.youtube.com/watch?v=Zb5Hopjl86k';
  }

  if (language === 'es') {
    srcVideo2 = 'https://youtu.be/hgNNIXt5CrQ';
  } else {
    srcVideo2 = 'https://youtu.be/hgNNIXt5CrQ';
  }

  const goToMembership = () => {
    history.push(PATHS.REQUEST_MEMBERSHIP);
  };

  return (
    <>
      <div className="row no-margin justify-content-center mt-4">
        <div className="col-12 col-md-12 px-3 px-lg-5">
          <ImgBanner
            src={`/assets/themes/${process.env.REACT_APP_THEME}/images/Nosotros.jpg`}
            alt=""
          />
        </div>
      </div>
      <div className="row no-margin mt-3 mt-lg-5">
        <div className="col-12 col-md-12 col-lg-6 ps-3 ps-lg-5 mb-2 mb-lg-5">
          <Title className="mb-3">{t('pages.aboutUs.title')}</Title>
          <Text className="pe-1  mb-3 mt-3">{t('pages.aboutUs.text01')}</Text>
          <Text className="pe-1  mb-3 mt-3">{t('pages.aboutUs.text02')}</Text>

          <Text className="ps-4  mb-3 mt-3">{t('pages.aboutUs.text02a')}</Text>
          <Text className="ps-4  mb-3 mt-3">{t('pages.aboutUs.text02b')}</Text>
          <Text className="ps-4  mb-3 mt-3">{t('pages.aboutUs.text02c')}</Text>

          <Text className="pe-1  mb-4 mt-3">{t('pages.aboutUs.text03')}</Text>
        </div>
        <div className="col-12 col-md-12 col-lg-6 pe-3 pe-lg-5 mb-4 mb-lg-5">
          <ReactPlayer className="ratio ratio-16x9 videoShow" controls url={srcVideo}></ReactPlayer>
        </div>
      </div>
      <div className="row no-margin mt-2 mt-lg-4">
        <div className="col-12 col-md-12 col-lg-6 ps-3 ps-lg-5 mb-4 mb-lg-5">
          <ReactPlayer
            className="ratio ratio-16x9 videoShow"
            controls
            url={srcVideo2}
          ></ReactPlayer>
        </div>
        <div className="col-12 col-md-12 col-lg-6 pe-3 pe-lg-5 mb-2 mb-lg-5">
          <Title className="ps-2 pe-3 mb-3">{t('pages.aboutUs.text03')}</Title>
          <Text className="ps-2 pe-3 mb-3 mt-3">{t('pages.aboutUs.text04')}</Text>
          <Text className="ps-2 pe-3 mb-3 mt-3">{t('pages.aboutUs.text05')}</Text>
          <Text className="ps-2 pe-3 mb-3 mt-3">{t('pages.aboutUs.text06')}</Text>
          <Text className="ps-2 pe-3 mb-3 mt-3">{t('pages.aboutUs.text07')}</Text>
        </div>
      </div>
      <div className="row justify-content-center no-margin mt-2 pb-5">
        <div className="col-12 col-md-12 px-3 px-lg-5">
          <BannerMember
            src={`/assets/themes/${process.env.REACT_APP_THEME}/images/Banner_Membresia.jpg`}
            alt="img01"
          />
          <BannerText className="row" breakDevice={breakDevice}>
            <div className="col-10 col-md-12 text-center">
              <MemberText01>MEMBRESÍA ANUAL</MemberText01>
              <MemberText02>¡Inicia tu negocio hoy mismo!</MemberText02>
              <div>
                <ButtonOther type="button" onClick={() => goToMembership()}>
                  Quiero ganar más
                </ButtonOther>
              </div>
            </div>
          </BannerText>
        </div>
      </div>
    </>
  );
};

AboutUs.propTypes = {};

export default AboutUs;
