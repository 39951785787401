import { handleActions } from 'redux-actions';

import {
  SET_NAV_FROM,
  CLEAN_NAV,
  SET_TIME_ZONE,
  SET_LANGUAGE,
  SET_REGION,
  GET_EU_COUNTRIES,
  GET_PACKAGES_GROUPS,
  SET_TIME_PACKAGES_GROUPS,
  GET_ALL_COUNTRIES,
  SET_EVO_REF,
  CLEAN_EVO_REF,
  SET_CAMPAIGN,
} from '../../constants/index';

export const nav = handleActions(
  {
    [CLEAN_NAV]: (state) => ({ ...state, from: undefined }),
    [SET_NAV_FROM]: (state, action) => ({ ...state, from: action.payload }),
    [SET_TIME_ZONE]: (state, action) => ({
      ...state,
      timeZone: action.payload.timeZone,
    }),
    [SET_LANGUAGE]: (state, action) => ({
      ...state,
      language: action.payload.lang,
    }),
    [SET_REGION]: (state, action) => ({
      ...state,
      region: action.payload.region,
    }),
    [GET_EU_COUNTRIES]: (state, action) => ({
      ...state,
      regions: action.payload.data,
    }),
    [GET_PACKAGES_GROUPS]: (state, action) => ({
      ...state,
      groups: action.payload.data,
    }),
    [SET_TIME_PACKAGES_GROUPS]: (state, action) => ({
      ...state,
      groupsTime: action.payload,
    }),
    [GET_ALL_COUNTRIES]: (state, action) => ({
      ...state,
      countries: action.payload.data,
    }),
    [SET_EVO_REF]: (state, action) => ({
      ...state,
      evoRef: action.payload,
    }),
    [CLEAN_EVO_REF]: (state, action) => ({
      ...state,
      evoRef: undefined,
    }),
    [SET_CAMPAIGN]: (state, action) => ({
      ...state,
      campaign: action.payload,
    }),
  },
  {}
);
