import styled from 'styled-components';

export const LabelStatus = styled.label`
  color: #469fa3;
  font-size: 0.85rem;
  margin-left: 10px;
`;

export const ContainerLegend = styled.div`
  border: 1px solid #469fa3;
  padding: 10px !important;
  border-radius: 10px;
`;

export const Legend = styled.div`
  color: #469fa3;
  font-size: 0.85rem;
`;

export const ImageLegend = styled.img`
  height: 30px;
`;

export const SwitchStatus = styled.input`
  background-color: ${({ statusCheck }) =>
    statusCheck ? '#469fa3 !important' : '#fff !important'};
  border-color: ${({ statusCheck }) => (statusCheck ? '#469fa3 !important' : '#bfbfbf !important')};
  box-shadow: rgb(74 74 104 / 20%) 0px 2px 2px 0px inset !important;
  /* width: 3rem !important; */
`;

export const RefererLink = styled.div`
  font-size: 1rem;
  font-weight: normal;
  color: #fff;
  text-decoration: underline;
`;
