import React from 'react';
import PropTypes from 'prop-types';
// import { useTranslation } from 'react-i18next';
import {
  RowPrincipal,
  ColNopadding,
  ImageAvatar,
  Row,
  ColNopaddingIMG,
  ColCenter,
  Rank,
} from './styled';
import { RANKS } from '../../../../../constants/index';

// {
//   "rank": 1,
//   "promoter": true,
//   "directFirstLevel": true,
// }

const SimpleTreeNode = (props) => {
  const { data } = props;
  // const { t } = useTranslation();
  let icon = ``;
  // -- 1. Reg, Evo: pointsPersonal = 0, promoter = false
  // 2. Promotor y Kyc , promoter = true && maxHistoryRank === 0 && rank === 0
  // -- 3. Cliente: pointsPersonal > 0, promoter = false
  // 4. Promoter No calificad: promoter = true && rank === 0 && maxHistoryRank > 0

  // if (data.info.promoter) {
  if (data.info.rank > 1) {
    icon = `ranks/${RANKS[`L${data.info.rank}`].image}.png`; // imagen rango
  } else {
    icon = `arca_logo.png`;
    // if (data.info.maxHistoryRank && data.info.maxHistoryRank > 0) {
    // icon = `ranks/SinRango.png`;
    // } else {
    //   icon = `ranks/Activo.png`;
    // }
  }
  // } else {
  //   if (data.info.pointsPersonal && data.info.pointsPersonal > 0) {
  //     icon = `ranks/Cliente.png`;
  //   } else {
  //     icon = `ranks/Registrado.png`;
  //   }
  // }

  // images / arca_logo.png;
  // const currentLevel = USER_LEVELS.find(
  //   (element) => element.value === data.info.rank
  // );
  // const level1 = firstLevel + 1;
  return (
    <RowPrincipal id={data.id} className="row nodeSimple">
      <ColNopaddingIMG className="col-2 col-md-1 col-lg-1 d-none d-md-block d-flex justify-content-center align-items-center">
        <ImageAvatar
          alt="Avatar"
          src={`/assets/themes/${process.env.REACT_APP_THEME}/images/${icon}`}
        ></ImageAvatar>
      </ColNopaddingIMG>
      {/* {currentLevel && (
        <>
          <ColNopaddingRANK className="col-2 col-md-2 col-lg-2 no-padding">
            <ImageRank
              alt={t(currentLevel.label)}
              title={t(currentLevel.label)}
              src={`/assets/images/images-resources/${currentLevel?.image}`}
            ></ImageRank>
            <RankText
              title={t(currentLevel.label)}
              className="d-none d-md-block"
            >
              {t(currentLevel.label)}
            </RankText>
          </ColNopaddingRANK>
        </>
      )} */}
      <ColCenter
        className="col-10 col-md-11 col-lg-11"
        directFirstLevel={data.info.directFirstLevel}
      >
        <Row>
          <ColNopadding className="col-12 col-md-12 col-lg-12">{data.info.email}</ColNopadding>
        </Row>
        <Row>
          <ColNopadding className="col-12 col-md-12 col-lg-12">
            {`${data.info.name} (${data.info.nickname})`}
            {/* {data.info.nickname} */}
            {/* {data.info.rank !== undefined && (
              <Rank
                rankColor={RANKS[`LP${data.info.rank}`]}
                title={data.info.rank}
              >{`LP${data.info.rank}`}</Rank>
            )} */}
          </ColNopadding>
        </Row>
        {/* {data.info.name && (
          <Row>
            <ColNopadding className="col-12 col-md-12 col-lg-12">{data.info.name}</ColNopadding>
          </Row>
        )} */}
        <Row>
          <ColNopadding className="col-12 col-md-12 col-lg-12">{data.info.country}</ColNopadding>
        </Row>
      </ColCenter>
    </RowPrincipal>
  );
};

SimpleTreeNode.propTypes = {
  node: PropTypes.object,
  onClickNode: PropTypes.func,
  firstLevel: PropTypes.number,
  nodeVisited: PropTypes.array,
};

export default SimpleTreeNode;
