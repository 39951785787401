import { store } from '../store/index';
import {
  filterAddresses,
  getAddressByUuid,
  modifyAddress,
  setDefaultAddress,
  removeAddress,
  createAddress,
} from '../store/actions/AddressActions';

/**
 * List Address
 */
export const listFilter = async (filter) => {
  const result = await store.dispatch(filterAddresses(filter));
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};

/**
 * Create Address
 */
export const addAddress = async (values) => {
  const objSend = { ...values };
  const result = await store.dispatch(createAddress(objSend));
  if (result?.payload?.data?.uuid) {
    return result.payload.data;
  }
  return null;
};

/**
 * Get Address
 */
export const getAddress = async (uuid) => {
  const param = [uuid];
  const result = await store.dispatch(getAddressByUuid(param));
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};

/**
 * Update Address
 */
export const updateAddress = async (values, uuid) => {
  const objSend = { ...values };
  delete objSend.default;
  const param = [uuid];
  const result = await store.dispatch(modifyAddress(objSend, param));
  if (result?.payload?.data?.uuid) {
    return result.payload.data;
  }
  return null;
};

/**
 * Set as default Address
 */
export const setAsDefault = async (uuid) => {
  const param = [uuid];
  const result = await store.dispatch(setDefaultAddress(param));
  if (result?.payload?.data?.uuid) {
    return 'OK';
  }
  return null;
};

/**
 * Delete address
 */
export const deleteAddress = async (uuid) => {
  const param = [uuid];
  const result = await store.dispatch(removeAddress(param));
  if (result?.payload?.data?.uuid) {
    return 'OK';
  }
  return null;
};
