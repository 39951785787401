import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import AuthRoute from './AuthRoute';
import PATHS from '../constants/paths';
// import NotificationFormPage from '../pages/notification/form';
import DocsListPage from '../pages/user/docs/list';
/* GENERATOR_INJECT_IMPORT_PAGE */

class DocsRoutesContainer extends Component {
  componentDidMount() {}

  render() {
    return (
      <Switch>
        <AuthRoute path={PATHS.DOCS_LIST} component={DocsListPage} />
        {/* GENERATOR_INJECT_LIST_ROUTE */}
        {/* <AuthRoute exact path={PATHS.CREATE_NOTIFICATION} component={NotificationFormPage} />
        <AuthRoute
          exact
          path={PATHS.UPDATE_NOTIFICATION}
          component={(props) => <NotificationFormPage uuid={props.match.params.uuid} />}
        /> */}

        {/* GENERATOR_INJECT_FORM_ROUTE */}
      </Switch>
    );
  }
}

DocsRoutesContainer.propTypes = {};

export default DocsRoutesContainer;
