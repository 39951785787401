import React from 'react';
import AddressesList from '../../../../components/user/addresses/addressesFilterList';
import Layout from '../../../../components/layouts/settingsLayout';

const UserAddressesPage = () => {
  return (
    <Layout
      showBanner
      bannerName="ajustes.jpg"
      textBanner="settings"
      bannerPosition="60"
      active="addresses"
      body={
        <>
          <AddressesList />
        </>
      }
    />
  );
};

UserAddressesPage.propTypes = {};

export default UserAddressesPage;
