import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import AuthRoute from './AuthRoute';
import PATHS from '../constants/paths';
import UserAddress from '../pages/user/userAddress/list';
import UserAddressForm from '../pages/user/userAddress/form';
// import NotificationFormPage from '../pages/notification/form';
/* GENERATOR_INJECT_IMPORT_PAGE */

class AddressesRoutesContainer extends Component {
  componentDidMount() {}

  render() {
    return (
      <Switch>
        <AuthRoute exact path={PATHS.LIST_MY_ADDRESSES} component={UserAddress} />
        {/* GENERATOR_INJECT_LIST_ROUTE */}
        <AuthRoute exact path={PATHS.NEW_MY_ADDRESSES} component={UserAddressForm} />

        <AuthRoute
          exact
          path={PATHS.UPDATE_MY_ADDRESSES}
          component={(props) => <UserAddressForm uuid={props.match.params.uuid} />}
        />
        {/* GENERATOR_INJECT_FORM_ROUTE */}
      </Switch>
    );
  }
}

AddressesRoutesContainer.propTypes = {};

export default AddressesRoutesContainer;
