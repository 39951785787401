import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import * as GeneralServices from '../../../../services/GeneralServices';
// import PATHS from '../../../../constants/paths';
import {
  PRODUCT_LINE_WELLNESS,
  PRODUCT_LINE_SERVICES,
  PRODUCT_LINE_ACADEMY,
  PRODUCT_LINE_STYLE,
  PRODUCT_LINE_COMMERCE,
  PRODUCT_LINE_EXPERIENCES,
  PRODUCT_LINE_HOME,
  PRODUCT_LINE_GOURMET,
  CACHE_GROUPS,
} from '../../../../constants/index';
// import { Button } from '@arcaitprod/react-basic-components';
// import Menu, { Item as MenuItem } from 'rc-menu';
import 'rc-menu/assets/index.css';
import {
  IconStyled,
  ButtonOption,
  Logo,
  OffCanvasHeader,
  NavLink,
  MenuContainer,
  LinkLogo,
  TitleCategories,
  Separator,
} from './styled';
// import { showDialog } from '@arcaitprod/react-utils-components';
// import { Can, AbilityContext } from '../../../permissions/Can';
// import { USER_PERMISSIONS } from '../../../permissions/rolesPermission';
import ReactTooltip from 'react-tooltip';
import { useHistory } from 'react-router-dom';
import PATHS from '../../../../constants/paths';
// import { useSelector } from 'react-redux';
// import { getGroupsSelector, getTimeGroupsSelector } from '../../../../store/selectors/navSelector';

const Sidebar = (props) => {
  const { offCanvas } = props;
  // const [groups, setGroups] = useState([]);
  const { t, i18n } = useTranslation();
  let history = useHistory();
  // const groupsCache = useSelector(getGroupsSelector);
  // const timeGroupsCache = useSelector(getTimeGroupsSelector);
  // useEffect(() => {
  //   let cancel = false;
  //   const currentTime = new Date().getTime();
  //   const betWentTime = currentTime - (timeGroupsCache ? timeGroupsCache : 0);
  //   if (!groupsCache || (groupsCache && betWentTime >= CACHE_GROUPS)) {
  //     GeneralServices.getPackageGroupsSidebar().then((result) => {
  //       if (cancel) return;
  //       if (result && result.length > 0) {
  //         GeneralServices.setTimeCachePackageGroups();
  //         setGroups(result);
  //       }
  //     });
  //   } else {
  //     setGroups(groupsCache);
  //   }

  //   return () => {
  //     cancel = true;
  //   };
  // }, []);

  const handlerSelectOption = async (key, uuid) => {
    switch (key) {
      case 'mining':
        history.push(PATHS.DESERT_ANTS);
        break;
      case PRODUCT_LINE_SERVICES.code:
      case PRODUCT_LINE_ACADEMY.code:
      case PRODUCT_LINE_STYLE.code:
      case PRODUCT_LINE_COMMERCE.code:
      case PRODUCT_LINE_EXPERIENCES.code:
      case PRODUCT_LINE_HOME.code:
      case PRODUCT_LINE_GOURMET.code:
        history.push(`/products/${key.toLowerCase()}`);
        break;
      case 'GROUP':
        history.push(`/products/group/${uuid}`);
        break;
      default:
        break;
    }
  };

  // const renderGroups = () => {
  //   return (
  //     <>
  //       {groups.map((group) => {
  //         return (
  //           <div key={`op_${group.uuid}`}>
  //             <NavLink href="#">
  //               <span className="mx-4">
  //                 <ButtonOption
  //                   active={true}
  //                   onClick={() => {
  //                     handlerSelectOption('GROUP', group.uuid);
  //                   }}
  //                 >
  //                   {group.name[i18n.language]}
  //                 </ButtonOption>
  //               </span>
  //             </NavLink>
  //             <Separator></Separator>
  //           </div>
  //         );
  //       })}
  //     </>
  //   );
  // };
  return (
    <>
      <ReactTooltip
        id="optionDisabled"
        type="dark"
        effect="float"
        place="right"
        className="tooltipSidebar"
        // delayHide={99999999999999999}
      >
        <p>{t('optionDisabled')}</p>
      </ReactTooltip>
      {!offCanvas && (
        <LinkLogo
          href={`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_HOST}${
            process.env.REACT_APP_PORT ? `:${process.env.REACT_APP_PORT}` : ''
          }/home`}
          className="nav-link text-center"
        >
          <Logo
            src={`/assets/themes/${process.env.REACT_APP_THEME}/images/arca.png`}
            alt="logo"
            className="logo"
            offCanvas={offCanvas}
          />
        </LinkLogo>
      )}

      <MenuContainer className="nav flex-column text-white w-100">
        {offCanvas && (
          <OffCanvasHeader className="offcanvas-header">
            {/* <h5 className="offcanvas-title" id="offcanvasExampleLabel"></h5> */}
            <button
              type="button"
              className="btn-close text-reset"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </OffCanvasHeader>
        )}

        {/* <TitleCategories>{t('categories.title')}</TitleCategories> */}
        {/* <Separator className="mt-2"></Separator> */}
        <NavLink href="#">
          <span className="mx-2">
            <ButtonOption
              active={true}
              onClick={() => {
                // handlerSelectOption(PRODUCT_LINE_ACADEMY.code);
              }}
            >
              <div>
                <IconStyled
                  src={`/assets/themes/${process.env.REACT_APP_THEME}/images/categories/IA_v.png`}
                  alt={t('categories.ai')}
                />
              </div>
              <div>{t('categories.ai')}</div>
            </ButtonOption>
          </span>
        </NavLink>
        {/* <Separator></Separator> */}
        <NavLink href="#">
          <span className="mx-2">
            <ButtonOption
              active={true}
              onClick={() => {
                handlerSelectOption('mining');
              }}
            >
              <div>
                <IconStyled
                  src={`/assets/themes/${process.env.REACT_APP_THEME}/images/categories/Mineria_v.png`}
                  alt={t('categories.mineri')}
                />
              </div>
              <div>{t('categories.mineri')}</div>
            </ButtonOption>
          </span>
        </NavLink>
        {/* <Separator></Separator> */}
        <NavLink href="#">
          <span className="mx-2">
            <ButtonOption
              active={true}
              onClick={() => {
                // handlerSelectOption(PRODUCT_LINE_COMMERCE.code);
              }}
            >
              <div>
                <IconStyled
                  src={`/assets/themes/${process.env.REACT_APP_THEME}/images/categories/Tarjetas_v.png`}
                  alt={t('categories.cards')}
                />
              </div>
              <div>{t('categories.cards')}</div>
            </ButtonOption>
          </span>
        </NavLink>
        {/* <Separator></Separator> */}
        <NavLink href="#">
          <span className="mx-2">
            <ButtonOption
              active={true}
              onClick={() => {
                // handlerSelectOption(PRODUCT_LINE_STYLE.code);
              }}
            >
              <div>
                <IconStyled
                  src={`/assets/themes/${process.env.REACT_APP_THEME}/images/categories/Formacion_v.png`}
                  alt={t('categories.learning')}
                />
              </div>
              <div>{t('categories.learning')}</div>
            </ButtonOption>
          </span>
        </NavLink>
        {/* <Separator></Separator> */}
        <NavLink href="#">
          <span className="mx-2">
            <ButtonOption
              active={true}
              onClick={() => {
                // handlerSelectOption(PRODUCT_LINE_GOURMET.code);
              }}
            >
              <div>
                <IconStyled
                  src={`/assets/themes/${process.env.REACT_APP_THEME}/images/categories/Marketing_v.png`}
                  alt={t('categories.marketing')}
                />
              </div>
              <div>{t('categories.marketing')}</div>
            </ButtonOption>
          </span>
        </NavLink>
        {/* <Separator></Separator> */}
        <NavLink href="#">
          <span className="mx-2">
            <ButtonOption
              active={true}
              onClick={() => {
                // handlerSelectOption(PRODUCT_LINE_HOME.code);
              }}
            >
              <div>
                <IconStyled
                  src={`/assets/themes/${process.env.REACT_APP_THEME}/images/categories/NFT_v.png`}
                  alt={t('categories.tokens')}
                />
              </div>
              <div>{t('categories.tokens')}</div>
            </ButtonOption>
          </span>
        </NavLink>
        {/* <Separator></Separator> */}
        <NavLink href="#">
          <span className="mx-2">
            <ButtonOption
              active={true}
              onClick={() => {
                // handlerSelectOption(PRODUCT_LINE_SERVICES.code);
              }}
            >
              <div>
                <IconStyled
                  src={`/assets/themes/${process.env.REACT_APP_THEME}/images/categories/Netshipping_v.png`}
                  alt={t('categories.netsshipping')}
                />
              </div>
              <div>{t('categories.netsshipping')}</div>
            </ButtonOption>
          </span>
        </NavLink>
        {/* <Separator></Separator> */}
        <NavLink href="#">
          <span className="mx-2">
            <ButtonOption
              active={true}
              onClick={() => {
                // handlerSelectOption(PRODUCT_LINE_EXPERIENCES.code);
              }}
            >
              <div>
                <IconStyled
                  src={`/assets/themes/${process.env.REACT_APP_THEME}/images/categories/Metaverse_v.png`}
                  alt={t('categories.meta')}
                />
              </div>
              <div>{t('categories.meta')}</div>
            </ButtonOption>
          </span>
        </NavLink>
        <NavLink href="#">
          <span className="mx-2">
            <ButtonOption
              active={true}
              onClick={() => {
                // handlerSelectOption(PRODUCT_LINE_EXPERIENCES.code);
              }}
            >
              <div>
                <IconStyled
                  src={`/assets/themes/${process.env.REACT_APP_THEME}/images/categories/BotTrading_v.png`}
                  alt={t('categories.trading')}
                />
              </div>
              <div>{t('categories.trading')}</div>
            </ButtonOption>
          </span>
        </NavLink>
        {/* <Separator></Separator> */}
        {/* {groups && groups.length > 0 && renderGroups()} */}
      </MenuContainer>
    </>
  );
};

// Sidebar.contextType = AbilityContext;

Sidebar.propTypes = {
  nickname: PropTypes.string,
  offCanvas: PropTypes.bool,
  userStatus: PropTypes.string,
  role: PropTypes.string,
};

export default Sidebar;
