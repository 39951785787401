import styled from 'styled-components';
import { Link } from '@arcaitprod/react-basic-components';

export const ButtonMarkAll = styled.button`
  color: ${({ theme }) => theme.colors.primary};
  border: 0px;
  background: transparent;

  &:hover {
    color: ${({ theme }) => theme.colors.primaryLight};
    text-decoration: underline;
  }
`;

export const LinkActionStyled = styled(Link)`
  margin-right: 5px;
`;

export const IconMarkAll = styled.i`
  margin-left: 10px;
  margin-right: 10px;
  color: ${({ theme }) => theme.colors.secondary};
`;

export const LinkAction2Styled = styled.a`
  margin-right: 5px;
  color: #fff;
  font-size: 0.875rem;

  &:hover {
    color: #fff;
    font-size: 0.875rem;
  }
`;

export const RowText = styled.div`
  color: ${({ theme }) => theme.colors.secondary};
  font-weight: ${({ readed }) => (readed ? 'normal' : 'bold')};
  font-size: ${({ readed }) => (readed ? '0.875rem' : '0.9rem')};
  padding-left: ${({ date }) => (date ? '30px' : '0px')};
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const RowTextBody = styled.div`
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 0.875rem;
`;

export const Bookmark = styled.i`
  color: ${({ theme }) => theme.colors.terciary};
  font-size: 1rem;
`;

export const HeaderList = styled.div`
  background: #35363a;
  margin-right: 0px;
  margin-left: 0px;
  color: #fff;
  padding: 10px 15px;
  border: 1px solid #35363a;
`;
