import { createSelector } from 'reselect';

const getStateNotificationSelector = (state) => state.notifications;

export const getCountPersonalNotificationsSelector = createSelector(
  getStateNotificationSelector,
  (notifications) => {
    if (notifications && notifications.personalcount) {
      return notifications.personalcount;
    }

    return 0;
  }
);

export const getCountGeneralNotificationsSelector = createSelector(
  getStateNotificationSelector,
  (notifications) => {
    if (notifications && notifications.generalcount) {
      return notifications.generalcount;
    }

    return 0;
  }
);
