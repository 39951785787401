import styled from 'styled-components';

export const MemberContainerContainer = styled.div`
  background: ${({}) =>
    `url('/assets/themes/${process.env.REACT_APP_THEME}/images/FondoMembresia.png')`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* background-attachment: fixed; */
  height: 100%;
  min-height: calc(100vh - 359px) !important;
  margin-right: -30px;
  margin-left: -30px;
`;

export const DataContainer = styled.div`
  background: #0009;
  padding: 40px;
`;

export const ImgMember = styled.img`
  width: 100%;
`;

export const MemberTitle = styled.div`
  color: #fff;
  font-size: 1.6rem;
`;

export const Points = styled.div`
  color: #469fa3;
  font-size: 0.8rem;
`;

export const MemberText = styled.div`
  color: #fff;
  font-size: 0.875rem;
`;

export const MemberList = styled.ul`
  padding-left: 50px;
  list-style: none;
`;
