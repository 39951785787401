import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Button } from '@arcaitprod/react-basic-components';
import { SecctionTitleContainer } from '../../../../design/styleComponents/globalComponents';
import * as Exchange from '../../../../utils/formats/exchange';
import {
  ContainerPaymentText,
  ContainerTotal,
  FinalPrice,
  TitleItem,
  TextReturn,
  ValueReturn,
  ContainerAddress,
  TitleAddress,
} from './itemReturn.styled';
import {
  REGION_EU,
  SYMBOL_MONEY,
  DISCOUNT_PRODUCT,
  TREVOL_VALUE_GAL,
  PRODUCT_STATUS,
} from '../../../../constants/index';
import QRcode from 'qrcode.react';

const ItemReturnPage = (props) => {
  const { data, onBack, confirmReturn, onlyView, orderPrint, showTerms } = props;
  const { t } = useTranslation();

  let status;
  let TAX;
  let unitPrice = 0;
  let subtotals = 0;
  let totalPay = 0;
  let payWithTre = 0;
  let discount = 0;
  let totalPurchase = 0;
  let tax = 0;

  let totalPayTreGl = 0;
  let totalPayTre = 0;
  let totalPayGal = 0;

  if (data?.purchase) {
    const hasTax = data.purchase.countryData.EU;
    TAX = data.purchase.countryData?.tax || 0;
    const isEu = data.purchase.countryData.EU;

    unitPrice = isEu ? data.item.unitPrice : data.item.unitPriceOutEU;
    subtotals =
      Exchange.crypto2Satoshi('GAL', isEu ? data.item.unitPrice : data.item.unitPriceOutEU) *
      data.item.amount;

    payWithTre =
      Exchange.crypto2Satoshi('GAL', isEu ? data.item.tokens : data.item.tokensOutEU) *
      Number(data.item.amount);

    discount =
      Exchange.crypto2Satoshi(
        'GAL',
        isEu ? data.item.directDiscount : data.item.directDiscountOutEU
      ) * Number(data.item.amount);

    totalPurchase = subtotals + (data?.purchase.paymentMethod === 'wallets' ? -discount : 0);

    if (hasTax) {
      tax = parseInt((totalPurchase * TAX) / 100);
    }

    totalPay = totalPurchase + tax;

    totalPayTreGl = payWithTre;
    totalPayTre = (payWithTre * TREVOL_VALUE_GAL) / 100;
    totalPayGal = totalPay;

    if (data?.purchase.paymentMethod === 'wallets') {
      totalPayGal += -totalPayTreGl;
    }

    status = PRODUCT_STATUS.find((prod) => prod.code === data.item.status);
  }

  return (
    <>
      <div className="row justify-content-center no-margin">
        <SecctionTitleContainer className="col-11 col-md-11 mt-5 mb-5">
          {t('forms.order.labels.returnItem')}
        </SecctionTitleContainer>
      </div>
      <div className="row justify-content-center no-margin">
        <TextReturn className="col-11 col-md-11">{t('forms.order.return.item')}</TextReturn>
      </div>
      <div className="row justify-content-center no-margin">
        <div className="col-12 col-md-11">
          <div className="row mt-3">
            <TitleItem className="col-12">{data?.item?.packageTitle}</TitleItem>
          </div>

          <ul className="nav nav-tabs returnItem mt-4" id="myTabItems" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className="nav-link active"
                id="dataReturn-tab"
                data-bs-toggle="tab"
                data-bs-target="#dataReturn"
                type="button"
                role="tab"
                aria-controls="dataReturn"
                aria-selected="true"
              >
                {t('forms.order.return.dataReturn')}
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="profile-tab"
                data-bs-toggle="tab"
                data-bs-target="#profile"
                type="button"
                role="tab"
                aria-controls="profile"
                aria-selected="false"
              >
                {t('forms.order.return.dataShipping')}
              </button>
            </li>
            {/* <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="contact-tab"
                data-bs-toggle="tab"
                data-bs-target="#contact"
                type="button"
                role="tab"
                aria-controls="contact"
                aria-selected="false"
              >
                {t('forms.order.return.dataTracking')}
              </button>
            </li> */}
          </ul>
          <div className="tab-content returnItemContent" id="myTabItemsContent">
            <div
              className="tab-pane fade show active mt-4"
              id="dataReturn"
              role="tabpanel"
              aria-labelledby="dataReturn-tab"
            >
              <div className="row mt-3">
                <div className="col-3">
                  <img src={data?.item?.image}></img>
                </div>
                <div className="col-4">
                  <div>
                    {t('forms.order.return.status')}: <ValueReturn>{t(status?.label)}</ValueReturn>
                  </div>
                  <div>
                    {t('forms.order.return.amount')}:{' '}
                    <ValueReturn>{data?.item?.amount}</ValueReturn>
                  </div>
                  <div>
                    {t('forms.order.return.unitPrice')}: <ValueReturn>{unitPrice}</ValueReturn>
                  </div>
                </div>
                <div className="col-5">
                  <div className="row mb-2">
                    <ContainerPaymentText className="col-5">
                      {t('forms.order.return.subtotals')}
                    </ContainerPaymentText>
                    <ContainerPaymentText className="col-7 text-end">
                      {`${Exchange.satoshi2Crypto('GAL', subtotals)} ${SYMBOL_MONEY}`}
                    </ContainerPaymentText>
                  </div>
                  {data?.purchase.paymentMethod === 'wallets' && discount > 0 && (
                    <div className="row mb-2">
                      <ContainerPaymentText className="col-5">
                        {t('forms.order.return.discountWallet')}
                      </ContainerPaymentText>
                      <ContainerPaymentText className="col-7 text-end">
                        {`-${Exchange.satoshi2Crypto('GAL', discount)} ${SYMBOL_MONEY}`}
                      </ContainerPaymentText>
                    </div>
                  )}
                  <ContainerTotal className="row mb-2 pt-2">
                    <div className="col-12">
                      <div className="row mb-2">
                        <ContainerPaymentText className="col-5 ">
                          <b>{t('forms.order.return.totalPurchase')}</b>
                        </ContainerPaymentText>
                        <ContainerPaymentText className="col-7 text-end">
                          <b>{`${Exchange.satoshi2Crypto(
                            'GAL',
                            totalPurchase
                          )} ${SYMBOL_MONEY}`}</b>
                        </ContainerPaymentText>
                      </div>
                      <div className="row mb-2">
                        <ContainerPaymentText className="col-5 ">
                          <b>{`${t('forms.order.return.tax')} (${TAX}%)`}</b>
                        </ContainerPaymentText>
                        <ContainerPaymentText className="col-7 text-end">
                          <b>{`${
                            tax === 0 ? '0.00' : Exchange.satoshi2Crypto('GAL', tax)
                          } ${SYMBOL_MONEY}`}</b>
                        </ContainerPaymentText>
                      </div>
                    </div>
                  </ContainerTotal>
                  <ContainerTotal className="row mb-2 pt-2">
                    <div className="col-12">
                      <div className="row mb-2">
                        <ContainerPaymentText className="col-5 ">
                          <FinalPrice>{t('forms.order.return.totalReturn')}</FinalPrice>
                        </ContainerPaymentText>
                        <ContainerPaymentText className="col-7 text-end">
                          <FinalPrice>{`${Exchange.satoshi2Crypto(
                            'GAL',
                            totalPay
                          )} ${SYMBOL_MONEY}`}</FinalPrice>
                        </ContainerPaymentText>
                      </div>
                    </div>
                  </ContainerTotal>

                  <div className="row mb-2 pt-2">
                    <div className="col-12">
                      {(data?.purchase?.paymentMethod === 'wallets' ||
                        data?.purchase?.paymentMethod === 'wallet') && (
                        <>
                          <div className="row mb-2">
                            <ContainerPaymentText className="col-5 ">
                              <FinalPrice>{t('forms.order.return.totalReturnGal')}</FinalPrice>
                            </ContainerPaymentText>
                            <ContainerPaymentText className="col-7 text-end">
                              <FinalPrice>{`${Exchange.satoshi2Crypto(
                                'GAL',
                                totalPayGal
                              )} ${SYMBOL_MONEY}`}</FinalPrice>
                            </ContainerPaymentText>
                          </div>
                          {data?.purchase?.paymentMethod === 'wallets' && (
                            <div className="row mb-2">
                              <ContainerPaymentText className="col-5 ">
                                <FinalPrice>{t('forms.order.return.totalReturnTre')}</FinalPrice>
                              </ContainerPaymentText>
                              <ContainerPaymentText className="col-7 text-end">
                                <FinalPrice>
                                  {`(${Exchange.satoshi2Crypto('GAL', totalPayTreGl)} GL) `}
                                  {`${totalPayTre} TRE`}
                                </FinalPrice>
                              </ContainerPaymentText>
                            </div>
                          )}
                        </>
                      )}
                      {data?.purchase?.paymentMethod === 'card' && (
                        <>
                          <div className="row mb-2">
                            <ContainerPaymentText className="col-5 ">
                              <FinalPrice>{t('forms.order.return.totalReturnCard')}</FinalPrice>
                            </ContainerPaymentText>
                            <ContainerPaymentText className="col-7 text-end">
                              <FinalPrice>{`${Exchange.satoshi2Crypto(
                                'GAL',
                                totalPayGal
                              )} ${SYMBOL_MONEY}`}</FinalPrice>
                            </ContainerPaymentText>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade mt-4"
              id="profile"
              role="tabpanel"
              aria-labelledby="profile-tab"
            >
              <div className="row justify-content-start">
                <div className="col-7">
                  <div className="row mt-2">
                    <div className="col-12">{t('forms.order.return.descriptionSteps')}</div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-12">
                      <Button
                        type="button"
                        variant="primary"
                        className="me-3"
                        onClick={() => showTerms()}
                      >
                        {`${t('forms.order.buttons.showTerms')}`}
                      </Button>
                    </div>
                  </div>

                  {/* <div className="row mt-3">
                    <div className="col-1 text-end no-padding">1.-</div>
                    <div className="col-11">{t('forms.order.return.step.step1')}</div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-1 text-end no-padding">2.-</div>
                    <div className="col-11">{t('forms.order.return.step.step2')}</div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-1 text-end no-padding">3.-</div>
                    <div className="col-11">{t('forms.order.return.step.step3')}</div>
                  </div> */}
                </div>
                {/* <div className="col-4">
                  <div className="row mt-3">
                    <TitleAddress className="col-11">
                      {t('forms.order.return.returnAddess')}
                    </TitleAddress>
                  </div>
                  <div className="row mt-3">
                    <div className="col-12">
                      <ContainerAddress>
                        <div>
                          <b>{data?.addressReturn?.contactName}</b>
                        </div>
                        <div>{`${data?.addressReturn?.address}${
                          data?.addressReturn?.addressAdc
                            ? `, ${data?.addressReturn?.addressAdc}`
                            : ''
                        }`}</div>
                        <div>{`${data?.addressReturn?.cp}, ${data?.addressReturn?.city} ${
                          data?.addressReturn?.province
                            ? `(${data?.addressReturn?.province.toUpperCase()})`
                            : ''
                        }`}</div>
                        <div>{data?.addressReturn?.countryData?.visualName.toUpperCase()}</div>
                        <div>{data?.addressReturn?.phone}</div>
                        <div>{data?.addressReturn?.email}</div>
                      </ContainerAddress>
                    </div>
                  </div>
                  {data?.requestReturn && (
                    <>
                      <div className="row mt-3">
                        <div className="col-12 text-center">
                          <QRcode value={data.requestReturn.uuid} id="returnQrCode" />
                        </div>
                      </div>
                      <div className="row mt-3">
                        <Button
                          type="button"
                          variant="primary"
                          className="me-3"
                          onClick={() => orderPrint(data.requestReturn.uuid)}
                        >
                          {`${t('forms.order.buttons.printOrder')}`}
                        </Button>
                      </div>
                    </>
                  )}
                  {!data?.requestReturn && (
                    <div className="row mt-3">
                      <div className="col-12">
                        <div className="alert alert-danger" role="alert">
                          {t('forms.order.return.noPrint')}
                        </div>
                      </div>
                    </div>
                  )}
                </div> */}
              </div>
            </div>
            {/* <div
              className="tab-pane fade"
              id="contact"
              role="tabpanel"
              aria-labelledby="contact-tab"
            >
              hola
            </div> */}
          </div>
        </div>
      </div>
      <div className="row justify-content-center no-margin mt-5">
        <div className="col-4 text-center">
          <div className="d-grid gap-2 d-md-block">
            <Button type="button" variant="secondary" className="me-3" onClick={() => onBack()}>
              {/* {`${
                onlyView ? t('forms.order.buttons.back') : t('forms.order.buttons.returnCancel')
              }`} */}
              {t('forms.order.buttons.back')}
            </Button>
            {/* {!onlyView && (
              <Button type="button" variant="primary" onClick={() => confirmReturn()}>
                {`${t('forms.order.buttons.returnConfirm')}`}
              </Button>
            )} */}
          </div>
        </div>
      </div>
    </>
  );
};

ItemReturnPage.propTypes = {
  data: PropTypes.object,
  onBack: PropTypes.func,
  confirmReturn: PropTypes.func,
  onlyView: PropTypes.bool,
  orderPrint: PropTypes.func,
  showTerms: PropTypes.func,
};

export default ItemReturnPage;
