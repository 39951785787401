import styled from 'styled-components';

export const GalExplain = styled.div`
  font-size: 0.7rem;
  color: #469fa3;
`;

export const LinkCopy = styled.div`
  padding: 8px 15px;
  cursor: pointer;
  font-size: 0.85rem;
`;

export const ShareIcon = styled.i`
  color: #fff;
  margin-left: 10px;
`;

export const ButtonShare = styled.button`
  background: #469fa3;
  color: #fff;
  border: 1px solid #469fa3;

  &:hover {
    background: #469fa3 !important;
    border: 1px solid #469fa3;
  }
  &:active {
    background: #469fa3 !important;
    border: 1px solid #469fa3;
  }
  &:focus {
    background: #469fa3 !important;
    border: 1px solid #469fa3;
  }
`;

export const PrincipalImage = styled.img`
  width: 100%;
`;

export const TextOffer = styled.div`
  text-transform: uppercase;
`;

export const TextAfterOffer = styled.div`
  font-size: 0.7rem;
  margin-bottom: 10px;
`;

export const Title = styled.div`
  color: #469fa3;
  font-size: 1.3rem;
  font-weight: 700;
`;

export const ShortDescription = styled.div`
  color: #fff;
  font-size: 0.875rem;
  font-weight: normal;
`;

export const Price = styled.div`
  color: ${({ isOffer }) => (isOffer ? '#469fa3' : '#469fa3')};
  font-size: 1.3rem;
  font-weight: 900;
`;

export const NotAvailable = styled.div`
  color: #469fa3;
  font-size: 0.875rem;
  font-weight: 900;
`;

export const Tax = styled.div`
  color: #fff;
  font-size: 0.7rem;
`;

export const Gallons = styled.div`
  color: #469fa3;
  font-size: 1.3rem;
`;

export const Description = styled.div`
  color: #fff;
  font-size: 0.85rem;
  font-weight: normal;
  /* line-height: 1rem; */
`;

export const FormShoppingCart = styled.div`
  color: #fff !important;
  font-size: 0.85rem;
  font-weight: normal;
  line-height: 1rem;
`;

export const TermsConditions = styled.a`
  color: #fff !important;
  font-size: 0.85rem;
  font-weight: normal;
  text-decoration: underline;

  &:hover {
    color: #469fa3 !important;
  }
`;

export const AvailableCountriesButton = styled.button`
  color: #fff !important;
  font-size: 0.85rem;
  font-weight: bold;
  text-decoration: underline;
  background: transparent;
  border: 0px;

  &:hover {
    color: #469fa3 !important;
  }
`;

export const Additional = styled.div`
  color: #fff;
  font-size: 0.85rem !important;
  font-weight: normal;
  line-height: 1rem !important;
`;

export const TitleAdditional = styled.div`
  color: #000;
  font-size: 1.75rem;
  font-weight: 700;
  line-height: 1rem;
`;

export const ImgButton = styled.img`
  height: 35px;
  margin-right: 10px;
`;

export const Proximamente = styled.div`
  color: #fff;
  font-size: 1.5rem !important;
  font-weight: bold;
  line-height: 1rem !important;
`;
