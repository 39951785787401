import styled from 'styled-components';

export const ContainerHeader = styled.div`
  min-height: 50vh;
  /* padding-top: 8vh; */
  background: ${() =>
    `url('/assets/themes/${process.env.REACT_APP_THEME}/images/Banner_Wellness.webp')`};
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const ContentHeader = styled.div`
  min-height: 50vh;
  /* display: flex;
  justify-content: space-between;
  align-items: center; */
`;

export const ContentLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const LogoCategory = styled.img`
  /* height: 18rem; */
  width: 100%;
  -o-object-fit: contain;
  object-fit: contain;
`;

export const ContainerImgRight = styled.div`
  height: 100%;
`;

export const LogoCategoryRight = styled.img`
  height: 5rem;
`;

export const ContainerBrand = styled.div`
  font-size: 2.5rem;
`;

export const Brand = styled.p`
  font-size: 1.3rem;
  color: #fff;
  margin: 1em 0;
`;

export const ContainerCta = styled.div`
  width: 100%;
  height: 7rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`;

export const TitleCta = styled.p`
  width: 80%;
  color: #fcfcfc;
  font-size: 1.1rem;
  font-weight: 300;
  text-align: center;
  /* text-transform: capitalize; */
  line-height: 1.3rem;
`;

export const ButtonProduct = styled.a`
  display: block;
  max-width: 12.5rem;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 0.8rem;
  /* border: 2px solid var(--color-services); */
  padding: 0.9rem 1.5rem;
  margin: 0.5rem 0;
  transition: all 0.5s ease;
  background: #e81c75;
  border-radius: 5px;
  color: #fff;
  min-width: 15rem;

  &:hover {
    color: #fff;
    text-decoration: none;
  }
`;

export const BlankSection = styled.section`
  height: 100%;
  background: rgb(255, 255, 255);
`;

export const BlankContainer = styled.div`
  height: 100%;
`;

export const BoxContainer = styled.div`
  height: 20rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const BlankImageContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 25%;
`;

export const BlankImage = styled.img`
  width: 15rem;
  height: 15rem;
  -o-object-fit: contain;
  object-fit: contain;
`;

export const BlankTextContainer = styled.div`
  flex-basis: 70%;
`;

export const BlankText = styled.p`
  font-size: 1.1rem;
  line-height: 1.725rem;
  color: #a0a2a4;
`;

export const Banner = styled.section`
  background: ${() =>
    `url('/assets/themes/${process.env.REACT_APP_THEME}/images/Banner2wellness.webp')`};
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  height: 50vh;
  background-blend-mode: multiply;
`;

export const BannerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  height: 100%;
  margin: 0 auto;
`;

export const BannerBox = styled.div`
  padding: 5rem;
  text-align: center;
`;

export const BannerTitle = styled.h2`
  color: #fff;
  font-size: 2rem;
  display: block;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
`;

export const ProductList = styled.section`
  height: 100%;
  padding: 2.5rem 0;
`;

export const ProductTitle = styled.h2`
  text-align: center;
  text-transform: uppercase;
  font-weight: 300;
  color: #469fa3;
  margin: 0;
  font-size: 1.5rem;
`;

export const ReishiImage = styled.img`
  width: 15rem;
  margin: 2.5rem 0;
`;
