import React from 'react';
import PropTypes from 'prop-types';
// import { useTranslation } from 'react-i18next';
import {
  Row,
  ColNopadding,
  NodeContainer,
  Rank,
  Arrow,
  ColArrow,
  ColNopaddingText,
  ColNopaddingImg,
} from './styled';
import { RANKS } from '../../../../../constants/index';
// import { formatMoney } from '../../../../../utils/formats/numbers';
const TreeNode = (props) => {
  // {
  //   "rank": 1,
  //   "promoter": true,
  //   "directFirstLevel": true,
  //   "pointsPersonal": true
  // }

  const { node, firstLevel, nodeVisited } = props;
  // const { t } = useTranslation();
  const level1 = firstLevel + 1;
  const visited = nodeVisited.includes(node.userUuid);
  const levelArrows = firstLevel % 2;
  let icon = ``;
  // 1. Reg, Evo: pointsPersonal = 0, promoter = false
  // 2. Promotor y Kyc , promoter = true && (maxHistoryRank > 0 || rank > 0)
  // 3. Cliente: pointsPersonal > 0, promoter = false
  // 4. Promoter No calificad: promoter = true && rank === 0 && maxHistoryRank > 0
  // 5. Resto--> icono rango;
  // if (node.promoter) {
  if (node.rank > 1) {
    icon = `ranks/${RANKS[`L${node.rank}`].image}.png`; // imagen rango
  } else {
    icon = `arca_logo.png`;
    // if (node.maxHistoryRank && node.maxHistoryRank > 0) {
    //   icon = `ranks/SinRango.png`;
    // } else {
    //   icon = `ranks/Activo.png`;
    // }
  }
  // } else {
  //   if (node.pointsPersonal && node.pointsPersonal > 0) {
  //     icon = `ranks/Cliente.png`;
  //   } else {
  //     icon = `ranks/Registrado.png`;
  //   }
  // }
  // const currentLevel = USER_LEVELS.find(
  //   (element) => element.value === node.rank
  // );
  return (
    <div
      id={node.userUuid}
      className={`node-hidden customNode ${node.level === level1 ? 'nodeLevel1' : ''}`}
    >
      <NodeContainer className="nodeAction">
        <Row className="row">
          <ColNopaddingImg
            directFirstLevel={node.directFirstLevel}
            className="col-4 d-flex justify-content-center align-items-center"
          >
            <img
              alt="Avatar"
              src={`/assets/themes/${process.env.REACT_APP_THEME}/images/${icon}`}
            ></img>
          </ColNopaddingImg>
          <ColNopaddingText className="col-8">
            {/* {currentLevel && (
              <Row className="row">
                <ColNopadding className="col-6 node-Text">
                  {t(currentLevel.label)}
                </ColNopadding>
                <ColNopadding className="col-6 node-Text text-right">
                  <Medal
                    src={`/assets/images/images-resources/${currentLevel?.image}`}
                  ></Medal>
                </ColNopadding>
              </Row>
            )} */}
            {/* {node.nickname && node.nickname.length > 0 && (
              <Row className="row">
                <ColNopadding className="col-12 node-Text">
                  {node.nickname}
                </ColNopadding>
              </Row>
            )} */}
            {/* {node.level === level1 && ( 
            <>*/}
            {node.name && (
              <Row className="row">
                <ColNopadding className="col-12 node-Text">
                  <span title={node.name}>{node.name}</span>
                </ColNopadding>
              </Row>
            )}
            {node.nickname && (
              <Row className="row">
                <ColNopadding className="col-12 node-Text">
                  <span title={node.nickname}>{node.nickname}</span>
                </ColNopadding>
              </Row>
            )}
            {/* {node.email && (
              <Row className="row">
                <ColNopadding className="col-12 node-Text">
                  <span title={node.email}>{node.email}</span>
                </ColNopadding>
              </Row>
            )} */}
            {/* {node.country && (
              <Row className="row">
                <ColNopadding className="col-12 node-Text">
                  <span title={node.country}>{node.country}</span>
                </ColNopadding>
              </Row>
            )} */}
            {node.rank !== undefined && (
              <Row className="row">
                <ColNopadding className="col-12 node-Text">
                  {/* <Rank
                    rankColor={RANKS[`LP${node.rank}`]}
                    title={node.rank}
                  >{`LP${node.rank}`}</Rank> */}
                </ColNopadding>
              </Row>
            )}
            {/*</>
             )} */}
          </ColNopaddingText>
        </Row>
      </NodeContainer>
      {node.level % 2 === levelArrows && visited === false && (
        <Row className="row">
          <ColArrow className="col-12 text-center">
            <Arrow
              src={`/assets/themes/${process.env.REACT_APP_THEME}/images/arrow-down.png`}
              alt="more nodes"
              data-get-more="moreNodes"
            ></Arrow>
          </ColArrow>
        </Row>
      )}
    </div>
  );
};

TreeNode.propTypes = {
  node: PropTypes.object,
  onClickNode: PropTypes.func,
  firstLevel: PropTypes.number,
  nodeVisited: PropTypes.array,
};

export default TreeNode;
