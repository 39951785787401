import { store } from '../store/index';
import { filterCommission, getResumeCommission } from '../store/actions/CommissionActions';

/**
 * List commissions
 */
export const listFilter = async (filter) => {
  const result = await store.dispatch(filterCommission(filter));
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};

/**
 * List commissions
 */
export const getResumeFilter = async (filter) => {
  const result = await store.dispatch(getResumeCommission(filter));
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};
