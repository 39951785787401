import React from 'react';
import CheckOut from '../../../components/user/checkOut';
import Layout from '../../../components/layouts/simpleLayout';

const CheckOutPage = () => {
  return (
    <Layout
      hiddenButtonShoppingCart
      body={
        <>
          <CheckOut />
        </>
      }
    />
  );
};

CheckOutPage.propTypes = {};

export default CheckOutPage;
