import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  MachineImg,
  Container,
  ContainerText,
  Label,
  SerialList,
  SerialItem,
  Label1,
  WaynanCeAlert,
} from './my.styled';
// import {
// } from '../../../../constants/index';
import { useSelector } from 'react-redux';
import { SecctionTitleContainer } from '../../../../design/styleComponents/globalComponents';
import { getHasWaynanceWalletSelector } from '../../../../store/selectors/authSelector';
import { Button, Title, Text } from '@arcaitprod/react-basic-components';
import PATHS from '../../../../constants/paths';
import { useHistory } from 'react-router-dom';
import { COMBOS_PACK } from '../../../../constants/index';

const MyDesertAntsPage = (props) => {
  const { data } = props;
  console.log(data);
  const { t } = useTranslation();
  // const context = useContext(AbilityContext);
  const items = data ? Object.keys(data) : null;
  const hasWaynance = useSelector(getHasWaynanceWalletSelector);
  let history = useHistory();

  const goToWallet = async () => {
    history.push(PATHS.WALLETS_SETTING);
  };

  const renderPacks = (id) => {
    return (
      <>
        {data[id]['128'] > 0 && (
          <div>{`${t(COMBOS_PACK[128].name)}  - ${data[id]['128'] / COMBOS_PACK[128].quota} ${t(
            'packBuy'
          )} (${data[id]['128']} ${t('parts')})`}</div>
        )}
        {data[id]['64'] > 0 && (
          <div>{`${t(COMBOS_PACK[64].name)} - ${data[id]['64'] / COMBOS_PACK[64].quota} ${t(
            'packBuy'
          )} (${data[id]['64']} ${t('parts')})`}</div>
        )}
        {data[id]['32'] > 0 && (
          <div>{`${t(COMBOS_PACK[32].name)} - ${data[id]['32'] / COMBOS_PACK[32].quota} ${t(
            'packBuy'
          )} (${data[id]['32']} ${t('parts')})`}</div>
        )}
        {data[id]['16'] > 0 && (
          <div>{`${t(COMBOS_PACK[16].name)} - ${data[id]['16'] / COMBOS_PACK[16].quota} ${t(
            'packBuy'
          )} (${data[id]['16']} ${t('parts')})`}</div>
        )}
        {data[id]['8'] > 0 && (
          <div>{`${t(COMBOS_PACK[8].name)} - ${data[id]['8'] / COMBOS_PACK[8].quota} ${t(
            'packBuy'
          )} (${data[id]['8']} ${t('parts')})`}</div>
        )}
        {data[id]['4'] > 0 && (
          <div>{`${t(COMBOS_PACK[4].name)} - ${data[id]['4'] / COMBOS_PACK[4].quota} ${t(
            'packBuy'
          )} (${data[id]['4']} ${t('parts')})`}</div>
        )}
        {data[id]['2'] > 0 && (
          <div>{`${t(COMBOS_PACK[2].name)} - ${data[id]['2'] / COMBOS_PACK[2].quota} ${t(
            'packBuy'
          )} (${data[id]['2']} ${t('parts')})`}</div>
        )}
        {data[id]['1'] > 0 && (
          <div>{`${t(COMBOS_PACK[1].name)} - ${data[id]['1'] / COMBOS_PACK[1].quota} ${t(
            'packBuy'
          )} (${data[id]['1']} ${t('parts')})`}</div>
        )}
      </>
    );
  };

  const renderMachine = () => {
    return items.map((id, index) => {
      const uu = '';
      uu.toLowerCase();
      const isMix = data[id].product.toLowerCase().includes('mix');
      return (
        <div key={`${id}_mach_${index}`} className="col">
          <Container className="d-flex flex-column">
            <div className="d-flex flex-column flex-md-row">
              <ContainerText>
                <div>
                  <Label1>{t('packageBuy')}</Label1>
                </div>
                <div className="mb-3">{renderPacks(id)}</div>
                <div>
                  <Label>{t('slots')}</Label>
                </div>
                <div className="mb-3">
                  {`${data[id].slots} ${t('parts')}`}
                  {isMix && t('machineMixParts')}
                </div>
                <div>
                  <Label>{t('serialsMachine')}</Label>
                </div>
                <div>
                  <SerialList>
                    {data[id].serials.map((serial) => (
                      <SerialItem key={`${id}_${serial}`}>{`- ${serial}`}</SerialItem>
                    ))}
                  </SerialList>
                </div>
              </ContainerText>
            </div>
            <div> {isMix && t('machineData')}</div>
          </Container>
        </div>
      );
    });
  };

  return (
    <>
      <div className="row justify-content-center no-margin">
        <SecctionTitleContainer className="col-11 col-md-11 mt-5 mb-5">
          {t('pages.desertAnts.myMachine')}
        </SecctionTitleContainer>
      </div>
      {!hasWaynance && (
        <div className="row justify-content-center mb-5">
          <div className="col-11 col-md-11">
            <WaynanCeAlert>
              {t('forms.wallet.waynance.alert')}
              <Button
                type="button"
                className="btn btn-md mt-3"
                variant="primary"
                onClick={() => goToWallet()}
              >
                {t('waynanceWallet')}
              </Button>
            </WaynanCeAlert>
          </div>
        </div>
      )}
      {data ? (
        <>
          <div className="row justify-content-center pb-5 no-margin">
            <div className="col-12 col-md-11">
              {items ? (
                <>
                  <div className="row row-cols-2">{renderMachine()}</div>
                </>
              ) : (
                ''
              )}
            </div>
          </div>
        </>
      ) : (
        <div className="row justify-content-center pb-5 no-margin">
          <div className="col-12 col-md-11">{t('noMachine')}</div>
        </div>
      )}
    </>
  );
};

MyDesertAntsPage.propTypes = {
  data: PropTypes.object,
  onBack: PropTypes.func,
  requestReturn: PropTypes.func,
};

export default MyDesertAntsPage;
