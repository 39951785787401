import styled from 'styled-components';
import { Button } from '@arcaitprod/react-basic-components';

export const ReturnButton = styled(Button)`
  font-weight: normal;
  font-size: 0.8rem;
  border: 0px;
  background: transparent !important;
  color: ${({ theme }) => theme.colors.primary} !important;

  &:hover {
    background: transparent !important;
    color: ${({ theme }) => theme.colors.secondary} !important;
  }
`;

export const ContainerBalanceText = styled.div`
  font-weight: normal;
  font-size: 1rem;
`;

export const Text04 = styled.span`
  font-weight: normal;
  font-size: 0.8rem;
`;

export const ContainerResume = styled.div`
  font-weight: bold;
  font-size: 1rem;
  text-transform: uppercase;
  text-decoration: underline;
`;

export const InvoiceContainer = styled.div`
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
  border: 1px solid #ccc;
  padding: 20px;
  -webkit-box-shadow: inset 10px 10px 25px -16px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: inset 10px 10px 25px -16px rgba(0, 0, 0, 0.75);
  box-shadow: inset 10px 10px 25px -16px rgba(0, 0, 0, 0.75);
`;

export const ContainerListItems = styled.div`
  max-height: 400px;
  overflow: auto !important;
`;

export const ContainerItem = styled.div`
  border-bottom: 1px solid #ccc;
`;

export const ImageItem = styled.img`
  max-width: 100%;
  height: auto;
  /* max-height: 150px; */
`;

export const ContainerTotals = styled.div`
  font-weight: bold;
  font-size: 1rem;
`;

export const ContainerSubTotals = styled.div`
  font-weight: normal;
  font-size: 1rem;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #ccc;
`;

export const FinalPrice = styled.b`
  color: #469fa3;
`;

export const ContainerBalance = styled.div`
  font-weight: bold;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.primary};
`;
