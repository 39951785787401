import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as PurchaseService from '../../../../services/PurchaseService';
import { useTranslation } from 'react-i18next';
import ItemReturnForm from './itemReturn.view';
import PATHS from '../../../../constants/paths';
import { showDialog } from '@arcaitprod/react-utils-components';
import { jsPDF } from 'jspdf';

const ItemReturnFormContainer = (props) => {
  const { item, order } = props;
  const [data, setData] = useState(null);
  const [onlyView, setOnlyView] = useState(true);
  const { t, i18n } = useTranslation();
  let history = useHistory();

  useEffect(() => {
    let cancel = false;

    if (item && order) {
      PurchaseService.requestReturnItem(item, order).then((result) => {
        if (cancel) return;
        if (result) {
          setData(result);
          if (!result.requestReturn) {
            setOnlyView(false);
          }
        }
      });
    }

    return () => {
      cancel = true;
    };
  }, [item, order]);

  const loadDataRequestItem = () => {
    PurchaseService.requestReturnItem(item, order).then((result) => {
      if (result) {
        setData(result);
        if (!result.requestReturn) {
          setOnlyView(false);
        }
      }
    });
  };

  // const handleSubmit = async () => {
  //   let icon = 'error';
  //   let title = t('forms.order.return.message.request.title');
  //   let text = t('forms.order.return.message.request.error');
  //   let okAction = undefined;
  //   if (data && item && order) {
  //     const result = await PurchaseService.confirmReturn(item, order);
  //     if (result?.uuid) {
  //       icon = 'success';
  //       text = t('forms.order.return.message.request.success');
  //       okAction = () => {
  //         history.push(PATHS.MY_ORDER_LIST);
  //       };
  //     } else {
  //       icon = 'error';
  //     }
  //     showDialog(title, text, icon, false, 'OK', false, okAction);
  //   }
  // };

  const handlerOnBack = async () => {
    history.push(`/orders/detail/${order}`);
  };

  const handlerShowTerms = async () => {
    if (data.product.terms) {
      window.open(
        data.product.terms[i18n.language]
          ? data.product.terms[i18n.language]
          : data.product.terms.es,
        '_blank'
      );
    }
  };

  const handlerPrintOrder = async (uuid) => {
    // Defines the pdf
    let pdf = new jsPDF({
      unit: 'mm',
      format: 'a4',
    });

    // Transforms the canvas into a base64 image
    let base64Image = document.getElementById('returnQrCode').toDataURL();

    pdf.setFont('times', 'bold');
    pdf.setFontSize(20);
    pdf.text(t('forms.order.return.orderReturnPDF.text01'), 105, 40, null, null, 'center');

    pdf.setFontSize(12);
    pdf.setFont('times', 'normal');
    pdf.text(t('forms.order.return.orderReturnPDF.text02'), 105, 50, null, null, 'center');
    pdf.text(t('forms.order.return.orderReturnPDF.text03'), 105, 55, null, null, 'center');

    // Adds the image to the pdf
    pdf.addImage(base64Image, 'png', 80, 65, 50, 50);
    pdf.setFontSize(16);
    pdf.text(uuid, 105, 130, null, null, 'center');
    // Downloads the pdf

    if (data?.requestReturn && item && order) {
      const result = await PurchaseService.printOrderReturn(item, order, data.requestReturn.uuid);
      if (result) {
        loadDataRequestItem();
        pdf.save(t('forms.order.return.orderReturnPDF.pdfName'));
      }
    }
  };

  return (
    <ItemReturnForm
      data={data}
      onBack={() => handlerOnBack()}
      showTerms={handlerShowTerms}
      // confirmReturn={() => handleSubmit()}
      onlyView={onlyView}
      orderPrint={handlerPrintOrder}
    />
  );
};

ItemReturnFormContainer.propTypes = {};

export default ItemReturnFormContainer;
