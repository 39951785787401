import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { LabelText, LabelValue, LabelValueTotal, Discraimer } from './MyCommission.styled';
import * as exchangeUtil from '../../../../utils/formats/exchange';

const MyCommissionsView = (props) => {
  const { data } = props;
  const { t } = useTranslation();
  let stelar = 0;
  let lider = 0;
  let shock = 0;
  let total = 0;
  if (data?.data && data?.data.length > 0) {
    for (const com of data.data) {
      if (com.commissionType === 0) {
        stelar = com.totalAmount;
        total += stelar;
      }
      if (com.commissionType === 1) {
        lider = com.totalAmount;
        total += lider;
      }
      if (com.commissionType === 2) {
        shock = com.totalAmount;
        total += shock;
      }
    }
  }

  const mes = t(`months.${data?.month}`);
  return (
    <>
      <div className="row">
        <div className="col-12">
          <LabelText>{t('shockBonus')}:</LabelText>
          <LabelValue>{exchangeUtil.satoshi2Crypto('GAL', shock)}</LabelValue>
          <LabelText>€</LabelText>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <LabelText>{t('stelarBonus')}:</LabelText>
          <LabelValue>{exchangeUtil.satoshi2Crypto('GAL', stelar)}</LabelValue>
          <LabelText>€</LabelText>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <LabelText>{t('leaderBonus')}:</LabelText>
          <LabelValue>{exchangeUtil.satoshi2Crypto('GAL', lider)}</LabelValue>
          <LabelText>€</LabelText>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-12">
          <LabelText>{t('total')}:</LabelText>
        </div>
      </div>
      <div className="row">
        <div className="col-12 text-center">
          <LabelValueTotal>{`${exchangeUtil.satoshi2Crypto('GAL', total)} €`}</LabelValueTotal>
        </div>
      </div>
      {/* <div className="row">
        <div className="col-12 text-end mt-3">
          <Discraimer>{t('commissionDisclaimer', { month: mes, year: data?.year })}</Discraimer>
        </div>
      </div> */}
    </>
  );
};

MyCommissionsView.propTypes = {
  data: PropTypes.any,
};

export default MyCommissionsView;
