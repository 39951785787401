/* eslint-disable indent */
import React from 'react';
import RequestMembership from '../../../components/user/requestMembership';
import Layout from '../../../components/layouts/simpleLayout';

const RequestMembershipPage = (props) => {
  return (
    <Layout
      body={
        <>
          <RequestMembership />
        </>
      }
    />
  );
};

RequestMembershipPage.propTypes = {};

export default RequestMembershipPage;
