/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import axios from 'axios';
import promise from 'promise';
import { show, hide } from 'redux-modal';
import { handleErrorResponse } from '../utils/errors/ErrorControl';
import { store } from '../store';

const showLoading = () => {
  const currentState = store.getState();
  if (
    !currentState.modal.LoadingModal ||
    (currentState.modal.LoadingModal && !currentState.modal.LoadingModal.show)
  ) {
    store.dispatch(show('LoadingModal', {}));
  }
};

const hideLoading = () => {
  const currentState = store.getState();
  if (
    currentState.modal.LoadingModal ||
    (currentState.modal.LoadingModal && currentState.modal.LoadingModal.show === true)
  ) {
    store.dispatch(hide('LoadingModal', {}));
  }
};

// Add a request interceptor
export const axiosInstanceNormal = axios.create();

// use request with credentials, auto save login token into browser
// axiosInstanceNormal.defaults.withCredentials = true;

// Interceptor that add process token into request if exist. The process token is saved in the store.
axiosInstanceNormal.interceptors.request.use(
  async (config) => {
    if (config.headers.loading) {
      showLoading();
    }
    const storeState = store.getState();
    if (storeState.auth && storeState.auth.status && storeState.auth.status.token) {
      const { token } = storeState.auth.status;
      if (token && config.headers.Authorization && config.headers.Authorization === true) {
        config.headers.Authorization = 'JWT ' + token;
      }
    }
    if (storeState?.nav?.language) {
      config.headers['Accept-Language'] = storeState.nav.language;
    }
    return config;
  },
  (error) => {
    // Do something with request error
    hideLoading();
    return promise.reject(error);
  }
);

axiosInstanceNormal.interceptors.response.use(
  async (config) => {
    hideLoading();
    return config;
  },
  (error) => {
    // Do something with request error
    hideLoading();
    handleErrorResponse(error);
    return promise.reject(error);
  }
);
