import React from 'react';
// import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import RankMedals from './RankMedals.view';
import { getMedalsSelector } from '../../../../store/selectors/userSelector';
import { getUserRankSelector } from '../../../../store/selectors/authSelector';
import ContentLoader from 'react-content-loader';
import { RANKS } from '../../../../constants/index';

const RanksMedalsContainer = (props) => {
  const medals = useSelector(getMedalsSelector);
  const rank = useSelector(getUserRankSelector);

  const dataRank = RANKS[`L${rank}`];

  const renderListLoader = () => {
    return (
      <div className="row">
        <div className="col-12 mt-4">
          <ContentLoader
            speed={1}
            viewBox="0 0 400 160"
            backgroundColor="#0000005c"
            foregroundColor="#1919195c"
            style={{ width: '100%' }}
          >
            <rect x="5" y="1" rx="3" ry="3" width="300" height="5" />
            <rect x="5" y="15" rx="3" ry="3" width="350" height="5" />
          </ContentLoader>
        </div>
      </div>
    );
  };

  return (
    <>
      {medals ? (
        <RankMedals medals={medals} rank={dataRank}></RankMedals>
      ) : (
        <>{renderListLoader()}</>
      )}
    </>
  );
};

// RanksMedalsContainer.propTypes = {};

export default RanksMedalsContainer;
