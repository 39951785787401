import { createAction } from 'redux-actions';
import { apiGet, apiPost, apiPut } from '../../api/index';

import {
  urlNitroxRegister,
  urlNitroxActivation,
  urlSubscriptionSecured,
  urlSubscriptionSecuredCancel,
  urlSubscriptionSecuredActive,
  urlSubscriptionReactivateSecured,
  urlNitroxReactive,
  urlUpdateIgnite,
} from '../../api/urls';

import { PAGE_SIZE_10 } from '../../constants/index';

const generateFilterQuery = ({ filters, page, pageSize }) => {
  let filter = '?';

  page ? (filter += 'page=' + page) : (filter += 'page=1');
  pageSize ? (filter += '&pageSize=' + pageSize) : (filter += '&pageSize=' + PAGE_SIZE_10);

  if (filters) {
    if (filters.active !== -1) {
      filter += '&active=' + (filters.active === 0 ? false : true);
    }
    if (filters.userUuid) {
      filter += '&userUuid=' + filters.userUuid;
    }
    if (filters.productUuid) {
      filter += '&productUuid=' + filters.productUuid;
    }
    if (filters.packageUuid) {
      filter += '&packageUuid=' + filters.packageUuid;
    }
  }

  return filter;
};

/**
 * Filter list Notification.
 */
export const filterSubscriptions = createAction('FILTER_SUBSCRIPTIONS', (filter, param) =>
  apiGet(urlSubscriptionSecured + generateFilterQuery(filter), param, true)()
);

/**
 * Add Item shopinng cart.
 */
export const registerNitrox = createAction('REGISTER_NITROX', (obj, param) =>
  apiPost(urlNitroxRegister, obj, param, true)()
);

/**
 * Add Item shopinng cart.
 */
export const reactivateNitrox = createAction('REGISTER_NITROX', (obj, param) =>
  apiPost(urlNitroxReactive, obj, param, true)()
);

/**
 * Get Product activation nitrox.
 */
export const getNitroxProductByToken = createAction('GET_NITROX_ACCOUNT', (param) =>
  apiGet(urlNitroxActivation, param, true)()
);

/**
 * Cancel service.
 */
export const cancelService = createAction('CANCEL_SERVICE', (param) =>
  apiPut(urlSubscriptionSecuredCancel, undefined, param, true)()
);

/**
 * Active service.
 */
export const activeService = createAction('ACTIVE_SERVICE', (param) =>
  apiPut(urlSubscriptionSecuredActive, undefined, param, true)()
);

/**
 * Load data susbcrition reactivation.
 */
export const loadSubsReactivation = createAction('LOAD_REACTIVATION', (param) =>
  apiGet(urlSubscriptionReactivateSecured, param, true)()
);
