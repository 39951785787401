import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import queryString from 'query-string';
import * as NavService from '../services/NavService';

class CustomRoute extends Component {
  componentDidMount() {
    const query = queryString.parse(this.props.location.search);
    if (query && query.evoRef) {
      NavService.setEvolutionRef(query.evoRef);
    }
    if (query && query.campaign) {
      NavService.setCampaignRef(query.campaign);
    }
  }

  render() {
    const { component: Component, ...rest } = this.props;
    return <Route {...rest} render={(props) => <Component {...props} />} />;
  }
}

export default CustomRoute;
