import styled from 'styled-components';

export const IconStyled = styled.i`
  width: 30px;
`;

export const ImgIconStyled = styled.img`
  width: 18px;
  margin-right: 12px;
`;

export const IconUser = styled.i`
  font-size: 0.775rem;
  margin-right: 5px;
`;

export const IconArrowLogin = styled.i`
  font-size: 0.775rem;
  margin-left: 5px;
`;

export const LabelLogin = styled.span`
  margin-right: 5px;
  margin-top: 3px;
`;

export const LoginButton = styled.button`
  background: transparent;
  border: 0px;
  text-decoration: none;
  text-transform: capitalize;
  color: #a6a79f;
  font-size: 0.85rem;
  font-family: 'Lato', sans-serif !important;
  margin-right: 10px;
  display: flex;
  align-items: center;
`;
