import styled from 'styled-components';

export const ContainerList = styled.div`
  border-bottom: 1px solid #ccc;
`;

export const LogoWallet = styled.img`
  width: 100%;
  max-width: 60px;
`;

export const InfoContainer = styled.div`
  width: 100%;
  background-color: #000;
  padding: 25px 35px 35px;
  border-radius: 25px;
  -webkit-box-shadow: 10px 9px 28px -12px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 9px 28px -12px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 9px 28px -12px rgba(0, 0, 0, 0.75);
  border: 1px solid #555;
`;

export const LabelInfo = styled.div`
  font-weight: bold;
`;

export const NormalInfoText = styled.div`
  margin-left: 0px;
  margin-top: 2px;
`;

export const AddressInfoText = styled.div`
  font-weight: bold;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1rem;
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const AmountInfoText = styled.div`
  font-weight: bold;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1.5rem;
`;

export const NoInfoText = styled.div`
  margin-top: 10px;
`;

export const TextList = styled.div`
  font-size: 0.875rem;
`;

export const TransactionLink = styled.a`
  font-size: 0.875rem;
  margin-left: 10px;
`;

export const HeaderList = styled.div`
  background: #35363a;
  margin-right: 0px;
  margin-left: 0px;
  color: #fff;
  padding: 10px 15px;
  border: 1px solid #35363a;
`;

export const RowList = styled.div`
  margin-right: 0px;
  margin-left: 0px;
`;
//#00a79d,  d9be00

export const ContainerIconWallet = styled.div`
  margin-right: 20px;
  margin-left: 20px;
  width: 60px;
  border-bottom: ${({ active, theme }) =>
    !active ? '0px solid #e7e7e7' : `3px solid ${theme.colors.primary}`};
  display: flex;
  justify-content: center;
  /* width: 100px !important; */
  &:hover {
    border-bottom: ${({ active, theme }) =>
      !active ? `3px solid ${theme.colors.primary}` : `3px solid ${theme.colors.primary}`};
  }
`;

export const ButtonCurrency = styled.button`
  background: transparent;
  border: 0px;
  padding: 10px;
  border-radius: 50px;
`;

export const ButtonCurrencyImg = styled.img`
  background: transparent;
  height: 40px !important;
`;

export const ImgWaynance = styled.img`
  background: transparent;
  width: 100%;
`;
