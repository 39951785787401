const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};

let GtraderPixel;
let OnePixel;
let VidaPixel;

const initGtraderPixel = () => {
  // GtraderPixel = require('react-facebook-pixel').default;
  // GtraderPixel.init(process.env.REACT_APP_GTRADER_PIXEL_TRACKER, advancedMatching, options);
  // GtraderPixel.pageView();
};

const initOnePixel = () => {
  OnePixel = require('react-facebook-pixel').default;
  OnePixel.init(process.env.REACT_APP_ONE_PIXEL_TRACKER, advancedMatching, options);
  // OnePixel.pageView();
};

const initVidaPixel = () => {
  VidaPixel = require('react-facebook-pixel').default;
  VidaPixel.init(process.env.REACT_APP_VIDA_PIXEL_TRACKER, advancedMatching, options);
  // VidaPixel.pageView();
};

export const InitPixels = () => {
  initGtraderPixel();
  initOnePixel();
  initVidaPixel();
};

export const getGtraderPixel = () => {
  return GtraderPixel;
};

export const getOnePixel = () => {
  return OnePixel;
};

export const getVidaPixel = () => {
  return VidaPixel;
};

export const getPixelByCampaign = (name) => {
  let pixel;
  switch (name) {
    case 'ONE':
      pixel = OnePixel;
      break;
    case 'VIDA':
      pixel = VidaPixel;
      break;
    default:
      break;
  }
  return pixel;
};

export const getDataPixel = (name) => {
  let data = {};
  switch (name) {
    case 'ONE':
      data.pixel = process.env.REACT_APP_ONE_PIXEL_TRACKER;
      data.token = process.env.REACT_APP_ONE_API_CONVERSION_TOKEN;
      break;
    case 'VIDA':
      data.pixel = process.env.REACT_APP_VIDA_PIXEL_TRACKER;
      data.token = process.env.REACT_APP_VIDA_API_CONVERSION_TOKEN;
      break;
    default:
      break;
  }
  return data;
};
