import { store } from '../store/index';
import {
  filterNotification,
  removeNotification,
  modifyNotification,
  modifyAllNotification,
  getNotificationByUuid,
  createNotification,
  getCountPendingNotification,
  filterGeneralNotification,
} from '../store/actions/NotificationActions';

/**
 * Create Notification
 */
export const addNotification = async (values) => {
  const objSend = { ...values };
  const result = await store.dispatch(createNotification(objSend));
  if (result?.payload?.data?.uuid) {
    return 'OK';
  }
  return null;
};

/**
 * Get Notification
 */
export const getNotification = async (uuid) => {
  const param = [uuid];
  const result = await store.dispatch(getNotificationByUuid(param));
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};

/**
 * Update Notification
 */
export const updateNotification = async (values, uuid) => {
  const objSend = { ...values };
  const param = [uuid];
  const result = await store.dispatch(modifyNotification(objSend, param));
  if (result?.payload?.data?.uuid) {
    return 'OK';
  }
  return null;
};

/**
 * List Notification
 */
export const listFilter = async (filter) => {
  const result = await store.dispatch(filterNotification(filter));
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};

/**
 * List Notification
 */
export const listGeneralFilter = async (filter) => {
  const result = await store.dispatch(filterGeneralNotification(filter));
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};

/**
 * Delete Notification
 */
export const readedNotification = async (uuid) => {
  const param = [uuid];
  const objSend = { readed: true, dateReaded: new Date() };
  const result = await store.dispatch(modifyNotification(objSend, param));
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};

/**
 * Mark all Notification
 */
export const readeAllNotification = async () => {
  const result = await store.dispatch(modifyAllNotification());
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};

/**
 * Get pending Notification
 */
export const getPendingNotification = async (filter) => {
  const result = await store.dispatch(getCountPendingNotification(filter));
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};
