/* eslint-disable indent */
import React from 'react';
import FromCampaign from '../../../components/others/shockwave/fromCampaign';
import Layout from '../../../components/layouts/simpleLayout';
import PropTypes from 'prop-types';

const FromCampaignPage = (props) => {
  return (
    <Layout
      // showUserMenu={false}
      body={
        <>
          <FromCampaign token={props.token} />
        </>
      }
    />
  );
};

FromCampaignPage.propTypes = {
  token: PropTypes.string,
};

export default FromCampaignPage;
