import styled from 'styled-components';

export const SettingButton = styled.button`
  border: 0px;
  background: transparent;
  color: ${({ active, theme }) =>
    active ? theme.links.settings.active : theme.links.settings.default};
  text-decoration: ${({ active }) => (active ? 'underline' : 'none')};
  padding: 10px 10px 10px 10px;
  font-size: 1.2rem;
  font-weight: 500;

  &:hover {
    color: ${({ active, theme }) =>
      active ? theme.links.settings.active : theme.links.settings.hover};
  }
`;

export const BodyContainer = styled.div`
  min-height: calc(100vh - 50px) !important;
  background-color: ${({ theme }) => (theme ? theme.body.backgroundColor : '#fff')};
  padding: 0px;
`;

export const PageContent = styled.div`
  margin-right: -15px;
  margin-left: -15px;
`;

export const SectionBanner = styled.div`
  background: ${({ bannerName }) =>
    `url('/assets/themes/${process.env.REACT_APP_THEME}/images/${bannerName}')`};
  background-position: ${({ bannerPosition }) => `bottom ${bannerPosition}% center`};
  background-repeat: no-repeat;
  background-size: 100%;
  /* background-attachment: fixed; */
  height: 20vh;
  background-blend-mode: multiply;
  margin-right: -15px;
  margin-left: -15px;
`;

export const BannerText = styled.div`
  color: #fff;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
