import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { connectModal } from 'redux-modal';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { ButtonContainer } from './ModalInfo.styled';
import { Button } from '@arcaitprod/react-basic-components';

class ModalInfo extends Component {
  componentDidMount() {}

  render() {
    const { show, handleHide, body } = this.props;
    return (
      <Modal size="lg" show={show} onHide={handleHide} centered backdrop={true}>
        <Modal.Body>
          <div className="row">
            <div className="col-12 text-center">{body}</div>
          </div>
          <div className="row justify-content-center align-items-center">
            <ButtonContainer className="col-12 col-md-3 col-lg-3 ">
              <Button
                type="button"
                className="btn btn-md btn-block"
                variant="primary"
                onClick={() => handleHide()}
              >
                OK
              </Button>
            </ButtonContainer>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

ModalInfo.propTypes = {
  show: PropTypes.any,
  handleHide: PropTypes.func,
  body: PropTypes.any,
};

export default connectModal({ name: 'ModalInfo' })(withTranslation()(ModalInfo));
