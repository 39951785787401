import { combineReducers } from 'redux';
import { reducer as modal } from 'redux-modal';
// import { ignoreActions } from 'redux-ignore';

import { auth } from './auth';
import { user } from './user';
import { nav } from './nav';
import { dashboard } from './dashboard';
import { shoppingCart } from './shoppingCart';
import { walletReducer } from './walletReducer';
// INIT NOTIFICATION URLS
import { notifications } from './notification';
// END NOTIFICATION URLS
/* GENERATOR_INJECT_IMPORT_REDUCER */

export default combineReducers({
  /* GENERATOR_INJECT_ADD_REDUCER */
  notifications,
  modal,
  auth,
  dashboard,
  shoppingCart,
  nav,
  user,
  wallet: walletReducer,
});
