import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  ContainerVisible,
  IconDown,
  ContainerHidden,
  ContainerData,
  ContainerDataHidden,
  ContainerHiddenEmpty,
} from './styled';

const ContainerExpandable = (props) => {
  const {
    header,
    body,
    classNameHeader,
    classNameContainer,
    classNameBody,
    classNameContainerVisible,
    classNameContainerHidden,
    onClickActionOpen,
    onClickActionClose,
  } = props;
  const [isOpen, setIsOpen] = useState(false);

  const toggle = async () => {
    if (onClickActionOpen && !isOpen) {
      await onClickActionOpen();
    } else if (onClickActionClose && isOpen) {
      await onClickActionClose();
    }
    setIsOpen(!isOpen);
  };

  return (
    <Container className={classNameContainer}>
      <ContainerVisible
        className={classNameContainerVisible}
        onClick={toggle}
        expandable={body ? true : false}
      >
        <ContainerData className={classNameHeader}> {header || ''}</ContainerData>
        {body && <IconDown className="fas fa-chevron-right toggle" ariaExpanded={isOpen} />}
      </ContainerVisible>
      {body ? (
        <ContainerHidden className={classNameContainerHidden} ariaExpanded={!isOpen}>
          <ContainerDataHidden className={classNameBody}> {body || ''}</ContainerDataHidden>
        </ContainerHidden>
      ) : (
        <ContainerHiddenEmpty />
      )}
    </Container>
  );
};

ContainerExpandable.propTypes = {
  header: PropTypes.any,
  body: PropTypes.any,
  classNameContainer: PropTypes.string,
  classNameHeader: PropTypes.string,
  classNameBody: PropTypes.string,
  classNameContainerVisible: PropTypes.string,
  classNameContainerHidden: PropTypes.string,
  onClickActionOpen: PropTypes.func,
  onClickActionClose: PropTypes.func,
};

export default ContainerExpandable;
