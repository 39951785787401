import React, { useContext } from 'react';
import ShoppingCart from './shoppingCartNav.view';
import { useSelector } from 'react-redux';
import { getItemsShoppingCartSelector } from '../../../../store/selectors/shoppingCartSelector';
import { getAuthTokenSelector } from '../../../../store/selectors/authSelector';
import * as ProductService from '../../../../services/ProductService';
import * as NavService from '../../../../services/NavService';
import { useHistory } from 'react-router-dom';
import PATHS from '../../../../constants/paths';
import { SHOPPING_CART } from '../../../../constants/index';
import { getRegionSelector } from '../../../../store/selectors/navSelector';
import { Can, AbilityContext } from '../../../permissions/Can';
import { USER_PERMISSIONS } from '../../../permissions/rolesPermission';

const ShoppingCartNavContainer = () => {
  const items = useSelector(getItemsShoppingCartSelector);
  const auth = useSelector(getAuthTokenSelector);
  const region = useSelector(getRegionSelector);
  const history = useHistory();
  const context = useContext(AbilityContext);

  const handlerRemoveItem = async (item, index) => {
    await ProductService.removeProduct(item, index, auth);
  };

  const handlerOnchangeAmountItem = async (value, item, index) => {
    if (value !== '' && value >= 0) {
      await ProductService.changeAmountItemProduct(value, item, index, auth);
    }
  };

  const handlerGotoPay = async () => {
    if (auth) {
      history.push(PATHS.PAYMENT_CHECKOUT);
    } else {
      await NavService.setFromPage(SHOPPING_CART);
      history.push(PATHS.LOGIN);
    }
  };

  const cleanShoppingCart = async () => {
    await ProductService.cleanShoppingCartOutPurchase(auth);
  };

  return (
    <>
      {/* {context.can(USER_PERMISSIONS.IS_MEMBER) && ( */}
      <ShoppingCart
        items={items}
        onRemove={handlerRemoveItem}
        onChangeAmount={handlerOnchangeAmountItem}
        goToPay={handlerGotoPay}
        region={region}
        cleanShoppingCart={cleanShoppingCart}
      />
      {/* )} */}
    </>
  );
};

ShoppingCartNavContainer.propTypes = {};

export default ShoppingCartNavContainer;
