import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import * as UserService from '../../../services/UserService';
import * as WalletService from '../../../services/WalletService';
import { getHasWalletSelector } from '../../../store/selectors/authSelector';
import { showDialog } from '@arcaitprod/react-utils-components';
import WithdrawForm from './withdraw.view';
import PATHS from '../../../constants/paths';

class WithdrawContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      walletInfo: null,
      refreshData: false,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    const { hasWallet } = this.props;
    if (hasWallet) {
      const result = await UserService.getStatusWallet('BTC');
      if (result && result.data) {
        this.setState({ walletInfo: result.data });
      }
    }
  };

  handleSubmit = async (values) => {
    try {
      const result = await WalletService.withdraw(values);
      let title = '';
      let text = '';
      let icon = '';
      if (result && result === 'OK') {
        title = this.props.t('forms.wallet.messages.withdraw.title');
        text = this.props.t('forms.wallet.messages.withdraw.success');
        icon = 'success';
      } else {
        title = 'Error';
        text = this.props.t('forms.wallet.messages.withdraw.error');
        icon = 'error';
      }
      const okAction = () => {
        this.props.history.push(PATHS.WALLETS);
      };

      showDialog(
        title,
        text,
        icon,
        false,
        'OK',
        false,
        okAction,
        undefined,
        undefined,
        '#e10a41',
        '#272727'
      );
    } catch (error) {
      //console.log('Error', error);
    }
  };

  handleOnback = () => {
    this.props.history.push(PATHS.WALLETS);
  };

  render() {
    return (
      <WithdrawForm
        walletInfo={this.state.walletInfo}
        onSubmit={this.handleSubmit}
        onback={this.handleOnback}
        minFee={this.state.walletInfo?.withdrawConditions?.minCommission || 0}
        percentageFee={this.state.walletInfo?.withdrawConditions?.percentageCommission || 0}
      />
    );
  }
}

WithdrawContainer.propTypes = {};

const mapStateToProps = (state) => ({
  hasWallet: getHasWalletSelector(state),
});

export default withRouter(connect(mapStateToProps, {})(withTranslation()(WithdrawContainer)));
