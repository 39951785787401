import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { LabelLevel, ValueLevel } from './UsersLevels.styled';
// import { checkBootstrapSizeBreak } from '../../../../utils/device/checkDevice';

const MyLevelRank = (props) => {
  const { data } = props;
  const { t } = useTranslation();
  // const breakDevice = checkBootstrapSizeBreak();

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-12">
          <div className="row mb-3">
            <LabelLevel className="col-12">
              {`${t('level')} 1: `}
              <ValueLevel>{data?.L1.countPersonalPoints}</ValueLevel>
            </LabelLevel>
          </div>
          <div className="row mb-3">
            <LabelLevel className="col-12">
              {`${t('level')} 2: `}
              <ValueLevel>{data?.L2.countPersonalPoints}</ValueLevel>
            </LabelLevel>
          </div>
          <div className="row mb-3">
            <LabelLevel className="col-12">
              {`${t('level')} 3: `}
              <ValueLevel>{data?.L3.countPersonalPoints}</ValueLevel>
            </LabelLevel>
          </div>
          <div className="row mb-3">
            <LabelLevel className="col-12">
              {`${t('level')} 4: `}
              <ValueLevel>{data?.L4.countPersonalPoints}</ValueLevel>
            </LabelLevel>
          </div>
          <div className="row mb-3">
            <LabelLevel className="col-12">
              {`${t('level')} 5: `}
              <ValueLevel>{data?.L5.countPersonalPoints}</ValueLevel>
            </LabelLevel>
          </div>
          <div className="row mb-3">
            <LabelLevel className="col-12">
              {`${t('level')} 6: `}
              <ValueLevel>{data?.L6.countPersonalPoints}</ValueLevel>
            </LabelLevel>
          </div>
          <div className="row mb-3">
            <LabelLevel className="col-12">
              {`${t('level')} 7: `}
              <ValueLevel>{data?.L7.countPersonalPoints}</ValueLevel>
            </LabelLevel>
          </div>
          <div className="row mb-3">
            <LabelLevel className="col-12">
              {`${t('level')} 8: `}
              <ValueLevel>{data?.L8.countPersonalPoints}</ValueLevel>
            </LabelLevel>
          </div>
          <div className="row mb-3">
            <LabelLevel className="col-12">
              {`${t('level')} 9: `}
              <ValueLevel>{data?.L9.countPersonalPoints}</ValueLevel>
            </LabelLevel>
          </div>
          <div className="row mb-3">
            <LabelLevel className="col-12">
              {`${t('level')} 10: `}
              <ValueLevel>{data?.L10.countPersonalPoints}</ValueLevel>
            </LabelLevel>
          </div>
          <div className="row mb-3">
            <LabelLevel className="col-12">
              {`${t('level')} 11: `}
              <ValueLevel>{data?.L11.countPersonalPoints}</ValueLevel>
            </LabelLevel>
          </div>
          <div className="row mb-3">
            <LabelLevel className="col-12">
              {`${t('level')} 12: `}
              <ValueLevel>{data?.L12.countPersonalPoints}</ValueLevel>
            </LabelLevel>
          </div>
        </div>
      </div>
    </>
  );
};

MyLevelRank.propTypes = {
  data: PropTypes.object,
};

export default MyLevelRank;
