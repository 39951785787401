import { handleActions } from 'redux-actions';

import { SET_COUNT_NOTIFICATION } from '../../constants/index';

export const notifications = handleActions(
  {
    // [CLEAN_FILTERS]: () => ({}),
    [SET_COUNT_NOTIFICATION]: (state, action) => ({
      ...state,
      personalcount: action.payload.data?.personalcount,
      generalcount: action.payload.data?.generalcount,
    }),
  },
  {}
);
