import { createAction } from 'redux-actions';
import { apiGet, apiPost } from '../../api/index';

import {
  urlWalletTransactions,
  urlWalletSecured,
  urlKraken,
  urlWalletExchangeSecured,
  urlWalletMakeExchangeSecured,
  urlCreateCoinbaseTransaction, // Add this new URL
} from '../../api/urls';

import {
  PAGE_SIZE_10,
  LIST_TRANSACTIONS,
  SEND_WITHDRAW,
  CREATE_COINBASE_TRANSACTION,
  CREATE_COINBASE_TRANSACTION_SUCCESS,
  CREATE_COINBASE_TRANSACTION_FAILURE,
} from '../../constants/index';

const generateFilterQuery = ({ filters, page, pageSize, sort }) => {
  let filter = '?';

  page ? (filter += 'page=' + page) : (filter += 'page=1');
  pageSize ? (filter += '&pageSize=' + pageSize) : (filter += '&pageSize=' + PAGE_SIZE_10);

  if (filters) {
    if (filters.addressWallet) filter += '&addressWallet=' + filters.addressWallet;
  }

  if (sort) filter += '&sort=' + sort;

  return filter;
};

const generateKrakenFilter = (filter) => {
  let params = `?pair=${filter.crypto}${filter.currency}`;
  return params;
};

/**
 * Filter list BotConfiguration.
 */
export const listTransactions = createAction(LIST_TRANSACTIONS, (filter, param) =>
  apiGet(urlWalletTransactions + generateFilterQuery(filter), param, true)()
);

/**
 * Create BotConfiguration.
 */
export const sendWithdraw = createAction(SEND_WITHDRAW, (obj) =>
  apiPost(urlWalletSecured, obj, undefined, true)()
);

/**
 * Filter list BotConfiguration.
 */
export const exchange = createAction('GET_EXCHANGE', (filter) =>
  apiGet(urlKraken + generateKrakenFilter(filter), undefined, false)()
);

/**
 * Execute exchange.
 */
export const makeExchangeCurrency = createAction('MAKE_EXCHANGE', (obj) =>
  apiPost(urlWalletMakeExchangeSecured, obj, undefined, true, true)()
);

/**
 * Get exchange.
 */
export const getChangeCurrencies = createAction('GET_EXCHANGE', (obj) =>
  apiPost(urlWalletExchangeSecured, obj, undefined, true, true)()
);

/**
 * Create Coinbase transaction.
 */
/**
 * Create Coinbase transaction.
 */
export const createCoinbaseTransaction = (obj) => async (dispatch) => {
  dispatch({ type: CREATE_COINBASE_TRANSACTION });
  try {
    const response = await apiPost(urlCreateCoinbaseTransaction, obj, undefined, true, true)();
    console.log('response', response);

    // Assuming apiPost returns the response data directly
    dispatch({ type: CREATE_COINBASE_TRANSACTION_SUCCESS, payload: response });

    return response; // Return response for further handling if needed
  } catch (error) {
    console.error('Coinbase transaction error:', error); // Log the full error for debugging
    let errorMessage = 'An unknown error occurred';
    if (error) {
      if (typeof error === 'string') {
        errorMessage = error;
      } else if (error.message) {
        errorMessage = error.message;
      } else if (error.toString) {
        errorMessage = error.toString();
      }
    }
    dispatch({ type: CREATE_COINBASE_TRANSACTION_FAILURE, payload: errorMessage });
  }
};

export const RESET_COINBASE_TRANSACTION = 'RESET_COINBASE_TRANSACTION';

export const resetCoinbaseTransaction = () => ({
  type: RESET_COINBASE_TRANSACTION
});
