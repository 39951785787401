import styled from 'styled-components';
import { Link } from '@arcaitprod/react-basic-components';

export const IconAddItem = styled.i`
  font-size: 2.5rem;
  font-weight: bold;
  color: #fff;
  margin-bottom: 10px;
`;

export const TextAddItem = styled.span`
  color: #fff;
  font-weight: bold;
  font-size: 1.2rem;
`;

export const LinkActionAdd = styled(Link)`
  padding: 0px;
  &:hover {
    text-decoration: none;
  }
`;

export const LinkActionStyled = styled(Link)`
  margin-right: 10px;
`;

export const ActionButton = styled.button`
  color: ${({ theme }) => theme.colors.primary};
  border: 0px;
  background: transparent;
  margin-right: 10px;

  &:hover {
    color: ${({ theme }) => theme.colors.primaryLight};
    text-decoration: underline;
  }
`;

export const ActionsContainer = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  border-top: 1px solid #fff;
  padding-top: 10px;
`;

export const DefaultContainer = styled.div`
  color: ${({ theme }) => theme.colors.primary};
`;

export const DefaultIcon = styled.i`
  color: ${({ theme }) => theme.colors.primary};
  margin-right: 5px;
`;

export const ContainerAddAddress = styled.div`
  color: ${({ theme }) => theme.colors.secondary};
  padding: 20px 30px;
  border: 2px dashed #fff;
  border-radius: 10px;
  min-height: 250px;
`;

export const ContainerAddress = styled.div`
  color: ${({ theme }) => theme.colors.secondary};
  padding: 20px 30px;
  border: 1px solid #fff;
  border-radius: 10px;
  min-height: 250px;
`;

export const RowTextTitle = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-weight: bold;
  font-size: 1rem;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const RowText = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-weight: normal;
  font-size: 0.875rem;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
