import { createAction } from 'redux-actions';
import { apiPut, apiGet, apiPost } from '../../api/index';
import {
  urlauthProfile,
  urlGetunilevelTree,
  urlInfoDashboard,
  urlLanguageProfile,
  urlRequestWallet,
  urlVerifyActivationAccount,
  urlauthUpline,
  urlStatusWallet,
  urlRequestPromoter,
  urlDashboardMyWallet,
  urlDashboardUserLevels,
  urlMyCommissions,
  urlConfirmContract,
  urlDataIgnite,
  urlGenerateDataIgnite,
  urlUpdateIgnite,
  urlAuthPutProfile,
  urlAuthPutBilling,
  urlDataDesertAnts,
  urlAuthPutWaynance,
} from '../../api/urls';
import {
  UPDATE_PROFILE,
  GET_UNILEVEL_TREE,
  GET_INFO_DASHBOARD,
  UPDATE_WALLET,
  VERIFY_ACTIVATION_USER,
  GET_UPLINE,
  SET_USER_SUPPORT,
  GET_DASHBOARD_USER_LEVELS,
} from '../../constants/index';

/**
 * Get auth info profile (user loged).
 */
export const getAuthProfile = createAction('SET_AUTH_PROFILE', () =>
  apiGet(urlauthProfile, undefined, true)()
);

/**
 * Get dashboard info (user loged).
 */
export const getDashBoardInfo = createAction(GET_INFO_DASHBOARD, () =>
  apiGet(urlInfoDashboard, undefined, true)()
);

/**
 * Update wallet (user loged).
 */
export const requestWallet = createAction(UPDATE_WALLET, (param) =>
  apiGet(urlRequestWallet, param, true, true)()
);

/**
 * Get unilevel tree.
 */
export const getUnilevelTree = createAction(GET_UNILEVEL_TREE, (param) =>
  apiGet(urlGetunilevelTree, param, true)()
);

/**
 * Update language.
 */
export const updateLanguage = createAction(UPDATE_PROFILE, (obj) =>
  apiPut(urlLanguageProfile, obj, undefined, true)()
);

/**
 * Verify activation user.
 */
export const verifyActivation = createAction(VERIFY_ACTIVATION_USER, (param) =>
  apiGet(urlVerifyActivationAccount, param, true)()
);

/**
 * Get auth info profile (user loged).
 */
export const getUpline = createAction(GET_UPLINE, () => apiGet(urlauthUpline, undefined, true)());

/**
 * Set user role at support
 */
export const setRoleSupport = createAction(SET_USER_SUPPORT, (data) => data);

/**
 * Update wallet (user loged).
 */
export const statusWallet = createAction('STATUS_WALLET', (param) =>
  apiGet(urlStatusWallet, param, true)()
);

/**
 * Update role promoter.
 */
export const updateToPromoter = createAction('UPDATE_PROMOTER', () =>
  apiPut(urlRequestPromoter, {}, undefined, true, true)()
);

/**
 * Get info my wallets.
 */
export const getMyWallets = createAction('GET_INFO_MY_WALLETS', () =>
  apiGet(urlDashboardMyWallet, undefined, true)()
);

/**
 * Get info user Levels.
 */
export const getUserLevels = createAction(GET_DASHBOARD_USER_LEVELS, (param) =>
  apiGet(urlDashboardUserLevels, param, true)()
);

/**
 * Get my commisions
 */
export const getMyCommissions = createAction('MY_COMMISSIONS', (param) =>
  apiGet(urlMyCommissions, param, true, true)()
);

/**
 * Update language.
 */
export const confirmContract = createAction('CONFIRM_CONTRACT', (obj) =>
  apiPut(urlConfirmContract, obj, undefined, true)()
);

/**
 * Get data Ignite
 */
export const getUserDataIgnite = createAction('GET_DATA_IGNITE', () =>
  apiGet(urlDataIgnite, undefined, true, true)()
);

/**
 * Get data Ignite
 */
export const generateUserDataIgnite = createAction('GENERATE_DATA_IGNITE', (obj) =>
  apiPost(urlGenerateDataIgnite, obj, undefined, true, true)()
);

/**
 * Update data user ignite.
 */
export const updateDataUserIgnite = createAction('UPDATE_DATA_USER_IGNITE', (obj) =>
  apiPut(urlUpdateIgnite, obj, undefined, true, true)()
);

/**
 * Get auth info profile (user loged).
 */
export const updateProfile = createAction(UPDATE_PROFILE, (obj) =>
  apiPut(urlAuthPutProfile, obj, undefined, true)()
);

/**
 * Get auth info profile (user loged).
 */
export const updateBilling = createAction(UPDATE_PROFILE, (obj) =>
  apiPut(urlAuthPutBilling, obj, undefined, true)()
);

/**
 * Get data Ignite
 */
export const getUserDataDesertAnts = createAction('GET_DATA_DESERT_ANTS', () =>
  apiGet(urlDataDesertAnts, undefined, true, true)()
);

/**
 * Update Waynance id.
 */
export const updateWaynanceId = createAction(UPDATE_PROFILE, (obj) =>
  apiPut(urlAuthPutWaynance, obj, undefined, true)()
);
