import { createAction } from 'redux-actions';
import { LOGIN_SUCCESS, CLEAN_AUTH, UPDATE_TOKEN, SET_TIME_TFA } from '../../constants/index';

export const loginSucess = createAction(LOGIN_SUCCESS, (data) => data);

export const logoutUser = createAction(CLEAN_AUTH, (data) => data);

export const updateToken = createAction(UPDATE_TOKEN, (data) => data);

export const setTfaTime = createAction(SET_TIME_TFA, (data) => data);
