import { axiosInstanceNormal } from '../api/axios';
import axios from 'axios';
import { ulid } from 'ulid';
import { PRODUCT_FB_CAMPAIGN } from '../constants/index';
import { getPixelByCampaign, getDataPixel } from '../campaingPixel';
import * as GeneralServices from './GeneralServices';
import { createHash } from 'crypto';

const generateHash = (data) => {
  const hash = createHash('sha256').update(data).digest('hex');

  return hash;
};

export const sendApiAddToCartEvent = async (location, pixel, token, product) => {
  const time = new Date().getTime();
  const resIp = await axios.get('https://api.ipify.org/?format=json');

  const url = `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_HOST}${location.pathname}`;
  const body = {
    data: [
      {
        event_name: 'AddToCart',
        event_time: Math.floor(time / 1000),
        action_source: 'website',
        event_source_url: url,
        event_id: ulid(),
        user_data: {
          client_ip_address: resIp.data.ip,
          client_user_agent: window.navigator.userAgent,
        },
        custom_data: {
          content_ids: [product.code],
          content_category: product.productLineType,
          content_type: 'product',
          currency: 'EUR',
          content_name: product.title,
          value: Number(product.totalPrice),
        },
      },
    ],
    // test_event_code: 'TEST14241',
  };

  return axiosInstanceNormal({
    method: 'post',
    maxBodyLength: Infinity,
    url: `https://graph.facebook.com/v17.0/${pixel}/events?access_token=${token}`,
    data: body,
    headers: {
      'Content-type': 'application/json',
    },
  })
    .then((response) => {
      if (response.data) {
        return response.data;
      }
    })
    .catch((errorResponse) => {
      console.log(errorResponse.response);
    });
};

export const sendTrackAddToCartEvent = async (campaignToken, product, location) => {
  let products;
  let campaignName;
  if (campaignToken && PRODUCT_FB_CAMPAIGN[campaignToken]) {
    products = PRODUCT_FB_CAMPAIGN[campaignToken].products;
    campaignName = PRODUCT_FB_CAMPAIGN[campaignToken].campaign;
  }
  if (campaignName && products && products.find((code) => code === product.items[0].productCode)) {
    const Pixel = getPixelByCampaign(campaignName);
    const pixelData = getDataPixel(campaignName);
    if (Pixel) {
      Pixel.fbq('track', 'AddToCart', {
        content_name: product.title,
        content_category: product.productLineType,
        content_ids: [product.code],
        content_type: 'product',
        currency: 'EUR',
        value: Number(product.totalPrice),
      });

      sendApiAddToCartEvent(location, pixelData.pixel, pixelData.token, product);
    }
  }
};

export const sendApiLeadEvent = async (location, pixel, token) => {
  const time = new Date().getTime();
  const resIp = await axios.get('https://api.ipify.org/?format=json');

  const url = `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_HOST}${location.pathname}`;

  const body = {
    data: [
      {
        event_name: 'Lead',
        event_time: Math.floor(time / 1000),
        action_source: 'website',
        event_id: ulid(),
        event_source_url: url,
        user_data: {
          client_ip_address: resIp.data.ip,
          client_user_agent: window.navigator.userAgent,
        },
      },
    ],
    // test_event_code: 'TEST14241',
  };

  return axiosInstanceNormal({
    method: 'post',
    maxBodyLength: Infinity,
    url: `https://graph.facebook.com/v17.0/${pixel}/events?access_token=${token}`,
    data: body,
    headers: {
      'Content-type': 'application/json',
    },
  })
    .then((response) => {
      if (response.data) {
        return response.data;
      }
    })
    .catch((errorResponse) => {
      console.log(errorResponse);
    });
};

export const sendTrackLeadEvent = async (campaignToken, itemCode, location) => {
  let codes;
  let campaignName;
  if (campaignToken && PRODUCT_FB_CAMPAIGN[campaignToken]) {
    codes = PRODUCT_FB_CAMPAIGN[campaignToken].products.concat(
      PRODUCT_FB_CAMPAIGN[campaignToken].groups
    );
    campaignName = PRODUCT_FB_CAMPAIGN[campaignToken].campaign;
  }

  if (campaignName && codes && codes.find((code) => code === itemCode)) {
    const Pixel = getPixelByCampaign(campaignName);
    const pixelData = getDataPixel(campaignName);
    if (Pixel) {
      Pixel.fbq('track', 'Lead');

      sendApiLeadEvent(location, pixelData.pixel, pixelData.token);
    }
  }
};

export const sendApiInitCheckOutEvent = async (location, pixel, token, user, items) => {
  const time = new Date().getTime();
  const resIp = await axios.get('https://api.ipify.org/?format=json');

  const url = `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_HOST}${location.pathname}`;
  const countries = await GeneralServices.getAllNationalities();
  const country = countries.find((contr) => contr.uuid === user.countryUuid);

  const contentIds = [];
  let total = 0;
  for (const item of items) {
    contentIds.push(item.productCode);
    total += item.unitPrice;
  }

  const body = {
    data: [
      {
        event_name: 'InitiateCheckout',
        event_time: Math.floor(time / 1000),
        action_source: 'website',
        event_id: ulid(),
        event_source_url: url,
        user_data: {
          client_ip_address: resIp.data.ip,
          client_user_agent: window.navigator.userAgent,
          em: [generateHash(user.email.toLowerCase())],
          country: [generateHash(country.iso2.toLowerCase())],
          fn: [generateHash(user.contactName.toLowerCase())],
          ln: [generateHash(user.contactName.toLowerCase())],
        },
        custom_data: {
          content_ids: contentIds,
          content_type: 'product',
          currency: 'EUR',
          num_items: `${items.length}`,
          value: Number(total),
        },
      },
    ],
    // test_event_code: 'TEST14241',
  };

  return axiosInstanceNormal({
    method: 'post',
    maxBodyLength: Infinity,
    url: `https://graph.facebook.com/v17.0/${pixel}/events?access_token=${token}`,
    data: body,
    headers: {
      'Content-type': 'application/json',
    },
  })
    .then((response) => {
      if (response.data) {
        return response.data;
      }
    })
    .catch((errorResponse) => {
      console.log(errorResponse.response);
    });
};

export const sendTrackInitCheckoutEvent = async (campaignToken, location, user, items) => {
  let campaignName;
  let products;
  const itemsCampaign = [];

  if (campaignToken && PRODUCT_FB_CAMPAIGN[campaignToken]) {
    products = PRODUCT_FB_CAMPAIGN[campaignToken].products;
    campaignName = PRODUCT_FB_CAMPAIGN[campaignToken].campaign;
  }

  for (const item of items) {
    const it = products && products.find((code) => code === item.productCode);
    if (it) {
      itemsCampaign.push(item);
    }
  }

  if (campaignName && itemsCampaign && itemsCampaign.length > 0) {
    const Pixel = getPixelByCampaign(campaignName);
    const pixelData = getDataPixel(campaignName);
    if (Pixel) {
      Pixel.fbq('track', 'InitiateCheckout');

      sendApiInitCheckOutEvent(location, pixelData.pixel, pixelData.token, user, itemsCampaign);
    }
  }
};

export const sendApiPurchaseEvent = async (location, pixel, token, user, items) => {
  const time = new Date().getTime();
  const resIp = await axios.get('https://api.ipify.org/?format=json');

  const url = `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_HOST}${location.pathname}`;
  const countries = await GeneralServices.getAllNationalities();
  const country = countries.find((contr) => contr.uuid === user.countryUuid);

  const contentIds = [];
  let total = 0;
  for (const item of items) {
    contentIds.push(item.productCode);
    total += item.unitPrice;
  }

  const body = {
    data: [
      {
        event_name: 'Purchase',
        event_time: Math.floor(time / 1000),
        action_source: 'website',
        event_id: ulid(),
        event_source_url: url,
        user_data: {
          client_ip_address: resIp.data.ip,
          client_user_agent: window.navigator.userAgent,
          em: [generateHash(user.email.toLowerCase())],
          country: [generateHash(country.iso2.toLowerCase())],
          fn: [generateHash(user.contactName.toLowerCase())],
          ln: [generateHash(user.contactName.toLowerCase())],
        },
        custom_data: {
          content_ids: contentIds,
          content_type: 'product',
          currency: 'EUR',
          num_items: `${items.length}`,
          value: Number(total),
        },
      },
    ],
    // test_event_code: 'TEST14241',
  };

  return axiosInstanceNormal({
    method: 'post',
    maxBodyLength: Infinity,
    url: `https://graph.facebook.com/v17.0/${pixel}/events?access_token=${token}`,
    data: body,
    headers: {
      'Content-type': 'application/json',
    },
  })
    .then((response) => {
      if (response.data) {
        console.log(response.data);
        return response.data;
      }
    })
    .catch((errorResponse) => {
      console.log(errorResponse.response);
    });
};

export const sendTrackPurchaseEvent = async (campaignToken, location, user, items) => {
  let campaignName;
  let products;
  const itemsCampaign = [];

  if (campaignToken && PRODUCT_FB_CAMPAIGN[campaignToken]) {
    products = PRODUCT_FB_CAMPAIGN[campaignToken].products;
    campaignName = PRODUCT_FB_CAMPAIGN[campaignToken].campaign;
  }

  let total = 0;
  for (const item of items) {
    const it = products && products.find((code) => code === item.productCode);
    if (it) {
      itemsCampaign.push(item);
      total += item.unitPrice;
    }
  }

  if (campaignName && itemsCampaign && itemsCampaign.length > 0) {
    const Pixel = getPixelByCampaign(campaignName);
    const pixelData = getDataPixel(campaignName);
    if (Pixel) {
      Pixel.fbq('track', 'Purchase', {
        currency: 'EUR',
        value: total,
        num_items: `${itemsCampaign.length}`,
      });

      sendApiPurchaseEvent(location, pixelData.pixel, pixelData.token, user, itemsCampaign);
    }
  }
};
