import styled from 'styled-components';

export const ItemStatus = styled.div`
  font-size: 0.8rem;
  font-weight: bold;
  background: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  width: fit-content;
  padding: 1px 3px;
`;

export const ItemTitle = styled.div`
  font-size: 0.8rem;
  font-weight: bold;
`;

export const ItemData = styled.div`
  font-size: 0.75rem;
`;

export const RowItem = styled.div`
  margin-bottom: 20px;
  border-bottom: ${({ isLast }) => (isLast ? '0px' : '1px')} solid #616161;
  padding: 15px;
`;

export const ImageContainer = styled.div`
  margin-right: 20px;
  width: 120px;
`;

export const ImageItem = styled.img`
  max-height: 80px;
`;

// export const RowText = styled.div`
//   color: ${({ theme }) => theme.colors.secondary};
//   font-weight: ${({ readed }) => (readed ? 'normal' : 'bold')};
//   font-size: ${({ readed }) => (readed ? '0.875rem' : '0.9rem')};
//   padding-left: ${({ date }) => (date ? '30px' : '0px')};
//   max-width: 100%;
//   text-overflow: ellipsis;
//   white-space: nowrap;
//   overflow: hidden;
// `;

export const HeadContainer = styled.div`
  background-color: #000;
  border: 1px solid #000;
  margin: 0px;
  padding: 10px 10px;
  border-radius: 10px 10px 0px 0px;
`;

export const ItemsContainer = styled.div`
  background-color: #242223;
  border: 1px solid #000;
  margin: 0px;
  padding: 10px 10px;
  border-radius: 0px;
`;

export const FooterContainer = styled.div`
  background-color: #000;
  border: 1px solid #000;
  margin: 0px 0px 20px 0px;
  padding: 10px 10px;
  height: 50px;
  border-radius: 0px 0px 10px 10px;
`;

export const LabelHead = styled.div`
  font-size: 0.77rem;
  text-transform: uppercase;
`;

export const ValueHead = styled.span`
  font-size: 0.77rem;
  text-transform: uppercase;
`;

export const ValueAddress = styled.span`
  font-size: 0.87rem;
  color: #469fa3;
  cursor: pointer;
  width: fit-content;
`;

export const ActionLink = styled.button`
  font-size: 0.87rem;
  color: #469fa3;
  cursor: pointer;
  border: 0px;
  background-color: transparent;

  &:hover {
    text-decoration: underline;
  }
`;
