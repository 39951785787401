import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  LogoEvo,
  TitleSection,
  SocialButton,
  IconSocial,
  LinkSection,
  SocialRazon,
  ContainerFooter,
  CopyRight,
} from './styled';
import { useHistory } from 'react-router-dom';
import PATHS from '../../../../constants/paths';

const Footer = () => {
  const { t } = useTranslation();
  let history = useHistory();

  const goTo = (path) => {
    history.push(path);
  };

  const goToSocial = (social) => {
    let url;
    switch (social) {
      case 'telegram':
        url = 'https://t.me/ARCAProject';
        break;
      case 'youtube':
        url = 'https://youtube.com/@ARCA-cx3ef?si=l0Ii1kxtMjumsVx1';
        break;
      case 'whatsapp':
        url = 'https://chat.whatsapp.com/BRjjEh7VnqY6YrtqNLri3Z';
        break;
      // case 'facebook':
      //   url = 'https://www.facebook.com/evolutionnetworkoficial';
      //   break;
      // case 'instagram':
      //   url = 'https://www.instagram.com/evolution_network_global/';
      //   break;
      // case 'twitter':
      //   url = 'https://twitter.com/Evolution_NTW';
      //   break;
      // case 'tiktok':
      //   url = 'https://www.tiktok.com/@evolutionnetwork_oficial?lang=es';
      //   break;
      default:
        break;
    }

    if (url) {
      window.open(url, '_blank');
    }
  };

  return (
    <>
      <ContainerFooter className="row no-margin justify-content-center">
        <div className="col-12 col-md-6 col-lg-5 ps-5">
          <div className="row">
            <div className="col-5">
              <LogoEvo
                loading="lazy"
                src={`/assets/themes/${process.env.REACT_APP_THEME}/images/arca.png`}
                alt=""
              />
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-12 mt-4">
              <CopyRight>Copyright &reg; 2024 The ARCA Project</CopyRight>
              <CopyRight>{t('footer.reserved')}.</CopyRight>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-5 col-lg-4">
          <div className="row">
            <div className="col-12 py-4">
              <TitleSection>{t('footer.information')}</TitleSection>
            </div>
          </div>
          <div className="row">
            <div className="col-12 mb-2">
              <LinkSection href={t('docs.termsAndConditions')} target="_blank">
                {t('footer.conditions')}
              </LinkSection>
            </div>
          </div>
          <div className="row">
            <div className="col-12 mb-2">
              <LinkSection href={t('docs.policy')} target="_blank">
                {t('footer.policy')}
              </LinkSection>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-11 col-lg-3">
          <div className="row">
            <div className="col-12 py-4">
              <TitleSection>{t('footer.contact')}</TitleSection>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <SocialRazon>support@thearcaproject.com</SocialRazon>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-12 d-flex align-items-center">
              <SocialRazon>{t('footer.channel')}:</SocialRazon>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <SocialButton onClick={() => goToSocial('telegram')} title="Telegram">
                <div className="container-img-connect fl-c">
                  <IconSocial className="fa-brands fa-telegram"></IconSocial>
                </div>
              </SocialButton>
              <SocialButton onClick={() => goToSocial('youtube')} title="Youtube">
                <div className="container-img-connect fl-c">
                  <IconSocial className="fa-brands fa-youtube"></IconSocial>
                </div>
              </SocialButton>
              <SocialButton onClick={() => goToSocial('whatsapp')} title="Whatsapp">
                <div className="container-img-connect fl-c">
                  <IconSocial className="fa-brands fa-whatsapp"></IconSocial>
                </div>
              </SocialButton>
              {/*<SocialButton onClick={() => goToSocial('tiktok')} title="Tiktok">
                <div className="container-img-connect fl-c">
                  <IconSocial className="fa-brands fa-tiktok"></IconSocial>
                </div>
              </SocialButton>
               <SocialButton onClick={() => goToSocial('facebook')} title="Facebook">
                <div className="container-img-connect fl-c">
                  <IconSocial className="fa-brands fa-facebook-f"></IconSocial>
                </div>
              </SocialButton>
              <SocialButton onClick={() => goToSocial('twitter')} title="Twitter">
                <div className="container-img-connect fl-c">
                  <IconSocial className="fa-brands fa-twitter"></IconSocial>
                </div>
              </SocialButton>
              <SocialButton onClick={() => goToSocial('instagram')} title="Instagram">
                <div className="container-img-connect fl-c">
                  <IconSocial className="fa-brands fa-instagram"></IconSocial>
                </div>
              </SocialButton> */}
            </div>
          </div>
        </div>
      </ContainerFooter>
    </>
  );
};

Footer.propTypes = {};

export default Footer;
