import React from 'react';
import UniLevelTree from '../../../components/user/unilevelTree';
import Layout from '../../../components/layouts/simpleLayout';

const UniLevelTreePage = () => {
  return (
    <Layout
      showBanner
      bannerName="arbol.jpg"
      textBanner="unilevelTree"
      bannerPosition="46"
      body={
        <>
          <UniLevelTree />
        </>
      }
    />
  );
};

UniLevelTreePage.propTypes = {};

export default UniLevelTreePage;
