import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getUserRankSelector, getNicknameSelector } from '../../../store/selectors/authSelector';
import UsersLevels from '../dashboard/UsersLevels';
import MyQualify from '../dashboard/MyQualify';
import MyCommission from '../dashboard/MyCommission';
import MyWallets from '../dashboard/myWallets';
import RankMedals from '../dashboard/RankMedals';
import Advertisement from '../dashboard/Advertisement';
import UserStatus from '../dashboard/UserStatus';
// import LeaderComission from '../dashboard/LeaderComission';
import { checkBootstrapSizeBreak } from '../../../utils/device/checkDevice';
import { getUrlSponsorSelector } from '../../../store/selectors/userSelector';

import {
  Container01,
  SponsorLabel,
  MyUpline,
  SponsorURL,
  Container02,
  Container03,
  DefaultContainer,
  Separator,
  HeaderContainer,
  ImageEscudo,
  Name,
  LabelHeader,
  Container,
  TitleContainer,
  MyWalletContainer,
  UserContainer,
  MedalContainer,
  MyQualifyContainer,
  AnounceContainer,
  ValueHeader,
  StatusContainer,
} from './dashboardUser.styled';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Dashboard = (props) => {
  const { t } = useTranslation();
  const { upLine } = props;
  const [upLineUser, setUpLineUser] = useState(upLine?.nickname);
  const rank = useSelector(getUserRankSelector);
  const breakDevice = checkBootstrapSizeBreak();
  const nickName = useSelector(getNicknameSelector);
  const urlSponsor = useSelector(getUrlSponsorSelector);

  // const nickName = useSelector(getNicknameSelector);

  useEffect(() => {
    if (upLine) {
      setUpLineUser(upLine.nickname);
    }
  }, [upLine]);
  return (
    <>
      <Container className="row no-margin">
        <div className="col-12">
          <HeaderContainer className="row align-items-center mb-3">
            <div className="col-12 col-md-3">
              <Name>{nickName ? nickName : t('noNickname')}</Name>
            </div>
            <div className="col-12 col-md-9">
              <div className="row">
                <div className="col-12 col-md-12 col-lg-4">
                  <LabelHeader>{t('forms.dashboard.sponsor')}:</LabelHeader>
                  <ValueHeader>{upLineUser ? upLineUser : ''}</ValueHeader>
                </div>
                <div className="col-12 col-md-12 col-lg-8">
                  <LabelHeader>{t('forms.dashboard.urlSponsor')}:</LabelHeader>
                  <ValueHeader>
                    <CopyToClipboard
                      text={urlSponsor.url}
                      onCopy={() => {
                        toast(t('linkCopied'), {
                          position: 'top-right',
                          autoClose: 1500,
                          hideProgressBar: true,
                          closeOnClick: true,
                          pauseOnHover: false,
                          draggable: false,
                          progress: undefined,
                        });
                      }}
                    >
                      <span title={t('copyLink')} className="urlSponsord">
                        {urlSponsor.url} <i className="fas fa-copy"></i>
                      </span>
                    </CopyToClipboard>
                  </ValueHeader>
                </div>
              </div>
            </div>
          </HeaderContainer>
          <div className="row justify-content-center no-margin pb-4">
            <div className="col-12">
              {/* <div className="row mb-3">
                <div className="col-12">
                  <StatusContainer>
                    <UserStatus></UserStatus>
                  </StatusContainer>
                </div>
              </div> */}
              <div className="row">
                <div className="col-12 col-md-12 col-lg-3">
                  {/* <div className="row">
                    <div className="col-12">
                      <TitleContainer>{t('forms.dashboard.news')}</TitleContainer>
                      <AnounceContainer>
                        <Advertisement></Advertisement>
                      </AnounceContainer>
                    </div>
                  </div> */}
                  <div className="row">
                    <div className="col-12 mt-3">
                      <TitleContainer>{t('forms.dashboard.ranks')}</TitleContainer>
                      {/* <MedalContainer> */}
                      <div className="ps-3">
                        <RankMedals />
                      </div>

                      {/* </MedalContainer> */}
                    </div>
                  </div>
                  {/* <div className="row">
                    <div className="col-12 mt-3">
                      <TitleContainer>{t('forms.dashboard.wallets')}</TitleContainer>
                      <MyWalletContainer>
                        <MyWallets />
                      </MyWalletContainer>
                    </div>
                  </div> */}
                </div>
                <div className="col-12 col-md-12 col-lg-3 mt-0 mt-md-3">
                  <div className="row">
                    <div className="col-12 col-md-12 mt-3 mt-md-0">
                      <div className="row">
                        <div className="col-12">
                          <TitleContainer>{t('forms.dashboard.qualify')}</TitleContainer>
                          <MyQualifyContainer>
                            <MyQualify></MyQualify>
                          </MyQualifyContainer>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 mt-3">
                          <TitleContainer>{t('forms.dashboard.commission')}</TitleContainer>
                          <MyWalletContainer>
                            <MyCommission></MyCommission>
                          </MyWalletContainer>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-12 col-lg-3 mt-0 mt-md-3">
                  <div className="row">
                    <div className="col-12 mt-3 mt-md-0">
                      <TitleContainer>{t('forms.dashboard.volume')}</TitleContainer>
                      <UserContainer>
                        <UsersLevels key="userVolume" volume />
                      </UserContainer>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-12 col-lg-3 mt-0 mt-md-3 mt-lg-0">
                  <div className="row">
                    <div className="col-12 mt-3">
                      <TitleContainer>{t('forms.dashboard.promotor')}</TitleContainer>
                      <UserContainer>
                        <UsersLevels key="userLevel" />
                      </UserContainer>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
      {/* <div id="dashboard">
        <div id="dashboard">
          <div className="row justify-content-center">
            <div className="col-12 col-md-11">
              <div className="row justify-content-center mt-2 mt-lg-4 mb-4">
                <div className="col-12 col-lg-4 mb-lg-0">
                  <Container03 breakDevice={breakDevice}>
                    <InfoRank rank={rank} />
                  </Container03>
                  <Separator />
                  <Container01 breakDevice={breakDevice}>
                    <div className="row mb-2">
                      <SponsorLabel className="col-12 text-center">{t('myUpline')}</SponsorLabel>
                    </div>
                    <div className="row mb-4">
                      <MyUpline className="col-12 text-center">{upLineUser || ''}</MyUpline>
                    </div>
                    <div className="row">
                      <SponsorLabel className="col-12 text-center">{t('urlSponsor')}</SponsorLabel>
                    </div>
                    <div className="row">
                      <SponsorURL className="col-12 text-center">
                        <Sponsor urls={urlSponsor} copyClipboard />
                      </SponsorURL>
                    </div>
                  </Container01>

                  <Separator />
                  <Container02 breakDevice={breakDevice}>
                    <UsersLevels />
                  </Container02>

                  <Separator />
                </div>
                <div className="col-12 col-lg-8 mb-lg-0">
                  <div className="row">
                    <div className="col-12">
                      <DefaultContainer>
                        <MyRankLevel />
                      </DefaultContainer>
                      <Separator />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <DefaultContainer>
                        <TeamCommission />
                      </DefaultContainer>
                      <Separator />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

Dashboard.propTypes = {
  urlSponsor: PropTypes.object,
  dashboardInfo: PropTypes.object,
  lang: PropTypes.string,
  promos: PropTypes.object,
};

export default Dashboard;
