import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as GeneralServices from '../../../../../../services/GeneralServices';
import PropTypes from 'prop-types';
import { REGION_WORLD } from '../../../../../../constants/index';
import { showDialog } from '@arcaitprod/react-utils-components';
import { useSelector } from 'react-redux';
import {
  getRegionSelector,
  getRegionsSelector,
} from '../../../../../../store/selectors/navSelector';
import { ImgShowSelected, LabelRegion } from './styled';
import ReactCountryFlag from 'react-country-flag';

const Regions = (props) => {
  const { breakDevice } = props;
  const { t } = useTranslation();
  const region = useSelector(getRegionSelector);
  const regionsCache = useSelector(getRegionsSelector);
  const [regions, setRegions] = useState([]);
  const [selected, setSelected] = useState(region || 'ESP');

  useEffect(() => {
    let cancel = false;
    if (!regionsCache || regionsCache.length < 100) {
      GeneralServices.getRegionsCountries().then((result) => {
        if (cancel) return;
        if (result && result.length > 0) {
          // const other = {
          //   EU: false,
          //   iso3: REGION_WORLD.code,
          //   visualName: t(REGION_WORLD.label),
          //   localName: t(REGION_WORLD.label),
          //   uuid: REGION_WORLD.code,
          // };
          // result.push(other);
          setRegions(result);
          const reg = result.find((country) => country.iso3 === 'ESP');
          if (!region) {
            GeneralServices.setPreferredRegion({ region: reg });
            setSelected(reg);
          }
        }
      });
    } else {
      setRegions(regionsCache);
      const reg = regionsCache.find((country) => country.iso3 === 'ESP');
      if (!region) {
        GeneralServices.setPreferredRegion({ region: reg });
        setSelected(reg);
      } else {
        setSelected(region);
      }
    }

    return () => {
      cancel = true;
    };
  }, [region]);

  const changeRegion = async (region) => {
    await GeneralServices.setPreferredRegion({ region: region });
    setSelected(region ? region : 'ESP');
    showDialog(
      t('region'),
      t('warningRegion'),
      'info',
      false,
      'OK',
      false,
      undefined,
      undefined,
      undefined,
      '#e10a41',
      '#272727'
    );
    // if (token && preferendLanguage && preferendLanguage !== this.props.i18n.language) {
    //   UserService.updatePreferendLanguage(lang);
    // }
  };

  return (
    <>
      {breakDevice >= 2 ? <LabelRegion>{t('buyRegion')}</LabelRegion> : ''}
      {breakDevice < 2 ? <LabelRegion>{t('shortBuyRegion')}</LabelRegion> : ''}
      <div className="dropdown select-region">
        <button
          className="btn btn-sm dropdown-toggle region-item "
          type="button"
          data-toggle="dropdown"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          id="dropdownMenuButton"
        >
          {/* <ImgShowSelected
            src={`/assets/images/flags/${selected.iso3}.${
              selected.iso3 === 'world' || selected.iso3 === 'CYP' || selected.iso3 === 'MLT'
                ? 'png'
                : 'webp'
            }`}
            alt={`${selected.localName}`}
          /> */}
          <ReactCountryFlag countryCode={selected.iso2} svg alt={`${selected.localName}`} />
          {/* {selected
            ? t(`regions.regions.${selected}`)
            : t('regions.select_option')} */}
        </button>
        <div className="dropdown-menu regions" aria-labelledby="dropdownMenuButton">
          {regions && regions.length > 0 && (
            <>
              {regions.map((reg) => {
                return (
                  <button
                    key={reg.uuid}
                    className="dropdown-item region-item "
                    onClick={() => {
                      changeRegion(reg);
                    }}
                  >
                    {/* <img
                      src={`/assets/images/flags/${reg.iso3}.${
                        reg.iso3 === 'world' || reg.iso3 === 'CYP' || reg.iso3 === 'MLT'
                          ? 'png'
                          : 'webp'
                      }`}
                      alt={`${reg.localName}`}
                    /> */}
                    <ReactCountryFlag countryCode={reg.iso2} svg />
                    <div>{reg.localName ? reg.localName : reg.visualName}</div>
                  </button>
                );
              })}
            </>
          )}
        </div>
      </div>
    </>
  );
};

Regions.propTypes = {
  preferredRegion: PropTypes.string,
  token: PropTypes.string,
  breakDevice: PropTypes.any,
};

export default Regions;
