import { createAction } from 'redux-actions';
import { apiGet } from '../../api/index';

import { urlMyCampaign, urlGetCampaignToken } from '../../api/urls';

/**
 * Filter list campaign.
 */
export const getMyCampaign = createAction('GET_MY_CAMPAIGNS', () =>
  apiGet(urlMyCampaign, undefined, true, true)()
);

/**
 * Filter list order.
 */
export const getCampaignByToken = createAction('GET_CAMPAIGN', (param) =>
  apiGet(urlGetCampaignToken, param, false, true)()
);
