import React, { useState, useContext } from 'react';
import List from '../cashflowsList';
import Filter from '../cashflowsFilter';
import { Title } from '@arcaitprod/react-basic-components';
import { useTranslation } from 'react-i18next';
import { WALLET_SERVICE } from '../../../../constants/index';
import { SecctionTitleContainer } from '../../../../design/styleComponents/globalComponents';
import { ButtonCurrency, ButtonCurrencyImg } from './cashflowsFilterList.styled';
import { Can, AbilityContext } from '../../../permissions/Can';
import { USER_PERMISSIONS } from '../../../permissions/rolesPermission';

const MovementsListPage = (props) => {
  const { t } = useTranslation();
  const [wallet, setWallet] = useState(WALLET_SERVICE['BTC']);
  const [filters, setFilters] = useState(undefined);
  const context = useContext(AbilityContext);

  const handlerOnSubmitFilter = (values) => {
    let newFilter = {};

    const valuesFilters = Object.entries(values);
    valuesFilters.forEach((value) => {
      if (value[1] && value[1].toString().length > 0) {
        newFilter[value[0]] = value[1];
      }
    });

    setFilters(newFilter);
  };

  const handlerCleanFilter = async (resetForm) => {
    resetForm();
    setFilters(undefined);
  };

  // const handlerSelectCurrency = async (currency) => {
  //   setWallet(WALLET_SERVICE[currency]);
  // };

  return (
    <div className="row no-margin">
      <div className="col-12">
        <div className="row justify-content-center ">
          <SecctionTitleContainer className="col-11 col-md-11 mt-5 mb-5">
            {t('forms.cashflow.title')}
          </SecctionTitleContainer>
        </div>
        <div className="row justify-content-center ">
          <div className="col-12 col-md-11">
            <div className="row justify-content-center justify-content-lg-end">
              <div className="col-12 col-md-11 col-lg-12 text-end">
                <Filter onSubmit={handlerOnSubmitFilter} onClean={handlerCleanFilter} />
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center ">
          <div className="col-12 col-md-11 ">
            <List className="marg-top-1rem" filters={filters} walletService={wallet} />
          </div>
        </div>
      </div>
    </div>
  );
};

MovementsListPage.propTypes = {};

export default MovementsListPage;
