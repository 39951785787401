import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import PATHS from '../../../../constants/paths';
import * as ProductService from '../../../../services/ProductService';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  getAuthTokenSelector,
  getAuthInfoSelector,
} from '../../../../store/selectors/authSelector';
import { getRegionSelector, getCampaignSelector } from '../../../../store/selectors/navSelector';
import ProductDetail from './detail.view';
import { show, hide } from 'redux-modal';
import { useDispatch } from 'react-redux';
import CountriesAvailable from '../../countriesAvailable';
import ContentLoader from 'react-content-loader';
import { useLocation } from 'react-router-dom';
import * as FBCampaignService from '../../../../services/FaceBookApiConversion';

const ProductDetailContainer = (props) => {
  const { uuid, preview, closeModal } = props;
  const history = useHistory();
  const location = useLocation();
  const auth = useSelector(getAuthTokenSelector);
  const region = useSelector(getRegionSelector);
  const { t, i18n } = useTranslation();
  const [data, setData] = useState(undefined);
  const dispatch = useDispatch();
  const userInfo = useSelector(getAuthInfoSelector);
  const campaign = useSelector(getCampaignSelector);

  // const [itemSelected, setItemSelected] = useState(null);

  useEffect(() => {
    let cancel = false;
    if (uuid) {
      ProductService.getProduct(uuid).then((result) => {
        if (cancel) return;
        if (result.uuid) {
          if (userInfo?.extra || campaign) {
            const campaignToken = userInfo?.extra ? userInfo.extra : campaign;
            FBCampaignService.sendTrackLeadEvent(
              campaignToken,
              result.items[0].productCode,
              location
            );
          }
          if (result.i18nData) {
            const languages = Object.keys(result.i18nData.data);
            for (const lng of languages) {
              i18n.addResourceBundle(lng, 'productDetail', result.i18nData.data[lng], true, true);
            }
          }
          setData(result);
        }
      });
    }

    return () => {
      cancel = true;
    };
  }, [uuid]);

  const handlerAddShoppingCart = async (values) => {
    if (values.amount > 0 && uuid) {
      const result = await ProductService.addProduct(values.amount, data, auth, t);
      if (result !== 'noStock') {
        if (userInfo?.extra || campaign) {
          const campaignToken = userInfo?.extra ? userInfo.extra : campaign;
          await FBCampaignService.sendTrackAddToCartEvent(campaignToken, data, location);
        }
        let myOffcanvas = document.getElementById('offcanvasRight');
        let bsOffcanvas = new window.bootstrap.Offcanvas(myOffcanvas);
        bsOffcanvas.show();
      }
      if (preview && closeModal) {
        closeModal();
      }
    }
  };

  const handlerClosePopup = (popupName) => {
    dispatch(hide(popupName));
  };

  const handlerShowAvailableCountries = async (countries) => {
    if (countries && countries.length > 0) {
      dispatch(
        show('ModalPreview', {
          handleHide: () => handlerClosePopup('ModalPreview'),
          body: (
            <>
              <CountriesAvailable availableCountries={countries}></CountriesAvailable>
            </>
          ),
        })
      );
    }
  };

  const renderLoader = () => {
    return (
      <div className="row">
        <div className="col-12 mt-4">
          <ContentLoader
            speed={2}
            viewBox="0 0 650 500"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            style={{ width: '100%' }}
          >
            <rect x="298" y="26" rx="3" ry="3" width="125" height="6" />
            <rect x="297" y="5" rx="3" ry="3" width="173" height="8" />
            <rect x="302" y="147" rx="3" ry="3" width="60" height="6" />
            <rect x="12" y="-1" rx="0" ry="0" width="245" height="259" />
            <rect x="302" y="161" rx="3" ry="3" width="60" height="6" />
            <rect x="412" y="143" rx="0" ry="0" width="157" height="38" />
            <rect x="16" y="328" rx="3" ry="3" width="514" height="6" />
            <rect x="298" y="83" rx="3" ry="3" width="265" height="5" />
            <rect x="298" y="94" rx="3" ry="3" width="265" height="5" />
            <rect x="298" y="104" rx="3" ry="3" width="265" height="5" />
            <rect x="298" y="215" rx="3" ry="3" width="265" height="5" />
            <rect x="298" y="226" rx="3" ry="3" width="265" height="5" />
            <rect x="15" y="295" rx="3" ry="3" width="180" height="18" />
            <rect x="16" y="345" rx="3" ry="3" width="514" height="6" />
          </ContentLoader>
        </div>
      </div>
    );
  };

  return (
    <>
      {data ? (
        <ProductDetail
          data={data}
          onSubmit={handlerAddShoppingCart}
          // productLine={productLine.toUpperCase()}
          // onBack={handlerOnback}
          // itemSelected={itemSelected}
          // onListProduct={toList}
          // onClickItem={handlerOnClickItem}
          // addToShoppingCart={handlerAddShoppingCart}
          // onChangeProduct={handlerOnChangeProduct}
          region={region}
          showAvailableCountry={handlerShowAvailableCountries}
          userInfo={userInfo || null}
        />
      ) : (
        renderLoader()
      )}
    </>
  );
};

ProductDetailContainer.propTypes = {
  uuid: PropTypes.string,
};

export default ProductDetailContainer;
