import { store } from '../store/index';
import {
  finishPurchase,
  costShippingPurchase,
  returnItem,
  confirmReturnItem,
  returnOrderPrinted,
} from '../store/actions/PurchaseActions';

/**
 * Get cost shipping
 */
export const getCostShipping = async (countryUuid) => {
  const result = await store.dispatch(costShippingPurchase([countryUuid]));
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};

/**
 * Finish buy
 */
export const finishBuy = async (
  shippingAddress,
  billingAddress,
  paymentMethod,
  paymentMethodId = undefined,
  paymentIntentId = undefined
) => {
  const objSend = {
    shippingAddress: shippingAddress.uuid,
    billingAddress: billingAddress.uuid,
    paymentMethod: paymentMethod,
    confirmIntent: false,
  };

  if (paymentMethodId && (paymentMethod === 'card' || paymentMethod === 'mixed')) {
    objSend.paymentMethodId = paymentMethodId;
  }

  if (paymentIntentId && (paymentMethod === 'card' || paymentMethod === 'mixed')) {
    objSend.paymentIntentId = paymentIntentId;
    objSend.confirmIntent = true;
  }

  const result = await store.dispatch(finishPurchase(objSend));
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};

/**
 * Request return items
 */
export const requestReturnItem = async (productUuid, orderUuid) => {
  const object = {
    itemUuid: productUuid,
    purchaseUuid: orderUuid,
  };
  const result = await store.dispatch(returnItem(object));
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};

/**
 * Confirm return items
 */
export const confirmReturn = async (productUuid, orderUuid) => {
  const object = {
    itemUuid: productUuid,
    purchaseUuid: orderUuid,
  };
  const result = await store.dispatch(confirmReturnItem(object));
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};

/**
 * Confirm return items
 */
export const printOrderReturn = async (productUuid, orderUuid, requestReturnUuid) => {
  const object = {
    itemUuid: productUuid,
    purchaseUuid: orderUuid,
  };
  const param = [requestReturnUuid];
  const result = await store.dispatch(returnOrderPrinted(object, param));
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};
