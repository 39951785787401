import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
// import { CSSTransition } from 'react-transition-group';
import * as AuthService from '../../../../services/AuthService';
import PATHS from '../../../../constants/paths';
import { ROLE_SUPPORT } from '../../../../constants/index';
// import { Button } from '@arcaitprod/react-basic-components';
// import Menu, { Item as MenuItem } from 'rc-menu';
import 'rc-menu/assets/index.css';
import { Container } from './styled';
import { checkBootstrapSizeBreak } from '../../../../utils/device/checkDevice';
import ShoppingCartNav from '../shoppingCartNav';
import Search from '../search';

class SubNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lists: true,
    };
  }

  render() {
    const { t } = this.props;
    const breakDevice = checkBootstrapSizeBreak();

    return (
      <>
        <Container className={`container-fluid ${breakDevice >= 3 ? '' : ' d-none'}`}>
          <div
            className={
              breakDevice >= 3
                ? 'row justify-content-center mt-3 pb-3'
                : 'row justify-content-center'
            }
          >
            <div className="col-12 col-md-8 no-padding-right">{/* <Search></Search> */}</div>
            {breakDevice >= 3 && (
              <div className="col-12 col-md-4 d-flex justify-content-end">
                <ShoppingCartNav />
              </div>
            )}
          </div>
        </Container>
      </>
    );
  }
}

// SubNav.contextType = AbilityContext;

SubNav.propTypes = {
  nickname: PropTypes.string,
  offCanvas: PropTypes.bool,
  userStatus: PropTypes.string,
  role: PropTypes.string,
};

export default withRouter(withTranslation()(SubNav));
