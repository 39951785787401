const PATHS = {};

PATHS.INIT = '/';
PATHS.ERROR = '/error';
PATHS.LOGOUT = '/logout';
PATHS.LOGIN = '/login';
PATHS.ABOUT_US = '/aboutUs';
PATHS.BUSINESS_PLAN = '/businessPlan';
PATHS.CONTACT = '/contact';
//
PATHS.LOGIN_IMPERSONATE = '/login/impersonate/:token';
PATHS.EXTERNAL_CONFIRM_LOGIN = '/auth/confirm/:token';
PATHS.REGISTER = '/register/:sponsorUId';
PATHS.SPONSOR = '/register/';
PATHS.CONFIRM_REGISTER = '/register/confirm';
PATHS.VERIFY_REGISTER = '/register/verify/:token';
PATHS.RECOVERY_PASSWORD = '/recovery/password/:token';
PATHS.FORGOT_PASSWORD = '/forgotPassword';
PATHS.MY_SETTINGS = '/mySettings';
PATHS.SECURITY = '/security';
PATHS.WALLETS = '/points';
PATHS.WALLETS_SETTING = '/wallet';
PATHS.UNI_TREENODES = '/treeNodes/unilevels';
PATHS.TERM_AND_CONDITIONS = '/term_and_conditions';
PATHS.WITHDRAW = '/wallet/withdraw';
PATHS.EXCHANGE_GAL = '/wallet/exchange/:from/:to';
/** CLIENT PATH */
PATHS.HOME = '/home';
// PATHS.CLIENT_HOME = '/client/home';
PATHS.SPECIAL_VERIFY = '/account/verify/:token';
PATHS.SPECIAL_ACTIVATE = '/account/active/:token';

// INIT NOTIFICATION PATHS
PATHS.NOTIFICATION = '/notification';
PATHS.NOTIFICATION_LIST = '/notification/list';
PATHS.GENERAL_NOTIFICATION_LIST = '/notification/general/list';
PATHS.CREATE_NOTIFICATION = '/notification/form/new';
PATHS.UPDATE_NOTIFICATION = '/notification/form/update/:uuid';
// END NOTIFICATION PATHS

PATHS.NEWS_DETAILS = '/news/details/:uuid';

PATHS.MY_BILLING_ADDRESS = '/billingAddress';
PATHS.REQUIRED_BILLING_ADDRESS = '/config/billingAddress';
PATHS.MY_ADDRESSES = '/myAddresses';
PATHS.LIST_MY_ADDRESSES = '/myAddresses/list';
PATHS.NEW_MY_ADDRESSES = '/myAddresses/form/new';
PATHS.UPDATE_MY_ADDRESSES = '/myAddresses/form/update/:uuid';
/* GENERATOR_INJECT_PATHS */

PATHS.PRODUCTS = '/products';
PATHS.PRODUCTS_HOME = '/products/:productLine';
PATHS.PRODUCTS_GROUP = '/products/group/:uuid';
// PATHS.PRODUCTS_WELLNESS = '/products/wellness';
// PATHS.PRODUCTS_SERVICES = '/products/services';
// PATHS.PRODUCTS_ACADEMY = '/products/academy';
// PATHS.PRODUCTS_EXPERIENCES = '/products/experiences';

PATHS.DETAIL_PRODUCT = '/products/detail/:uuid';

// PATHS.DETAIL_PRODUCT_BRAINLANG = '/products/detail/brainlang';
// PATHS.DETAIL_PRODUCT_NITROX = '/products/detail/nitrox';

PATHS.PAYMENT_CHECKOUT = '/shopping/checkout';
PATHS.CHECKOUT_COMPLETED = '/shopping/checkout/completed';

PATHS.MY_ORDER = '/orders';
PATHS.MY_ORDER_DETAIL = '/orders/detail/:uuid';
PATHS.MY_ORDER_LIST = '/orders/list';
PATHS.RETURN_ITEM = '/orders/return/:order/:item';

PATHS.MY_CASHFLOW = '/cashFlow';
PATHS.MY_CASHFLOW_LIST = '/cashFlow/list';

PATHS.MY_SUBSCRIPTIONS = '/subscriptions';
PATHS.MY_SUBSCRIPTIONS_LIST = '/subscriptions/list';

PATHS.MY_DASHBOARD = '/dashboard';

PATHS.NITROX_ACTIVATION = '/activation/account/nitrox/:token';
PATHS.NITROX_REACTIVATION = '/reactivation/account/nitrox/:token';
PATHS.REQUEST_MEMBERSHIP = '/membership/request';

PATHS.MY_COMMISSIONS = '/commissions';
PATHS.MY_COMMISSIONS_LIST = '/commissions/list';
PATHS.DESERT_ANTS = '/desertAnts';
PATHS.MY_DESERT_ANTS = '/my_machines';
// PATHS.PRODUCT_WELLNESS = '/products/wellness';
// PATHS.PRODUCT_SERVICES = '/products/services';
// PATHS.PRODUCT_ACADEMY = '/products/academy';
// PATHS.PRODUCT_STYLE = '/products/style';
// PATHS.PRODUCT_COMMERCE = '/products/commerce';
// PATHS.PRODUCT_EXPERIENCES = '/products/experiences';
// PATHS.PRODUCT_HOME = '/products/home';
// PATHS.PRODUCT_GOURMET = '/products/gourmet';

PATHS.DOCS = '/docs';
PATHS.DOCS_LIST = '/docs/list';

PATHS.IGNITE = '/ignite';
PATHS.SHOCKWAVE = '/shockwave';

PATHS.FROM_CAMPAIGN = '/from_campaign/:token';

PATHS.FREE_COURSE = '/free/course';

export default PATHS;
