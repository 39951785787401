import styled, { css } from 'styled-components';

export const ButtonSearch = styled.button`
  color: #fff !important;
  font-size: 0.875rem;
  height: 45px;
  background: #e81d76;
  border: 1px solid #e1e1e1;
  width: 100px;
  border-radius: 0px 30px 30px 0px;
`;

export const InputSearch = styled.input`
  height: 45px !important;
  border: 1px solid #e1e1e1;
  width: calc(100% - 100px);
  border-radius: 30px 0px 0px 30px;
  padding-left: 20px;
`;

export const IconNav = styled.i`
  color: #827a85 !important;
  font-size: 1.5rem;
`;

export const NavContainer = styled.nav`
  margin-left: -15px;
  margin-right: -15px;
  /* min-height: 8vh;
  background: rgb(255, 255, 255) !important;
  box-shadow: 0px 4px 5px 0px rgb(201 201 201 / 75%); */
  padding: 0px;
`;

export const IconStyled = styled.i`
  width: 30px;
`;

export const IconCloseStyled = styled.i`
  width: 30px;
  font-size: 1.4rem;
`;

export const ButtonOption = styled.button`
  ${({ theme, active }) => {
    return css`
      text-decoration: none;
      color: #575861;
      text-transform: uppercase;
      margin: 0;
      display: flex;
      align-items: center;
      border: 0px;
      background: transparent;
      height: 3rem;

      &:hover {
        /* color: ${theme ? theme.sidebar.colorLinkHover : '#fff'} !important; */
        box-shadow: 0 3px #469fa3;
        transition: 0.5s;
        cursor: pointer;
        position: relative;
        z-index: 100;
        transition: 0.3s;
        color: #575861;
      }

      &:focus {
        color: #575861;
      }
    `;
  }}
`;

export const SubButtonOption = styled.button`
  border: 0px;
  background: transparent;
  color: ${({ theme }) => (theme ? theme.sidebar.colorLink : '#fff')};
  padding-left: 0px;
  width: 100%;
`;

export const SubButtonOptionImage = styled.img`
  max-width: 65%;
  max-height: ${({ breakDevice }) =>
    breakDevice !== undefined && breakDevice <= 2 ? '50px' : '90%'};
`;

export const LogoButton = styled.button`
  border: 0px;
  background: transparent;
`;

// export const Logo = styled.img`;
//   width: 100%;
//   max-width: ${({ offCanvas }) => (offCanvas ? '100px' : '120px')};
//   margin-top: ${({ offCanvas }) => (offCanvas ? '-30px' : 'initial')};
// `;

export const UlNav = styled.ul`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* width: 45%; */
  min-height: 4vh;
  padding: 0;
  background: #fff;
`;

export const NavLink = styled.li`
  display: block;
  padding-left: 0.8rem !important;
  padding-right: 0.8rem !important;
`;

export const NavLinkMenu = styled.li`
  display: block;
  color: #fff;
  /* padding: 0.5rem 0rem; */
`;

export const NavLinkChild = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: ${({ breakDevice }) =>
    breakDevice !== undefined && breakDevice <= 2 ? '25px' : '0px'};
  padding: 0rem 0rem !important;
  /* background: #363f5a; */
`;

export const SubMenuContainer = styled.div`
  background: rgba(254, 254, 254);
  width: ${({ breakDevice }) =>
    breakDevice !== undefined && breakDevice <= 2 ? 'fit-content' : '85%'};
  border-top: 2px solid #000;
  left: 7.5% !important;
  height: auto;
  top: 13vh !important;
  position: fixed !important;
  /* opacity: 0; */
  /* visibility: hidden; */
  box-shadow: 0px 2px 30px rgb(192 187 187);
`;

export const SubMenu = styled.ul`
  /* padding: 0.5rem 0rem; */
  width: ${({ breakDevice }) => (breakDevice !== undefined && breakDevice <= 2 ? '90%' : '90%')};
  min-height: 40vh;
  display: grid;
  grid-template-columns: ${({ breakDevice }) =>
    breakDevice !== undefined && breakDevice <= 2 ? ' repeat(1, 1fr)' : ' repeat(4, 1fr)'};
  grid-template-rows: repeat(2, 1fr);
  justify-content: space-between;
  align-content: center;
  padding: 1.5rem;
  margin: 0 auto;
`;

export const BrandImage = styled.img`
  /* padding: 0.5rem 0rem; */
  max-height: 7vh;
  margin-left: 20%;
`;
