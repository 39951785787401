import styled from 'styled-components';

export const Banner = styled.div`
  background: ${() => `url('/assets/themes/${process.env.REACT_APP_THEME}/images/banner02.jpg')`};
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 300px;
`;

export const ImgBanner = styled.img`
  /* height: 70px; */
  width: 100%;
`;

export const IconOpt = styled.img`
  /* height: 70px; */
  /* width: 100%; */
  width: 55px;
  height: 55px;
  margin-right: 50px;
`;

export const ImgBanner02 = styled.img`
  /* height: 70px; */
  /* width: 100%; */
  max-height: 250px;
`;

export const Title = styled.h2`
  font-size: 1.6rem;
  font-family: 'Biome Light';
  /* font-weight: 600; */
  text-transform: uppercase;
  color: #fff;
  font-weight: bold;
  letter-spacing: 2px;
`;

export const Title2 = styled.h2`
  font-size: 1.6rem;
  font-family: 'Biome Light';
  /* font-weight: 600; */
  color: #fff;
  font-weight: bold;
  letter-spacing: 2px;
`;

export const SubTitle = styled.h3`
  font-size: 1.2rem;
  font-family: 'Biome Light';
  /* font-weight: 600; */
  color: #fff;
  margin-top: -10px;
`;

export const SubTitleIcon = styled.h3`
  font-size: 1.4rem;
  font-family: 'Biome Light';
  /* font-weight: 600; */
  color: #fff;
  margin-top: 20px;
  text-align: start;
`;

export const SubTitle01 = styled.div`
  font-size: 1.2rem;
  font-family: 'Biome Light';
  /* font-weight: 600; */
  color: #fff;
  margin-top: 10px;
`;

export const Text = styled.p`
  font-size: 0.875rem;
  font-family: 'Biome Light';
  color: #fff;
  white-space: break-spaces;
  line-height: 1rem;
`;
