import styled from 'styled-components';

export const VideoContainer = styled.div`
  background: #e6e6e6;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const IconVideo = styled.i`
  font-size: 30px;
  color: #868686;
`;
