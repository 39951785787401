import * as Yup from 'yup';

export const dataModel = {
  waynanceCode: '',
  email: '',
};

export const validationDataModel = Yup.object().shape({
  waynanceCode: Yup.string().required('forms.validations.required'),
  email: Yup.string().email('forms.validations.email').required('forms.validations.required'),
});

export const setInitialValues = (initialValues) => {
  const newValues = { ...dataModel };
  if (initialValues) {
    const items = Object.keys(newValues);
    items.forEach((item) => {
      if (initialValues[item]) {
        newValues[item] = initialValues[item];
      }
    });
    return newValues;
  }

  return dataModel;
};
