import { ErrorMessage } from 'formik';
import { apiGetExternal } from '../../api/index';

/* eslint-disable no-mixed-operators */
const translations = {
  ADA: 'ADA',
  ATOM: 'ATOM',
  BCH: 'BCH',
  BSV: 'BSV',
  DASH: 'DASH',
  EOS: 'EOS',
  GNO: 'GNO',
  KFE: 'FEEE',
  QTUM: 'QTUM',
  USDT: 'USDT',
  DAO: 'XDAO',
  ETC: 'XETC',
  ETH: 'XETH',
  ICN: 'XICN',
  LTC: 'XLTC',
  MLN: 'XMLN',
  NMC: 'XNMC',
  REP: 'XREP',
  XTZ: 'XTZ',
  BTC: 'XXBT',
  XDG: 'XXDG',
  XLM: 'XXLM',
  XMR: 'XXMR',
  XRP: 'XXRP',
  XVN: 'XXVN',
  ZEC: 'XZEC',
  CAD: 'ZCAD',
  EUR: 'ZEUR',
  GBP: 'ZGBP',
  JPY: 'ZJPY',
  KRW: 'ZKRW',
  USD: 'ZUSD',
};

const CRYPTO_ATTR = {
  BTC: {
    decimals: 8,
  },
  EUR: {
    decimals: 2,
  },
  GAL: {
    decimals: 2,
  },
  Puntos: {
    decimals: 2,
  },
  TRE: {
    decimals: 4,
  },
};

/**
 * Delete Zero
 *
 * @param  {String|Number} n
 * @return {Number}
 */
const deleteZero = (n) => (parseInt(n.toString().split('.')[1]) === 0 ? parseInt(n) : n);

/**
 * Number is float
 *
 * @param  {String|Number} n
 * @return {Number}
 */
const isFloat = (n) => n % 1 !== 0;

/**
 * Get satoshi amount from cryptocurrency
 *
 * @param  {String} crypto
 * @param  {String} value
 * @return {Promise}
 */
export const crypto2Satoshi = (crypto, value) => {
  if (CRYPTO_ATTR[crypto]) {
    const satoshi = isFloat(value * 10 ** CRYPTO_ATTR[crypto].decimals)
      ? deleteZero((value * 10 ** CRYPTO_ATTR[crypto].decimals).toFixed(0))
      : value * 10 ** CRYPTO_ATTR[crypto].decimals;

    return satoshi;
  }
  throw new Error('Invalid cryptocurrency');
};

/**
 * Get cryptocurrency value from satoshi amount
 *
 * @param  {String} crypto
 * @param  {String} satoshi
 * @return {Promise}
 */
export const satoshi2Crypto = (crypto, satoshi) => {
  if (CRYPTO_ATTR[crypto]) {
    const btc = isFloat(satoshi / 10 ** CRYPTO_ATTR[crypto].decimals)
      ? deleteZero(
          (satoshi / 10 ** CRYPTO_ATTR[crypto].decimals).toFixed(CRYPTO_ATTR[crypto].decimals)
        )
      : satoshi / 10 ** CRYPTO_ATTR[crypto].decimals;

    return btc;
  }
  throw new Error('Invalid cryptocurrency');
};

/**
 * Get exchangeFiat
 *
 * @param  {String} crypto
 * @param  {String} currency
 * @return {Promise}
 */
export const exchangeFiat = (crypto, currency) => {
  if (currency === '') {
    throw new Error('Invalid currency');
  }
  if (crypto === '') {
    throw new ErrorMessage('Invalid cryptocurrency');
  }

  return cryptoPrice(`pair=${crypto}${currency}`).then(
    (value) => value[translations[crypto] + translations[currency]].b[0]
  );
};

/**
 * Get exchangeCrypto
 *
 * @param  {String} crypto
 * @param  {String} currency
 * @return {Promise}
 */
export const exchangeCrypto = (crypto, currency) => {
  if (currency === '') {
    throw new Error('Invalid currency');
  }
  if (crypto === '') {
    throw new Error('Invalid cryptocurrency');
  }

  return cryptoPrice(`pair=${crypto}${currency}`).then(
    (value) => value[translations[crypto] + translations[currency]].a[0]
  );
};

/**
 * Get BTC price
 *
 * @param  {String} pair
 * @return {Promise}
 */
const cryptoPrice = async (pair) => {
  let response;
  const url = `https://api.kraken.com/0/public//Ticker?${pair}`;
  const result = await apiGetExternal(url, undefined, false)();
  if (result?.result) {
    response = result.result;
  }
  return response;
};

// new Promise((resolve, reject) => {
//
//   request(url)
//     .then((res) => {
//       const obj = JSON.parse(res);
//       return resolve(obj.result);
//     })
//     .catch((err) => {
//       logger.error(`Error getting ${pair} value`, err);

//       return reject(Boom.badGateway(`Error getting ${pair} value from Kraken`));
//     });
// });
