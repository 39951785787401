import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import AuthRoute from './AuthRoute';
import PATHS from '../constants/paths';
// import NotificationFormPage from '../pages/notification/form';
import SubscriptionListPage from '../pages/user/subscriptions/list';
/* GENERATOR_INJECT_IMPORT_PAGE */

class SubscriptionRoutesContainer extends Component {
  componentDidMount() {}

  render() {
    return (
      <Switch>
        <AuthRoute path={PATHS.MY_SUBSCRIPTIONS_LIST} component={SubscriptionListPage} />

        {/* GENERATOR_INJECT_LIST_ROUTE */}
        {/* <AuthRoute exact path={PATHS.CREATE_NOTIFICATION} component={NotificationFormPage} />
        <AuthRoute
          exact
          path={PATHS.UPDATE_NOTIFICATION}
          component={(props) => <NotificationFormPage uuid={props.match.params.uuid} />}
        /> */}

        {/* GENERATOR_INJECT_FORM_ROUTE */}
      </Switch>
    );
  }
}

SubscriptionRoutesContainer.propTypes = {};

export default SubscriptionRoutesContainer;
