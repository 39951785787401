import styled from 'styled-components';

export const PaginationContainer = styled.div`
  width: 100%;
  display: flex;
  /* padding-left: 30px;
  padding-right: 30px; */
  list-style: none;
  border-radius: 0.25rem;
  justify-content: ${({ canNextPage, canPreviousPage }) =>
    canNextPage && !canPreviousPage ? 'end' : 'space-between'};
  position: absolute;
  top: calc(50% - 60px);
  right: 0;
  z-index: 10;
`;

export const ButtonArrow = styled.button`
  background: transparent;
  font-size: 3rem;
  border: 0px;
  color: #dbdbdb;
  opacity: 0.5;

  &:hover {
    color: #e81d76;
    opacity: 1;
  }
`;
