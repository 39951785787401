/* eslint-disable indent */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import * as AuthService from '../../../services/AuthService';
import * as UserService from '../../../services/UserService';
import * as NavService from '../../../services/NavService';
import * as GeneralServices from '../../../services/GeneralServices';
import * as ProductServices from '../../../services/ProductService';

import PATHS from '../../../constants/paths';
import { SHOPPING_CART, PLATFORM } from '../../../constants/index';
import { getAuthTokenSelector } from '../../../store/selectors/authSelector';
import { getItemsShoppingCartSelector } from '../../../store/selectors/shoppingCartSelector';
import {
  getNavFromSelector,
  getEvoRefSelector,
  getCampaignSelector,
} from '../../../store/selectors/navSelector';
import { show } from 'redux-modal';
import PropTypes from 'prop-types';
import queryString from 'query-string';

class LoginContainer extends Component {
  constructor(props) {
    super(props);
    this.handleClear = this.handleClear.bind(this);
    this.handleClear();
  }

  componentDidMount() {
    if (this.props.clientToken) {
      this.loginImpersonateClient();
    } else {
      this.controllogin();
    }
  }

  loginImpersonateClient = async () => {
    const result = await GeneralServices.getItemTemp(this.props.clientToken, 'local');
    if (result && result.token) {
      const resultLogin = await AuthService.loginImpersonate(result.token);
      const resultInfo = await UserService.getAuthUserProfile(true);
      if (resultInfo?.data?.role) {
        this.props.history.push(PATHS.HOME);
      } else {
        this.props.history.push(PATHS.LOGIN);
      }
    } else {
      this.props.history.push(PATHS.LOGIN);
    }
  };

  handleClear = () => {
    try {
      if (this.props.token) {
        AuthService.logout();
        NavService.setCleanDashboard();
      }
      // UserService.cleanUserData();
      if (!this.props.from) {
        NavService.setCleanNav();
      }
    } catch (error) {
      console.error(error);
      this.props.history.push(PATHS.ERROR);
    }
  };

  getExternalToken = async (token, redirect) => {
    const result = await GeneralServices.getItemTemp(token);
    if (result && result.token) {
      this.confirmAccess(result.token, redirect);
    } else {
      this.props.history.push(PATHS.LOGIN);
    }
  };

  confirmAccess = async (token, redirect) => {
    const resultLogin = await AuthService.confirmExternalLogin(token);
    const resultInfo = await UserService.getAuthUserProfile();
    if (resultInfo?.data.role) {
      this.handlerShoppingCartSync(resultInfo.data);
      localStorage.setItem('evoToken', token);
      this.handlerGoToPage(redirect, resultInfo);
    } else {
      this.props.history.push(PATHS.LOGIN);
    }
  };

  handlerGoToPage = async (redirect, userInfo) => {
    let page = PATHS.HOME;
    if (redirect) {
      switch (redirect) {
        case 'course':
          page = PATHS.FREE_COURSE;
          break;

        default:
          break;
      }
    }
    if (this.props.from && this.props.from === SHOPPING_CART) {
      page = PATHS.PAYMENT_CHECKOUT;
    }
    NavService.setCleanNav();
    NavService.setCleanDashboard();
    if (!userInfo.data.billingAccept) {
      this.props.history.push(PATHS.REQUIRED_BILLING_ADDRESS);
    } else {
      this.props.history.push(page);
    }
  };

  handlerShoppingCartSync = async (data) => {
    const { items } = this.props;
    const { shoppingCartItems } = data;
    if (items && items.length > 0) {
      await ProductServices.syncShoppingCartProductToServer(items);
    } else if (shoppingCartItems && shoppingCartItems.length > 0) {
      await ProductServices.syncShoppingCartProductToLocal(shoppingCartItems);
    }
  };

  controllogin = () => {
    const query = queryString.parse(this.props.location.search);
    let forceAuth = false;
    let redirect = '';
    if (query && query.forceAuth) {
      forceAuth = true;
    }
    if (query && query.to) {
      redirect = query.to;
    }
    if (this.props.temporalToken) {
      this.getExternalToken(this.props.temporalToken, redirect);
      NavService.setCleanEvoRef();
    } else {
      const token = localStorage.getItem('evoToken');
      if (!token) {
        if (this.props.evoRef && !forceAuth) {
          window.location.href = `${process.env.REACT_APP_SMARTNET_REGISTER_EVO}/${PLATFORM}/${
            this.props.evoRef
          }${this.props.campaign ? `?extra=${this.props.campaign}` : ''}`;
        } else {
          window.location.href = `${process.env.REACT_APP_SMARTNET_LOGIN}`;
        }
      } else {
        this.confirmAccess(token, redirect);
        NavService.setCleanEvoRef();
      }
    }
  };

  render() {
    return <>Loading...</>;
  }
}

LoginContainer.propTypes = {
  temporalToken: PropTypes.string,
  clientToken: PropTypes.string,
};

const mapStateToProps = (state) => ({
  token: getAuthTokenSelector(state),
  items: getItemsShoppingCartSelector(state),
  from: getNavFromSelector(state),
  evoRef: getEvoRefSelector(state),
  campaign: getCampaignSelector(state),
});

export default withRouter(connect(mapStateToProps, { show })(withTranslation()(LoginContainer)));
