//CONSTANTS
export const PAGE_SIZE_10 = 10;
export const PAGE_SIZE_25 = 25;
export const PAGE_SIZE_50 = 50;
export const IVA = 21;
export const MIN_COMMISION = 40000;
export const TFA_TIME_SECURITY = 120000;
export const LOGIN_ATTEMPS = 3600000;
export const CACHE_GROUPS = 86400000;
export const MINUS_TIME_LIMIT = 6300000;
export const TIME_REMAINED = 900000;
export const SYMBOL_MONEY = '€';
export const SYMBOL_GALLONS = 'Puntos';
export const SYMBOL_MONEY_PAY_BTC = 'BTC';
export const SHOPPING_CART = 'SHOPPING_CART';
export const DISCOUNT_PRODUCT = 10;
export const TREVOL_VALUE_GAL = 20; //20 Trevol
export const EXPERIENCE_PRODUCT_CODE = 6;
export const PACKAGE_NITROX_CODE = 5;
export const PACKAGE_EXPERIENCE_CODE = 7;
export const PACKAGE_IGNITE_CODE = 9;
export const PACKAGE_XPLOSIVE_CODE = 193;
export const PACKAGE_SPARK_CODE = 191;
export const PACKAGE_FIRE_CODE = 192;
export const PACKAGE_FREE_CODE = 198;
export const IGNITE_PRODUCT_UUID = 'd7597f6f-06fe-4fd7-aa0d-40732b71c74c';
export const PLATFORM = 'evolution';

//WALETS
export const WALLET_SERVICE = {
  BTC: process.env.REACT_APP_WALLET_BTC_SERVICE,
  GAL: process.env.REACT_APP_WALLET_GAL_SERVICE,
  TRE: process.env.REACT_APP_WALLET_TREVOL_SERVICE,
};

//KYC STATUS
export const KYC_STATUS_PENDING = 'pending';
export const KYC_STATUS_APPROVED = 'approved';
export const KYC_STATUS_DECLINED = 'declined';

// USER ROLE
export const ROLE_STAFF = 'STAFF';
export const ROLE_PROMOTER = 'PROMOTER';

export const ROLE_SUPPORT = 'support';
export const ROLE_CLIENT = 'client';

// ACTIONS STATUS
export const GET_STATUS = 'GET_STATUS';
export const GET_ITEM = 'GET_ITEM';
export const GET_EU_COUNTRIES = 'GET_EU_COUNTRIES';
export const GET_PACKAGES_GROUPS = 'GET_PACKAGES_GROUPS';
export const SET_TIME_PACKAGES_GROUPS = 'SET_TIME_PACKAGES_GROUPS';
export const GET_ALL_COUNTRIES = 'GET_ALL_COUNTRIES';

export const FILTER_COMMISSION = 'FILTER_COMMISSION';
export const GET_RESUME_FILTER_COMMISSION = 'GET_RESUME_FILTER_COMMISSION';

// ACTIONS AUTH
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const CLEAN_AUTH = 'CLEAN_AUTH';
export const UPDATE_TOKEN = 'UPDATE_TOKEN';
export const SET_AUTH_PROFILE = 'SET_AUTH_PROFILE';
export const FORGOT_PASS = 'FORGOT_PASS';
export const RECOVERY_PASS = 'RECOVERY_PASS';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const GET_INFO_DASHBOARD = 'GET_INFO_DASHBOARD';
export const UPDATE_LANGUAGE = 'UPDATE_LANGUAGE';
export const UPDATE_WALLET = 'UPDATE_WALLET';
export const VERIFY_ACTIVATION_USER = 'VERIFY_ACTIVATION_USER';
export const GET_UPLINE = 'GET_UPLINE';
export const SET_USER_SUPPORT = 'SET_USER_SUPPORT';
export const SET_TIME_TFA = 'SET_TIME_TFA';
export const GET_DASHBOARD_USER_LEVELS = 'GET_DASHBOARD_USER_LEVELS';

// ACTIONS USER
export const USER_INFO = 'USER_INFO';
export const CLEAN_USER = 'CLEAN_USER';
export const USER_REGISTER = 'USER_REGISTER';
export const SET_TEMPORAL = 'SET_TEMPORAL';
export const VERIFY_EMAIL = 'VERIFY_EMAIL';
export const REGISTER_DATA_KYC = 'REGISTER_DATA_KYC';
export const ACTIVE_USER = 'ACTIVE_USER';

// ACTIONS NAVS
export const SET_NAV_FROM = 'SET_NAV_FROM';
export const SET_TIME_ZONE = 'SET_TIME_ZONE';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const CLEAN_NAV = 'CLEAN_NAV';
export const SET_REGION = 'SET_REGION';
export const CLEAN_DASHBOARD = 'CLEAN_DASHBOARD';
export const SET_EVO_REF = 'SET_EVO_REF';
export const CLEAN_EVO_REF = 'CLEAN_EVO_REF';
export const SET_CAMPAIGN = 'SET_CAMPAIGN';

// GLOBALS ACTIONS
export const GET_NATIONALITIES = 'GET_NATIONALITIES';
export const GET_UNILEVEL_TREE = 'GET_UNILEVEL_TREE';

export const ENE = { code: '0', label: 'months.ene' };
export const FEB = { code: '1', label: 'months.feb' };
export const MAR = { code: '2', label: 'months.mar' };
export const ABR = { code: '3', label: 'months.apr' };
export const MAY = { code: '4', label: 'months.may' };
export const JUN = { code: '5', label: 'months.jun' };
export const JUL = { code: '6', label: 'months.jul' };
export const AGO = { code: '7', label: 'months.ago' };
export const SEP = { code: '8', label: 'months.sep' };
export const OCT = { code: '9', label: 'months.oct' };
export const NOV = { code: '10', label: 'months.nov' };
export const DIC = { code: '11', label: 'months.dic' };

export const MESES = [ENE, FEB, MAR, ABR, MAY, JUN, JUL, AGO, SEP, OCT, NOV, DIC];

export const LANGUAGE_ES = {
  code: 'es',
  label: 'language.languages.es',
};
export const LANGUAGE_EN = {
  code: 'en',
  label: 'language.languages.en',
};
export const LANGUAGE_DE = {
  code: 'de',
  label: 'language.languages.de',
};
export const LANGUAGE_FR = {
  code: 'fr',
  label: 'language.languages.fr',
};
export const LANGUAGE_ID = {
  code: 'id',
  label: 'language.languages.id',
};
export const LANGUAGE_IT = {
  code: 'it',
  label: 'language.languages.it',
};
export const LANGUAGE_JA = {
  code: 'jp',
  label: 'language.languages.jp',
};
export const LANGUAGE_KO = {
  code: 'kr',
  label: 'language.languages.kr',
};
export const LANGUAGE_MS = {
  code: 'my',
  label: 'language.languages.my',
};
export const LANGUAGE_PT = {
  code: 'pt',
  label: 'language.languages.pt',
};
export const LANGUAGE_RU = {
  code: 'ru',
  label: 'language.languages.ru',
};
export const LANGUAGE_TH = {
  code: 'th',
  label: 'language.languages.th',
};
export const LANGUAGE_VI = {
  code: 'vn',
  label: 'language.languages.vn',
};
export const LANGUAGE_ZH = {
  code: 'cn',
  label: 'language.languages.cn',
};

export const REGION_EU = {
  code: 'eu',
  label: 'regions.regions.eu',
};
export const REGION_WORLD = {
  code: 'world',
  label: 'regions.regions.world',
};

export const REGIONS = [REGION_EU, REGION_WORLD];

export const LANGUAGES = [
  LANGUAGE_ES,
  LANGUAGE_EN,
  LANGUAGE_DE,
  LANGUAGE_FR,
  LANGUAGE_ID,
  LANGUAGE_IT,
  LANGUAGE_JA,
  LANGUAGE_KO,
  LANGUAGE_MS,
  LANGUAGE_PT,
  LANGUAGE_RU,
  LANGUAGE_TH,
  LANGUAGE_VI,
  LANGUAGE_ZH,
];

export const COIN_BTC = {
  code: 'BTC',
  label: 'forms.wallet.currencies.btc',
  icon: 'binance.png',
};

export const CURRENCIES = [COIN_BTC];

export const EXCHANGE_BTC_TO_GAL = {
  code: 'GAL',
  label: 'forms.wallet.exchange.toGal',
};

export const EXCHANGE_GAL_TO_BTC = {
  code: 'BTC',
  label: 'forms.wallet.exchange.toBtc',
};

export const EXCHANGE_TYPE = [EXCHANGE_BTC_TO_GAL, EXCHANGE_GAL_TO_BTC];

// INIT NOTIFICATION CONSTANTS
export const CREATE_NOTIFICATION = 'CREATE_NOTIFICATION';
export const GET_NOTIFICATION = 'GET_NOTIFICATION';
export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION';
export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION';
export const FILTER_NOTIFICATION = 'FILTER_NOTIFICATION';
export const SET_COUNT_NOTIFICATION = 'SET_COUNT_NOTIFICATION';
// END NOTIFICATION CONSTANTS

export const FILTER_ORDER = 'FILTER_ORDER';

export const CREATE_ADDRESS = 'CREATE_ADDRESS';
export const GET_ADDRESS = 'GET_ADDRESS';
export const UPDATE_ADDRESS = 'UPDATE_ADDRESS';
export const DELETE_ADDRESS = 'DELETE_ADDRESS';
export const FILTER_ADDRESS = 'FILTER_ADDRESS';
export const SET_COUNT_ADDRESS = 'SET_COUNT_ADDRESS';
export const SET_DEFAULT_ADDRESS = 'SET_DEFAULT_ADDRESS';

export const FILTER_PRODUCT = 'FILTER_PRODUCT';
export const GET_PRODUCT = 'GET_PRODUCT';
export const ADD_SHOPPING_CART = 'ADD_SHOPPING_CART';
export const CLEAN_SHOPPING_CART = 'CLEAN_SHOPPING_CART';
export const REMOVE_SHOPPING_CART = 'REMOVE_SHOPPING_CART';
export const CHANGE_AMOUNT_SHOPPING_CART = 'CHANGE_AMOUNT_SHOPPING_CART';
export const SYNC_SHOPPING_CART = 'SYNC_SHOPPING_CART';
export const LOCAL_SYNC_SHOPPING_CART = 'LOCAL_SYNC_SHOPPING_CART';
export const FINISH_PURCHASE = 'FINISH_PURCHASE';
export const GET_SHIPPING_COST = 'GET_SHIPPING_COST';
export const CREATE_PAYMENT_INTENT = 'CREATE_PAYMENT_INTENT';
export const CLEAN_SHOPPING_CART_SERVER = 'CLEAN_SHOPPING_CART_SERVER';
export const SET_PURCHASE = 'SET_PURCHASE';

export const LIST_TRANSACTIONS = 'LIST_TRANSACTIONS';
export const SEND_WITHDRAW = 'SEND_WITHDRAW';

export const BLOCK_LEVEL_NO_BLOCK = {
  code: '-1',
  label: 'forms.user.blockLevels.noBlock',
};
export const BLOCK_LEVEL1 = {
  code: 'L1',
  label: 'forms.user.blockLevels.l1',
};
export const BLOCK_LEVEL2 = {
  code: 'L2',
  label: 'forms.user.blockLevels.l2',
};
export const BLOCK_LEVEL3 = {
  code: 'L3',
  label: 'forms.user.blockLevels.l3',
};
export const BLOCK_LEVELS = [BLOCK_LEVEL_NO_BLOCK, BLOCK_LEVEL1, BLOCK_LEVEL2, BLOCK_LEVEL3];

export const PRODUCT_LINE_WELLNESS = {
  code: 'WELLNESS',
  label: 'forms.productLine.wellness',
};
export const PRODUCT_LINE_SERVICES = {
  code: 'SERVICES',
  label: 'forms.productLine.service',
};
export const PRODUCT_LINE_ACADEMY = {
  code: 'ACADEMY',
  label: 'forms.productLine.academy',
};
export const PRODUCT_LINE_STYLE = {
  code: 'STYLE',
  label: 'forms.productLine.style',
};
export const PRODUCT_LINE_COMMERCE = {
  code: 'COMMERCE',
  label: 'forms.productLine.commerce',
};
export const PRODUCT_LINE_EXPERIENCES = {
  code: 'EXPERIENCES',
  label: 'forms.productLine.experiences',
};
export const PRODUCT_LINE_HOME = {
  code: 'HOME',
  label: 'forms.productLine.home',
};
export const PRODUCT_LINE_GOURMET = {
  code: 'GOURMET',
  label: 'forms.productLine.gourmet',
};

export const PRODUCT_LINES = [
  PRODUCT_LINE_WELLNESS,
  PRODUCT_LINE_SERVICES,
  PRODUCT_LINE_ACADEMY,
  PRODUCT_LINE_STYLE,
  PRODUCT_LINE_COMMERCE,
  PRODUCT_LINE_EXPERIENCES,
  PRODUCT_LINE_HOME,
  PRODUCT_LINE_GOURMET,
];

export const CASHFLOW_TYPE_DEFAULT = {
  code: -1,
  label: 'forms.cashflow.type.default',
};
export const CASHFLOW_TYPE_PURCHASE = {
  code: 1,
  label: 'forms.cashflow.type.purchase',
};
export const CASHFLOW_TYPE_WITHDRAW = {
  code: 2,
  label: 'forms.cashflow.type.withdraw',
};
export const CASHFLOW_TYPE_DEPOSIT = {
  code: 3,
  label: 'forms.cashflow.type.deposit',
};
export const CASHFLOW_TYPE_ERROR_WITHDRAW = {
  code: 4,
  label: 'forms.cashflow.type.errorWithdraw',
};
export const CASHFLOW_TYPE_WITHDRAW_EXCHANGE = {
  code: 7,
  label: 'forms.cashflow.type.withdrawExchange',
};
export const CASHFLOW_TYPE_DEPOSIT_EXCHANGE = {
  code: 8,
  label: 'forms.cashflow.type.depositExchange',
};
export const CASHFLOW_TYPE_COMMISSION_PAYMENT = {
  code: 13,
  label: 'forms.cashflow.type.commissionPayment',
};
export const CASHFLOW_TYPE_IRPF_PAYMENT = {
  code: 14,
  label: 'forms.cashflow.type.irpfPayment',
};

export const CASHFLOW_FILTER_TYPES = [
  CASHFLOW_TYPE_DEFAULT,
  CASHFLOW_TYPE_PURCHASE,
  // CASHFLOW_TYPE_WITHDRAW,
  // CASHFLOW_TYPE_DEPOSIT,
  // CASHFLOW_TYPE_ERROR_WITHDRAW,
  // CASHFLOW_TYPE_WITHDRAW_EXCHANGE,
  // CASHFLOW_TYPE_DEPOSIT_EXCHANGE,
  CASHFLOW_TYPE_COMMISSION_PAYMENT,
  CASHFLOW_TYPE_IRPF_PAYMENT,
];

export const IGNITE_DATA_TYPE_NAME = {
  code: 'name',
  label: 'forms.ignite.labels.name',
};
export const IGNITE_DATA_TYPE_NICKNAME = {
  code: 'nickname',
  label: 'forms.ignite.labels.nickName',
};

export const IGNITE_DATA_TYPE_SHOW = [IGNITE_DATA_TYPE_NAME, IGNITE_DATA_TYPE_NICKNAME];

export const CASH_STATUS_WITH_SOLICITED = {
  code: 1,
  label: 'forms.cashflow.status.withdrawSolicited',
};
export const CASH_STATUS_WITH_CONFIRMED = {
  code: 2,
  label: 'forms.cashflow.status.withdrawConfirmed',
};
export const CASH_STATUS_WITH_SENT = {
  code: 3,
  label: 'forms.cashflow.status.withdrawSendt',
};

export const CASH_STATUS_WITH_SENT_ERROR = {
  code: 1000,
  label: 'forms.cashflow.status.withdrawSendtError',
};

export const CASHFLOW_STATUS = [
  CASH_STATUS_WITH_SOLICITED,
  CASH_STATUS_WITH_CONFIRMED,
  CASH_STATUS_WITH_SENT,
];

export const METHOD_WALLETS = {
  code: 'wallets',
  label: 'paymentMethod.wallets',
};
export const METHOD_WALLET = {
  code: 'wallet',
  label: 'paymentMethod.wallet',
};
export const METHOD_CARD = {
  code: 'card',
  label: 'paymentMethod.card',
};
export const METHOD_MIXED = {
  code: 'mixed',
  label: 'paymentMethod.mixed',
};

export const PAYMENT_METHODS = [METHOD_WALLET, METHOD_CARD, METHOD_MIXED, METHOD_WALLETS];

export const PRODUCT_STATUS_PENDING = {
  code: 'PENDING',
  label: 'productStatus.pending',
};
export const PRODUCT_STATUS_CONFIRMED = {
  code: 'CONFIRMED',
  label: 'productStatus.confirmed',
};
export const PRODUCT_STATUS_PENDING_SEND = {
  code: 'PENDING_SEND',
  label: 'productStatus.pendingSend',
};
export const PRODUCT_STATUS_SENDED = {
  code: 'SENDED',
  label: 'productStatus.sended',
};
export const PRODUCT_STATUS_RECEIVED = {
  code: 'RECEIVED',
  label: 'productStatus.received',
};
export const PRODUCT_STATUS_FINISHED = {
  code: 'FINISHED',
  label: 'productStatus.finished',
};
export const PRODUCT_STATUS_RETURN_REQUEST = {
  code: 'RETURN_REQUEST',
  label: 'productStatus.returnRequest',
};
export const PRODUCT_STATUS_RETURN_PROCESS = {
  code: 'RETURN_PROCESS',
  label: 'productStatus.returnProcess',
};
export const PRODUCT_STATUS_RETURNED = {
  code: 'RETURNED',
  label: 'productStatus.returned',
};
export const PRODUCT_STATUS_ERROR = {
  code: 'ERROR',
  label: 'productStatus.error',
};
export const PRODUCT_STATUS_CANCELED = {
  code: 'CANCELED',
  label: 'productStatus.canceled',
};
export const PRODUCT_STATUS = [
  PRODUCT_STATUS_PENDING,
  PRODUCT_STATUS_CONFIRMED,
  PRODUCT_STATUS_PENDING_SEND,
  PRODUCT_STATUS_SENDED,
  PRODUCT_STATUS_RECEIVED,
  PRODUCT_STATUS_FINISHED,
  PRODUCT_STATUS_RETURN_REQUEST,
  PRODUCT_STATUS_RETURN_PROCESS,
  PRODUCT_STATUS_RETURNED,
  PRODUCT_STATUS_ERROR,
  PRODUCT_STATUS_CANCELED,
];

export const RANKS = {
  L0: {
    value: 0,
    label: 'ranks.noRank',
    image: 'noRank',
  },
  L1: {
    value: 1,
    label: 'ranks.cadet',
    image: 'Cadet',
    color1: '#b9b9b9',
    color2: '#3a3a3a',
  },
  L2: {
    value: 2,
    label: 'ranks.cadetStelar',
    image: 'Cadet_stelar',
    color1: '#b9b9b9',
    color2: '#3a3a3a',
  },
  L3: {
    value: 3,
    label: 'ranks.pilot',
    image: 'Pilot',
    color1: '#6fc9e6',
    color2: '#103d5b',
  },
  L4: {
    value: 4,
    label: 'ranks.sargent',
    image: 'Sargent',
    color1: '#58b7cc',
    color2: '#0a3346',
  },
  L5: {
    value: 5,
    label: 'ranks.lieutenant',
    image: 'Lieutenant',
    color1: '#c2dd4a',
    color2: '#02391f',
  },
  L6: {
    value: 6,
    label: 'ranks.starLieutenant',
    image: 'Star_Lieutenant',
    color1: '#87bf5b',
    color2: '#02391f',
  },
  L7: {
    value: 7,
    label: 'ranks.captain',
    image: 'Captain',
    color1: '#f7883c',
    color2: '#8c2b20',
  },
  L8: {
    value: 8,
    label: 'ranks.starCaptain',
    image: 'Star_Captain',
    color1: '#df2a5d',
    color2: '#411215',
  },
  L9: {
    value: 9,
    label: 'ranks.commander',
    image: 'Commander',
    color1: '#a84f9f',
    color2: '#4f2440',
  },
  L10: {
    value: 10,
    label: 'ranks.starCommander',
    image: 'Star_Commander',
    color1: '#844b7e',
    color2: '#320122',
  },
};

export const RANKS_LIST = [
  RANKS.L0,
  RANKS.L1,
  RANKS.L2,
  RANKS.L3,
  RANKS.L4,
  RANKS.L5,
  RANKS.L6,
  RANKS.L7,
  RANKS.L8,
  RANKS.L9,
  RANKS.L10,
];
export const WALLET_BTC = {
  code: `BTC`,
  label: 'btc',
};
export const WALLET_GAL = {
  code: `GAL`,
  label: 'gal',
};
export const WALLET_TRE = {
  code: `TRE`,
  label: 'tre',
};
export const WALLETS = [WALLET_BTC, WALLET_GAL, WALLET_TRE];

export const SUBSCRIPTION_STATUS_DEFAULT = {
  code: -1,
  label: 'subscription_status',
};
export const SUBSCRIPTION_STATUS_ENABLED = {
  code: 1,
  label: 'enabled',
};
export const SUBSCRIPTION_STATUS_DISABLED = {
  code: 0,
  label: 'disabled',
};

export const SUBSCRIPTION_STATUS = [
  SUBSCRIPTION_STATUS_DEFAULT,
  SUBSCRIPTION_STATUS_ENABLED,
  SUBSCRIPTION_STATUS_DISABLED,
];

//////////////////////////////

export const COMMISSION_TYPE_DEFAULT = {
  code: -1,
  label: 'forms.commission.type.default',
};

export const COMMISSION_TYPE_UNILEVEL = {
  code: 0,
  label: 'forms.commission.type.unilevel',
};

export const COMMISSION_TYPE_LEADER = {
  code: 1,
  label: 'forms.commission.type.leader',
};

export const COMMISSION_TYPE_UNILEVEL_REDUCED = {
  code: 2,
  label: 'forms.commission.type.unilevelReduced',
};

export const COMMISSIONS_TYPES = [
  COMMISSION_TYPE_DEFAULT,
  COMMISSION_TYPE_UNILEVEL,
  COMMISSION_TYPE_UNILEVEL_REDUCED,
  COMMISSION_TYPE_LEADER,
];

export const COMMISSIONS_LEVELS = {
  1: '25%',
  2: '8%',
  3: '6%',
  4: '6%',
  5: '5%',
  6: '5%',
  7: '4%',
  8: '4%',
  9: '3%',
  10: '3%',
  11: '3%',
  12: '3%',
};

export const COMMISSIONS_LEVELS_LEAD = {
  1: '5%',
  2: '4%',
  3: '4%',
  4: '4%',
  5: '4%',
  6: '4%',
};

export const COMMISSIONS_LEVELS_REDUCED = {
  1: '38%',
  2: '24%',
  3: '18%',
  4: '12%',
  5: '8%',
};

export const CAMPAIGN_STATUS_ON_SELL = {
  code: 'ON_SELL',
  label: 'forms.shockwave.status.onSell',
};

export const CAMPAIGN_STATUS_ON_EXE = {
  code: 'ON_EXECUTION',
  label: 'forms.shockwave.status.onExecution',
};

export const CAMPAIGN_STATUS_CLOSED = {
  code: 'CLOSED',
  label: 'forms.shockwave.status.closed',
};

export const CAMPAIGN_STATUS_FINISHED = {
  code: 'FINISHED',
  label: 'forms.shockwave.status.finished',
};

export const CAMPAIGN_STATUS = [
  CAMPAIGN_STATUS_ON_SELL,
  CAMPAIGN_STATUS_ON_EXE,
  CAMPAIGN_STATUS_CLOSED,
  CAMPAIGN_STATUS_FINISHED,
];

export const PRODUCT_FB_CAMPAIGN = {
  '01H6NE4QWZY8KNZCE34BQZN5RE': {
    campaign: 'ONE',
    products: [26, 27, 28, 29, 30, 31, 32, 33],
    groups: ['0n37n20l1-5482-2222-a293-8547869c03d4'],
  },
  '01H6NE4QWZEBMQ8HARABTMDX4Q': {
    campaign: 'VIDA',
    products: [159],
    groups: [],
  },
};

export const COMBOS_PACK = {
  128: {
    quota: 1,
    name: 'combo.128',
  },
  64: {
    quota: 2,
    name: 'combo.64',
  },
  32: {
    quota: 4,
    name: 'combo.32',
  },
  16: {
    quota: 8,
    name: 'combo.16',
  },
  8: {
    quota: 16,
    name: 'combo.8',
  },
  4: {
    quota: 32,
    name: 'combo.4',
  },
  2: {
    quota: 64,
    name: 'combo.2',
  },
  1: {
    quota: 128,
    name: 'combo.1',
  },
};

/* GENERATOR_INJECT_CONSTANTS */
export const CRYPTO_PAYMENT_ENABLED = true;
export const CREATE_COINBASE_TRANSACTION = 'CREATE_COINBASE_TRANSACTION';
export const CREATE_COINBASE_TRANSACTION_SUCCESS = 'CREATE_COINBASE_TRANSACTION_SUCCESS';
export const CREATE_COINBASE_TRANSACTION_FAILURE = 'CREATE_COINBASE_TRANSACTION_FAILURE';
export const RESET_COINBASE_TRANSACTION = 'RESET_COINBASE_TRANSACTION';
