import React from 'react';
import Header from '../commons/header';
import { CenterPageContent } from './styled';

const CenterLayout = ({ ...prop }) => (
  <div id="sb-site">
    <div id="page-wrapper-login" className="container-fluid">
      <Header
        type="login"
        showUserMenu={prop.showUserMenu !== undefined ? prop.showUserMenu : undefined}
      />
      <CenterPageContent id="page-content">{prop.body}</CenterPageContent>
    </div>
  </div>
);

export default CenterLayout;
