/* eslint-disable indent */
import React from 'react';
import Login from '../../../components/user/login';
import Layout from '../../../components/layouts/centerLayout';
import PropTypes from 'prop-types';

const LoginPage = (props) => {
  return (
    <Layout
      showUserMenu={false}
      body={
        <>
          <Login temporalToken={props.token} />
        </>
      }
    />
  );
};

LoginPage.propTypes = {
  token: PropTypes.string,
};

export default LoginPage;
