import styled from 'styled-components';

export const ButtonCountPlus = styled.button`
  background: #ccc;
  border: 0px;
  margin-bottom: 2px;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;

  &:hover {
    background: #b4b3b3;
  }
`;

export const ButtonCountMinus = styled.button`
  border: 0px;
  margin-top: 2px;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  background: #ccc;

  &:hover {
    background: #b4b3b3;
  }
`;

export const ContainerButtons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
`;
