const urlBase = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}/api/`;
const urlBaseWallet = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_WALLET_HOST}:${process.env.REACT_APP_API_WALLET_PORT}/api/`;
export const urlKraken = `https://api.kraken.com/0/public/Ticker`;

/** PLATFORM STATUS */
export const urlGetStatus = `${urlBase}v1/adm/status`;

/** AUTH **/
export const urlauthUpline = `${urlBase}v1/users-s/upline`;
export const urlauthProfile = `${urlBase}v1/users-s/profile`;
export const urlRequestWallet = `${urlBase}v1/users-s/request/wallet`;
export const urlStatusWallet = `${urlBase}v1/users-s/status/wallet`;
export const urlLanguageProfile = `${urlBase}v1/users-s/profile/lang`;
export const urlInfoDashboard = `${urlBase}v1/users-s/profile/dashboard`;
export const urlVerifyActivationAccount = `${urlBase}v1/auth/account/activation/verify`;
export const urlRequestPromoter = `${urlBase}v1/users-s/request/promoter`;
export const urlConfirmContract = `${urlBase}v1/users-s/confirm/contract/promoter`;
export const urlAuthPutProfile = `${urlBase}v1/users-s/my/profile`;
export const urlAuthPutBilling = `${urlBase}v1/users-s/my/billing`;
export const urlAuthPutWaynance = `${urlBase}v1/users-s/my/waynance`;

/** GLOBALS **/
export const urlGetNationalities = `${urlBase}v1/countries`;
export const urlGetEuCountries = `${urlBase}v1/countries/europeans`;
export const urlGetunilevelTree = `${urlBase}v1/tree/unilevel`;
export const urlGetItem = `${urlBase}v1/items`;
export const urlGetItemLocal = `${urlBase}v1/items/local`;
export const urlGetPackagesGroupsSidebar = `${urlBase}v1/groupPackages/sidebar`;
export const urlGetAllCountriesSorted = `${urlBase}v1/countries/sorted`;
export const urlGetLandings = `${urlBase}v1/landings`;

// INIT NOTIFICATION URLS
export const urlNotificationSecured = `${urlBase}v1/notifications-s`;
export const urlNotificationMarkAllSecured = `${urlBase}v1/notifications-s/all`;
export const urlGeneralNotificationSecured = `${urlBase}v1/notifications-s/general`;
export const urlNotification = `${urlBase}v1/notifications`;
export const urlNotificationPendingSecured = `${urlBase}v1/notifications-s/pendingRead`;
// END NOTIFICATION URLS

export const urlWalletSecured = `${urlBase}v1/wallet-s`;
export const urlWalletExchangeSecured = `${urlBase}v1/wallet-s/exchange`;
export const urlWalletMakeExchangeSecured = `${urlBase}v1/wallet-s/make/exchange`;

// export const urlWalletTransactions = `${urlBaseWallet}v1/transaction/{param}`;
export const urlGetNews = `${urlBase}v1/news`;

export const urlWalletTransactions = `${urlBaseWallet}v1/transaction/{param}`;
export const urlAddressSecured = `${urlBase}v1/address-s`;
export const urlSetDefaultAddressSecured = `${urlBase}v1/address-s/default`;

export const urlProductsSecured = `${urlBase}v1/products`;
export const urlProductsFreeSecured = `${urlBase}v1/products/free`;

export const urlPackageSecured = `${urlBase}v1/products/package`;

export const urlShoppingCartSecured = `${urlBase}v1/shoppingCart-s`;
export const urlSyncShoppingCartSecured = `${urlBase}v1/shoppingCart-s/sync`;
export const urlCleanShoppingCartSecured = `${urlBase}v1/shoppingCart-s/clean`;

export const urlPurchaseSecured = `${urlBase}v1/purchase-s`;
export const urlPurchaseShippingSecured = `${urlBase}v1/purchase-s/shipping`;
export const urlPurchaseRequestReturnItemSecured = `${urlBase}v1/purchase-s/requestReturn`;
export const urlPurchaseConfirmReturnItemSecured = `${urlBase}v1/purchase-s/confirmReturn`;
export const urlPurchaseReturnOrderPrintedSecured = `${urlBase}v1/purchase-s/returnOrderPrinted`;

export const urlCashFlowSecured = `${urlBase}v1/cashFlow-s`;
export const urlCashFlowMoreInfoSecured = `${urlBase}v1/cashFlow-s/moreInfo`;

export const urlSubscriptionSecured = `${urlBase}v1/subscription-s`;
export const urlSubscriptionSecuredCancel = `${urlBase}v1/subscription-s/cancel`;
export const urlSubscriptionSecuredActive = `${urlBase}v1/subscription-s/active`;
export const urlSubscriptionReactivateSecured = `${urlBase}v1/subscription-s/reactivate`;

export const urlNitroxActivation = `${urlBase}v1/subscription/nitrox/activation`;
export const urlNitroxRegister = `${urlBase}v1/subscription/register/nitrox`;
export const urlNitroxReactive = `${urlBase}v1/subscription/reactivate/nitrox`;

/* DASHBOARD */
export const urlDashboardMyWallet = `${urlBase}v1/dashboard/wallets`;
export const urlDashboardUserLevels = `${urlBase}v1/dashboard/levels`;
export const urlMyCommissions = `${urlBase}v1/commission/resume`;

export const urlCommissionSecured = `${urlBase}v1/commission`;
export const urlGetResumeCommissionSecured = `${urlBase}v1/commission/filterResume`;

export const urlMyCampaign = `${urlBase}v1/campaign-s/myCampaign`;
export const urlGetCampaignToken = `${urlBase}v1/campaign/token`;

export const urlDataIgnite = `${urlBase}v1/users-s/dataIgnite`;
export const urlGenerateDataIgnite = `${urlBase}v1/users-s/dataIgnite/generate`;
export const urlUpdateIgnite = `${urlBase}v1/users-s/ignite/dataUser`;
export const urlDataDesertAnts = `${urlBase}v1/users-s/dataDesert`;
/* GENERATOR_INJECT_URLS */

export const urlCreateCoinbaseTransaction = `${urlBase}v1/coinbasePayment/transaction`;
