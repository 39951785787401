export const checkBootstrapTypeBreak = () => {
  let envs = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'];

  let el = document.createElement('div');
  document.body.appendChild(el);

  let curEnv = envs.shift();

  for (let env of envs.reverse()) {
    el.classList.add(`d-${env}-none`);

    if (window.getComputedStyle(el).display === 'none') {
      curEnv = env;
      break;
    }
  }

  document.body.removeChild(el);
  return curEnv;
};

export const checkBootstrapSizeBreak = () => {
  let envs = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'];

  let el = document.createElement('div');
  document.body.appendChild(el);

  let curEnv = envs.shift();

  for (let env of envs.reverse()) {
    el.classList.add(`d-${env}-none`);

    if (window.getComputedStyle(el).display === 'none') {
      curEnv = env;
      break;
    }
  }

  document.body.removeChild(el);
  let sizeNumber = 0;
  switch (curEnv) {
    case 'xs':
      sizeNumber = 0;
      break;
    case 'sm':
      sizeNumber = 1;
      break;
    case 'md':
      sizeNumber = 2;
      break;
    case 'lg':
      sizeNumber = 3;
      break;
    case 'xl':
      sizeNumber = 4;
      break;
    case 'xxl':
      sizeNumber = 5;
      break;
    default:
      break;
  }
  return sizeNumber;
};
