import React from 'react';
import PropTypes from 'prop-types';
import ProductDetail from '../../../../components/others/products/detail';
import Layout from '../../../../components/layouts/verticalLayout';

const ProductDetailPage = (props) => {
  return (
    <Layout
      body={
        <>
          <ProductDetail uuid={props.uuid} />
        </>
      }
    />
  );
};

ProductDetailPage.propTypes = {
  uuid: PropTypes.string,
};

export default ProductDetailPage;
