import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Header from '../commons/header';
import {
  checkBootstrapTypeBreak,
  checkBootstrapSizeBreak,
} from '../../../utils/device/checkDevice';
import { PageContent, BodyContainer, SettingButton, SectionBanner, BannerText } from './styled';
import { SecctionTitleContainer } from '../../../design/styleComponents/globalComponents';
import { Title } from '@arcaitprod/react-basic-components';
import PATHS from '../../../constants/paths';
import Nav from '../commons/nav';
import ShoppingCartNav from '../commons/shoppingCartNav';
import Footer from '../commons/footer';
import { Can } from '../../permissions/Can';
import { USER_PERMISSIONS } from '../../permissions/rolesPermission';

const SettingsLayout = (props) => {
  const { type, showBanner, bannerName, textBanner, bannerPosition } = props;

  const breakDevice = checkBootstrapTypeBreak();
  const breakSize = checkBootstrapSizeBreak();
  const [toggle, setToggle] = useState(breakDevice === 'xs' || breakDevice === 'sm' ? false : true);

  const { t } = useTranslation();
  const history = useHistory();
  const hideShowSidebar = () => {
    setToggle(!toggle);
  };

  const goTo = (key) => {
    switch (key) {
      case 'my_profile':
        history.push(PATHS.MY_SETTINGS);
        break;
      case 'addresses':
        history.push(PATHS.LIST_MY_ADDRESSES);
        break;
      case 'billinAddress':
        history.push(PATHS.MY_BILLING_ADDRESS);
        break;
      case 'wallets':
        history.push(PATHS.WALLETS);
        break;
      case 'walletsSetting':
        history.push(PATHS.WALLETS_SETTING);
        break;

      default:
        break;
    }
  };

  return (
    <>
      <div id="sb-site">
        <BodyContainer className="container-fluid" id="page-wrapper">
          <div className="sticky-top">
            <Header
              showUserMenu={props.showUserMenu !== undefined ? props.showUserMenu : undefined}
            />
            {/* <Nav></Nav> */}
          </div>
          {showBanner && (
            <SectionBanner bannerName={bannerName} bannerPosition={bannerPosition}>
              <BannerText>{t(`banners.${textBanner}`)}</BannerText>
            </SectionBanner>
          )}
          <div id="page-content-wrapper">
            {/* {!props.hiddenButtonShoppingCart && <ShoppingCartNav />} */}
            <PageContent id="page-content">
              <div className="row justify-content-center no-margin">
                <SecctionTitleContainer className="col-11 col-md-10 mt-5 mb-4">
                  {t('settings')}
                </SecctionTitleContainer>
              </div>
              <div className="row justify-content-center mt-5 no-margin">
                <div className="col-12 col-md-10">
                  <div className="row">
                    <div className="col-12 col-lg-3">
                      <ul className={breakSize < 3 ? 'nav mb-3' : 'nav flex-column'}>
                        <li className="nav-item px-4 mb-3">
                          <SettingButton
                            active={props.active && props.active === 'profile'}
                            type="button"
                            onClick={() => goTo('my_profile')}
                          >
                            {t('my_profile')}
                          </SettingButton>
                        </li>
                        <li className="nav-item px-4 mb-3">
                          <SettingButton
                            active={props.active && props.active === 'addresses'}
                            type="button"
                            onClick={() => goTo('addresses')}
                          >
                            {t('addresses')}
                          </SettingButton>
                        </li>
                        <li className="nav-item px-4 mb-3">
                          <SettingButton
                            active={props.active && props.active === 'billinAddress'}
                            type="button"
                            onClick={() => goTo('billinAddress')}
                          >
                            {t('billinAddress')}
                          </SettingButton>
                        </li>
                        <Can do={USER_PERMISSIONS.IS_MEMBER}>
                          <li className="nav-item px-4 mb-3">
                            <SettingButton
                              active={props.active && props.active === 'wallets'}
                              type="button"
                              onClick={() => goTo('wallets')}
                            >
                              {t('myWallet')}
                            </SettingButton>
                          </li>
                        </Can>
                        <li className="nav-item px-4 mb-3">
                          <SettingButton
                            active={props.active && props.active === 'walletsSetting'}
                            type="button"
                            onClick={() => goTo('walletsSetting')}
                          >
                            {t('wallets')}
                          </SettingButton>
                        </li>
                      </ul>
                    </div>
                    <div className="col-12 col-lg-9">{props.body}</div>
                  </div>
                </div>
              </div>
            </PageContent>
          </div>
        </BodyContainer>
        <Footer></Footer>
      </div>
    </>
  );
};

export default SettingsLayout;
