import styled from 'styled-components';

export const ContainerIcon = styled.div`
  color: #fff;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonIcon = styled.button`
  color: ${({ general, theme }) =>
    general ? theme.notifications.general.color : theme.notifications.personal.color} !important;
  background-color: ${({ general, theme }) =>
    general
      ? theme.notifications.general.backgroundColor
      : theme.notifications.personal.backgroundColor};
  border: 0px;
  border-radius: 30px;
  height: 35px;
  width: 35px;
  padding: 0;

  &:hover {
    color: ${({ general, theme }) =>
      general
        ? theme.notifications.general.colorHover
        : theme.notifications.personal.colorHover} !important;
    background-color: ${({ general, theme }) =>
      general
        ? theme.notifications.general.backgroundColorHover
        : theme.notifications.personal.backgroundColorHover} !important;
  }

  &:active {
    color: ${({ general, theme }) =>
      general
        ? theme.notifications.general.colorHover
        : theme.notifications.personal.colorHover} !important;
    background-color: ${({ general, theme }) =>
      general
        ? theme.notifications.general.backgroundColorHover
        : theme.notifications.personal.backgroundColorHover} !important;
  }
`;

export const Badge = styled.span`
  color: #fff;
  top: 6px !important;
  padding: 0.2rem 0.4rem !important;
  font-size: 0.65rem !important;
  background-color: ${({ general, theme }) =>
    general ? theme.notifications.general.badge : theme.notifications.personal.badge} !important;
`;
