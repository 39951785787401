import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { CenterContainer } from '../../../design/styleComponents/globalComponents';
import { ImageLogo, TextDataImage, NetShipping } from './maintenance.styled';
import ReactPlayer from 'react-player';

const MaintenanceForm = (props) => {
  const { t } = props;
  return (
    <CenterContainer className="row justify-content-center align-items-center">
      <div className="col-10 col-sm-8 col-lg-6 text-center mt-5">
        {/* <div className="d-flex justify-content-center align-items-center flex-column mt-5"> */}
        <ImageLogo
          src={`/assets/themes/${process.env.REACT_APP_THEME}/images/arca_logo.png`}
          alt=""
        />
        <TextDataImage>
          <NetShipping>LA EVOLUCIÓN DEFINITIVA</NetShipping>
          ACTUALMENTE ESTAMOS HACIENDO LABORES DE MANTENIMIENTO, EN BREVE ESTAREMOS DE VUELTA.
        </TextDataImage>
        <div className="mt-4">
          <ReactPlayer
            className="ratio ratio-16x9 videoShow"
            controls
            url="https://youtu.be/BFKWeI8uWmc"
          ></ReactPlayer>
        </div>
        {/* </div> */}
      </div>
    </CenterContainer>
  );
};

MaintenanceForm.propTypes = {
  handleSubmit: PropTypes.func,
};

export default withTranslation()(MaintenanceForm);
