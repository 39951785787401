/* eslint-disable indent */
import React from 'react';
import Register from '../../../components/user/register';
import Layout from '../../../components/layouts/centerLayout';
import PropTypes from 'prop-types';

const RegisterPage = (props) => {
  return <Register token={props.token} />;
};

RegisterPage.propTypes = {
  token: PropTypes.string,
};

export default RegisterPage;
