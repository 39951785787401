import styled from 'styled-components';

export const LabelText = styled.span`
  font-size: 0.8rem;
  color: #fff;
  font-family: 'Gotham Book';
  margin-right: 10px;
`;

export const LabelValue = styled.span`
  font-size: 1rem;
  color: #fff;
  font-family: 'Gotham Book';
  margin-right: 5px;
`;

export const LabelValueTotal = styled.span`
  font-size: 2rem;
  color: #fff;
  font-weight: 600;
  font-family: 'Gotham Book';
`;

export const Discraimer = styled.span`
  font-size: 0.675rem;
  color: #fff;
  font-family: 'Gotham Book';
`;
