import styled from 'styled-components';

export const LoginLogoContainer = styled.div`
  width: 100%;
  margin-bottom: 2rem;
  text-align: center;
`;

export const LoginLogo = styled.img`
  width: 80%;
`;

export const FormContainer = styled.div`
  border-right: 1px solid #272727;
  height: 100%;
`;

export const TextForm = styled.div`
  color: #469fa3;
  font-size: 0.875rem;
`;

export const LandingImg = styled.img`
  width: 100%;
`;

export const LandingTitle = styled.div`
  color: #000;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonGo = styled.button`
  background: transparent;
  border: 0px;
  color: #469fa3;
  text-align: start;
  font-size: 0.775rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ButtonDownload = styled.button`
  background: transparent;
  border: 0px;
  color: #000;
  text-align: start;
  font-size: 0.775rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    color: #469fa3;
  }
`;

export const IconLink = styled.i`
  margin-right: 5px;
  color: #000;
  font-size: 0.875rem;
`;

export const ContainerLink = styled.div`
  margin-right: 5px;
`;
