import styled from 'styled-components';

export const Letter = styled.div`
  /* font-size: 1rem; */
  font-family: 'Gotham Light';
  font-weight: bold;
  color: #000;
  font-size: 1.8rem;
`;

export const Country = styled.div`
  /* font-size: 1rem; */
  font-family: 'Gotham Light';
  font-weight: ${({ available }) => (available ? 'bold' : 'normal')};
  color: #000;
  font-size: 0.875rem;
  opacity: ${({ available }) => (available === true ? '1' : '0.2')};
`;

export const SwitchStatus = styled.input`
  background-color: ${({ statusCheck }) =>
    statusCheck ? '#469fa3 !important' : '#fff !important'};
  border-color: ${({ statusCheck }) => (statusCheck ? '#469fa3 !important' : '#bfbfbf !important')};
  box-shadow: rgb(74 74 104 / 20%) 0px 2px 2px 0px inset !important;
  /* width: 3rem !important; */
`;

export const LabelStatus = styled.label`
  color: #469fa3;
  font-size: 0.8rem;
  margin-left: 10px;
`;

export const TextStatus = styled.div`
  color: #000;
  font-size: 1rem;
  font-weight: bold;
`;
