import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as CommissionService from '../../../../services/CommissionService';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import {
  PartialAmount,
  RowText,
  RowTextUuid,
  HeaderList,
  ResumeColHeader,
  ResumeColData,
  ResumeRow,
  LegendContainer,
  LegendSquare,
} from './commissionList.styled';
import { AbilityContext } from '../../../permissions/Can';
import ContainerExpandable from '../../../utils/commons/containerExpandable';
import { InfiniteList } from '@arcaitprod/react-complex-components';
import moment from 'moment-timezone';
import * as exchangeUtil from '../../../../utils/formats/exchange';
import ContentLoader from 'react-content-loader';
import { connect } from 'react-redux';
import { getTimeZoneSelector } from '../../../../store/selectors/navSelector';
import GraphAmountMaxMinItem from '../../../utils/GraphAmountMaxMinItem';
import * as exchangeUtils from '../../../../utils/formats/exchange';
import { Title } from '@arcaitprod/react-basic-components';
import {
  COMMISSIONS_TYPES,
  COMMISSIONS_LEVELS,
  COMMISSIONS_LEVELS_REDUCED,
  COMMISSIONS_LEVELS_LEAD,
  RANKS_LIST,
  COMMISSION_TYPE_UNILEVEL,
  COMMISSION_TYPE_UNILEVEL_REDUCED,
  COMMISSION_TYPE_LEADER,
} from '../../../../constants/index';
import { Button } from '@arcaitprod/react-basic-components';

class CommissionListContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      refreshData: false,
      info: {},
      resume: null,
    };
  }

  componentDidUpdate(prevState) {
    if (prevState.refreshData !== this.state.refreshData && this.state.refreshData === true) {
      this.resetRefreshData();
    }
  }

  resetRefreshData = () => {
    this.setState({ refreshData: false });
  };

  toList = async (filter) => {
    const result = await CommissionService.listFilter(filter);
    if (filter.page === 1) {
      const resume = await CommissionService.getResumeFilter(filter);
      if (resume) {
        this.setState({ resume });
      }
    }
    return result;
  };

  renderRowExchange = (mov) => {
    return (
      <ContainerExpandable
        key={`containerExp_${mov.uuid}`}
        header={this.renderHeaderRow(mov)}
        classNameHeader=""
      />
    );
  };

  renderHeaderRow = (com) => {
    const { timeZone, t } = this.props;
    const date = moment(com.createdAt);
    const commissionType = COMMISSIONS_TYPES.find((type) => type.code === com.commissionType);
    const rank = RANKS_LIST.find((rank) => rank.value === com.rank);
    let commissionLevel = 0;

    if (com.commissionType === COMMISSION_TYPE_UNILEVEL.code) {
      commissionLevel = COMMISSIONS_LEVELS[com.fromLevelCompressed];
    } else if (com.commissionType === COMMISSION_TYPE_UNILEVEL_REDUCED.code) {
      commissionLevel = COMMISSIONS_LEVELS_REDUCED[com.fromLevelCompressed];
    } else {
      commissionLevel = COMMISSIONS_LEVELS_LEAD[com.fromLevelCompressed];
    }

    return (
      <div id={`rowComm_${com.uuid}`} className="row align-items-center">
        <div className="col-4 col-md-3 d-flex flex-column">
          <RowText>{date.tz('Europe/Madrid').format('DD-MM-yyyy HH:mm')}</RowText>
          <RowTextUuid id={`idLabel_${com.uuid}`} className="d-none d-md-block">
            {com.uuid}
          </RowTextUuid>
        </div>
        <div className="col-3 col-md-2 text-center">
          <RowText>{`${commissionType ? t(commissionType.label) : 'N/A'}`}</RowText>
        </div>
        <div className="col-2 col-md-2 text-center d-none d-md-block">
          <RowText>{`${t(rank.label)}`}</RowText>
        </div>
        <div className="col-2 col-md-2 text-center d-none d-md-block">
          <RowText>{`${t('forms.commission.labels.level')} ${
            com.fromLevelCompressed
          } (${commissionLevel})`}</RowText>
        </div>
        <div className="col-5 col-md-3 text-center">
          <PartialAmount>{`${exchangeUtil.satoshi2Crypto(
            com.currency,
            com.amount
          )} Puntos`}</PartialAmount>
        </div>
      </div>
    );
  };

  renderHeaderList = () => {
    const { t } = this.props;
    return (
      <HeaderList className="row align-items-center mt-2">
        <div className="col-4 col-md-3 text-start">{t('forms.commission.table.header.date')}</div>
        <div className="col-3 col-md-2 text-center">
          {t('forms.commission.table.header.commissionType')}
        </div>
        <div className="col-2 col-md-2 text-center d-none d-md-block">
          {t('forms.commission.table.header.rank')}
        </div>
        <div className="col-2 col-md-2 text-center d-none d-md-block">
          {t('forms.commission.table.header.fromLevelCompressed')}
        </div>
        <div className="col-5 col-md-3 text-center">
          {t('forms.commission.table.header.amount')}
        </div>
      </HeaderList>
    );
  };

  renderListLoader = () => {
    return (
      <div className="row">
        <div className="col-12 mt-4">
          <ContentLoader
            speed={2}
            viewBox="0 0 400 160"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            style={{ width: '100%' }}
          >
            <rect x="5" y="1" rx="3" ry="3" width="400" height="5" />
            <rect x="5" y="15" rx="3" ry="3" width="400" height="5" />
          </ContentLoader>
        </div>
      </div>
    );
  };

  renderResume = (resume) => {
    const { t } = this.props;
    let totalCommissionsBonus = 0;
    let totalCommissionsBonusReduced = 0;
    let totalCommissionsBonusLead = 0;
    let totalAmountCommissionsBonus = 0;
    let totalAmountCommissionsBonusReduced = 0;
    let totalAmountCommissionsBonusLead = 0;

    for (const data of resume) {
      switch (data.commissionType) {
        case COMMISSION_TYPE_UNILEVEL.code:
          totalCommissionsBonus += data.count;
          totalAmountCommissionsBonus += data.totalAmount;
          break;
        case COMMISSION_TYPE_LEADER.code:
          totalCommissionsBonusLead += data.count;
          totalAmountCommissionsBonusLead += data.totalAmount;
          break;
        case COMMISSION_TYPE_UNILEVEL_REDUCED.code:
          totalCommissionsBonusReduced += data.count;
          totalAmountCommissionsBonusReduced += data.totalAmount;
          break;
        default:
          break;
      }
    }

    return (
      <>
        <ResumeRow className="row">
          <ResumeColHeader className="col-4"></ResumeColHeader>
          <ResumeColHeader className="col-4 text-center">
            {t('forms.commission.labels.numCommission')}
          </ResumeColHeader>
          <ResumeColHeader className="col-4 text-center">
            {t('forms.commission.labels.amountCommission')}
          </ResumeColHeader>
        </ResumeRow>
        <ResumeRow className="row">
          <ResumeColHeader className="col-4">
            {t('forms.commission.labels.countCommissionUni')}
          </ResumeColHeader>
          <ResumeColData className="col-4 text-center">{totalCommissionsBonus}</ResumeColData>
          <ResumeColData className="col-4 text-center">{`${
            totalAmountCommissionsBonus
              ? exchangeUtil.satoshi2Crypto('Puntos', totalAmountCommissionsBonus)
              : '0.00'
          } Puntos`}</ResumeColData>
        </ResumeRow>
        <ResumeRow className="row">
          <ResumeColHeader className="col-4">
            {t('forms.commission.labels.countCommissionUniReduced')}
          </ResumeColHeader>
          <ResumeColData className="col-4 text-center">
            {totalCommissionsBonusReduced}
          </ResumeColData>
          <ResumeColData className="col-4 text-center">{`${
            totalAmountCommissionsBonusReduced
              ? exchangeUtil.satoshi2Crypto('Puntos', totalAmountCommissionsBonusReduced)
              : '0.00'
          } Puntos`}</ResumeColData>
        </ResumeRow>
        <ResumeRow className="row">
          <ResumeColHeader className="col-4">
            {' '}
            {t('forms.commission.labels.countCommissionLead')}
          </ResumeColHeader>
          <ResumeColData className="col-4 text-center">{totalCommissionsBonusLead}</ResumeColData>
          <ResumeColData className="col-4 text-center">{`${
            totalAmountCommissionsBonusLead
              ? exchangeUtil.satoshi2Crypto('Puntos', totalAmountCommissionsBonusLead)
              : '0.00'
          } Puntos`}</ResumeColData>
        </ResumeRow>
        <ResumeRow className="row">
          <ResumeColHeader className="col-4">
            <strong> {t('forms.commission.labels.totals')}</strong>
          </ResumeColHeader>
          <ResumeColData className="col-4 text-center">
            <strong>
              {totalCommissionsBonus + totalCommissionsBonusLead + totalCommissionsBonusReduced}
            </strong>
          </ResumeColData>
          <ResumeColData className="col-4 text-center">
            <strong>{`${
              totalAmountCommissionsBonus +
                totalAmountCommissionsBonusLead +
                totalAmountCommissionsBonusReduced !==
              0
                ? exchangeUtil.satoshi2Crypto(
                    'Puntos',
                    totalAmountCommissionsBonus +
                      totalAmountCommissionsBonusLead +
                      totalAmountCommissionsBonusReduced
                  )
                : '0.00'
            } Puntos`}</strong>
          </ResumeColData>
        </ResumeRow>
      </>
    );
  };

  renderValueGraphTooltipAmount = (unit, value) => {
    return `${exchangeUtils.satoshi2Crypto(unit, value)} `;
  };

  renderValueGraphTooltipCount = (unit, value) => {
    return `${value} `;
  };

  requestExchangeBTC = () => {
    this.props.history.push('/wallet/exchange/GAL/BTC');
  };

  render() {
    const { t, filters, className, timeZone } = this.props;
    const { resume } = this.state;
    const bonusStellarCountPoints = [];
    const bonusStellarAmountsPoints = [];
    const bonusLeadCountPoints = [];
    const bonusLeadAmountsPoints = [];
    const bonusReducedCountPoints = [];
    const bonusReducedAmountsPoints = [];

    const counts = [];
    const amounts = [];
    if (resume) {
      for (const data of resume) {
        const date = moment(data.date).tz('Europe/Madrid').format('MM/yyyy');
        switch (data.commissionType) {
          case COMMISSION_TYPE_UNILEVEL.code:
            bonusStellarCountPoints.push({ date, value: data.count });
            bonusStellarAmountsPoints.push({ date, value: data.totalAmount });
            break;
          case COMMISSION_TYPE_LEADER.code:
            bonusLeadCountPoints.push({ date, value: data.count });
            bonusLeadAmountsPoints.push({ date, value: data.totalAmount });
            break;
          case COMMISSION_TYPE_UNILEVEL_REDUCED.code:
            bonusReducedCountPoints.push({ date, value: data.count });
            bonusReducedAmountsPoints.push({ date, value: data.totalAmount });
            break;
          default:
            break;
        }
      }

      counts.push({ points: bonusStellarCountPoints, colorLine: '#469fa3', id: 'botCount' });
      counts.push({ points: bonusLeadCountPoints, colorLine: '#A820CE', id: 'botLeadCount' });
      counts.push({ points: bonusReducedCountPoints, colorLine: '#9ecb58', id: 'botReducedCount' });

      amounts.push({ points: bonusStellarAmountsPoints, colorLine: '#469fa3', id: 'botAmount' });
      amounts.push({ points: bonusLeadAmountsPoints, colorLine: '#A820CE', id: 'botLeadAmount' });
      amounts.push({
        points: bonusReducedAmountsPoints,
        colorLine: '#9ecb58',
        id: 'botReducedAmount',
      });
    }

    return (
      <>
        <div className="row mt-4">
          <div className="col-12 col-md-8 col-lg-9 d-flex align-items-end order-2 order-md-1">
            <Title type="h5" variant="primary">
              {t('forms.commission.resume')}
            </Title>
          </div>
          {/* <div className="col-12 col-md-4 col-lg-3 text-end pb-3 order-1 order-md-2">
            <Button type="button" variant="primary" onClick={() => this.requestExchangeBTC()}>
              {t('forms.commission.galRetired')}
            </Button>
          </div> */}
        </div>
        <div className="row">
          <div className="col-12">{this.state.resume && this.renderResume(this.state.resume)}</div>
        </div>
        <div className="row mt-4">
          <div className="col-12 col-md-6">
            <GraphAmountMaxMinItem
              textTitle={t('forms.commission.historyCounts')}
              grahpType="markLine"
              itemNameX="date"
              itemNameY="value"
              axisNameX={t('forms.commission.cycle')}
              axisNameY={t('forms.commission.amount')}
              data={counts}
              renderGraphValueTooltip={this.renderValueGraphTooltipCount}
              unit={t('forms.commission.commission')}
              grahpHeight={300}
              showAxisYvalues={true}
              showDefaultAxisYvalues={false}
              emptyAxisValue
              multiLine
            />
          </div>
          <div className="col-12 col-md-6">
            <GraphAmountMaxMinItem
              textTitle={t('forms.commission.historyAmounts')}
              grahpType="markLine"
              itemNameX="date"
              itemNameY="value"
              axisNameX={t('forms.commission.cycle')}
              axisNameY={t('forms.commission.amount')}
              data={amounts}
              renderGraphValueTooltip={this.renderValueGraphTooltipAmount}
              unit="Puntos"
              grahpHeight={300}
              showAxisYvalues={true}
              showDefaultAxisYvalues={false}
              emptyAxisValue
              multiLine
            />
          </div>
        </div>
        <ResumeRow className="row justify-content-center mt-1 mb-4">
          <LegendContainer className="col-11 col-md-12 col-lg-8">
            <div className="row">
              <div className="col-12 col-md-4 col-lg-4 d-flex align-items-center justify-content-start justify-content-md-center">
                <LegendSquare color="#469fa3"></LegendSquare>
                <span>{t('forms.commission.uni')}</span>
              </div>
              <div className="col-12 col-md-4 col-lg-4 d-flex align-items-center justify-content-start justify-content-md-center">
                <LegendSquare color="#9ecb58"></LegendSquare>
                <span>{t('forms.commission.reduced')}</span>
              </div>
              <div className="col-12 col-md-4 col-lg-4 d-flex align-items-center justify-content-start justify-content-md-center">
                <LegendSquare color="#A820CE"></LegendSquare>
                <span>{t('forms.commission.lead')}</span>
              </div>
            </div>
          </LegendContainer>
        </ResumeRow>
        <div className="row mt-5">
          <div className="col-12">
            <Title type="h5" variant="primary">
              {t('forms.commission.list')}
            </Title>
          </div>
        </div>
        <div className={className || ''}>
          <InfiniteList
            id="commissions"
            renderItem={this.renderRowExchange}
            toList={this.toList}
            filters={filters}
            variantTitle="primary"
            forceRefresh={this.state.refreshData}
            endMessage={t('goToTop')}
            messageButtonMore={t('readMore')}
            variantButtonMore="simplePrimary"
            headerComponentList={this.renderHeaderList}
            renderLoader={this.renderListLoader}
          />
        </div>
      </>
    );
  }
}

CommissionListContainer.contextType = AbilityContext;

CommissionListContainer.propTypes = {
  t: PropTypes.any,
  filters: PropTypes.object,
  className: PropTypes.string,
};

const mapStateToProps = (state) => ({
  timeZone: getTimeZoneSelector(state),
});

export default withRouter(connect(mapStateToProps, {})(withTranslation()(CommissionListContainer)));
