import styled from 'styled-components';

export const LabelText = styled.span`
  font-size: 0.8rem;
  color: #fff;
  font-family: 'Gotham Book';
  margin-right: 10px;
  text-transform: uppercase;
`;

export const LabelTextRank = styled.span`
  font-size: 0.7rem;
  color: #fff;
  font-family: 'Gotham Book';
  text-transform: uppercase;
`;

export const LabelValue = styled.span`
  font-size: 1.2rem;
  color: #fff;
  font-family: 'Gotham Book';
  margin-right: 5px;
  font-weight: bold;
`;

export const IconItem = styled.img`
  height: 30px;
  margin-right: 10px;
`;

export const IconStar = styled.img`
  height: 20px;
  margin-right: 10px;
`;

export const IconRank = styled.img`
  height: 50px;
  margin-right: 10px;
`;

export const IconMyRank = styled.img`
  height: 40px;
  margin-right: 5px;
`;
