import styled from 'styled-components';

export const LoginLogoContainer = styled.div`
  width: 100%;
  margin-bottom: 2rem;
  text-align: center;
`;

export const LoginLogo = styled.img`
  width: 80%;
`;

export const ImageLogo = styled.img`
  /* border: 1px solid #12374e; */
  width: 100px;
`;

export const TextDataImage = styled.div`
  color: #fff;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* width: 28%; */
  text-align: center;
  font-size: 1rem;
`;

export const NetShipping = styled.span`
  color: #fff;
  text-align: center;
  font-size: 1.8rem;
`;
