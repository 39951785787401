import * as Yup from 'yup';

export const dataModel = {
  amount: '',
  address: '',
  currency: 'BTC',
};

export const validationDataModel = Yup.object().shape({
  amount: Yup.number().required('forms.validations.required'), //TODO regex
  address: Yup.string().required('forms.validations.required'),
  currency: Yup.string().required('forms.validations.required'),
  // .matches(/^(bc1|[13])[a-zA-HJ-NP-Z0-9]{25,39}$/, 'forms.validations.wallet'),
});

export const setInitialValues = (initialValues) => {
  const newValues = { ...dataModel };
  if (initialValues) {
    const items = Object.keys(newValues);
    items.forEach((item) => {
      if (initialValues[item]) {
        newValues[item] = initialValues[item];
      }
    });
    return newValues;
  }

  return dataModel;
};
