import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as AddressServices from '../../../../services/AddressService';
import { withTranslation } from 'react-i18next';
import {
  RowText,
  ActionButton,
  ContainerAddress,
  RowTextTitle,
  DefaultIcon,
  DefaultContainer,
  ActionsContainer,
  LinkActionStyled,
  LinkActionAdd,
  TextAddItem,
  IconAddItem,
  ContainerAddAddress,
} from './addressList.styled';
import { AbilityContext } from '../../../permissions/Can';
import { InfiniteList } from '@arcaitprod/react-complex-components';
import ContentLoader from 'react-content-loader';
import { checkBootstrapSizeBreak } from '../../../../utils/device/checkDevice';
import { connect } from 'react-redux';
import { getTimeZoneSelector } from '../../../../store/selectors/navSelector';
import { showDialog } from '@arcaitprod/react-utils-components';
import PATHS from '../../../../constants/paths';

class AddressListContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      refreshData: false,
      refreshItem: null,
      breakDevice: checkBootstrapSizeBreak(),
    };
  }

  componentDidUpdate(prevState) {
    if (prevState.refreshData !== this.state.refreshData && this.state.refreshData === true) {
      this.resetRefreshData();
    }
  }

  resetRefreshData = () => {
    this.setState({ refreshData: false });
  };

  toList = async (filter) => {
    const result = await AddressServices.listFilter(filter);
    return result;
  };

  renderRow = (address) => {
    const { t } = this.props;

    return (
      <div key={address.uuid} className="col-12 col-md-6 col-lg-4 mb-4">
        <div className="row no-margin">
          <ContainerAddress className="col-12 d-flex flex-column justify-content-between">
            <div>
              {address.default && (
                <div className="row">
                  <DefaultContainer className="col-12 text-end">
                    <DefaultIcon
                      className="fa-solid fa-bookmark"
                      title={t('forms.address.default')}
                    ></DefaultIcon>
                    {t('forms.address.default')}
                  </DefaultContainer>
                </div>
              )}
              <div className="row">
                <div className="col-12">
                  <RowTextTitle>{address.alias}</RowTextTitle>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <RowTextTitle>{address.contactName}</RowTextTitle>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <RowText>{address.address}</RowText>
                </div>
              </div>
              {address.addressAdc && (
                <div className="row">
                  <div className="col-12">
                    <RowText>{address.addressAdc}</RowText>
                  </div>
                </div>
              )}
              <div className="row">
                <div className="col-12">
                  <RowText>{`${address.province ? `${address.province},` : ''} ${address.city} ${
                    address.cp
                  }`}</RowText>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <RowText>{address.countryData.visualName}</RowText>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <RowText>{`${t('forms.address.labels.phone')}: ${address.phone}`}</RowText>
                </div>
              </div>
            </div>
            <ActionsContainer className="row">
              <div className="col-12">
                <LinkActionStyled
                  to={`/myAddresses/form/update/${address.uuid}`}
                  type="routerDom"
                  variant="primary"
                >
                  {t('forms.address.buttons.edit')}
                </LinkActionStyled>
                <ActionButton onClick={() => this.handlerRemove(address)}>
                  {t('forms.address.buttons.delete')}
                </ActionButton>
                {!address.default && (
                  <ActionButton onClick={() => this.setAsDefault(address)}>
                    {t('forms.address.buttons.default')}
                  </ActionButton>
                )}
              </div>
            </ActionsContainer>
          </ContainerAddress>
        </div>
      </div>
    );
  };

  setAsDefault = async (value) => {
    const result = await AddressServices.setAsDefault(value.uuid);
    if (result && result === 'OK') {
      this.setState({ refreshData: true });
    }
  };

  handlerRemove = (value) => {
    const { t } = this.props;

    const okAction = async () => {
      const result = await AddressServices.deleteAddress(value.uuid);
      if (result && result === 'OK') {
        this.setState({ refreshData: true });
      }
    };

    showDialog(
      t('forms.address.dialog.remove.title'),
      t('forms.address.dialog.remove.text'),
      'warning',
      true,
      t('forms.address.dialog.remove.buttonOk'),
      false,
      okAction,
      t('forms.address.dialog.remove.buttonNo')
    );
  };

  renderListLoader = () => {
    return (
      <div className="row">
        <div className="col-12 mt-4">
          <ContentLoader
            speed={2}
            viewBox="0 0 400 160"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            style={{ width: '100%' }}
          >
            <rect x="5" y="1" rx="3" ry="3" width="400" height="5" />
            <rect x="5" y="15" rx="3" ry="3" width="400" height="5" />
          </ContentLoader>
        </div>
      </div>
    );
  };

  renderAddNewItem = (address) => {
    const { t } = this.props;

    return (
      <div key={address.uuid} className="col-12 col-md-6 col-lg-4 mb-4">
        <div className="row no-margin">
          <LinkActionAdd to={PATHS.NEW_MY_ADDRESSES} type="routerDom" variant="primary">
            <ContainerAddAddress className="col-12 d-flex flex-column justify-content-center align-items-center">
              <IconAddItem className="fa-solid fa-plus"></IconAddItem>
              <TextAddItem>{t('forms.address.buttons.add')}</TextAddItem>
            </ContainerAddAddress>
          </LinkActionAdd>
        </div>
      </div>
    );
  };

  render() {
    const { t, filters, className } = this.props;
    return (
      <div className={className || ''}>
        <InfiniteList
          id="addressesList"
          renderItem={this.renderRow}
          toList={this.toList}
          filters={filters}
          variantTitle="primary"
          forceRefresh={this.state.refreshData}
          itemRefresh={this.state.refreshItem}
          endMessage={t('goToTop')}
          messageButtonMore={t('readMore')}
          variantButtonMore="simplePrimary"
          idItem="uuid"
          renderLoader={this.renderListLoader}
          customClassName="row"
          addNewItemInList
          renderNewItemInList={this.renderAddNewItem}
        />
      </div>
    );
  }
}

AddressListContainer.contextType = AbilityContext;

AddressListContainer.propTypes = {
  t: PropTypes.any,
  filters: PropTypes.object,
  className: PropTypes.string,
  general: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  timeZone: getTimeZoneSelector(state),
});

export default connect(mapStateToProps, {})(withTranslation()(AddressListContainer));
