/* eslint-disable indent */
import React from 'react';
import BusinessPlan from '../../../components/others/businessPlan';
import Layout from '../../../components/layouts/verticalLayout';

const BusinessPlanPage = (props) => {
  return (
    <Layout
      body={
        <>
          <BusinessPlan />
        </>
      }
    />
  );
};

BusinessPlanPage.propTypes = {};

export default BusinessPlanPage;
