/* eslint-disable indent */
import React from 'react';
import DesertAnts from '../../../components/user/desertAnts/my';
import Layout from '../../../components/layouts/simpleLayout';

const DesertAntsPage = (props) => {
  return <Layout showBanner bannerName="minig2.jpg" bannerPosition="25" body={<DesertAnts />} />;
};

DesertAntsPage.propTypes = {};

export default DesertAntsPage;
