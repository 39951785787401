import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { connectModal } from 'redux-modal';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

class InvoiceModal extends Component {
  componentDidMount() {}

  render() {
    const { show, handleHide, body, className } = this.props;
    return (
      <Modal
        size="lg"
        show={show}
        onHide={handleHide}
        centered
        backdrop={true}
        className={className}
      >
        <Modal.Body>
          <div className="row">
            <div className="col-12">{body}</div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

InvoiceModal.propTypes = {
  show: PropTypes.any,
  handleHide: PropTypes.func,
  body: PropTypes.any,
  className: PropTypes.any,
};

export default connectModal({ name: 'InvoiceModal' })(withTranslation()(InvoiceModal));
