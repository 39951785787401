import styled, { css } from 'styled-components';

export const TextReturn = styled.div`
  font-size: 0.875rem;
  font-weight: bold;
  text-transform: uppercase;
`;

export const ValueReturn = styled.span`
  font-size: 0.875rem;
  font-weight: bold;
  text-transform: uppercase;
`;

export const TitleItem = styled.div`
  color: #469fa3;
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
`;

export const ContainerPaymentText = styled.div`
  font-weight: normal;
  font-size: 0.875rem;
`;

export const ContainerTotal = styled.div`
  border-top: 1px solid #ccc;
`;

export const FinalPrice = styled.b`
  color: #469fa3;
`;

export const ContainerAddress = styled.div`
  border: 1px solid #469fa3;
  border-radius: 10px;
  padding: 0.8rem 1.2rem;
`;

export const TitleAddress = styled.div`
  color: #469fa3;
  text-transform: uppercase;
  font-weight: bold;
`;
