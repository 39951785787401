import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'rc-menu/assets/index.css';
import {
  ShoppingCartButton,
  ImageItem,
  ButtonDeleteItem,
  ContainerShopping,
  ContainerActions,
  TextSubtotal,
  TotalPrice,
  Header,
  LinkButton,
  ImageShoppingCart2,
  TextButton,
  ButtonClose,
} from './shoppingCartNav.styled';
import * as Exchange from '../../../../utils/formats/exchange';
import { Button } from '@arcaitprod/react-basic-components';
import { useTranslation } from 'react-i18next';
import { SYMBOL_MONEY } from '../../../../constants/index';
import InputNumber from '../../../utils/InputNumber';

const ShoppingCartNav = (props) => {
  const { items, onRemove, onChangeAmount, goToPay, region, cleanShoppingCart } = props;
  const { t } = useTranslation();
  let numItems = 0;
  let totals = 0;

  const renderItem = () => {
    return items.map((item, index) => {
      if (item) {
        numItems += item.amount ? Number(item.amount) : 0;
        const subtotal =
          Exchange.crypto2Satoshi('GAL', region?.EU ? item.unitPrice : item.unitPriceOutEU) *
          (item.amount ? item.amount : 0);
        totals += subtotal;
        return (
          <div key={`${item.packageUuid}_${index}`} className="row mb-4">
            <div className="col-4 d-flex justify-content-center align-items-center">
              <ImageItem src={item.image}></ImageItem>
            </div>
            <div className="col-8">
              <div className="row mb-1">
                <div className="col-10">{item.packageTitle}</div>
                <div className="col-2">
                  <ButtonDeleteItem type="button" onClick={() => onRemove(item, index)}>
                    <i className="fa-solid fa-trash-can"></i>
                  </ButtonDeleteItem>
                </div>
              </div>
              <div className="row mb-1">
                <div className="col-12">{`${t('forms.shoppingCart.labels.price')} ${
                  region?.EU ? item.unitPrice : item.unitPriceOutEU
                } ${SYMBOL_MONEY}`}</div>
              </div>
              <div className="row">
                <div className="col-5">
                  <InputNumber
                    id={`${item.packageUuid}_${index}_input`}
                    className="form-control"
                    name="amount"
                    allowNegative={false}
                    buttonsClassName="numberButtons"
                    // showButtons
                    value={item.amount ? item.amount : 0}
                    mandatory
                    decimals={0}
                    min={1}
                    max={item.maxShoppingCart ? item.maxShoppingCart : undefined}
                    onChangeValue={async (dataValue) => {
                      if (item.maxShoppingCart && dataValue > item.maxShoppingCart) {
                        onChangeAmount(item.maxShoppingCart, item, index);
                      } else {
                        onChangeAmount(dataValue, item, index);
                      }
                    }}
                  />
                </div>
                <div className="col-7 d-flex justify-content-end align-items-end">{`${Exchange.satoshi2Crypto(
                  'GAL',
                  subtotal
                )} ${SYMBOL_MONEY}`}</div>
              </div>
            </div>
          </div>
        );
      }
    });
  };

  return (
    <>
      <div
        className="offcanvas offcanvas-end"
        tabIndex="-1"
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
      >
        <Header className="offcanvas-header">
          <h5 id="offcanvasRightLabel">{t('forms.shoppingCart.title')}</h5>
          <ButtonClose
            type="button"
            className="text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          >
            <i className="fa-solid fa-xmark"></i>
          </ButtonClose>
        </Header>
        <div className="offcanvas-body">
          <ContainerShopping className="row">
            <div className="col-12">{items && items.length > 0 && renderItem()}</div>
          </ContainerShopping>
          <ContainerActions className="row">
            <div className="col-12 mt-3">
              <div className="row mb-4">
                <div className="col-12 d-flex justify-content-center">
                  <TextSubtotal>{t('forms.shoppingCart.subtotal')}</TextSubtotal>
                  <TotalPrice>{`${Exchange.satoshi2Crypto(
                    'GAL',
                    totals
                  )} ${SYMBOL_MONEY}`}</TotalPrice>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-12 text-center">
                  <Button
                    type="button"
                    className="btn btn-md btn-block"
                    variant="primary"
                    onClick={() => goToPay()}
                  >
                    {t('forms.shoppingCart.buttons.gotoPay')}
                  </Button>
                </div>
              </div>
              <div className="row">
                <div className="col-12 text-center">
                  <LinkButton
                    type="button"
                    // className="btn btn-md btn-block"
                    // variant="secondary"
                    onClick={() => cleanShoppingCart()}
                  >
                    {t('forms.shoppingCart.buttons.clean')}
                  </LinkButton>
                </div>
              </div>
            </div>
          </ContainerActions>
        </div>
      </div>
      <ShoppingCartButton
        type="button"
        variant="primary"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasRight"
        aria-controls="offcanvasRight"
      >
        <div className="d-flex align-items-center">
          {/* <ImageShoppingCart
            src={`/assets/themes/${process.env.REACT_APP_THEME}/images/MarketPlaceLogo.png`}
          ></ImageShoppingCart> */}
          <ImageShoppingCart2 className="fa-solid fa-cart-shopping"></ImageShoppingCart2>
          <TextButton>{numItems} item(s)</TextButton>
        </div>
      </ShoppingCartButton>
    </>
  );
};

ShoppingCartNav.propTypes = {
  items: PropTypes.any,
  onRemove: PropTypes.func,
  onChangeAmount: PropTypes.func,
  goToPay: PropTypes.func,
  region: PropTypes.object,
  cleanShoppingCart: PropTypes.func,
};

export default ShoppingCartNav;
