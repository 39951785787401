import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { LabelLevel, ValueLevel, LabelValue, ImageHeader, Total } from './UsersLevels.styled';
// import { checkBootstrapSizeBreak } from '../../../../utils/device/checkDevice';

const MyLevelRank = (props) => {
  const { data } = props;
  let total = 0;
  const { t } = useTranslation();

  const renderHeader = () => {
    return (
      <div className="row mb-3">
        <div className="col-1"></div>
        <LabelValue className="col-2 no-padding">
          <ImageHeader
            src={`/assets/themes/${process.env.REACT_APP_THEME}/images/Registrado.png`}
            alt="Registrado"
          />
        </LabelValue>
        <LabelValue className="col-2 no-padding">
          <ImageHeader
            src={`/assets/themes/${process.env.REACT_APP_THEME}/images/Cliente.png`}
            alt="Registrado"
          />
        </LabelValue>
        <LabelValue className="col-2 no-padding">
          <ImageHeader
            src={`/assets/themes/${process.env.REACT_APP_THEME}/images/SinRango.png`}
            alt="Registrado"
          />
        </LabelValue>
        <LabelValue className="col-2 no-padding">
          <ImageHeader
            src={`/assets/themes/${process.env.REACT_APP_THEME}/images/ConRango.png`}
            alt="Registrado"
          />
        </LabelValue>
      </div>
    );
  };

  const renderRows = (levels) => {
    return levels.map((level) => {
      const partialTotal =
        (data[`L${level}`].countUser || 0) +
        (data[`L${level}`].countClient || 0) +
        (data[`L${level}`].countRankTotal || 0) +
        (data[`L${level}`].countOldPromoter || 0) +
        (data[`L${level}`].countPrePromoter || 0);

      total += partialTotal;

      return (
        <div key={`Level_${level}`} className="row mb-3">
          <LabelLevel className="col-6">{`${t('level')}${level}: `}</LabelLevel>
          {/* <LabelValue className="col-2 no-padding">
            <ValueLevel>{data[`L${level}`].countUser || 0}</ValueLevel>
          </LabelValue>
          <LabelValue className="col-2 no-padding">
            <ValueLevel>{data[`L${level}`].countClient || 0}</ValueLevel>
          </LabelValue>
          <LabelValue className="col-2 no-padding">
            <ValueLevel>
              {(data[`L${level}`].countOldPromoter || 0) +
                (data[`L${level}`].countPrePromoter || 0)}
            </ValueLevel>
          </LabelValue>
          <LabelValue className="col-2 no-padding">
            <ValueLevel>{data[`L${level}`].countRankTotal || 0}</ValueLevel>
          </LabelValue> */}
          <LabelValue className="col-6 no-padding">
            <ValueLevel>{partialTotal}</ValueLevel>
          </LabelValue>
        </div>
      );
    });
  };

  return (
    <>
      {/* <div className="row justify-content-center">
        <div className="col-12 col-md-6">{renderHeader()}</div>
        <div className="col-12 col-md-6">{renderHeader()}</div>
      </div> */}
      <div className="row">
        <div className="col-12 col-md-6">
          {data ? renderRows([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]) : ''}
        </div>
        {/* <div className="col-12 col-md-6">{data ? renderRows([7, 8, 9, 10, 11, 12]) : ''}</div> */}
      </div>
      {/* <div className="row justify-content-center mt-2 pe-4">
        <Total className="col-12 text-end">{`${t('totalComercialNetwork')} ${total}`}</Total>
      </div> */}
    </>
  );
};

MyLevelRank.propTypes = {
  data: PropTypes.object,
};

export default MyLevelRank;
