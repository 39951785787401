import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Title, Button } from '@arcaitprod/react-basic-components';
import { Tree, SimpleTree } from '@arcaitprod/react-utils-components';
import UniLevelTreeNode from './components/uniLevelTreeNode';
import SimpleTreeNode from './components/simpleTreeNode';
import {
  SwitchStatus,
  LabelStatus,
  ImageLegend,
  Legend,
  ContainerLegend,
  RefererLink,
} from './unilevelTree.styled';
import { SecctionTitleContainer } from '../../../design/styleComponents/globalComponents';
import { RANKS } from '../../../constants/index';
import { useSelector } from 'react-redux';
import { getUrlSponsorSelector } from '../../../store/selectors/userSelector';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const coreTheme = {
  evolution: {
    text: '#000',
    bg: '#e7e7e7',
    highlight: '#e7e7e7',
    decal: '#469fa3',
    accent: '#469fa3',
  },
};

const UnilevelTree = (props) => {
  const [loading, setLoading] = useState(true);
  const { t, data, getNodes, parentNode, nodeVisited, viewType, onChangeType } = props;
  const urlSponsor = useSelector(getUrlSponsorSelector);

  let imageFinal = '';
  const isPromoter = parentNode.roles.find((rol) => rol === 'PROMOTER');
  // if (isPromoter) {
  if (parentNode.rank > 1) {
    imageFinal = `/assets/themes/${process.env.REACT_APP_THEME}/images/ranks/${
      RANKS[`L${parentNode.rank}`].image
    }.png`; // imagen rango
  } else {
    // if (pa
    imageFinal = `/assets/themes/${process.env.REACT_APP_THEME}/images/arca_logo.png`;
    //   imageFinal = `/assets/themes/${process.env.REACT_APP_THEME}/images/ranks/SinRango.png`;
    // } else {
    //   imageFinal = `/assets/themes/${process.env.REACT_APP_THEME}/images/ranks/Activo.png`;
    // }
  }
  // } else {
  //   if (parentNode.pointsPersonal && parentNode.pointsPersonal > 0) {
  //     imageFinal = `/assets/themes/${process.env.REACT_APP_THEME}/images/ranks/Cliente.png`;
  //   } else {
  //     imageFinal = `/assets/themes/${process.env.REACT_APP_THEME}/images/ranks/Registrado.png`;
  //   }
  // }

  const parent = {
    userUuid: parentNode?.uuid,
    name: `${parentNode?.nickname || ''}`,
    email: `${parentNode?.email || ''}`,
    image: imageFinal,
  };

  const closeLoading = () => {
    setLoading(false);
  };

  const generateData = () => {
    const nodes = [];
    if (parentNode && data) {
      const parent = {
        label: parentNode?.email,
        id: parentNode?.uuid,
        parentId: null,
        items: null,
        info: {
          promoter: isPromoter,
          maxHistoryRank: parentNode.maxHistoryRank,
          pointsPersonal: parentNode.pointsPersonal,
          rank: parentNode.lastRank,
          email: parentNode.email,
          nickname: parentNode.nickname,
          name: parentNode.name || null,
        },
      };
      nodes.push(parent);
      data.forEach((node) => {
        const temp = {
          label: node.email,
          id: node.userUuid,
          parentId: node.fatherUuid,
          items: null,
          info: node,
        };
        nodes.push(temp);
      });
    }
    return nodes;
  };
  return (
    <>
      <div className="row justify-content-center no-margin">
        <SecctionTitleContainer className="col-11 col-md-11 mt-5 mb-4">
          {t('unilevel')}
        </SecctionTitleContainer>
      </div>
      <div className="row">
        <RefererLink className="col-12 text-center">{t('myLink')}</RefererLink>
      </div>
      <div className="row justify-content-center no-margin">
        <div className="col-11 col-md-11 col-lg-6 mt-2 mb-3">
          <div className="alert alert-danger text-center" role="alert">
            <CopyToClipboard
              text={urlSponsor.url}
              onCopy={() => {
                toast(t('linkCopied'), {
                  position: 'top-right',
                  autoClose: 1500,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: false,
                  progress: undefined,
                });
              }}
            >
              <span title={t('copyLink')} className="urlSponsord">
                {urlSponsor.url} <i className="fas fa-copy"></i>
              </span>
            </CopyToClipboard>
          </div>
        </div>
      </div>
      {/* <div className="row justify-content-center no-margin">
        <ContainerLegend className="col-11 mt-1 mb-1">
          <div className="row justify-content-center no-margin">
            <Legend className="col-12 mt-1 mb-2 d-flex">{t('treeLegend')}</Legend>
          </div>
          <div className="row justify-content-center no-margin">
            <div className="col-12 mt-1 mb-1 d-flex">
              <div className="d-flex align-items-center me-4">
                <ImageLegend
                  alt="Avatar"
                  src={`/assets/themes/${process.env.REACT_APP_THEME}/images/ranks/Registrado.png`}
                ></ImageLegend>
                <div>{t('userRegistered')}</div>
              </div>
              <div className="d-flex align-items-center me-4">
                <ImageLegend
                  alt="Avatar"
                  src={`/assets/themes/${process.env.REACT_APP_THEME}/images/ranks/Activo.png`}
                ></ImageLegend>
                <div>{t('userPromoter')}</div>
              </div>
              <div className="d-flex align-items-center me-4">
                <ImageLegend
                  alt="Avatar"
                  src={`/assets/themes/${process.env.REACT_APP_THEME}/images/ranks/Cliente.png`}
                ></ImageLegend>
                <div>{t('userClient')}</div>
              </div>
              <div className="d-flex align-items-center">
                <ImageLegend
                  alt="Avatar"
                  src={`/assets/themes/${process.env.REACT_APP_THEME}/images/ranks/SinRango.png`}
                ></ImageLegend>
                <div>{t('userNoCalified')}</div>
              </div>
            </div>
          </div>
        </ContainerLegend>
      </div> */}

      <div className="row justify-content-center mt-4 no-margin">
        <div className="col-12 col-md-5 col-lg-5 marg-btm-1rem text-center">
          {/* <div>{t('simpleTree')}</div> */}
          <div className="serviceStatusContainer form-switch">
            <SwitchStatus
              statusCheck={viewType === 'rama'}
              className="serviceStatus form-check-input"
              type="checkbox"
              id="selectUnilevel"
              onChange={(event) => {
                if (event.target.checked) {
                  onChangeType('rama');
                } else {
                  onChangeType('simple');
                }
              }}
              checked={viewType === 'rama'}
            />
            <LabelStatus className="form-check-label">{t('complexTree')}</LabelStatus>
          </div>
        </div>
      </div>

      <div className="row justify-content-center no-margin">
        <div className="col-12 col-md-11 mb-5">
          {viewType === 'simple' ? (
            <SimpleTree
              nodes={generateData()}
              icon="fas fa-chevron-right"
              onSelect={getNodes}
              isLoading={loading}
              colorLoader="#469fa3"
              heightLoader={50}
              widthLoader={50}
              closeLoading={closeLoading}
              theme="evolution"
              customTheme={coreTheme}
            >
              <SimpleTreeNode firstLevel={parentNode.treeLevel}></SimpleTreeNode>
            </SimpleTree>
          ) : (
            <Tree
              nameParentId="fatherUuid"
              idNode="userUuid"
              data={data || []}
              onSelectNode={getNodes}
              parentNode={parent}
              idNodeTitle="name"
              idNodeText="email"
              idImageNode="image"
              connectorColor="#469fa3"
              nodeVisited={nodeVisited}
            >
              <UniLevelTreeNode firstLevel={parentNode.treeLevel} nodeVisited={nodeVisited} />
            </Tree>
          )}
        </div>
      </div>
    </>
  );
};

UnilevelTree.propTypes = {
  data: PropTypes.array,
  getNodes: PropTypes.func,
  parentNode: PropTypes.object,
  t: PropTypes.any,
  nodeVisited: PropTypes.array,
  onChangeType: PropTypes.func,
  viewType: PropTypes.string,
};

export default UnilevelTree;
