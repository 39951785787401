import styled, { css } from 'styled-components';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

// import { ButtonContainer } from './../../../utils/modalInfo/ModalInfo.styled';
// import { ButtonCurrencyImg } from './../../../user/wallet/wallet.styled';
export const TextPrice = styled.div`
  font-size: 0.875rem;
  color: ${({ isOffer }) => (isOffer ? '#469fa3' : '#fff')};
`;

export const TextAfterOffer = styled.div`
  color: #fff;
  font-size: 0.7rem;
`;

export const ItemContainer = styled.div`
  border: 0px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  z-index: 100;
`;

export const OfferContainer = styled.div`
  font-size: 0.7rem;
  width: 55px;
  background: #9c05c7;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  margin-bottom: 6px;
  position: absolute;
  top: 0;
  right: 0;
`;

export const NewContainer = styled.div`
  font-size: 0.7rem;
  width: 55px;
  background: #017a58;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  margin-bottom: 6px;
  position: absolute;
  top: 0;
`;

export const GallonsContainer = styled.div`
  font-size: 0.7rem;
  width: 55px;
  background: #469fa3;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  position: absolute;
  top: ${({ isNewItem }) => (isNewItem ? '32px' : '0')};
`;

export const TaxStyled = styled.span`
  font-size: 0.675rem;
  /* text-transform: capitalize; */
  line-height: 1rem;
  font-weight: normal;
  /* margin-left: 5px; */
`;

export const ProductContainer = styled.div`
  /* background-color: #fe0fe0; */
  width: 100%;
  /* border: 1px solid #fff; */
`;

export const ProductButton = styled.div`
  width: 100%;
  border: 1px solid #ccc;
`;

export const ProductTitleContainer = styled.div`
  font-size: 0.875rem;
  text-transform: capitalize;
  /* margin-top: 5px; */
  line-height: 1rem;
`;

export const ProductBasePriceContainer = styled.div`
  font-size: 0.775rem;
  text-transform: capitalize;
  margin-top: 2px;
  line-height: 0.8rem;
  font-weight: normal;
`;

export const ProductPriceContainer = styled.div`
  font-size: 0.875rem;
  text-transform: capitalize;
  /* margin-top: 3px; */
  line-height: 0.8rem;
  font-weight: bold;
`;

export const InfoItem = styled.div`
  height: 40px;
`;

export const InfoTitle = styled.div`
  min-height: 55px;
  color: #fff;
`;

export const ActionItemsContainer = styled.div`
  /* background-color: #fe0; */
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: end;
  padding: 0px 0px 10px 5px;
`;

export const ImageItem = styled.div`
  /* height: 250px; */
  /* padding-top: 8vh; */
  /* background: ${({ image }) => `url('${image}')`};
  background-color: #fff;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain; */
`;

export const ImageProductItem = styled(LazyLoadImage)`
  ${({ buyallowed }) => {
    if (buyallowed === 'true') {
      return css`
        width: 100%;
      `;
    } else {
      return css`
        width: 100%;
        filter: url(filters.svg#grayscale); /* Firefox 3.5+ */
        filter: gray; /* IE5+ */
        -webkit-filter: grayscale(1); /* Webkit Nightlies & Chrome Canary */
        -webkit-transition: all 0.5s ease-in-out;
      `;
    }
  }}
`;

export const ButtonAddCart = styled.button`
  /* width: 100%; */
  border: 0px;
  background: transparent;
  /* margin-top: 8px; */
  display: flex;
  justify-content: end;
`;

// export const ImageAddCart = styled.img`
//   /* width: 100%; */
//   border: 0px;
//   max-height: 35px;
//   background: transparent;
// `;

export const ImageAddCart = styled.i`
  /* width: 100%; */
  border: 0px;
  max-height: 35px;
  background: #469fa3;
  color: #fff;
  font-size: 1rem;
  padding: 10px;
  border-radius: 8px;
`;

export const LinkPreview = styled.a`
  color: #fff;
  border: 1px solid #fff;
  border-radius: 20px;
  width: fit-content;
  padding: 5px 20px;
  cursor: pointer;

  &:hover {
    color: #fff;
    background: #469fa3;
    text-decoration: none;
    border: 1px solid #469fa3;
  }
`;

export const LinkMoreInfo = styled.a`
  color: #fff;
  font-size: 0.8rem;
  cursor: pointer;

  &:hover {
    color: #469fa3;
    text-decoration: none;
  }
`;

export const ShortDescription = styled.span`
  color: #fff;
  font-size: 0.775rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
`;
