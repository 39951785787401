import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { ContainerSelect, LinkTimeZone, IconTimeZone } from './styled';
import * as GeneralServices from '../../../../../../services/GeneralServices';
import moment from 'moment-timezone';

const TYPE_SERVER = 'SERVER';
const TYPE_LOCAL = 'LOCAL';
const TIME_ZONE_SERVER = 'Europe/Malta';

class SelectTimeZone extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: TYPE_SERVER,
    };
  }

  componentDidMount() {
    const { timeZone } = this.props;
    let type = TYPE_SERVER;
    if (timeZone && timeZone !== TIME_ZONE_SERVER) {
      type = TYPE_LOCAL;
    }
    if (!timeZone) {
      this.changeTimeZone(type);
    } else {
      this.setState({ selected: type });
    }
  }

  changeTimeZone = (timeZoneType) => {
    if (timeZoneType === TYPE_SERVER) {
      GeneralServices.setTimeZone({ timeZone: TIME_ZONE_SERVER });
    } else {
      GeneralServices.setTimeZone({ timeZone: moment.tz.guess() });
    }
    this.setState({ selected: timeZoneType });
  };

  render() {
    const { selected } = this.state;
    const { t } = this.props;
    return (
      <ContainerSelect>
        <LinkTimeZone
          className="d-none d-md-block"
          href="#"
          onClick={() => {
            this.changeTimeZone(selected === TYPE_LOCAL ? TYPE_SERVER : TYPE_LOCAL);
          }}
        >
          <IconTimeZone className="far fa-clock" />
          {selected === TYPE_LOCAL ? t('showServerTime') : t('showLocalTime')}
        </LinkTimeZone>
        <LinkTimeZone
          className="d-block d-md-none"
          href="#"
          onClick={() => {
            this.changeTimeZone(selected === TYPE_LOCAL ? TYPE_SERVER : TYPE_LOCAL);
          }}
        >
          <IconTimeZone className="far fa-clock" />
          {selected === TYPE_LOCAL ? t('shortShowServerTime') : t('shortShowLocalTime')}
        </LinkTimeZone>
      </ContainerSelect>
    );
  }
}

export default withTranslation()(SelectTimeZone);
