import { store } from '../store/index';
import {
  getUnilevelTree,
  getAuthProfile,
  getDashBoardInfo,
  updateLanguage,
  requestWallet,
  getUpline,
  setRoleSupport,
  statusWallet,
  updateToPromoter,
  getMyWallets,
  getUserLevels,
  getMyCommissions,
  confirmContract,
  getUserDataIgnite,
  generateUserDataIgnite,
  updateDataUserIgnite,
  updateProfile,
  updateBilling,
  getUserDataDesertAnts,
  updateWaynanceId,
} from '../store/actions/UserActions';
import { ROLE_SUPPORT } from '../constants/index';

/**
 * Get auth profile info
 */
export const getAuthUserProfile = async (impersonate = false) => {
  const result = await store.dispatch(getAuthProfile());
  if (impersonate) {
    await store.dispatch(setRoleSupport({ role: ROLE_SUPPORT }));
  }
  if (result && result.payload && result.payload.data) {
    return result.payload;
  }
  return null;
};

/**
 * Get auth profile info
 */
export const getInfoDashboard = async () => {
  const result = await store.dispatch(getDashBoardInfo());
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};

/**
 * Get unilevel tree nodes
 * @param {*} userId
 */
export const getUnilevelTreeNodes = async (userId) => {
  const objSend = [userId];
  const result = await store.dispatch(getUnilevelTree(objSend));
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};

/**
 * Update preferend user language
 */
export const updatePreferendLanguage = async (lang) => {
  const objSend = {
    lang: lang,
  };
  const result = await store.dispatch(updateLanguage(objSend));
  if (result && result.payload && result.payload) {
    return 'OK';
  }
  return null;
};

/**
 * Get upline
 */
export const getUserUpline = async () => {
  const result = await store.dispatch(getUpline());
  if (result && result.payload && result.payload.data) {
    return result.payload;
  }
  return null;
};

/**
 * Get wallet
 */
export const getAddressWallet = async (currency) => {
  const param = [currency];
  const result = await store.dispatch(requestWallet(param));
  if (result?.payload?.data) {
    return result.payload.data;
  }
  return null;
};

/**
 * Get status wallet
 */
export const getStatusWallet = async (currency) => {
  const param = [currency];
  const result = await store.dispatch(statusWallet(param));
  if (result?.payload?.data) {
    return result.payload.data;
  }
  return null;
};

/**
 * Update Promoter
 * @param {*} userId
 */
export const requestPromoter = async () => {
  const result = await store.dispatch(updateToPromoter());
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};

/**
 * Get my wallet info (dashboard)
 */
export const getDashboardMyWallet = async () => {
  const result = await store.dispatch(getMyWallets());
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};

/**
 * Get my wallet info (dashboard)
 */
export const getDashboardUserLevels = async (billingIntervalUuid) => {
  const result = await store.dispatch(getUserLevels([billingIntervalUuid]));
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};

/**
 * Get my commisions
 */
export const getUserCommissions = async () => {
  const result = await store.dispatch(getMyCommissions());
  if (result?.payload?.data) {
    return result.payload;
  }
  return null;
};

/**
 * Confirm Contract promoter
 */
export const updateConfirmContract = async (values) => {
  const objSend = {
    contractReader: values.contractReader,
    acceptReader: values.acceptReader,
  };
  const result = await store.dispatch(confirmContract(objSend));
  if (result && result.payload && result.payload) {
    return 'OK';
  }
  return null;
};

/**
 * Get my commisions
 */
export const getDataIgnite = async () => {
  const result = await store.dispatch(getUserDataIgnite());
  if (result?.payload?.data) {
    return result.payload.data;
  }
  return null;
};

/**
 * Generate Data Ignite
 */
export const generateDataIgnite = async () => {
  const result = await store.dispatch(generateUserDataIgnite({}));
  if (result?.payload?.data) {
    return result.payload.data;
  }
  return null;
};

/**
 * Update data user ignite
 */
export const updateDataIgnite = async (values) => {
  const objSend = {
    email: values.email,
    phone: values.phone || '',
    dataTypeShow: values.dataTypeShow,
  };

  const result = await store.dispatch(updateDataUserIgnite(objSend));
  if (result && result.payload && result.payload.data !== undefined) {
    return result.payload.data;
  }
  return null;
};

/**
 * Update profile
 */
export const updateMyProfile = async (values) => {
  const objSend = {
    nameLocal: values.nameLocal.trim(),
    surnameLocal: values.surnameLocal.trim(),
    nicknameLocal: values.nicknameLocal.trim(),
  };
  // if (values.lang && values.lang.length > 0) {
  //   objSend.lang = values.lang;
  // }
  const result = await store.dispatch(updateProfile(objSend));
  if (result && result.payload && result.payload) {
    return 'OK';
  }
  return null;
};

/**
 * Update profile
 */
export const updateMyBillingAddress = async (values) => {
  const objSend = {
    billingAddress: values.billingAddress.trim(),
    billingProvince: values.billingProvince.trim(),
    billingCountryUuid: values.billingCountryUuid,
    billingCity: values.billingCity.trim(),
    billingCp: values.billingCp.trim(),
    billingDocument: values.billingDocument.trim(),
    billingAccept: true,
    billingContactName: values.billingContactName.trim(),
  };

  const result = await store.dispatch(updateBilling(objSend));
  if (result && result.payload && result.payload) {
    return 'OK';
  }
  return null;
};

/**
 * Get my commisions
 */
export const getDataDesertAnts = async () => {
  const result = await store.dispatch(getUserDataDesertAnts());
  if (result?.payload?.data) {
    return result.payload.data;
  }
  return null;
};

/**
 * Update waynance wallet
 */
export const updateWaynanceWallet = async (values) => {
  const objSend = {
    waynanceCode: values.waynanceCode.trim(),
    email: values.email.trim(),
  };
  const result = await store.dispatch(updateWaynanceId(objSend));
  if (result?.payload?.hasWaynanceWallet) {
    return 'OK';
  }
  return null;
};
