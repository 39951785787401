import { createAction } from 'redux-actions';
import { apiGet } from '../../api/index';
import { urlGetNews } from '../../api/urls';
import { PAGE_SIZE_10 } from '../../constants/index';

const generateFilterQuery = ({ filters, page, pageSize, sort }) => {
  let filter = '?';

  page ? (filter += 'page=' + page) : (filter += 'page=1');
  pageSize ? (filter += '&pageSize=' + pageSize) : (filter += '&pageSize=' + PAGE_SIZE_10);

  if (filters) {
    if (filters.language) filter += '&language=' + filters.language;
  }

  if (sort) filter += '&sort=' + sort;

  return filter;
};

/**
 * Filter list BotConfiguration.
 */
export const filterNews = createAction('FILTER_NEWS', (filter, param) =>
  apiGet(urlGetNews + generateFilterQuery(filter), param, true)()
);

/**
 * Get news.
 */
export const getNewsByUuid = createAction('GET_NEWS', (param) =>
  apiGet(urlGetNews, param, false)()
);
