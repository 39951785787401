import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as NotificationServices from '../../../../services/NotificationService';
import { withTranslation } from 'react-i18next';
import {
  RowText,
  Bookmark,
  RowTextBody,
  HeaderList,
  ButtonMarkAll,
  IconMarkAll,
} from './notificationList.styled';
import { AbilityContext } from '../../../permissions/Can';
import moment from 'moment-timezone';
import { InfiniteList } from '@arcaitprod/react-complex-components';
import ContainerExpandable from '../../../utils/commons/containerExpandable';
import { USER_PERMISSIONS } from '../../../permissions/rolesPermission';
import ContentLoader from 'react-content-loader';
import { checkBootstrapSizeBreak } from '../../../../utils/device/checkDevice';
import { connect } from 'react-redux';
import { getTimeZoneSelector } from '../../../../store/selectors/navSelector';

class NotificationListContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      refreshData: false,
      refreshItem: null,
      breakDevice: checkBootstrapSizeBreak(),
    };
  }

  componentDidUpdate(prevState) {
    if (prevState.refreshData !== this.state.refreshData && this.state.refreshData === true) {
      this.resetRefreshData();
    }
  }

  resetRefreshData = () => {
    this.setState({ refreshData: false });
  };

  toList = async (filter) => {
    const { general } = this.props;
    let result;
    if (general) {
      result = await NotificationServices.listGeneralFilter(filter);
    } else {
      result = await NotificationServices.listFilter(filter);
    }

    return result;
  };

  renderRowNotification = (not) => {
    return (
      <ContainerExpandable
        key={not.uuid}
        header={this.renderHeaderRow(not)}
        body={this.renderBodyRow(not)}
        classNameHeader=""
        onClickActionOpen={() => this.putNotificationReaded(not)}
      />
    );
  };

  putNotificationReaded = async (notification) => {
    const { general } = this.props;

    if (
      !general &&
      notification.readed === false &&
      this.context.can(USER_PERMISSIONS.NOTIFICATION_UPDATE)
    ) {
      const result = await NotificationServices.readedNotification(notification.uuid);
      if (result && result.uuid) {
        NotificationServices.getPendingNotification();
        this.setState({ refreshItem: result });
      }
    }
  };

  renderBodyRow = (notification) => {
    return (
      <div id={`${notification.uuid}_expanded`} className="row justify-content-center pb-3 pt-2">
        <div className="col-11">
          <RowTextBody>{notification.message}</RowTextBody>
        </div>
      </div>
    );
  };

  renderHeaderRow = (notification) => {
    const { general, t, timeZone } = this.props;
    const { breakDevice } = this.state;

    if (breakDevice <= 2) {
      return (
        <div id={notification.uuid} className="row align-items-center">
          <div className="col-5 text-center">
            <RowText readed={general ? true : notification.readed} date>
              {moment(notification.createdAt).tz('Europe/Madrid').format('DD/MM/yyyy HH:mm')}
            </RowText>
          </div>
          <div className="col-7 text-center">
            <RowText readed={general ? true : notification.readed}>{notification.message}</RowText>
          </div>
        </div>
      );
    } else {
      return (
        <div id={notification.uuid} className="row align-items-center">
          <div className="col-3 text-center">
            <RowText readed={general ? true : notification.readed} date>
              {moment(notification.createdAt).tz('Europe/Madrid').format('DD/MM/yyyy HH:mm')}
            </RowText>
          </div>
          <div className={general ? 'col-8 text-center' : 'col-6 text-center'}>
            <RowText readed={general ? true : notification.readed}>{notification.message}</RowText>
          </div>
          {!general && (
            <div className="col-3 text-center">
              <RowText>
                {notification.readed ? (
                  <Bookmark
                    className="fas fa-bookmark"
                    title={t('forms.notification.readed')}
                  ></Bookmark>
                ) : (
                  <Bookmark
                    className="far fa-bookmark"
                    title={t('forms.notification.notReaded')}
                  ></Bookmark>
                )}
              </RowText>
            </div>
          )}
        </div>
      );
    }
  };

  renderHeaderList = () => {
    const { t, general } = this.props;
    const { breakDevice } = this.state;

    if (breakDevice <= 2) {
      return (
        <HeaderList className="row align-items-center mt-2">
          <div className="col-5 text-center">{t('forms.notification.table.heads.date')}</div>
          <div className={`col-7 text-start`}>{t('forms.notification.table.heads.message')}</div>
        </HeaderList>
      );
    } else {
      return (
        <HeaderList className="row align-items-center mt-2">
          <div className="col-3 text-center">{t('forms.notification.table.heads.date')}</div>
          <div className={`col-${general ? '8' : '6'} text-start`}>
            {t('forms.notification.table.heads.message')}
          </div>
          {!general && (
            <div className="col-3 text-center">{t('forms.notification.table.heads.status')}</div>
          )}
        </HeaderList>
      );
    }
  };

  markAllReaded = async () => {
    const { general } = this.props;
    if (!general) {
      const result = await NotificationServices.readeAllNotification();
      if (result && result === 'OK') {
        NotificationServices.getPendingNotification();
        this.setState({ refreshData: true });
      }
    }
  };

  renderListLoader = () => {
    return (
      <div className="row">
        <div className="col-12 mt-4">
          <ContentLoader
            speed={2}
            viewBox="0 0 400 160"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            style={{ width: '100%' }}
          >
            <rect x="5" y="1" rx="3" ry="3" width="400" height="5" />
            <rect x="5" y="15" rx="3" ry="3" width="400" height="5" />
          </ContentLoader>
        </div>
      </div>
    );
  };

  render() {
    const { t, filters, className } = this.props;
    return (
      <>
        {this.context.can(USER_PERMISSIONS.NOTIFICATION_UPDATE) && (
          <div className="row">
            <div className="col-12 text-end mb-2">
              <ButtonMarkAll onClick={() => this.markAllReaded()}>
                <IconMarkAll className="fas fa-bookmark"></IconMarkAll>
                {t('forms.notification.markAll')}
              </ButtonMarkAll>
            </div>
          </div>
        )}

        <div className={className || ''}>
          <InfiniteList
            id="notifications"
            renderItem={this.renderRowNotification}
            toList={this.toList}
            filters={filters}
            variantTitle="primary"
            forceRefresh={this.state.refreshData}
            itemRefresh={this.state.refreshItem}
            endMessage={t('goToTop')}
            messageButtonMore={t('readMore')}
            variantButtonMore="simplePrimary"
            idItem="uuid"
            headerComponentList={this.renderHeaderList}
            renderLoader={this.renderListLoader}
          />
        </div>
      </>
    );
  }
}

NotificationListContainer.contextType = AbilityContext;

NotificationListContainer.propTypes = {
  t: PropTypes.any,
  filters: PropTypes.object,
  className: PropTypes.string,
  general: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  timeZone: getTimeZoneSelector(state),
});

export default connect(mapStateToProps, {})(withTranslation()(NotificationListContainer));
