import React from 'react';
import PropTypes from 'prop-types';
import { withFormik, Field } from 'formik';
import { withTranslation } from 'react-i18next';
import {
  InputDateFormik,
  InputSelectFormik,
  InputTextFormik,
  Autocomplete,
} from '@arcaitprod/react-forms-components';
import { dataModel, validationDataModel, setInitialValues } from './subscriptionsFilter.data.form';
import { Button } from '@arcaitprod/react-basic-components';
import { SUBSCRIPTION_STATUS } from '../../../../constants/index';
import { ButtonContainer } from './subscriptionsFilter.style';
import * as ProductService from '../../../../services/ProductService';

const SubscriptionFilterForm = (props) => {
  const { t, handleSubmit, filtered, onClean } = props;

  const onProductSearch = async (value, onLoad = false) => {
    if (value) {
      const filters = { filters: { title: value } };
      const suggestions = await ProductService.listFilterAll(filters);
      if (suggestions && suggestions.length > 0) {
        return suggestions;
      }
    }
    return [];
  };

  const renderProduct = (packageItem) => {
    return <>{packageItem.title.toUpperCase()}</>;
  };

  return (
    <>
      <form className="form-horizontal" onSubmit={handleSubmit} autoComplete="off">
        <div className="row justify-content-end">
          <div className="col-12 col-md-6 col-lg-4">
            <Field
              id="packageUuid"
              className="form-control"
              name="packageUuid"
              mandatory
              component={Autocomplete}
              fieldType="text"
              onSearch={onProductSearch}
              optionValue="uuid"
              optionLabel="title"
              textButtonReset={t('reset')}
              renderItems={renderProduct}
              placeholder={t('forms.mySubscription.labels.product')}
            />
          </div>
          <div className="col-12 col-md-8 col-lg-4 d-flex justify-content-between">
            <Field
              id="active"
              name="active"
              component={InputSelectFormik}
              placeholder=""
              optionLabel="label"
              optionValue="code"
              options={SUBSCRIPTION_STATUS.map((item) => ({
                ...item,
                label: t(item.label),
              }))}
              classNamePrefix="dark"
              classNameContainer="flex-grow-1"
            />
            <ButtonContainer>
              <Button type="submit" className="btn btn-md me-2" variant="primary">
                <i className="fas fa-search"></i>
              </Button>
              {/* {filtered && ( */}
              <Button
                type="button"
                className="btn btn-md"
                variant="secondary"
                onClick={() => onClean(props.resetForm)}
                title={t('cleanFilter')}
              >
                <i className="fas fa-trash"></i>
              </Button>
            </ButtonContainer>
          </div>
        </div>
      </form>
    </>
  );
};

SubscriptionFilterForm.propTypes = {
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  onClean: PropTypes.func,
  filtered: PropTypes.bool,
};

const SubscriptionFilterFormF = withFormik({
  mapPropsToValues: (props) => {
    if (props.initialValues) {
      return setInitialValues(props.initialValues);
    }
    return dataModel;
  },
  validationSchema: validationDataModel,
  handleSubmit: (values, { props, resetForm }) => {
    props.onSubmit(values, resetForm);
  },
  enableReinitialize: true,
  displayName: 'SubscriptionFilterForm',
})(withTranslation()(SubscriptionFilterForm));

export default SubscriptionFilterFormF;
