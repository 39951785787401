import styled from 'styled-components';

export const TextStyled = styled.span`
  font-size: 0.7rem;
  width: 100%;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.primary};
  letter-spacing: 1px;
  text-transform: uppercase;
`;

export const AmountStyled = styled.span`
  font-size: 0.9rem;
  font-weight: bold;
  width: 100%;
  color: ${({ theme }) => theme.colors.primary};
`;

export const IconStyled = styled.span`
  font-size: 0.75rem;
`;

export const IconTooltipStyled = styled.span`
  font-size: 0.6rem;
`;

export const RowText = styled.div`
  margin: 0px;
  height: 40px;
`;

export const RowGraph = styled.div`
  margin: 0px;
  height: ${(prop) => (prop.grahpHeight ? `${prop.grahpHeight}px` : '200px')};
`;
