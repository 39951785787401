import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import BillingAddressForm from './myBillingAddress.view';
import { withTranslation } from 'react-i18next';
// import * as UserService from '../../../services/UserService';
import { getDataBilling } from '../../../store/selectors/authSelector';
import * as UserService from '../../../services/UserService';
import { showDialog } from '@arcaitprod/react-utils-components';
import * as GeneralServices from '../../../services/GeneralServices';
import PATHS from '../../../constants/paths';

class MyBillingAddressContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nationalities: undefined,
      values: null,
    };
  }

  componentDidMount() {
    GeneralServices.getAllNationalities().then((result) => {
      if (result && result.length > 0) {
        this.setState({ nationalities: result });
      }
    });
  }

  handleSubmit = async (values) => {
    try {
      const result = await UserService.updateMyBillingAddress(values);
      let title = '';
      let text = '';
      let icon = '';
      if (result && result === 'OK') {
        await UserService.getAuthUserProfile();
        title = this.props.t('forms.myProfile.messages.update.title');
        text = this.props.t('forms.myProfile.messages.update.success');
        icon = 'success';
      } else {
        title = 'Error';
        text = this.props.t('forms.myProfile.messages.update.error');
        icon = 'error';
      }
      showDialog(
        title,
        text,
        icon,
        false,
        'OK',
        false,
        undefined,
        undefined,
        undefined,
        '#E83C7E',
        '#12374e'
      );
      this.props.history.push(PATHS.HOME);
    } catch (error) {
      //console.log('Error', error);
    }
  };

  onBack = () => {
    this.props.history.push(PATHS.HOME);
  };

  render() {
    return (
      <BillingAddressForm
        accepted={this.props.info?.billingAccept || null}
        initialValues={this.props.info}
        onSubmit={this.handleSubmit}
        nationalities={this.state.nationalities || []}
        onBack={this.onBack}
      />
    );
  }
}

MyBillingAddressContainer.propTypes = {};

const mapStateToProps = (state) => ({
  info: getDataBilling(state),
});

export default withRouter(
  connect(mapStateToProps, {})(withTranslation()(MyBillingAddressContainer))
);
