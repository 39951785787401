import React from 'react';
import PropTypes from 'prop-types';
import { withFormik, Field } from 'formik';
import { withTranslation } from 'react-i18next';
import {
  InputTextFormik,
  InputSelectFormik,
  InputCheckFormik,
} from '@arcaitprod/react-forms-components';
import { dataModel, validationDataModel, setInitialValues } from './myBillingAddress.data.form';
import { ButtonsContainer } from './myBillingAddress.styled';
import { Button } from '@arcaitprod/react-basic-components';

const MyProfileForm = (props) => {
  const { t, handleSubmit, nationalities, accepted, onBack } = props;

  return (
    <>
      <div className="row">
        <div className="col-12 mb-5">
          <form className="form-horizontal bordered-row" onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-12 col-sm-12 col-lg-12">
                {!accepted && (
                  <div className="row">
                    <div className="col-12 mb-4">
                      <h3>{t('titleConfirmBillingAddress')}</h3>
                    </div>
                  </div>
                )}

                <div className="row">
                  <div className="col-12 mb-4">{t(accepted ? '' : 'confirmBillingAddress')}</div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <Field
                      id="billingContactName"
                      className="form-control"
                      labelField={t('forms.address.labels.contactName')}
                      name="billingContactName"
                      placeholder=""
                      component={InputTextFormik}
                      fieldType="text"
                      mandatory
                      readOnly={accepted || false}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <Field
                      id="billingDocument"
                      className="form-control"
                      labelField={t('forms.address.labels.document')}
                      name="billingDocument"
                      placeholder=""
                      component={InputTextFormik}
                      fieldType="text"
                      mandatory
                      readOnly={accepted || false}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <Field
                      id="billingAddress"
                      className="form-control"
                      labelField={t('forms.address.labels.address')}
                      name="billingAddress"
                      placeholder={t('forms.address.labels.placeHolderLine1')}
                      component={InputTextFormik}
                      fieldType="text"
                      mandatory
                      readOnly={accepted || false}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <Field
                      id="billingCountryUuid"
                      labelField={t('forms.address.labels.country')}
                      name="billingCountryUuid"
                      component={InputSelectFormik}
                      placeholder=""
                      optionLabel="visualName"
                      optionValue="uuid"
                      options={nationalities}
                      classNamePrefix="dark"
                      mandatory
                      readOnly={accepted || false}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">
                    <Field
                      id="billingCp"
                      className="form-control"
                      labelField={t('forms.address.labels.cp')}
                      name="billingCp"
                      placeholder=""
                      component={InputTextFormik}
                      fieldType="text"
                      mandatory
                      readOnly={accepted || false}
                    />
                  </div>
                  <div className="col-8">
                    <Field
                      id="billingCity"
                      className="form-control"
                      labelField={t('forms.address.labels.city')}
                      name="billingCity"
                      placeholder=""
                      component={InputTextFormik}
                      fieldType="text"
                      mandatory
                      readOnly={accepted || false}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <Field
                      id="billingProvince"
                      className="form-control"
                      labelField={t('forms.address.labels.province')}
                      name="billingProvince"
                      placeholder=""
                      component={InputTextFormik}
                      fieldType="text"
                      mandatory
                      readOnly={accepted || false}
                    />
                  </div>
                </div>
                {!accepted && (
                  <>
                    <div className="row">
                      <div className="col-12">
                        <Field
                          id="billingAccept"
                          labelField={t('billingAddressAccept')}
                          name="billingAccept"
                          component={InputCheckFormik}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <ButtonsContainer className="col-10 col-sm-10 containerButtons">
                        <Button type="submit" className="btn btn-md" variant="primary">
                          {t('forms.myProfile.buttons.save')}
                        </Button>
                        <Button
                          type="button"
                          className="btn btn-md"
                          variant="primary"
                          onClick={() => onBack()}
                        >
                          {t('forms.myProfile.buttons.cancel')}
                        </Button>
                      </ButtonsContainer>
                    </div>
                  </>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

MyProfileForm.propTypes = {
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  userInfo: PropTypes.object,
};

const MyProfileFormF = withFormik({
  mapPropsToValues: (props) => {
    if (props.initialValues) {
      return setInitialValues(props.initialValues);
    }
    return dataModel;
  },
  validationSchema: validationDataModel,
  handleSubmit: (values, { props }) => {
    props.onSubmit(values);
  },
  enableReinitialize: true,
  displayName: 'MyProfileForm',
})(withTranslation()(MyProfileForm));

export default MyProfileFormF;
