import React from 'react';
import BillingAddress from '../../../components/user/myBillingAddress';
import Layout from '../../../components/layouts/settingsLayout';

const UserSettingsPage = () => {
  return (
    <Layout
      showBanner
      bannerName="ajustes.jpg"
      textBanner="settings"
      bannerPosition="60"
      active="billinAddress"
      body={
        <>
          <BillingAddress />
        </>
      }
    />
  );
};

UserSettingsPage.propTypes = {};

export default UserSettingsPage;
