import { handleActions } from 'redux-actions';

import {
  GET_INFO_DASHBOARD,
  GET_DASHBOARD_USER_LEVELS,
  CLEAN_DASHBOARD,
} from '../../constants/index';

export const dashboard = handleActions(
  {
    [CLEAN_DASHBOARD]: () => ({}),
    [GET_INFO_DASHBOARD]: (state, action) => ({
      ...state,
      info: action.payload.data,
    }),
    [GET_DASHBOARD_USER_LEVELS]: (state, action) => ({
      ...state,
      userLevel: action.payload.data,
    }),
  },
  {}
);
