import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import UserStatus from './UserStatus.view';
import { getUserStatusSelector } from '../../../../store/selectors/dashboardSelector';
import ContentLoader from 'react-content-loader';
import * as UserServices from '../../../../services/UserService';

const UserStatusContainer = (props) => {
  const { volume } = props;
  const dataCache = useSelector(getUserStatusSelector);
  const [data, setData] = useState(null);

  useEffect(() => {
    let cancel = false;

    setData(dataCache);

    return () => {
      cancel = true;
    };
  }, [dataCache]);

  useEffect(() => {
    let cancel = false;

    if (volume) {
      setData(dataCache);
    }

    return () => {
      cancel = true;
    };
  }, [dataCache]);

  const renderListLoader = () => {
    return (
      <div className="row">
        <div className="col-12 mt-4">
          <ContentLoader
            speed={1}
            viewBox="0 0 400 160"
            backgroundColor="#0000005c"
            foregroundColor="#1919195c"
            style={{ width: '100%' }}
          >
            <rect x="5" y="1" rx="3" ry="3" width="300" height="5" />
            {/* <rect x="5" y="15" rx="3" ry="3" width="350" height="5" /> */}
          </ContentLoader>
        </div>
      </div>
    );
  };

  return <>{data ? <UserStatus data={data}></UserStatus> : <>{renderListLoader()}</>}</>;
};

UserStatusContainer.propTypes = {
  volume: PropTypes.bool,
};

export default UserStatusContainer;
