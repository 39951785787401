import { handleActions } from 'redux-actions';

import {
  ADD_SHOPPING_CART,
  CLEAN_SHOPPING_CART,
  REMOVE_SHOPPING_CART,
  CHANGE_AMOUNT_SHOPPING_CART,
  LOCAL_SYNC_SHOPPING_CART,
  SYNC_SHOPPING_CART,
  SET_PURCHASE,
} from '../../constants/index';

export const shoppingCart = handleActions(
  {
    [CLEAN_SHOPPING_CART]: (state) => ({ ...state, items: [] }),
    [SET_PURCHASE]: (state, action) => {
      return {
        ...state,
        lastPurchase: action.payload.data,
      };
    },
    [ADD_SHOPPING_CART]: (state, action) => {
      let items = [];
      let cartUuid = state.uuid;
      if (action.payload.data === 'Unauthorized') {
        return {
          ...state,
        };
      }
      console.log('action.payload.data', action.payload.data);
      if (action.payload.data) {
        items = action.payload.data.items;
        cartUuid = action.payload.data.uuid || cartUuid; // Update cart UUID if provided
      } else {
        items = [...state.items];
        const index = items.findIndex((item) => item.packageUuid === action.payload.packageUuid);
        if (index >= 0) {
          items[index].amount += 1;
        } else {
          items.push(action.payload);
        }
      }
      return {
        ...state,
        items: items,
        uuid: cartUuid,
      };
    },
    [SYNC_SHOPPING_CART]: (state, action) => {
      const items = action.payload?.data?.items || [];
      const cartUuid = action.payload?.data?.uuid || state.uuid; // Preserve existing UUID if not provided
      return {
        ...state,
        items: items,
        uuid: cartUuid,
      };
    },
    [REMOVE_SHOPPING_CART]: (state, action) => {
      console.log('action.payload.data', action.payload.data);
      let indexItemRemove = null;
      const items = [...state.items];
      let cartUuid = state.uuid;
      if (action.payload.data === 'Unauthorized') {
        return {
          ...state,
          items: [],
        };
      }
      if (action.payload.data && action.payload.data.uuid) {
        indexItemRemove = state.items.findIndex((item) => item.uuid === action.payload.data.uuid);
        cartUuid = action.payload.data.cartUuid || cartUuid; // Update cart UUID if provided
      } else {
        indexItemRemove = action.payload.index;
      }
      if (indexItemRemove !== null && indexItemRemove > -1) {
        items.splice(indexItemRemove, 1);
        return { ...state, items: items, uuid: cartUuid };
      }
      return { ...state, uuid: cartUuid };
    },
    [CHANGE_AMOUNT_SHOPPING_CART]: (state, action) => {
      console.log('action.payload.data', action.payload.data);
      let indexItemChange = null;
      const items = [...state.items];
      let cartUuid = state.uuid;
      if (action.payload.data === 'Unauthorized') {
        return {
          ...state,
        };
      }
      if (action.payload.data && action.payload.data.uuid) {
        indexItemChange = state.items.findIndex((item) => item.uuid === action.payload.data.uuid);
        cartUuid = action.payload.data.cartUuid || cartUuid; // Update cart UUID if provided
      } else {
        indexItemChange = action.payload.index;
      }
      if (indexItemChange !== null && indexItemChange > -1) {
        items[indexItemChange].amount =
          action.payload.data && action.payload.data.amount
            ? action.payload.data.amount
            : action.payload.value;
        return { ...state, items: items, uuid: cartUuid };
      }
      return { ...state, uuid: cartUuid };
    },
    [LOCAL_SYNC_SHOPPING_CART]: (state, action) => ({
      ...state,
      items: action.payload.items,
      uuid: action.payload.uuid || state.uuid,
    }),
  },
  {
    items: [],
    uuid: null, // Initialize uuid in the initial state
  }
);
