import styled from 'styled-components';

export const Icon = styled.i`
  margin-top: 30px;
  font-size: 80px;
  color: #f27474;
`;

export const Title = styled.div`
  position: relative;
  max-width: 100%;
  margin: 0 0 0.4em;
  padding: 0;
  color: #595959;
  font-size: 1.875em;
  font-weight: 600;
  text-align: center;
  text-transform: none;
  word-wrap: break-word;
  color: #fff;
  margin-top: 30px;
`;

export const Text = styled.div`
  font-size: 1.125em;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  color: #fff;
`;

export const Counter = styled.div`
  font-size: 1.125em;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  color: #e10a41;
  margin-top: 30px;
`;

export const ButtonContainer = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
`;
