import { store } from '../store/index';
import { filterCashFlows, getCashFlowsMoreInfo } from '../store/actions/CashFlowsActions';

/**
 * List cashflows
 */
export const listFilter = async (filter) => {
  const result = await store.dispatch(filterCashFlows(filter));
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};

/**
 * List cashflows
 */
export const moreInfo = async (uuid) => {
  const param = [uuid];
  const result = await store.dispatch(getCashFlowsMoreInfo(param));
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};
