/* eslint-disable indent */
import React from 'react';
import DesertAnts from '../../../components/others/desertAnts';
import Layout from '../../../components/layouts/verticalLayout';

const DesertAntsPage = (props) => {
  return (
    <Layout
      body={
        <>
          <DesertAnts />
        </>
      }
    />
  );
};

DesertAntsPage.propTypes = {};

export default DesertAntsPage;
